import { Dispatch, SetStateAction } from 'react'
import { SignatureStyle, SignatureType } from '@/types/constants'
import { GetListSignatureMarksResponse } from '@/hooks/api/administration/profile/user/signatures/useSignaturesApi.types'
import {
  DocumentType,
  ReviewEnvelopeDataResponse
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { UseTableResponse } from '@/hooks/useTable/useTable'
import { MarkType } from '@/molecules/PdfViewer/PdfViewer.types'

export type Props = {
  children?: React.ReactNode
}

export enum Section {
  SignDocument = 'signDocument',
  ValidationMFA = 'validationMFA',
  FeedbackSigned = 'feedbackSigned',
  FeedbackRejected = 'feedbackRejected',
  FeedbackFailed = 'feedbackFailed',
  FeedbackEditing = 'feedbackEditing'
}

export enum MFAStatus {
  Initial = 'initial',
  Pending = 'pending',
  Resent = 'resent',
  Inserted = 'inserted',
  Failed = 'failed'
}

export type TStatusMFA = {
  emailCode: MFAStatus
  smsCode: MFAStatus
  passcode: MFAStatus
}

export type TStatusMFAKey = keyof TStatusMFA

type ValidationSchemaType = {
  title: string
  resend: string | null
  sendTo: string | null
  type: string
  isLastItem: boolean
  autoFocus: boolean
  tooltipInfo: string
  tooltipResent: string | null
  isDisabled?: boolean
  passcodeHint?: string | null
}

export type DataValidationMFAType = {
  emailCode: string | undefined
  smsCode: string | undefined
  passcode: string | undefined
}

export type OpenActionModalType = {
  open: boolean
  type:
    | 'signatureList'
    | 'rubricList'
    | 'createSignature'
    | 'createRubric'
    | 'acceptTerms'
    | 'rejectEnvelope'
    | null
}

export type IsDownloadingDocumentType = {
  loading: boolean
  type: 'certificateEnvelope' | 'originalDocument' | null
}

export type ReviewEnvelopeContextType = {
  dataReviewEnvelope: ReviewEnvelopeDataResponse | null | undefined
  currentSection: Section
  setCurrentSection: (section: Section) => void
  loadingPdf: boolean
  setLoadingPdf: (value: boolean) => void
  acceptTerms: boolean
  setAcceptTerms: (value: boolean) => void
  marks: MarkType[]
  activeMark: MarkType | null
  openActionModal: OpenActionModalType
  setOpenActionModal: Dispatch<SetStateAction<OpenActionModalType>>
  rejectHandler: (data: {
    reason?: string
    recaptchaToken: string
  }) => Promise<void>
  signHandler: () => Promise<void>
  onDialogClose: (value: DialogResultType) => void
  marksRefs: React.MutableRefObject<{
    [key: string]: React.RefObject<HTMLElement>
  }>
  mfaValidationSchema: {
    emailCode: ValidationSchemaType
    smsCode: ValidationSchemaType
    passcode: ValidationSchemaType
  } | null
  resendMFA: (
    key: TStatusMFAKey,
    recaptchaToken: string,
    isInitial?: boolean
  ) => Promise<void>
  validateMFA: (recaptchaToken: string) => void
  isValidateButtonDisabled: boolean
  currentSigner: ReviewEnvelopeDataResponse['signers'][0] | undefined
  mfaData: DataValidationMFAType
  setMfaData: Dispatch<SetStateAction<DataValidationMFAType>>
  setMfaStatus: Dispatch<SetStateAction<TStatusMFA>>
  tourOrderId: number
  setTourOrderId: Dispatch<SetStateAction<number>>
  nextSignMark: MarkType | undefined
  sendInitialMFA: () => void
  isLoadingApproveEnvelope: boolean
  isLoadingDataReviewEnvelope: boolean
  markClickHandler: (item: MarkType, edit?: boolean) => void
  deleteMarkHandler: (id: string) => void
  navigateToMark: (markId: string) => void
  alreadySigned: boolean
  isSandboxEnvelope: boolean | null
  isLoadingSkeleton: boolean
  sectionEnvelopeInfo: {
    title: string
    items: {
      title: string
      value: string | JSX.Element
    }[]
  }
  sectionEnvelopeViewDocuments: {
    title: string
    items?: {
      title: JSX.Element
      value: JSX.Element
    }[]
    emptyState: boolean
    emptyStateTitle: string
    emptyStateMessage: string
  }
  sectionEnvelopeSigners: {
    title: JSX.Element
    items?: {
      title: JSX.Element
      value: JSX.Element
    }[]
    emptyState: boolean
    emptyStateTitle: string
    emptyStateMessage: string
  }
  sectionEnvelopeTimeline: {
    title: JSX.Element
    items: {
      user: string
      action: string
      time: string
      icon: JSX.Element
      classNameIcon?: string
    }[]
    emptyState: boolean
    emptyStateTitle: string
    emptyStateMessage: string
  }
  navigateToNextSignMark: () => void
  documents: DocumentType[] | null
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  isLoadingRejectEnvelope: boolean
  tablePaginationSignature: UseTableResponse
  tablePaginationRubric: UseTableResponse
  dataListSignature: {
    data: GetListSignatureMarksResponse | null | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  dataListRubric: {
    data: GetListSignatureMarksResponse | null | undefined
    isLoading: boolean
    isSuccess: boolean
  }
}

export type ErrorWithResponse = {
  response?: {
    status: number
    [key: string]: any
  }
  [key: string]: any
}

export type DialogResultType = {
  signatureId?: string
  signatureType?: SignatureType.Rubric | SignatureType.Signature
  signatureStyle?:
    | SignatureStyle.Draw
    | SignatureStyle.Simulated
    | SignatureStyle.Upload
  signatureImage?: string
  signatureText?: string
  fontTypedSignature?: string
  saveMyProfile?: boolean
}

export type ApproveEnvelopeDataType = {
  signatureMarkId: string | null
  signatureMarkBase64: string | null
  rubricMarkId: string | null
  rubricMarkBase64: string | null
}

export type RecaptchaTokenType = {
  preReview: string | null
  review: string | null
}
