import React, { useMemo } from 'react'
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Draggable from 'react-draggable'
import useTranslation from '@/hooks/useTranslation'
import { SignatureType } from '@/types/constants'
import { MarkType } from '../PdfViewer/PdfViewer.types'
import { HEIGHT_MARK, WIDTH_MARK } from '@/constants/sizesPdf'
import { cn } from '@/lib/utils'

type FieldPropsType = {
  mark: MarkType
  onClick: (mark: MarkType, edit?: boolean) => void
  onDelete: (id: string) => void
  scaleFactor: number
  nextMarkSign: boolean
}

const SignableMark = React.forwardRef<HTMLDivElement, FieldPropsType>(
  ({ mark, onClick, onDelete, scaleFactor, nextMarkSign }, ref) => {
    const { t } = useTranslation('reviewEnvelope')

    const isSignature =
      mark.type === SignatureType.Signature ||
      mark.type === SignatureType.Rubric

    const sizes = useMemo(
      () => ({
        width: WIDTH_MARK * scaleFactor,
        height: HEIGHT_MARK * scaleFactor,
        fontSize: 12 * scaleFactor,
        heightSignature: 40 * scaleFactor,
        iconSize: 18 * scaleFactor,
        buttonSize: 25 * scaleFactor,
        buttonPadding: 5 * scaleFactor,
        buttonActionRounded: 5 * scaleFactor,
        markRounded: 5 * scaleFactor
      }),
      [scaleFactor]
    )

    const handleMarkClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (!mark.signature) {
        onClick(mark)
      }
      event.preventDefault()
      event.stopPropagation()
    }

    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onDelete(mark.id)
      event.preventDefault()
      event.stopPropagation()
    }

    const handleEditClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      edit = false
    ) => {
      onClick(mark, edit)
      event.preventDefault()
      event.stopPropagation()
    }

    const position = useMemo(() => {
      return {
        x: mark.x * scaleFactor,
        y: mark.y * scaleFactor
      }
    }, [mark.x, mark.y, scaleFactor])

    return (
      <Draggable
        nodeRef={ref as any}
        bounds="parent"
        position={{ x: position.x, y: position.y }}
        cancel="div"
      >
        <div
          onClick={handleMarkClick}
          ref={ref}
          className={cn(
            'absolute top-0 left-0 m-auto select-none flex items-center justify-center',
            isSignature ? 'cursor-pointer' : 'cursor-not-allowed',
            mark.signature ? 'border border-secondary-100 rounded-sm' : '',
            nextMarkSign ? 'bg-gray-200 border border-secondary-200' : ''
          )}
          id={mark.id}
          style={{
            width: `${sizes.width}px`,
            height: `${sizes.height}px`
          }}
        >
          {nextMarkSign && (
            <span className="absolute flex w-3 h-3 -left-1.5 -top-1.5">
              <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-secondary"></span>
              <span className="relative inline-flex w-3 h-3 rounded-full bg-secondary-600"></span>
            </span>
          )}
          {mark.signature && (
            <>
              <div
                className="absolute flex flex-col"
                style={{
                  left: `-${sizes.buttonSize}px`
                }}
              >
                <button
                  className="bg-indigo-100"
                  onClick={(e) => handleEditClick(e, true)}
                  style={{
                    padding: `${sizes.buttonPadding}px`,
                    borderTopRightRadius: `${sizes.buttonActionRounded}px`,
                    borderTopLeftRadius: `${sizes.buttonActionRounded}px`
                  }}
                >
                  <PencilSquareIcon
                    className="text-secondary-700"
                    style={{
                      width: `${sizes.iconSize}px`,
                      height: `${sizes.iconSize}px`
                    }}
                  />
                </button>
                <button
                  className="bg-indigo-100"
                  onClick={handleDeleteClick}
                  style={{
                    padding: `${sizes.buttonPadding}px`,
                    borderBottomRightRadius: `${sizes.buttonActionRounded}px`,
                    borderBottomLeftRadius: `${sizes.buttonActionRounded}px`
                  }}
                >
                  <XMarkIcon
                    className="text-secondary-700"
                    style={{
                      width: `${sizes.iconSize}px`,
                      height: `${sizes.iconSize}px`
                    }}
                  />
                </button>
              </div>
              <div className="flex items-center w-full h-full text-center">
                {mark.signature.signatureImage && (
                  <img
                    // style={{ height: `${sizes.heightSignature}px` }}
                    src={mark.signature.signatureImage}
                    alt={t?.signableMark?.signature}
                  />
                )}
              </div>
            </>
          )}
          {!mark.signature && (
            <div
              className={`m-auto p-1 flex justify-center items-center overflow-hidden w-full h-full bg-gray-100 border border-gray-200 text-gray-500`}
            >
              <p
                className={`text-center font-normal md:font-bold p-1 ${
                  nextMarkSign ? 'text-secondary' : ''
                }`}
                style={{ fontSize: `${sizes.fontSize}px` }}
              >
                {mark.type === SignatureType.Signature
                  ? t?.signableMark?.clickToSign
                  : t?.signableMark?.clickToSignRubric}
              </p>
            </div>
          )}
        </div>
      </Draggable>
    )
  }
)

SignableMark.displayName = 'SignableMark'

export default SignableMark
