import React, { FC, useCallback, useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import { Label } from '@/ui/atoms/shadcn'

export type ToggleButtonProps = {
  key: string
  label: string
}

type ToggleButtonGroupProps = {
  label?: string
  buttons: ToggleButtonProps[]
  currentButton?: string
  onToggle: (button: string) => void
  disabled?: boolean
  showSkeleton?: boolean
  timeoutSkeleton?: number
  classNameContainer?: string
  classNameButton?: string
  variant?: 'primary' | 'secondary'
}

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  label,
  buttons,
  currentButton,
  onToggle,
  disabled,
  showSkeleton = false,
  timeoutSkeleton,
  classNameContainer,
  classNameButton,
  variant = 'primary'
}) => {
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoadingSkeleton(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const handleClick = useCallback(
    (key: string) => {
      if (key === currentButton) return
      onToggle(key)
    },
    [currentButton, onToggle]
  )

  const baseButtonClass =
    'px-4 py-2 text-sm font-medium border focus:z-10 focus:ring-1 text-gray-600 hover:border-gray-400'

  const primaryButtonClass =
    'bg-primary-100 dark:bg-primary-700 border-primary-700 text-primary-700'
  const secondaryButtonClass =
    'bg-secondary-100 dark:bg-secondary-700 border-secondary-700 text-secondary-700 dark:text-accent-900'

  const activeButtonClass =
    variant === 'primary' ? primaryButtonClass : secondaryButtonClass

  if (showSkeleton || isLoadingSkeleton) {
    return (
      <div className="flex flex-col flex-1 w-full gap-1">
        {label && <div className="w-20 h-3 md:h-4 skeleton" />}
        <div className="w-56 h-10 skeleton"></div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative flex flex-col w-fit',
        label && 'gap-1',
        classNameContainer
      )}
    >
      {label && <Label>{label}</Label>}
      <div
        className={cn(
          'flex h-10 rounded-md shadow-sm w-full',
          label ? 'mt-1' : ''
        )}
        role="group"
      >
        {buttons?.map((button, index) => (
          <button
            key={`button-${button.key}-${index}`}
            type="button"
            className={cn(
              baseButtonClass,
              index === 0 ? 'rounded-l-md' : '',
              index === buttons.length - 1 ? 'rounded-r-md' : '',
              button.key === currentButton
                ? activeButtonClass
                : 'border-gray-300',
              disabled ? 'opacity-50 cursor-not-allowed' : '',
              classNameButton
            )}
            onClick={() => handleClick(button.key)}
            disabled={disabled}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ToggleButtonGroup
