import React, { memo, useCallback, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import useTranslation from '@/hooks/useTranslation'
import { PageSizeEnvelopeType } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { cn } from '@/lib/utils'
import { useEnvelope } from '@/providers'
import { Select } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { DrawerEnvelope } from '@/ui/molecules'

const PdfViewer = dynamic(
  () => {
    return import('@/molecules/PdfViewer')
  },
  { ssr: false }
)

const SectionSignDocumentArea: React.FC = () => {
  const { t } = useTranslation('envelope')
  const [valueSelectedDocument, setValueSelectedDocument] = useState<any>(null)
  const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const {
    document,
    setSelectedSignerMarkData,
    setLoadingPdfByDocumentId,
    valuesSelectDocuments,
    documents,
    setDocument
  } = useEnvelope()

  useEffect(() => {
    if (valuesSelectDocuments && !valueSelectedDocument && !hasBeenUpdated) {
      const newValue = documents[0]?.name

      if (newValue === valueSelectedDocument) return
      setLoadingPdfByDocumentId({
        documentId: documents[0]?.id as string,
        loading: true
      })
      setValueSelectedDocument(newValue)
      const selectedDocument = documents?.find(
        (file) => file.id === documents[0]?.id
      )

      setDocument({
        id: selectedDocument?.id as string,
        index: selectedDocument?.index as number,
        name: selectedDocument?.name as string,
        privateDescription: selectedDocument?.privateDescription as string,
        publicDescription: selectedDocument?.publicDescription as string,
        pageSizes: selectedDocument?.pageSizes as PageSizeEnvelopeType[]
      })
    }
  }, [
    documents,
    hasBeenUpdated,
    setDocument,
    setLoadingPdfByDocumentId,
    valueSelectedDocument,
    valuesSelectDocuments
  ])

  const handleClickCardSignMark = useCallback(
    (signer) => {
      setSelectedSignerMarkData(signer)
      setIsDrawerOpen(false)
    },
    [setSelectedSignerMarkData]
  )

  return (
    <div className="w-full h-full z-20 overflow-y-scroll overflow-x-hidden md:w-[calc(100%-375px)] lg:w-[calc(100%-475px)] xl:w-[calc(100%-560px)] md:h-containerFull md:min-h-screen md:bg-gray-100 md:fixed md:right-0 md:shadow-insetUploadEnvelope md:pt-2">
      <div className="flex flex-col justify-center w-full h-auto gap-2 p-2 bg-gray-100 md:bg-transparent sm:flex-row">
        {valuesSelectDocuments.length > 1 && (
          <Select
            id="select-document"
            label={t?.signDocumentArea?.selectLabel}
            placeholder={t?.signDocumentArea?.selectPlaceholder}
            options={valuesSelectDocuments}
            value={valueSelectedDocument}
            onChange={(value) => {
              setValueSelectedDocument(value)
              setHasBeenUpdated(true)
              const selectedDocument = documents?.find(
                (file) => file.name === value
              )

              if (document?.id !== selectedDocument?.id) {
                setDocument({
                  id: selectedDocument?.id as string,
                  index: selectedDocument?.index as number,
                  name: selectedDocument?.name as string,
                  privateDescription:
                    selectedDocument?.privateDescription as string,
                  publicDescription:
                    selectedDocument?.publicDescription as string,
                  pageSizes:
                    selectedDocument?.pageSizes as PageSizeEnvelopeType[]
                })

                setLoadingPdfByDocumentId({
                  documentId: selectedDocument?.id as string,
                  loading: true
                })
              }
            }}
            className="w-full max-w-md md:w-full"
          />
        )}
        <Button
          onClick={() => setIsDrawerOpen(true)}
          className={`md:hidden ${
            valuesSelectDocuments.length > 1 ? 'w-full sm:w-fit' : ''
          }`}
        >
          {t?.signDocumentArea?.buttonCreateSignMark}
        </Button>
      </div>
      <PdfViewer />
      <DrawerEnvelope
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        handleClickCardSignMark={handleClickCardSignMark}
      />
    </div>
  )
}

export default memo(SectionSignDocumentArea)
