import React, { useCallback, useMemo } from 'react'
import { BellIcon } from '@radix-ui/react-icons'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import ButtonWithTooltip from '../ButtonWithTooltip'
import useNotificationsApi from '@/hooks/api/platform/useNotificationsApi/useNotificationsApi'
import { NotificationType } from '@/hooks/api/platform/useNotificationsApi/useNotificationsApi.types'
import { Avatar, Typography } from '@/ui/atoms'
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/atoms/shadcn'
import { formatTimeDistance } from '@/utils'

const Notifications: React.FC = () => {
  const { t, isReady } = useTranslation(['headerOptionGroup', 'header'], true)
  const { t: tDate } = useTranslation('date')
  const { push } = useRouter()
  const { useGetListNotifications, useMarkAsRead } = useNotificationsApi()
  const {
    data: dataGetListNotifications,
    isLoading: isLoadingGetListNotifications
  } = useGetListNotifications()
  const { mutateAsync: mutateAsyncMarkAsRead } = useMarkAsRead()
  const { breakpoint } = useViewport()

  const handleClickNotification = useCallback(
    (notification: NotificationType) => {
      if (notification.type === 'InternalInvite') {
        mutateAsyncMarkAsRead({ id: notification.id })
        push('/profile/pending-invitations')
      }
    },
    [mutateAsyncMarkAsRead, push]
  )

  const renderNotification = useCallback(
    (notification: NotificationType) => {
      if (notification.type === 'InternalInvite') {
        return (
          <div key={notification.id} className="flex items-start gap-2 p-2">
            <Avatar
              name={notification.internalInvite.createdByName}
              imgSrc={notification.internalInvite.createdByAvatar}
            />
            <div className="flex-1 w-full">
              <div className="flex flex-col">
                <Typography
                  variant="text-sm-medium"
                  className="dark:text-popover-foreground"
                >
                  {notification.internalInvite.createdByName}
                </Typography>
                <Typography
                  variant="text-sm-regular"
                  className="dark:text-popover-foreground"
                >
                  {t?.notifications?.internalInvite?.title}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="text-gray-500 dark:text-popover-foreground"
                >
                  {notification.createdAtUtc && (
                    <>
                      {t?.notifications?.internalInvite?.prefixCreatedAt}{' '}
                      {formatTimeDistance(
                        notification.createdAtUtc,
                        tDate as any
                      )}
                    </>
                  )}
                </Typography>
              </div>
            </div>
          </div>
        )
      }
    },
    [
      t?.notifications?.internalInvite?.prefixCreatedAt,
      t?.notifications?.internalInvite?.title,
      tDate
    ]
  )

  const quantityNotifications = useMemo(
    () => dataGetListNotifications?.items?.length,
    [dataGetListNotifications?.items?.length]
  )

  return (
    <Popover>
      <PopoverTrigger>
        <ButtonWithTooltip
          type="button"
          variant="unstyled"
          className="relative text-gray-400"
          tooltipText={t?.options?.notifications}
        >
          <span className="sr-only">{t?.options?.notifications}</span>
          <BellIcon className="w-5 h-5" aria-hidden="true" />
          {!!quantityNotifications && (
            <div className="absolute bg-red-500 rounded-full top-[-3px] left-[-3px]">
              <span className="flex items-center justify-center w-[14px] h-[14px] text-[9px] text-white">
                {quantityNotifications}
              </span>
            </div>
          )}
        </ButtonWithTooltip>
      </PopoverTrigger>
      <PopoverContent
        className="p-2 rounded-md shadow-lg w-72 md:w-96"
        align={breakpoint === 'xs' ? 'center' : 'end'}
      >
        <div className="flex flex-col gap-1">
          {isLoadingGetListNotifications ? (
            Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="w-full h-16 p-2 rounded">
                <div className="flex gap-1">
                  <div className="w-8 h-8 bg-gray-200 rounded-full" />
                  <div className="flex-1 w-full">
                    <div className="flex flex-col gap-1">
                      <div className="w-1/2 h-4 bg-gray-200 rounded" />
                      <div className="w-3/4 h-3 bg-gray-200 rounded" />
                      <div className="w-1/2 h-3 bg-gray-200 rounded" />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : dataGetListNotifications?.items?.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
              <div className="relative w-full h-full max-w-32">
                <Image
                  src="/assets/icons/empty-state.svg"
                  alt="Folder Dashboard"
                  layout="fill"
                />
              </div>
              <Typography
                variant="text-sm-regular"
                className="text-center"
                showSkeleton={!isReady}
              >
                {t?.notifications?.emptyStateText}
              </Typography>
            </div>
          ) : (
            dataGetListNotifications?.items?.map((notification) => (
              <div
                key={notification.id}
                className="p-2 rounded cursor-pointer hover:bg-secondary-100 dark:hover:bg-gray-200"
                onClick={() => handleClickNotification(notification)}
              >
                {renderNotification(notification)}
              </div>
            ))
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default Notifications
