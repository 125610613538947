import React from 'react'
import { Modal } from '..'
import { Avatar, Typography } from '@/ui/atoms'

export type DataCardType = {
  title: string
  avatar: {
    imgSrc: string | null
    name: string
  }
  name: string
  email?: string
  date?: string
}

export type DataListType = {
  title: string
  value: string | null
}

type Props = {
  isOpen: boolean
  onClose: () => void
  title: string
  isLoading: boolean
  dataCards: DataCardType[] | null
  dataList: DataListType[] | null
}

const ModalViewTableData: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  isLoading,
  dataCards,
  dataList
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      displayAsDrawerOnMobile
    >
      <div className="flex flex-col gap-3 pb-2">
        {isLoading ? (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full h-24 skeleton"></div>
              <div className="w-full h-24 skeleton"></div>
            </div>
            <div className="w-full skeleton h-60"></div>
          </>
        ) : (
          <>
            {dataCards && (
              <div className="grid grid-cols-2 gap-4">
                {dataCards?.map((card, index) => (
                  <div
                    className="flex flex-col justify-center gap-1 p-2 border border-gray-200 rounded-sm"
                    key={index}
                  >
                    <Typography variant="text-xs-medium">
                      {card?.title}
                    </Typography>
                    <div className="flex items-center gap-1 lg:gap-2">
                      <Avatar
                        imgSrc={card?.avatar?.imgSrc}
                        name={card?.avatar?.name}
                      />
                      <div className="flex flex-col flex-1">
                        <Typography variant="text-sm-medium">
                          {card?.name}
                        </Typography>
                        <Typography variant="text-sm-medium">
                          {card?.email}
                        </Typography>
                        {!!card?.date && (
                          <Typography variant="text-xs-regular">
                            {card?.date}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {dataList && (
              <div className="flex flex-col gap-3 p-2 border border-gray-200 rounded-sm">
                {dataList?.map((item, index) => (
                  <div key={index} className="flex flex-col">
                    <Typography variant="text-xs-medium" type="p">
                      {item?.title}
                    </Typography>
                    <Typography
                      variant="text-xs-regular"
                      className="text-gray-700 dark:text-gray-700"
                      type="p"
                    >
                      {item?.value}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default ModalViewTableData
