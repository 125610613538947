import { CustomColumn } from '@/types/react-table-config'
import {
  Template,
  Templates
} from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'

export type FilterTemplatesType = {
  query: string | undefined
}

export type TemplateFormType = {
  id?: string
  name: string
  email: string
  description?: string
}

export type DataModalActionTemplateType = {
  action: 'createEnvelope' | 'edit' | 'move' | 'rename' | 'delete' | null
  id: string | null
}

export type FiltersTemplatesType = {
  query?: string | null
  user?: string | null
  filterVaults?: string | null
}

export enum FiltersKeyTemplates {
  QUERY = 'query',
  USER = 'user',
  FILTER_VAULTS = 'filterVaults'
}

export type UseTemplatesDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentData: {
    data: Templates | null | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  columns: CustomColumn<Template>[]
  onSubmitActionModal: (
    data: TemplateFormType,
    action: DataModalActionTemplateType['action']
  ) => void
  dataModalActionTemplate: DataModalActionTemplateType
  setDataModalActionTemplate: React.Dispatch<
    React.SetStateAction<DataModalActionTemplateType>
  >
  isOpenModal: boolean
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  handleDeleteTemplate: () => void
  optionsUser: SelectGroupOption[] | SelectOption[]
  handleSearchVaultCallback: (
    vault: {
      vaultId: string
      vaultName: string
    },
    templateId: string
  ) => void
  filters: FiltersTemplatesType | null
  preparedFilters: FiltersTemplatesType | null
  setPreparedFilters: (filters: FiltersTemplatesType) => void
  handleApplyFilters: (filters: FiltersTemplatesType) => void
  isLoadingTemplateOwners: boolean
  loadingSubmit: boolean
}

export type UseTemplatesDataProps = {
  type?: 'envelopeListTemplate' | 'default'
}
