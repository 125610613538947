import React, { SetStateAction, useEffect, useMemo } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  ButtonWithTooltip,
  Form,
  Modal,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '..'
import useContactApi from '@/hooks/api/ecm/useContactApi/useContactApi'
import {
  ActionModalContactType,
  ModalActionContactType
} from '@/hooks/useContactsData/useContactsData.types'
import { Button } from '@/ui/atoms/shadcn'

type ContactFormType = {
  name: string
  email: string
  description: string
}

type Props = {
  id: string | null
  action: ActionModalContactType
  setModalActionContact: React.Dispatch<SetStateAction<ModalActionContactType>>
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    values: ContactFormType,
    action: ModalActionContactType['action']
  ) => Promise<void> | void
  isLoading?: boolean
}

const ModalActionContact: React.FC<Props> = ({
  id,
  isOpen,
  action,
  onClose,
  setModalActionContact,
  onSubmit,
  isLoading = false
}) => {
  const { t, isReady } = useTranslation(['contacts', 'validations'], true)

  const { useGetContact } = useContactApi()
  const { data: dataContact, isLoading: isLoadingGetContact } =
    useGetContact(id)

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    email: z
      .string()
      .min(1, { message: t?.requiredField })
      .email({ message: t?.errorInvalidEmail })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z
      .string()
      .max(2000, { message: t?.errorMaxLength?.(2000) })
      .optional()
  })

  const form = useForm<ContactFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      description: ''
    }
  })
  const name = useWatch({
    control: form.control,
    name: 'name',
    defaultValue: ''
  })

  useEffect(() => {
    if ((action === 'update' || action === 'view') && dataContact) {
      form.reset({
        name: dataContact?.name || '',
        email: dataContact?.email || '',
        description: dataContact?.description || ''
      })
    }
  }, [dataContact, action, form])

  const loadingSkeleton = useMemo(() => {
    if (action === 'create') {
      return !isReady
    }
    if (isLoadingGetContact) {
      return isLoadingGetContact
    }
    if ((action === 'view' || action === 'update') && !name) {
      return !isReady
    }

    return !isReady ? true : false
  }, [action, isLoadingGetContact, isReady, name])

  return (
    <Modal
      isOpen={isOpen}
      title={t?.modalActionContacts?.title?.(action)}
      onClose={onClose}
      displayAsDrawerOnMobile
    >
      <Form<ContactFormType>
        {...form}
        onSubmit={(values) => onSubmit(values, action)}
      >
        <RenderFormRow>
          <RenderFormField
            control={form.control}
            name="name"
            type="input"
            disabled={action === 'view'}
            showSkeleton={loadingSkeleton}
            {...form.formState.errors.name}
            {...t?.modalActionContacts?.form?.name}
          />
          <RenderFormField
            control={form.control}
            name="email"
            type="input"
            disabled={action === 'view'}
            showSkeleton={loadingSkeleton}
            {...form.formState.errors.email}
            {...t?.modalActionContacts?.form?.email}
          />
        </RenderFormRow>
        <RenderFormField
          control={form.control}
          name="description"
          type="textarea"
          disabled={action === 'view'}
          showSkeleton={loadingSkeleton}
          {...form.formState.errors.description}
          {...t?.modalActionContacts?.form?.description}
        />
        <RenderFormActions align={action === 'view' ? 'between' : 'end'}>
          {action === 'create' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={loadingSkeleton}
              >
                {t?.modalActionContacts?.formFooter?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={loadingSkeleton}
                loading={isLoading}
              >
                {t?.modalActionContacts?.formFooter?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'update' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={loadingSkeleton}
              >
                {t?.modalActionContacts?.formFooter?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={loadingSkeleton}
                loading={isLoading}
              >
                {t?.modalActionContacts?.formFooter?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'view' && (
            <>
              <div className="flex gap-4">
                <ButtonWithTooltip
                  variant="outlineDestructive"
                  type="button"
                  onClick={() => {
                    setModalActionContact({
                      isOpen: true,
                      action: 'delete',
                      id: id as string
                    })
                  }}
                  showSkeleton={loadingSkeleton}
                  tooltipText={t?.tooltipDelete}
                >
                  <TrashIcon className="w-4 h-4" />
                </ButtonWithTooltip>
                <Button
                  type="button"
                  onClick={() => {
                    setModalActionContact({
                      isOpen: true,
                      action: 'update',
                      id: id as string
                    })
                  }}
                  showSkeleton={loadingSkeleton}
                >
                  {t?.modalActionContacts?.formFooter?.buttonUpdate}
                </Button>
              </div>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={loadingSkeleton}
              >
                {t?.modalActionContacts?.formFooter?.buttonClose}
              </Button>
            </>
          )}
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalActionContact
