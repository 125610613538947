import React, { useMemo } from 'react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon
} from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { cn } from '@/lib/utils'

type Props = {
  variant: 'success' | 'warning' | 'error' | 'info'
  size?: 'sm' | 'md' | 'lg'
  icon?: React.ReactNode
  title: string
  children?: React.ReactNode
  classNameTitle?: string
}

const Alert: React.FC<Props> = ({
  variant,
  size = 'md',
  title,
  children,
  icon,
  classNameTitle
}) => {
  const iconSizes = useMemo(() => {
    return {
      sm: 'w-4 h-4',
      md: 'w-5 h-5',
      lg: 'w-6 h-6'
    }
  }, [])

  const fontSizes = useMemo(() => {
    return {
      sm: 'text-xs',
      md: 'text-sm',
      lg: 'text-base'
    }
  }, [])

  const paddingSizes = useMemo(() => {
    return {
      sm: 'p-2',
      md: 'p-4',
      lg: 'p-6'
    }
  }, [])

  const iconAlert = useMemo(() => {
    const icons = {
      success: (
        <CheckCircleIcon
          className={`${iconSizes[size]} text-green-400`}
          aria-hidden="true"
        />
      ),
      warning: (
        <ExclamationCircleIcon
          className={`${iconSizes[size]} text-yellow-400`}
          aria-hidden="true"
        />
      ),
      error: (
        <XCircleIcon
          className={`${iconSizes[size]} text-red-400`}
          aria-hidden="true"
        />
      ),
      info: (
        <InformationCircleIcon
          className={`${iconSizes[size]} text-blue-400`}
          aria-hidden="true"
        />
      )
    }

    if (typeof variant === 'string') {
      return icons[variant as keyof typeof icons]
    }

    return icon
  }, [iconSizes, size, variant, icon])

  const className = useMemo(() => {
    const classes = {
      success: {
        text: 'text-success-900',
        bg: 'bg-green-50'
      },
      warning: {
        text: 'text-warning-900',
        bg: 'bg-yellow-100'
      },
      error: {
        text: 'text-error-900',
        bg: 'bg-red-50'
      },
      info: {
        text: 'text-info-900',
        bg: 'bg-blue-50'
      }
    }
    return classes[variant as keyof typeof classes]
  }, [variant])

  return (
    <div className={cn(paddingSizes[size], 'rounded-md', className?.bg)}>
      <div className="flex">
        <div className="flex-shrink-0">{iconAlert}</div>
        <div
          className={size === 'sm' ? 'ml-1' : size === 'md' ? 'ml-2' : 'ml-3'}
        >
          <h3
            className={cn(
              fontSizes[size],
              'font-medium',
              className?.text,
              classNameTitle
            )}
          >
            {title}
          </h3>
          {children && (
            <div className={cn('mt-1', fontSizes[size], className?.text)}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Alert
