import React, { ReactNode, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export interface MenuItem {
  key: string
  label: ReactNode
  disabled?: boolean
}

interface DropdownProps {
  menu: MenuItem[]
  children: ReactNode
  onSelect: (item: MenuItem) => void
}

const Dropdown: React.FC<DropdownProps> = ({ menu, children, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen}>
      <DropdownMenu.Trigger asChild>
        <button className="IconTrigger" aria-label="Button icon trigger">
          {children}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="min-w-[200px] w-full rounded-md bg-accent-100 dark:bg-gray-100 shadow-lg ring-1 ring-gray-200 py-2 focus:outline-none"
          align="end"
          sideOffset={5}
        >
          {menu.map((item) => (
            <DropdownMenu.Item key={item.key} disabled={item.disabled}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  if (item.disabled) return
                  onSelect(item)
                  setIsOpen(false)
                }}
                className={`flex items-center px-4 py-1 w-full text-sm leading-6 text-gray-700 hover:bg-gray-200 ${
                  item.disabled
                    ? 'cursor-not-allowed bg-transparent hover:bg-gray-100 opacity-50'
                    : ''
                }`}
              >
                {item.label}
              </button>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Dropdown
