import { useEffect, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import Gleap from 'gleap'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { IBreakpoint } from '@/hooks/useViewport/useViewport'
import { ButtonWithTooltip } from '@/ui/molecules'

const ToggleChat = () => {
  const { breakpoint } = useViewport()
  const [gleapOpened, setGleapOpened] = useState(true)
  const [firstTouch, setFirstTouch] = useState(true)
  const { t } = useTranslation('toggleChat')

  const toggleGleap = () => {
    if (gleapOpened && !firstTouch) closeGleap()
    else openGleap(true)
    setFirstTouch(false)
  }

  const openGleap = (openChat: boolean) => {
    Gleap.showFeedbackButton(true)
    if (openChat) Gleap.open()
    setGleapOpened(true)
  }

  const closeGleap = () => {
    Gleap.close()
    Gleap.showFeedbackButton(false)
    setGleapOpened(false)
  }

  useEffect(() => {
    if ((breakpoint as IBreakpoint) === 'xs') closeGleap()
    else openGleap(false)
  }, [breakpoint])

  return (
    <ButtonWithTooltip
      type="button"
      variant="unstyled"
      className="text-gray-400"
      onClick={toggleGleap}
      tooltipText={gleapOpened && !firstTouch ? t?.close : t?.open}
    >
      <span className="sr-only">
        {gleapOpened && !firstTouch ? t?.close : t?.open}
      </span>
      <QuestionMarkCircleIcon className="w-5 h-5" aria-hidden="true" />
    </ButtonWithTooltip>
  )
}

export default ToggleChat
