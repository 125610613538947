import React from 'react'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { cn } from '@/lib/utils'
import {
  ISelectedSignerMarkData,
  SignerEnvelopeInitial
} from '@/providers/Envelope/Envelope.types'
import { Badge, Button } from '@/ui/atoms/shadcn'

type Props = {
  signer: SignerEnvelopeInitial
  selectedSignerMarkData: ISelectedSignerMarkData
  handleClickCardSignMark: (
    selectedSignerMarkData: ISelectedSignerMarkData
  ) => void
  disabled?: boolean
}

const CardSignerMark: React.FC<Props> = ({
  signer,
  selectedSignerMarkData,
  handleClickCardSignMark,
  disabled = false
}) => {
  const { t } = useTranslation('envelope')

  return (
    <div
      className={cn(
        'border border-dashed border-gray-400 rounded-md py-6 px-4 flex gap-4 justify-between items-center bg-white dark:bg-gray-100',
        selectedSignerMarkData?.id === signer?.id
          ? 'border-secondary'
          : 'border-gray-400',
        disabled && 'opacity-50 cursor-not-allowed'
      )}
      key={signer?.id}
    >
      <div className="flex flex-col flex-grow min-w-0">
        <Badge className="mb-2 bg-secondary-100 text-secondary" size="xs">
          {
            t?.optionsRoles?.find(
              (option: any) => option.value === signer?.role
            )?.label
          }
        </Badge>
        <Typography variant="text-base-medium" className="break-words">
          {signer?.name}
        </Typography>
        <Typography
          variant="text-xs-medium"
          className="text-gray-400 break-all"
        >
          {signer?.email}
        </Typography>
      </div>
      <div className="flex flex-col flex-shrink-0 gap-2">
        <Button
          variant={
            selectedSignerMarkData?.type === 'Rubric' &&
            selectedSignerMarkData?.id === signer?.id
              ? 'default'
              : 'neutral'
          }
          className={cn(
            selectedSignerMarkData?.type === 'Rubric' &&
              selectedSignerMarkData?.id === signer?.id &&
              'cursor-not-allowed'
          )}
          onClick={() => {
            if (disabled) return
            handleClickCardSignMark({
              id: signer?.id,
              type: 'Rubric'
            })
          }}
          disabled={disabled}
        >
          {t?.marksTypes?.Rubric}
        </Button>
        <Button
          variant={
            selectedSignerMarkData?.type === 'Signature' &&
            selectedSignerMarkData?.id === signer?.id
              ? 'default'
              : 'neutral'
          }
          className={cn(
            selectedSignerMarkData?.type === 'Signature' &&
              selectedSignerMarkData?.id === signer?.id &&
              'cursor-not-allowed'
          )}
          onClick={() => {
            if (disabled) return
            handleClickCardSignMark({
              id: signer?.id,
              type: 'Signature'
            })
          }}
          disabled={disabled}
        >
          {t?.marksTypes?.Signature}
        </Button>
      </div>
    </div>
  )
}

export default CardSignerMark
