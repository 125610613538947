var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Q7dnqR6LJOFyxT83jUbIW"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT_NAME,
  tracesSampleRate: 1.0,
  debug: false,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true
    }),
    new ExtraErrorDataIntegration()
  ],
  beforeSend(event) {
    const exception = event?.exception?.values?.[0]

    if (exception) {
      const statusCode = exception?.value?.match(/\b\d{3}\b/)?.[0]
      if (statusCode && statusCode.startsWith('4')) {
        return null
      }
    }
    return event
  }
})
