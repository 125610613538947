import { useCallback, useMemo, useState } from 'react'
import Image from 'next/image'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { SignatureStyle, SignatureType } from '@/types/constants'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Typography } from '@/atoms/index'
import {
  OpenModalSignatureType,
  SuccessCallbackData,
  UseSignaturesDataResponse
} from './useSignaturesData.types'
import { useSignaturesApi } from '../api/administration/profile/user/signatures'
import { SignatureMark } from '../api/administration/profile/user/signatures/useSignaturesApi.types'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { Badge, Button } from '@/ui/atoms/shadcn'
import { ConfirmPop } from '@/ui/molecules'

const useSignaturesData = ({
  enabledListSignatureMarks = false,
  enabledListRubricMarks = false
}): UseSignaturesDataResponse => {
  const { t } = useTranslation('profileSignatures')
  const [openModalSignature, setOpenModalSignature] =
    useState<OpenModalSignatureType>({
      type: null,
      open: false
    })

  const tablePaginationSignature = useTable()
  const tablePaginationRubric = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const {
    useGetListSignatureMarks,
    useCreateSignatureMark,
    useDeleteSignatureMark,
    useGetListRubricMarks,
    useCreateRubricMark,
    useDeleteRubricMark
  } = useSignaturesApi()

  const {
    data: dataListSignatureMarks,
    isLoading: isLoadingListSignatureMarks,
    isSuccess: isSuccessListSignatureMarks,
    isFetching: isFetchingListSignatureMarks
  } = useGetListSignatureMarks(
    tablePaginationSignature.pageNumber,
    tablePaginationSignature.pageSize,
    enabledListSignatureMarks
  )
  const { mutateAsync: mutateAsyncCreateSignatureMark } =
    useCreateSignatureMark()
  const { mutateAsync: mutateAsyncDeleteSignatureMark } =
    useDeleteSignatureMark()
  const {
    data: dataListRubricMarks,
    isLoading: isLoadingListRubricMarks,
    isSuccess: isSuccessListRubricMarks,
    isFetching: isFetchingListRubricMarks
  } = useGetListRubricMarks(
    tablePaginationRubric.pageNumber,
    tablePaginationRubric.pageSize,
    enabledListRubricMarks
  )
  const { mutateAsync: mutateAsyncCreateRubricMark } = useCreateRubricMark()
  const { mutateAsync: mutateAsyncDeleteRubricMark } = useDeleteRubricMark()

  const dataListSignature = useMemo(() => {
    return {
      data: dataListSignatureMarks,
      isLoading: isLoadingListSignatureMarks || isFetchingListSignatureMarks,
      isSuccess: isSuccessListSignatureMarks
    }
  }, [
    dataListSignatureMarks,
    isFetchingListSignatureMarks,
    isLoadingListSignatureMarks,
    isSuccessListSignatureMarks
  ])

  const dataListRubric = useMemo(() => {
    return {
      data: dataListRubricMarks,
      isLoading: isLoadingListRubricMarks || isFetchingListRubricMarks,
      isSuccess: isSuccessListRubricMarks
    }
  }, [
    dataListRubricMarks,
    isFetchingListRubricMarks,
    isLoadingListRubricMarks,
    isSuccessListRubricMarks
  ])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      create: { width: '30%', minWidth: '150px' },
      type: { width: '25%', minWidth: '120px' },
      signature: { width: '35%', minWidth: '170px' },
      action: { width: '10%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const handleDeleteSignature = useCallback(
    async (id: string, type: 'signature' | 'rubric') => {
      if (type === 'signature') {
        try {
          await mutateAsyncDeleteSignatureMark(id)
          showToast.success(t?.toasts?.successDeleteSignature)
        } catch {
          showToast.error(t?.toasts?.errorDeleteSignature)
        }
      } else {
        try {
          await mutateAsyncDeleteRubricMark(id)
          showToast.success(t?.toasts?.successDeleteRubric)
        } catch {
          showToast.error(t?.toasts?.errorDeleteRubric)
        }
      }
    },
    [
      mutateAsyncDeleteRubricMark,
      mutateAsyncDeleteSignatureMark,
      t?.toasts?.errorDeleteRubric,
      t?.toasts?.errorDeleteSignature,
      t?.toasts?.successDeleteRubric,
      t?.toasts?.successDeleteSignature
    ]
  )

  const columnsBase: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdAtUtc',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.type || '',
        accessor: 'style',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          const item = t?.tableInformation?.mappingTypes?.[
            valuesOriginal.style as 'Draw' | 'Simulated' | 'Upload'
          ] || {
            label: '',
            color: ''
          }

          return (
            <div
              className={
                !value ? 'flex justify-center items-center text-accent-500' : ''
              }
            >
              {value ? (
                <Badge size="sm" className={item?.color} tooltip={item?.label}>
                  {item?.label}
                </Badge>
              ) : (
                '-'
              )}
            </div>
          )
        },
        ...tableColumnWidth.type
      },
      {
        Header: t?.tableInformation?.image || '',
        accessor: 'text',
        Cell: (value) => {
          const [isLoadingImage, setIsLoadingImage] = useState(true)
          const valuesOriginal = value?.row?.original

          const handleLoadingComplete = () => {
            setIsLoadingImage(false)
          }

          return (
            <div className="relative">
              {isLoadingImage && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-8 w-36 skeleton" />
                </div>
              )}
              <div className="relative h-8">
                <Image
                  src={valuesOriginal.imageUrl}
                  layout="fill"
                  objectFit="contain"
                  alt="Signature"
                  onLoadingComplete={handleLoadingComplete}
                  className={cn(
                    isLoadingImage ? 'opacity-0' : 'opacity-100',
                    'transition-opacity duration-500'
                  )}
                />
              </div>
            </div>
          )
        },
        ...tableColumnWidth.signature
      }
    ]
  }, [
    lang,
    t?.tableInformation?.create,
    t?.tableInformation?.image,
    t?.tableInformation?.mappingTypes,
    t?.tableInformation?.type,
    tableColumnWidth.create,
    tableColumnWidth.signature,
    tableColumnWidth.type
  ])

  const columnsSignature: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      ...columnsBase,
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'action',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex-1">
              <ConfirmPop
                title={t?.confirmPop?.titleSignature}
                description={t?.confirmPop?.descriptionSignature}
                confirmButtonText={t?.confirmPop?.confirmButtonText}
                cancelButtonText={t?.confirmPop?.cancelButtonText}
                onConfirm={async () => {
                  await handleDeleteSignature(valuesOriginal.id, 'signature')
                  tablePaginationSignature.resetPagination()
                }}
              >
                <Button variant="outlineDestructive">
                  {t?.tableInformation?.delete}
                </Button>
              </ConfirmPop>
            </div>
          )
        },
        ...tableColumnWidth.action
      }
    ]
  }, [
    columnsBase,
    handleDeleteSignature,
    t?.confirmPop?.cancelButtonText,
    t?.confirmPop?.confirmButtonText,
    t?.confirmPop?.descriptionSignature,
    t?.confirmPop?.titleSignature,
    t?.tableInformation?.action,
    t?.tableInformation?.delete,
    tableColumnWidth.action,
    tablePaginationSignature
  ])

  const columnsRubric: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      ...columnsBase,
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'action',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex-1">
              <ConfirmPop
                title={t?.confirmPop?.titleRubric}
                description={t?.confirmPop?.descriptionRubric}
                confirmButtonText={t?.confirmPop?.confirmButtonText}
                cancelButtonText={t?.confirmPop?.cancelButtonText}
                onConfirm={async () => {
                  await handleDeleteSignature(valuesOriginal.id, 'rubric')
                  tablePaginationRubric.resetPagination()
                }}
              >
                <Button variant="outlineDestructive">
                  {t?.tableInformation?.delete}
                </Button>
              </ConfirmPop>
            </div>
          )
        },
        ...tableColumnWidth.action
      }
    ]
  }, [
    columnsBase,
    handleDeleteSignature,
    t?.confirmPop?.cancelButtonText,
    t?.confirmPop?.confirmButtonText,
    t?.confirmPop?.descriptionRubric,
    t?.confirmPop?.titleRubric,
    t?.tableInformation?.action,
    t?.tableInformation?.delete,
    tableColumnWidth.action,
    tablePaginationRubric
  ])

  const onCloseModalSignature = () => {
    setOpenModalSignature({
      type: null,
      open: false
    })
  }

  const handleSuccessCallback = useCallback(
    async (data: SuccessCallbackData) => {
      if (data.signatureType === SignatureType.Signature) {
        if (data.signatureStyle === SignatureStyle.Draw) {
          try {
            await mutateAsyncCreateSignatureMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              style: SignatureStyle.Draw
            })
            showToast.success(t?.toasts?.successCreateSignature)
          } catch {
            showToast.error(t?.toasts?.errorCreateSignature)
          }
          return
        }
        if (data.signatureStyle === SignatureStyle.Simulated) {
          try {
            await mutateAsyncCreateSignatureMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              text: data.signatureText,
              font: data.fontTypedSignature,
              style: SignatureStyle.Simulated
            })
            showToast.success(t?.toasts?.successCreateSignature)
          } catch {
            showToast.error(t?.toasts?.errorCreateSignature)
          }

          return
        }
        if (data.signatureStyle === SignatureStyle.Upload) {
          try {
            await mutateAsyncCreateSignatureMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              style: SignatureStyle.Upload
            })
            showToast.success(t?.toasts?.successCreateSignature)
          } catch {
            showToast.error(t?.toasts?.errorCreateSignature)
          }

          return
        }
      }
      if (data.signatureType === SignatureType.Rubric) {
        if (data.signatureStyle === SignatureStyle.Draw) {
          try {
            await mutateAsyncCreateRubricMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              style: SignatureStyle.Draw
            })
            showToast.success(t?.toasts?.successCreateRubric)
          } catch {
            showToast.error(t?.toasts?.errorCreateRubric)
          }
          return
        }
        if (data.signatureStyle === SignatureStyle.Simulated) {
          try {
            await mutateAsyncCreateRubricMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              text: data.signatureText,
              font: data.fontTypedSignature,
              style: SignatureStyle.Simulated
            })
            showToast.success(t?.toasts?.successCreateRubric)
          } catch {
            showToast.error(t?.toasts?.errorCreateRubric)
          }

          return
        }
        if (data.signatureStyle === SignatureStyle.Upload) {
          try {
            await mutateAsyncCreateRubricMark({
              imageInBase64: data.signatureImage?.split(',')[1],
              style: SignatureStyle.Upload
            })
            showToast.success(t?.toasts?.successCreateRubric)
          } catch {
            showToast.error(t?.toasts?.errorCreateRubric)
          }

          return
        }
      }
    },
    [
      mutateAsyncCreateRubricMark,
      mutateAsyncCreateSignatureMark,
      t?.toasts?.errorCreateRubric,
      t?.toasts?.errorCreateSignature,
      t?.toasts?.successCreateRubric,
      t?.toasts?.successCreateSignature
    ]
  )

  return {
    tablePaginationSignature,
    tablePaginationRubric,
    dataListSignature,
    dataListRubric,
    columnsBase,
    columnsSignature,
    columnsRubric,
    openModalSignature,
    setOpenModalSignature,
    onCloseModalSignature,
    handleSuccessCallback
  }
}

export default useSignaturesData
