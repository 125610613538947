import React from 'react'

const CardPlanSkeleton: React.FC = () => {
  const randomWidthClass = () => {
    const classes = ['w-1/4', 'w-1/3', 'w-1/2', 'w-2/3', 'w-3/4']
    return classes[Math.floor(Math.random() * classes.length)]
  }

  return (
    <div className="p-8 ring-1 ring-gray-200 rounded-3xl">
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="h-8 skeleton w-full max-w-[200px]"></div>
        <div className="w-5/6 h-4 skeleton"></div>
      </div>
      <div className="flex items-baseline justify-center mt-6 gap-x-1">
        <div className="w-20 h-8 skeleton"></div>
        <div className="w-20 h-4 skeleton"></div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="h-5 skeleton mb-4 w-full max-w-[200px]"></div>
      </div>
      <div className="block w-full mt-3 rounded-md skeleton h-11"></div>
      <div className="flex flex-col w-full gap-3 mt-8">
        {Array.from({ length: 10 })?.map((_, index) => (
          <div key={index} className="flex items-center justify-start w-full">
            <div className="w-[24px] h-[24px] bg-gray-300 rounded mr-3"></div>
            <div className={`h-6 skeleton ${randomWidthClass()}`}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CardPlanSkeleton
