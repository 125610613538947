import React, { useMemo } from 'react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import useTranslation from '@/hooks/useTranslation'
import SectionViewDocument from '../SectionViewDocument'
import {
  CurrentDocumentFileType,
  IsLoadingDownloadDocumentType
} from '@/hooks/usePublicEnvelopeViewData/usePublicEnvelopeViewData.types'
import { Button } from '@/ui/atoms/shadcn'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'

type Props = {
  isOpen: boolean
  setIsOpen: (boolean: boolean) => void
  loadingPdf: boolean
  setLoadingPdf: React.Dispatch<React.SetStateAction<boolean>>
  currentDocumentFile: CurrentDocumentFileType
  isLoadingDownloadDocument: IsLoadingDownloadDocumentType
  handleDownloadDocument: (
    type: IsLoadingDownloadDocumentType['type'],
    document?: { id: string; name: string }
  ) => void
}

const DrawerViewDocument: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  loadingPdf,
  setLoadingPdf,
  currentDocumentFile,
  // buttonDownloadCertificateEnvelope,
  // buttonDownloadSignedDocument,
  // buttonDownloadOriginalDocument,
  isLoadingDownloadDocument,
  handleDownloadDocument
}) => {
  const { t, isReady } = useTranslation('viewEnvelope')

  const tButton = useMemo(() => {
    return {
      downloadCertificateEnvelope:
        t?.sectionEnvelopeViewDocuments?.downloadButtonList?.find(
          (item: any) => item.type === 'certificateEnvelope'
        ),
      downloadSignedDocument:
        t?.sectionEnvelopeViewDocuments?.downloadButtonList?.find(
          (item: any) => item.type === 'signedDocument'
        ),
      downloadOriginalDocument:
        t?.sectionEnvelopeViewDocuments?.downloadButtonList?.find(
          (item: any) => item.type === 'originalDocument'
        )
    }
  }, [t?.sectionEnvelopeViewDocuments?.downloadButtonList])

  return (
    <Drawer.Drawer
      open={isOpen}
      onOpenChange={(boolean) => {
        if (!boolean) {
          setIsOpen(false)
        }
      }}
    >
      <Drawer.DrawerContent className="bg-gray-100 h-[80vh]">
        <div className="relative pt-4 overflow-y-scroll">
          <SectionViewDocument
            loadingPdf={loadingPdf}
            setLoadingPdf={setLoadingPdf}
            currentDocumentFile={currentDocumentFile}
          />
          <div className="flex flex-col gap-2 p-4">
            {currentDocumentFile?.type === 'document' ? (
              <>
                <Button
                  variant="secondary"
                  className="w-full"
                  size="sm"
                  loading={
                    isLoadingDownloadDocument.type === 'signedDocument' &&
                    isLoadingDownloadDocument.loading
                  }
                  onClick={() => {
                    handleDownloadDocument('signedDocument', {
                      id: currentDocumentFile?.id as string,
                      name: currentDocumentFile?.name || ''
                    })
                  }}
                >
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {tButton?.downloadSignedDocument?.label}
                </Button>
                <Button
                  variant="secondary"
                  className="w-full"
                  size="sm"
                  loading={
                    isLoadingDownloadDocument.type === 'originalDocument' &&
                    isLoadingDownloadDocument.loading
                  }
                  onClick={() => {
                    handleDownloadDocument('originalDocument', {
                      id: currentDocumentFile?.id as string,
                      name: currentDocumentFile?.name || ''
                    })
                  }}
                >
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {tButton?.downloadOriginalDocument?.label}
                </Button>
              </>
            ) : (
              <Button
                variant="secondary"
                className="w-full"
                size="sm"
                loading={
                  isLoadingDownloadDocument.type === 'certificateEnvelope' &&
                  isLoadingDownloadDocument.loading
                }
                onClick={() => {
                  handleDownloadDocument('certificateEnvelope')
                }}
              >
                <ArrowDownTrayIcon className="w-4 h-4" />
                {tButton?.downloadCertificateEnvelope?.label}
              </Button>
            )}
          </div>
        </div>
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerViewDocument
