import React, { ComponentProps, forwardRef, useEffect, useState } from 'react'
import { FaSearch, FaTimes } from 'react-icons/fa'
import useTranslation from '@/hooks/useTranslation'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'

export type InputSearchProps = ComponentProps<'input'> & {
  onClear?: () => void
  label?: string
  className?: string
  fullWidth?: boolean
  showSkeleton?: boolean
  timeoutSkeleton?: number
}

const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  function InputSearch(
    {
      onClear,
      className,
      fullWidth,
      showSkeleton = false,
      timeoutSkeleton,
      ...props
    },
    ref
  ) {
    const { t } = useTranslation('validations')
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
      if (!timeoutSkeleton) {
        setIsLoading(false)
        return
      }
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, timeoutSkeleton)
      return () => clearTimeout(timer)
    }, [timeoutSkeleton])

    if (showSkeleton || isLoading) {
      return (
        <div className="flex flex-col w-full gap-1">
          <div className="w-24 h-3 md:h-4 skeleton" />
          <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
        </div>
      )
    }
    return (
      <div
        className={cn(
          'flex flex-col',
          fullWidth ? 'max-w-full' : 'w-full md:w-56',
          props.label && 'gap-1',
          className
        )}
      >
        {props.label && (
          <Label htmlFor={props.id}>
            {props.label}
            {/* {!props.required && props.label && (
              <span className="text-xs font-light leading-3">
                ({t?.optional})
              </span>
            )} */}
          </Label>
        )}
        <div
          className={cn(
            'relative flex items-center w-full h-10',
            props.label ? 'mt-1' : ''
          )}
        >
          <FaSearch className="absolute left-0 ml-3 text-gray-400" />
          <input
            className={cn(
              'block w-full h-10 px-8 text-sm font-normal leading-tight border border-gray-300 rounded-md shadow-sm outline-none bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700'
            )}
            type="text"
            ref={ref}
            id={props.label}
            {...props}
          />
          {!!props.value && (
            <button
              className="absolute right-0 mr-3"
              onClick={() => onClear?.()}
            >
              <FaTimes className="text-gray-400" />
            </button>
          )}
        </div>
      </div>
    )
  }
)

export default InputSearch
