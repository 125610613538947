import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { cn } from '@/lib/utils'

const linkVariants = cva('cursor-pointer transition-colors hover:underline', {
  variants: {
    variant: {
      default: 'text-primary hover:text-primary-700',
      secondary: 'text-secondary hover:text-secondary-700',
      destructive: 'text-destructive hover:text-destructive-700',
      neutral: 'text-gray-700 hover:text-gray-900',
      white: 'text-white hover:text-white-700'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type LinkProps = VariantProps<typeof linkVariants> &
  Omit<NextLinkProps, 'href'> & {
    children: React.ReactNode
    className?: string
    href?: string
    onClick?: () => void
  }

const Link: React.FC<LinkProps> = ({
  children,
  className,
  variant,
  href,
  onClick,
  ...props
}) => {
  const combinedClassName = cn(linkVariants({ variant }), className)

  if (href) {
    return (
      <NextLink href={href} passHref {...props}>
        <a className={combinedClassName}>{children}</a>
      </NextLink>
    )
  }

  return (
    <button className={combinedClassName} onClick={onClick} {...props}>
      {children}
    </button>
  )
}

export default Link
