import { type } from 'os'
import React, { useCallback, useMemo } from 'react'
import {
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import Link from 'next/link'
import useTranslation from '@/hooks/useTranslation'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import {
  Subscription,
  Trial
} from '@/hooks/api/dashboards/useDashboardApi/useDashboardApi.types'
import { CurrentPlanType } from '@/hooks/useDashboardData/useDashboardData.types'
import { useAuth, useLocale } from '@/providers'
import { Typography } from '@/ui/atoms'
import { Badge, Button } from '@/ui/atoms/shadcn'
import { formatDate } from '@/utils'

type Props = {
  showSkeleton: boolean
  pendingInvoiceId?: string
  currentPlan?: CurrentPlanType
  hasActiveSubscription?: boolean
  subscription?: Subscription
  hasActiveTrial?: boolean
  trial?: Trial
}

const BannerDashboard: React.FC<Props> = ({
  showSkeleton,
  pendingInvoiceId,
  currentPlan,
  hasActiveTrial,
  trial,
  hasActiveSubscription
}) => {
  const { t, isReady } = useTranslation('dashboardPage')
  const { lang } = useLocale()
  const { isAdmin } = useAuth()
  const { useBillingGetLinkPendingInvoice } = useBillingApi()
  const {
    mutateAsync: mutateAsyncBillingGetLinkPendingInvoice,
    isLoading: isLoadingBillingGetLinkPendingInvoice
  } = useBillingGetLinkPendingInvoice()

  const getLinkInvoicePending = useCallback(async () => {
    if (!pendingInvoiceId) return
    try {
      const response = await mutateAsyncBillingGetLinkPendingInvoice({
        id: pendingInvoiceId
      })
      if (response?.url) {
        window.open(response?.url as string, '_blank')
        return
      }
      return t?.bannerPaywall?.toasts?.successGetLinkPendingInvoice
    } catch {
      return t?.bannerPaywall?.toasts?.errorGetLinkPendingInvoice
    }
  }, [
    mutateAsyncBillingGetLinkPendingInvoice,
    pendingInvoiceId,
    t?.bannerPaywall?.toasts?.errorGetLinkPendingInvoice,
    t?.bannerPaywall?.toasts?.successGetLinkPendingInvoice
  ])

  const showBannerDashboard = useMemo(() => {
    return (hasActiveTrial &&
      trial?.envelopesLeft &&
      trial?.envelopesLeft > 0) ||
      (hasActiveTrial && trial?.envelopesLeft === 0) ||
      !hasActiveSubscription
      ? true
      : false
  }, [hasActiveSubscription, hasActiveTrial, trial?.envelopesLeft])

  const renderBanner = (
    type: 'trial' | 'noEnvelopes' | 'paywall' | 'purchase' | 'currentPlan',
    icon: React.ReactNode,
    title: string,
    descriptionPrefix: string,
    descriptionStrong?: string,
    descriptionSuffix?: string
  ) => (
    <div className="lg:sticky w-full sm:w-[600px] bottom-4 mb-4">
      <div className="flex items-start w-full h-full gap-4 px-4 py-5 rounded-lg shadow shadow-gray-50 bg-secondary">
        <div className="flex flex-col">
          <Typography
            variant="title-xl-medium"
            className="mb-2 text-white dark:text-white"
            type="h1"
          >
            {title}
          </Typography>
          <Typography
            variant="text-base-regular"
            className="text-white dark:text-white"
          >
            {descriptionPrefix}
            {descriptionStrong && <strong>{descriptionStrong}</strong>}
            {descriptionSuffix}
          </Typography>
          {type === 'paywall' ? (
            isAdmin ? (
              <Button
                className="w-full mt-3 md:w-fit"
                variant="default"
                onClick={getLinkInvoicePending}
                loading={isLoadingBillingGetLinkPendingInvoice}
              >
                {t?.bannerPaywall?.button}
              </Button>
            ) : null
          ) : (
            <Link href="/billing/plans">
              <Button
                className="w-full mt-3 md:w-fit"
                variant="default"
                type="button"
              >
                {t?.bannerDashboard?.labelButtonChoosePlan}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )

  if (showBannerDashboard && (showSkeleton || !isReady)) {
    return (
      <div className="h-20 mb-4 skeleton lg:fixed w-[90vw] sm:w-[600px] bottom-4"></div>
    )
  }

  if (pendingInvoiceId) {
    return renderBanner(
      'paywall',
      <ExclamationTriangleIcon className="w-6 h-6 mt-1 text-warning-500" />,
      t?.bannerPaywall?.title,
      t?.bannerPaywall?.description
    )
  }

  if (hasActiveTrial && trial?.envelopesLeft && trial?.envelopesLeft > 0) {
    return renderBanner(
      'trial',
      <ClockIcon className="w-6 h-6 mt-1 text-warning-500" />,
      t?.bannerDashboard?.titleTrialPeriod,
      t?.bannerDashboard?.descriptionTrialPeriod?.(
        trial?.envelopesLeft,
        formatDate(trial?.endsAtUtc as string, 'date', lang)
      ),
      t?.bannerDashboard?.descriptionTrialPeriodStrong
    )
  }

  if (hasActiveTrial && trial?.envelopesLeft === 0) {
    return renderBanner(
      'noEnvelopes',
      <ExclamationCircleIcon className="w-6 h-6 mt-1 text-warning-500" />,
      t?.bannerDashboard?.titleNoEnvelopes,
      t?.bannerDashboard?.descriptionNoEnvelopes,
      t?.bannerDashboard?.descriptionNoEnvelopesStrong
    )
  }

  if (!hasActiveSubscription) {
    return renderBanner(
      'purchase',
      <ExclamationTriangleIcon className="w-6 h-6 mt-1 text-warning-500" />,
      t?.bannerDashboard?.titlePurchase,
      t?.bannerDashboard?.descriptionPurchasePrefix,
      t?.bannerDashboard?.descriptionPurchaseStrong,
      t?.bannerDashboard?.descriptionPurchaseSuffix
    )
  }

  if (hasActiveSubscription) {
    return (
      <div className="lg:sticky w-full sm:w-[600px] bottom-4 mb-4">
        <div className="flex flex-col items-start justify-between w-full h-full gap-2 p-5 rounded-lg shadow bg-secondary shadow-gray-100 sm:gap-4 sm:flex-row">
          <div className="flex flex-col">
            <div className="flex items-center gap-2 mb-2">
              <Typography
                variant="title-xl-medium"
                className="text-white dark:text-white"
                type="h1"
              >
                {currentPlan?.title}
              </Typography>
              <Badge className={currentPlan?.plan?.badgePlan?.color} size="sm">
                {currentPlan?.plan?.badgePlan?.label}
              </Badge>
            </div>
            <Typography
              variant="text-base-regular"
              className="text-white dark:text-white"
            >
              {currentPlan?.plan?.planRecurrence}{' '}
              {currentPlan?.plan?.renewsAt &&
                `| ${currentPlan?.plan?.renewsAt}`}
            </Typography>
          </div>
          {isAdmin && currentPlan?.href && (
            <Link href={currentPlan?.href}>
              <Button variant="default" className="w-full mt-2 md:w-fit">
                {currentPlan?.buttonLabel}
              </Button>
            </Link>
          )}
        </div>
      </div>
    )
  }

  return null
}

export default BannerDashboard
