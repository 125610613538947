import { useMemo } from 'react'
import useTranslation from '../useTranslation'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'

type OwnerType = {
  userAccountId: string
  userAccountName: string
  isUserAccountActive: boolean
  userAccountAvatar?: string
}

type UseCreateOptionsUser = {
  users: OwnerType[]
}

const useCreateOptionsUser = ({
  users
}: UseCreateOptionsUser): SelectGroupOption[] | SelectOption[] => {
  const { t } = useTranslation('selectUsers')

  return useMemo(() => {
    const usersActive: SelectOption[] = []
    const usersInactive: SelectOption[] = []

    users?.forEach((item: OwnerType) => {
      const {
        userAccountId,
        userAccountName,
        isUserAccountActive,
        userAccountAvatar
      } = item
      const formattedItem: SelectOption = {
        value: userAccountId,
        label: userAccountName,
        avatar: userAccountAvatar ? userAccountAvatar : true
      }

      if (isUserAccountActive) {
        usersActive.push(formattedItem)
      } else {
        usersInactive.push(formattedItem)
      }
    })

    const sortByName = (a: SelectOption, b: SelectOption) =>
      a.label.localeCompare(b.label)

    usersActive.sort(sortByName)
    usersInactive.sort(sortByName)

    const totalUsers = usersActive.length + usersInactive.length
    const hasInactiveUsers = usersInactive.length > 0

    const groupedData: SelectGroupOption[] = []

    if (!hasInactiveUsers && totalUsers === 1) {
      return usersActive
    }

    if (!hasInactiveUsers && totalUsers > 1) {
      return [
        {
          label: '',
          options: [
            {
              value: t?.optionAll?.value,
              label: t?.optionAll?.label,
              avatar: t?.optionAll?.avatar
            }
          ]
        },
        {
          label: '',
          options: usersActive
        }
      ]
    }

    if (totalUsers > 1) {
      groupedData.push({
        label: '',
        options: [
          {
            value: t?.optionAll?.value,
            label: t?.optionAll?.label,
            avatar: t?.optionAll?.avatar
          }
        ]
      })
    }

    if (usersActive.length > 0) {
      groupedData.push({
        label: t?.labelActiveUsers,
        options: usersActive
      })
    }

    if (hasInactiveUsers) {
      groupedData.push({
        label: t?.labelInactiveUsers,
        options: usersInactive
      })
    }

    return groupedData
  }, [
    t?.labelActiveUsers,
    t?.labelInactiveUsers,
    t?.optionAll?.avatar,
    t?.optionAll?.label,
    t?.optionAll?.value,
    users
  ])
}

export default useCreateOptionsUser
