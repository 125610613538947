import React, { useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { CgSpinner } from 'react-icons/cg'
import { tv } from 'tailwind-variants'
import { cn } from '@/lib/utils'

export interface IDropdownItem {
  key: string
  label: string
  onClick: (values?: any) => void
  values?: any
  hidden?: boolean
  dividerTop?: boolean
}

type DropdownButtonVariants =
  | 'primary'
  | 'neutral'
  | 'secondary'
  | 'secondaryOutline'
type ButtonSizes = 'sm' | 'md'

interface DropdownButtonProps {
  label: string
  onClick: (key: any, e: any) => void
  dropdownItems: IDropdownItem[]
  containerButtonClassName?: string
  buttonMainClassName?: string
  buttonMenuClassName?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  disabledItems?: boolean
  placement?: 'bottom' | 'top' | 'left' | 'right'
  loading?: boolean
  variant?: DropdownButtonVariants
  size?: ButtonSizes
  showSkeleton?: boolean
  timeoutSkeleton?: number
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  label,
  onClick,
  dropdownItems,
  containerButtonClassName,
  buttonMainClassName,
  buttonMenuClassName,
  disabled,
  disabledItems,
  showSkeleton,
  timeoutSkeleton,
  loading = false,
  type = 'button',
  placement = 'bottom',
  variant = 'primary',
  size = 'md'
}) => {
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoadingSkeleton(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const dropdownButton = tv({
    slots: {
      containerButton:
        'flex items-center border text-md rounded-md overflow-hidden',
      buttonMain:
        'h-full text-sm font-medium px-4 disabled:opacity-50 disabled:cursor-not-allowed',
      buttonSecondary:
        'h-full px-3 disabled:opacity-50 disabled:cursor-not-allowed',
      buttonMenu: 'h-full px-3 disabled:opacity-50 disabled:cursor-not-allowed'
    },
    variants: {
      variant: {
        primary: {
          containerButton: 'border-primary text-primary',
          buttonMain:
            'border-r border-accent-500 bg-primary hover:bg-primary-foreground text-accent-foreground dark:text-accent-100',
          buttonMenu:
            'text-accent-foreground bg-primary hover:bg-primary-foreground dark:text-accent-100'
        },
        secondary: {
          containerButton: 'border-secondary text-secondary',
          buttonMain:
            'border-r border-accent-100 bg-secondary hover:bg-secondary-foreground text-accent-100 dark:text-accent-900 dark:border-accent-900',
          buttonMenu:
            'text-accent-100 bg-secondary hover:bg-secondary-foreground dark:text-accent-900'
        },
        secondaryOutline: {
          containerButton: 'border-secondary text-secondary',
          buttonMain:
            'text-secondary bg-transparent border-r border-secondary hover:bg-secondary-foreground hover:text-accent-100 dark:text-secondary dark:hover:text-accent-900',
          buttonMenu:
            'text-secondary bg-transparent hover:bg-secondary-foreground hover:text-accent-100 dark:text-secondary dark:hover:text-accent-900'
        },
        neutral: {
          containerButton: 'border-gray-400 text-gray-700',
          buttonMain:
            'text-gray-700 hover:text-gray-100 bg-transparent border-r border-gray-400 hover:bg-gray-900',
          buttonMenu: 'text-gray-700 hover:bg-gray-900 hover:text-gray-100'
        }
      },
      size: {
        sm: {
          containerButton: 'h-8',
          buttonMain: 'text-sm',
          buttonMenu: 'text-sm'
        },
        md: {
          containerButton: 'h-10',
          buttonMain: 'text-sm lg:text-md',
          buttonMenu: 'text-sm lg:text-md'
        },
        lg: {
          containerButton: 'h-12',
          buttonMain: 'text-md lg:text-lg',
          buttonMenu: 'text-md lg:text-lg'
        }
      }
    }
  })

  const { containerButton, buttonMain, buttonMenu } = dropdownButton({
    variant,
    size
  })

  if (showSkeleton || isLoadingSkeleton) {
    return <div className="h-10 skeleton w-36" />
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div
        className={containerButton({
          class: cn(
            disabled ? 'border-opacity-50 rounded-md' : '',
            containerButtonClassName
          )
        })}
      >
        <button
          type={type}
          onClick={(e) => onClick?.('main', e)}
          className={buttonMain({
            class: cn(buttonMainClassName, 'relative')
          })}
          disabled={disabled || loading}
        >
          <div
            style={{ visibility: loading ? 'hidden' : 'visible' }}
            className="flex items-center gap-2"
          >
            {label}
          </div>
          {loading && (
            <CgSpinner className="absolute inset-0 m-auto text-xl animate-spin" />
          )}
        </button>
        <Menu.Button
          className={buttonMenu({
            class: buttonMenuClassName
          })}
          disabled={disabledItems || loading}
        >
          <EllipsisHorizontalIcon className="w-5 h-5 text-current" />
        </Menu.Button>
      </div>

      {!loading && (
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'absolute z-10 min-w-[200px] w-full rounded-md bg-accent-100 shadow-lg ring-1 ring-accent-100 ring-opacity-5 focus:outline-none',
              {
                'right-0 mt-2 origin-top-right': placement === 'bottom',
                'right-0 bottom-full mb-2 origin-bottom-right':
                  placement === 'top',
                'right-full mt-[-2px] origin-top-left': placement === 'left',
                'left-full mt-[-2px] origin-top-right': placement === 'right'
              }
            )}
          >
            <div className="flex flex-col gap-1 px-2 py-3">
              {!disabledItems &&
                dropdownItems?.map((item) => {
                  if (item.hidden) return null
                  return (
                    <Menu.Item key={item.key}>
                      {({ active }) => (
                        <button
                          onClick={(e) => {
                            onClick?.(item.key, e)
                          }}
                          className={classNames(
                            active ? 'bg-gray-50 dark:bg-gray-100' : '',
                            'block px-3 py-2 text-sm w-full text-left rounded-md hover:bg-gray-50 text-gray-700',
                            item.dividerTop
                              ? 'border-t border-gray-200 rounded-t-none hover:rounded-t-md'
                              : ''
                          )}
                        >
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  )
                })}
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  )
}

export default DropdownButton
