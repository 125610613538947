import React from 'react'
import CardSignerMark from '../CardSignerMark'
import { SignerEnvelopeStatus } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { useEnvelope } from '@/providers'
import { ISelectedSignerMarkData } from '@/providers/Envelope/Envelope.types'

type Props = {
  handleClickCardSignMark: (
    selectedSignerMarkData: ISelectedSignerMarkData
  ) => void
}

const AreaSignersToSign: React.FC<Props> = ({ handleClickCardSignMark }) => {
  const { signers, selectedSignerMarkData, valuesSelectDocuments, noSigners } =
    useEnvelope()

  return (
    <div
      hidden={noSigners}
      className={`flex flex-col w-full gap-4 ${
        valuesSelectDocuments.length > 1 ? 'mt-6' : ''
      }`}
    >
      {signers?.map((signer) => {
        const currentSignerHasReviewed =
          signer?.status === SignerEnvelopeStatus.Approved ||
          signer?.status === SignerEnvelopeStatus.Rejected

        return (
          <CardSignerMark
            signer={signer}
            key={signer.email}
            selectedSignerMarkData={selectedSignerMarkData}
            handleClickCardSignMark={handleClickCardSignMark}
            disabled={currentSignerHasReviewed}
          />
        )
      })}
    </div>
  )
}

export default AreaSignersToSign
