import React, { useCallback, useEffect, useState } from 'react'
import { MdAdd, MdRemove } from 'react-icons/md'
import { cn } from '@/lib/utils'

type Props = {
  valueInitial?: number
  onCallback?: (value: number) => void
  min?: number
  max?: number
  step?: number
  value?: number
  onChange?: (value: number) => void
}

const ControlButtons: React.FC<Props> = ({
  valueInitial = 1,
  onCallback,
  min = 1,
  max = Infinity,
  step = 1,
  value,
  onChange
}) => {
  const [internalQuantity, setInternalQuantity] = useState(
    Math.min(Math.max(valueInitial, min), max)
  )

  const quantity = value !== undefined ? value : internalQuantity

  const updateQuantity = useCallback(
    (newQuantity: number) => {
      const clampedQuantity = Math.min(Math.max(newQuantity, min), max)
      if (onChange) {
        onChange(clampedQuantity)
      } else {
        setInternalQuantity(clampedQuantity)
      }
      if (onCallback) {
        onCallback(clampedQuantity)
      }
    },
    [max, min, onChange, onCallback]
  )

  const handleDecrement = useCallback(() => {
    updateQuantity(quantity - step)
  }, [quantity, updateQuantity, step])

  const handleIncrement = useCallback(() => {
    updateQuantity(quantity + step)
  }, [quantity, updateQuantity, step])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newQuantity = Math.max(min, Number(e.target.value))
      updateQuantity(newQuantity)
    },
    [updateQuantity, min]
  )

  useEffect(() => {
    if (value === undefined) {
      setInternalQuantity(Math.min(Math.max(valueInitial, min), max))
    }
  }, [valueInitial, min, max, value])

  const baseButtonClasses =
    'p-3 border border-primary-700 text-primary-700 rounded-full hover:bg-primary-700 hover:text-accent-900 h-10 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-primary-700'

  return (
    <form className="max-w-xs">
      <div className="relative flex items-center max-w-[130px]">
        <button
          type="button"
          className={cn(baseButtonClasses)}
          disabled={quantity === min}
          onClick={handleDecrement}
        >
          <MdRemove className="w-4 h-4 text-current" />
        </button>
        <input
          type="number"
          id="quantity-input"
          className="bg-transparent border-0 h-10 text-center text-accent-900 text-sm focus:ring-transparent focus:ring-1 focus:outline-none focus:border-none block w-full py-2.5 px-1"
          required
          value={quantity}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
        />
        <button
          type="button"
          className={cn(baseButtonClasses)}
          onClick={handleIncrement}
          disabled={quantity === max}
        >
          <MdAdd className="w-4 h-4 text-current" />
        </button>
      </div>
    </form>
  )
}

export default ControlButtons
