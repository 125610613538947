import { useMemo } from 'react'
import {
  UsersIcon,
  DocumentIcon,
  EnvelopeIcon,
  DocumentPlusIcon,
  LockClosedIcon,
  UserGroupIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline'
import { useLocale } from '@/providers/Locale'
import {
  NavigationItem,
  QuickAction,
  Section
} from './useCommandPalletSearchBar.types'
import useDebounce from '../useDebounce'
import useTranslation from '../useTranslation'
import useSearchApi from '@/hooks/api/platform/useSearchApi/useSearchApi'
import { handlePlural, normalizeText } from '@/utils'

const useCommandPalletSearchBar = () => {
  const [query, setQuery] = useDebounce<string>('', 300)

  const { t } = useTranslation(['commandPalletSearchBar', 'sidebar'], true)
  const { lang } = useLocale()

  const { useCommandPalletSearchBar } = useSearchApi()
  const {
    data: dataCommandPaletteSearchBar,
    isLoading: isLoadingCommandPaletteSearchBar,
    fetchStatus: fetchStatusCommandPaletteSearchBar,
    isStale: isStaleCommandPaletteSearchBar
  } = useCommandPalletSearchBar(query)

  const minLengthQuery = 3

  const dataFilteredPagesSidebar = useMemo(() => {
    if (!t?.navigation || query.length <= 1) return []

    let filteredItems: NavigationItem[] = []

    const normalizedQuery = handlePlural(normalizeText(query), lang)

    t?.navigation?.forEach((group: any) => {
      const matchingItems = group.items.filter((item: any) =>
        handlePlural(normalizeText(item.label), lang).includes(normalizedQuery)
      )
      filteredItems = [...filteredItems, ...matchingItems]
    })

    const simplifiedItems = filteredItems.map(({ id, label, href, icon }) => ({
      id,
      text: label,
      icon: icon,
      url: href
    }))

    return simplifiedItems
  }, [lang, query, t?.navigation])

  const dataFiltered: Section[] = useMemo(() => {
    return [
      {
        id: 1,
        titleSection: t?.vaults,
        items: dataCommandPaletteSearchBar?.vaults?.map(
          ({ vaultId, vaultName }) => ({
            id: vaultId,
            text: vaultName,
            icon: LockClosedIcon,
            url: `/vaults/${vaultId}`
          })
        )
      },
      {
        id: 2,
        titleSection: t?.users,
        items: dataCommandPaletteSearchBar?.userAccounts?.map(
          ({ userAccountId, userAccountName, userAccountEmail, isActive }) => ({
            id: userAccountId,
            text: [userAccountName, userAccountEmail]
              .filter(Boolean)
              .join(' - '),
            icon: UsersIcon,
            url: `/users?typeView=${isActive ? 'active' : 'inactive'}`
          })
        )
      },
      {
        id: 3,
        titleSection: t?.contacts,
        items: dataCommandPaletteSearchBar?.contacts?.map(
          ({ contactId, contactName, contactEmail }) => ({
            id: contactId,
            text: [contactName, contactEmail].filter(Boolean).join(' - '),
            icon: UserGroupIcon,
            url: `/contacts?triggerViewContact=${contactId}`
          })
        )
      },
      {
        id: 4,
        titleSection: t?.envelopes,
        items: dataCommandPaletteSearchBar?.envelopes?.map(
          ({ envelopeId, envelopeName }) => ({
            id: envelopeId,
            text: envelopeName,
            icon: EnvelopeIcon,
            url: `/envelope/${envelopeId}/view`
          })
        )
      },
      {
        id: 5,
        titleSection: t?.documents,
        items: dataCommandPaletteSearchBar?.documents?.map(
          ({ documentId, documentName, envelopeId }) => ({
            id: documentId,
            text: documentName,
            icon: DocumentIcon,
            url: `/envelope/${envelopeId}/view?documentId=${documentId}`
          })
        )
      },
      {
        id: 6,
        titleSection: t?.templates,
        items: dataCommandPaletteSearchBar?.templates?.map(
          ({ templateId, templateName }) => ({
            id: templateId,
            text: templateName,
            icon: DocumentTextIcon,
            url: `/template/${templateId}`
          })
        )
      }
    ]
  }, [
    dataCommandPaletteSearchBar?.contacts,
    dataCommandPaletteSearchBar?.documents,
    dataCommandPaletteSearchBar?.envelopes,
    dataCommandPaletteSearchBar?.templates,
    dataCommandPaletteSearchBar?.userAccounts,
    dataCommandPaletteSearchBar?.vaults,
    t?.contacts,
    t?.documents,
    t?.envelopes,
    t?.templates,
    t?.users,
    t?.vaults
  ])

  const quickActions: QuickAction[] = useMemo(() => {
    return [
      {
        id: 1,
        name: t?.createNewEnvelope,
        icon: DocumentPlusIcon,
        url: '/envelope'
      },
      {
        id: 2,
        name: t?.createNewTemplate,
        icon: DocumentDuplicateIcon,
        url: '/template'
      },
      {
        id: 3,
        name: t?.createNewContact,
        icon: UserGroupIcon,
        url: '/contacts?triggerCreateContact=true'
      }
    ]
  }, [t?.createNewContact, t?.createNewEnvelope, t?.createNewTemplate])

  const isDataEmpty = useMemo(() => {
    return dataFiltered.every(
      (section) => section?.items?.length === 0 || section.items === undefined
    )
  }, [dataFiltered])

  const isQueryEmpty = useMemo(() => {
    return (
      (query === '' || query?.length < minLengthQuery) &&
      !isLoadingCommandPaletteSearchBar
    )
  }, [isLoadingCommandPaletteSearchBar, query])

  const shouldShowNoData = useMemo(() => {
    return (
      query.length > minLengthQuery &&
      isDataEmpty &&
      !isLoadingCommandPaletteSearchBar
    )
  }, [isDataEmpty, isLoadingCommandPaletteSearchBar, query])

  const shouldShowResults = useMemo(() => {
    return !isDataEmpty && query?.length >= minLengthQuery
  }, [isDataEmpty, query?.length])

  const isLoading = useMemo(
    () =>
      isLoadingCommandPaletteSearchBar &&
      fetchStatusCommandPaletteSearchBar === 'fetching' &&
      isStaleCommandPaletteSearchBar,
    [
      fetchStatusCommandPaletteSearchBar,
      isLoadingCommandPaletteSearchBar,
      isStaleCommandPaletteSearchBar
    ]
  )

  return {
    query,
    setQuery,
    dataFiltered,
    dataFilteredPagesSidebar,
    isLoading,
    isQueryEmpty,
    shouldShowNoData,
    shouldShowResults,
    quickActions
  }
}

export default useCommandPalletSearchBar
