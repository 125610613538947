import React, { useMemo } from 'react'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { Modal } from '..'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ModalProcessingActionsEnvelope: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const { t, isReady } = useTranslation('viewEnvelope')

  const loadedT = useMemo(() => {
    if (t?.modalProcessingActions || !isReady) return true
    return false
  }, [isReady, t?.modalProcessingActions])

  return (
    <Modal isOpen={isOpen} size="fit" hiddenHeader>
      <div className="flex flex-col items-center gap-6 py-4 w-full md:w-[430px]">
        <div className="flex flex-col items-center w-full gap-2">
          <Image
            src="/assets/animations/animation-processing-actions.gif"
            width={150}
            height={150}
            alt="Processing actions"
          />
          <Typography
            variant="title-2xl-medium"
            className="text-center text-gray-700"
            showSkeleton={!loadedT}
            classNameSkeleton="w-full"
          >
            {t?.modalProcessingActions?.title}
          </Typography>
          <Typography
            variant="text-base-regular"
            className="text-center text-gray-700"
            showSkeleton={!loadedT}
            classNameSkeleton="w-2/3"
          >
            {t?.modalProcessingActions?.subtitle}
          </Typography>
          <Button onClick={onClose}>{t?.modalProcessingActions?.button}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalProcessingActionsEnvelope
