import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  CreateUserInviteRequest,
  IUsersInvite
} from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useInviteApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { authMetadata } = useAuth()

  const useUsersInvite = (
    pageNumber = 0,
    pageSize = 10,
    typeView: 'active' | 'inactive' | 'invite'
  ) =>
    useQuery({
      queryKey: [QueryKeys.UsersInvite, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, IUsersInvite>(
          `/administration/invites?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && typeView === 'invite'
    })

  const useDeleteUserInvite = () =>
    useMutation({
      mutationFn: (id: string) => {
        return handleApi<void, void>(`/administration/invites/${id}`, 'DELETE')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UsersInvite]
        })
      }
    })

  const useCreateUserInvite = () =>
    useMutation({
      mutationFn: (data: CreateUserInviteRequest) => {
        return handleApi<CreateUserInviteRequest, void>(
          `/administration/invites`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UsersInvite]
        })
      }
    })

  return {
    useUsersInvite,
    useDeleteUserInvite,
    useCreateUserInvite
  }
}

export default useInviteApi
