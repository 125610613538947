import { MarkupOrientationType, OwnerType } from '@/types/global'
import { Lang } from '@/types/global'
import { Pagination } from '@/hooks/useApi/useApi.types'

export type PublishEnvelopeRequest = {
  id: string
}

export enum SignerEnvelopeStatus {
  None = 'None',
  ReadyToReview = 'ReadyToReview',
  Queued = 'Queued',
  Approved = 'Approved',
  Rejected = 'Rejected',
  CancelledByMfaError = 'CancelledByMfaError'
}

export enum ActionType {
  View = 'View',
  Approval = 'Approval'
}

export type Action = {
  id: string
  createdAtUtc: string
  type: keyof typeof ActionType
  ip: string
  userAgent: string
  location: string
}

export type SignerEnvelope = {
  id?: string
  index: number
  status: keyof typeof SignerEnvelopeStatus
  name: string
  email: string
  role: RoleEnvelope
  title: string
  shouldEnforceEmailValidation?: boolean
  shouldEnforcePasscodeValidation?: boolean
  passcode?: string | null
  passcodeHint?: string | null
  shouldEnforceSmsValidation?: boolean
  phoneIdd?: any
  phoneNumber?: any
  signMarks: SignMarkEnvelopeType[]
}

export type SignerEnvelopeResponse = SignerEnvelope & {
  actions: Action[]
}

export enum EnvelopeStatus {
  Draft = 'Draft', // Rascunho
  PublishScheduled = 'PublishScheduled', // Publicação agendada
  Published = 'Published', // Publicado
  Hold = 'Hold', // Em espera
  Cancelled = 'Cancelled', // Cancelado
  CancelledBySignerMfaError = 'CancelledBySignerMfaError', // Cancelado por erro de MFA do signatário
  Rejected = 'Rejected', // Rejeitado
  Signed = 'Signed', // Assinado
  Expired = 'Expired' // Expirado
}

export type EnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type EnvelopeDataRequestWithId = EnvelopeDataRequest & {
  id: string | null
}

export type EnvelopeDataResponse = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar: string | null
  updatedAtUtc: string
  lastUpdateById: string
  updatedByName: string
  updatedByAvatar: string | null
  userAccountOwnerId: string
  userAccountOwnerName: string
  userAccountOwnerAvatar: string | null
  vaultName: string | null
  vaultId: string | null
  name: string
  privateDescription: string | null
  publicDescription: string | null
  status: keyof typeof EnvelopeStatus
  message: string
  reviewReminder: boolean
  expiresAtUtc: string
  expirationReminder: boolean
  toBePublishedAtUtc: string
  language: Lang
  markupOrientation: MarkupOrientationType
  signInOrder: boolean
  certifiedAtUtc: string | null
  signers: SignerEnvelopeResponse[]
  documents: DocumentEnvelope[]
  hasActionsBeingProcessed: boolean
  hasScheduledPublish: boolean
  isSandbox: boolean
  isRemoved: boolean
}

export type EnvelopeRequest = {
  vaultId: string
  name: string
  privateDescription?: string | null
  publicDescription?: string | null
  message?: string | null
  reviewReminder?: boolean
  expiresAtUtc?: Date | null
  expirationReminder?: boolean
  toBePublishedAtUtc?: Date | null
  language?: any
  markupOrientation?: any
  signInOrder?: boolean
  signers?: SignerEnvelope[]
  documents?: DocumentEnvelope[]
}

export type EnvelopeResponse = {
  envelopeId: string
}

export type UpdateEnvelopeRequestWithId = EnvelopeRequest & {
  id: string
}

export type RenameEnvelopesRequest = {
  name: string
}

export type RenameEnvelopesRequestWithId = RenameEnvelopesRequest & {
  id: string
}

type RoleEnvelope = 'Sign' | 'Approve'

export type MarkTypeEnvelope = 'Signature' | 'Rubric' | 'Free-Signature'

export type SignMarkEnvelopeType = {
  id?: string
  documentId: string
  type: MarkTypeEnvelope
  page: number
  x: number
  y: number
  rotation: number
}

export type PageSizeEnvelopeType = {
  page: number
  width: number
  height: number
}

export type DocumentEnvelope = {
  id: string
  index: number
  name: string
  privateDescription: string | null
  publicDescription: string | null
  pageSizes: PageSizeEnvelopeType[]
}

export type PreReviewEnvelopeDataResponse = {
  status: string
  envelopeId: string | null
  signerId: string | null
}

export type SignMark = {
  id: string
  documentId: string
  type: 'Rubric' | 'Signature'
  page: number
  x: number
  y: number
  rotation: number
}

export type DocumentType = {
  id: string
  fileName: string
  index: number
  name: string
  description: string
  pageSizes: PageSizeEnvelopeType[]
}

export type ReviewEnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type ReviewEnvelopeDataRequestWithId = ReviewEnvelopeDataRequest & {
  id: string
}

export type ReviewEnvelopeDataResponse = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar: string
  updatedAtUtc: string | null
  lastUpdateById: string | null
  updatedByName: string | null
  updatedByAvatar: string | null
  userAccountOwnerId: string
  userAccountOwnerName: string
  userAccountOwnerAvatar: string
  language: Lang
  name: string
  description: string
  signInOrder: boolean
  expiresAtUtc: string | null
  isSandbox: boolean
  hasActionsBeingProcessed: boolean
  documents: DocumentType[]
  signers: {
    id: string
    isCurrentSigner: boolean
    index: number
    status: string
    hadApproved: boolean
    approvedAtUtc: string
    hadRejected: boolean
    rejectedAtUtc: string
    name: string
    email: string
    role: string
    title: string
    shouldEnforceEmailValidation: boolean
    shouldEnforceSmsValidation: boolean
    shouldEnforcePasscodeValidation: boolean
    passcodeHint?: string | null
    phoneIdd?: number
    phoneNumber?: string
  }[]
  signMarks: SignMark[]
  actions: {
    id: string
    createdAtUtc: string
    type: string
    ip: string
    userAgent: string
    location: string
  }[]
}

export type RequestEmailMFA = {
  frontendVersion?: string
  recaptchaToken?: string
}

export type RequestEmailMFAWithId = RequestEmailMFA & {
  id: string
}

export type RequestSmsMFA = {
  frontendVersion?: string
  recaptchaToken?: string
}

export type RequestSmsMFAWithId = RequestSmsMFA & {
  id: string
}

export type ApproveEnvelopeRequest = {
  frontendVersion: string
  emailCode?: string
  smsCode?: string
  passcode?: string
  signatureMarkId?: string | null
  signatureMarkBase64?: string | null
  rubricMarkId?: string | null
  rubricMarkBase64?: string | null
  recaptchaToken: string
}

export type ApproveEnvelopeRequestWithId = ApproveEnvelopeRequest & {
  id: string
}

export type RejectEnvelopeRequest = {
  reason?: string
  frontendVersion: string
  recaptchaToken: string
}

export type RejectEnvelopeRequestWithId = RejectEnvelopeRequest & {
  id: string
}

export type EnvelopeRestoreRequest = {
  vaultId: string
}

export type EnvelopeRestoreRequestWithId = EnvelopeRestoreRequest & {
  id: string
}

export type OwnersResponse = {
  items: OwnerType[]
}

export type EnvelopeType = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar?: string
  updatedAtUtc?: string
  lastUpdateById?: string
  updatedByName?: string
  updatedByAvatar?: string
  name: string
  status: keyof typeof EnvelopeStatus
  vaultId: string
  vaultName: string
  signers: {
    name: string
    email: string
  }[]
}

export type EnvelopesListResponse = {
  items: EnvelopeType[]
  pagination: Pagination
}

export type EnvelopeUnsubscribeRequest = {
  frontendVersion?: string
  reason?: string
  recaptchaToken: string
}

export type EnvelopeUnsubscribeRequestWithId = EnvelopeUnsubscribeRequest & {
  id: string
}

export type EnvelopePublishedType = EnvelopeType & {
  publishedAtUtc: string | null
  publishedById: string | null
  publishedByName: string | null
  publishedByAvatar: string | null
}

export type EnvelopeCancelledType = EnvelopeType & {
  cancelledAtUtc: string | null
  cancelledById: string | null
  cancelledByName: string | null
  cancelledByAvatar: string | null
}

export type EnvelopeExpiredType = EnvelopeType & {
  expiredAtUtc: string | null
}

export type EnvelopeSignedType = EnvelopeType & {
  signedAtUtc: string | null
}

export type EnvelopePublishScheduledType = EnvelopeType & {
  scheduledToBePublishedAtUtc: string | null
}

export type EnvelopeRejectedType = EnvelopeType & {
  rejectedAtUtc: string | null
  rejectedBySignerName: string | null
  rejectedBySignerEmail: string | null
}

export type EnvelopeCancelledBySignerMfaError = EnvelopeType & {
  cancelledAtUtc: string | null
  cancelledBySignerName: string | null
  cancelledBySignerEmail: string | null
}

export type EnvelopeExpireSoon = EnvelopeType & {
  expiresAtUtc: string | null
}

export type EnvelopesListPublishedResponse = {
  items: EnvelopePublishedType[]
  pagination: Pagination
}

export type EnvelopesListCancelledResponse = {
  items: EnvelopeCancelledType[]
  pagination: Pagination
}

export type EnvelopesListExpiredResponse = {
  items: EnvelopeExpiredType[]
  pagination: Pagination
}

export type EnvelopesListSignedResponse = {
  items: EnvelopeSignedType[]
  pagination: Pagination
}

export type EnvelopesListPublishScheduledResponse = {
  items: EnvelopePublishScheduledType[]
  pagination: Pagination
}

export type EnvelopesListRejectedResponse = {
  items: EnvelopeRejectedType[]
  pagination: Pagination
}

export type EnvelopesListCancelledBySignerMfaErrorResponse = {
  items: EnvelopeCancelledBySignerMfaError[]
  pagination: Pagination
}

export type EnvelopesListExpireSoonResponse = {
  items: EnvelopeExpireSoon[]
  pagination: Pagination
}

export type EnvelopePendingType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  pendingSinceUtc: string | null
  signerId: string
}

export type EnvelopesListPendingResponse = {
  items: EnvelopePendingType[]
  pagination: Pagination
}

export type EnvelopeApprovedByMeType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  approvedAtUtc: string | null
  name: string
}

export type EnvelopesListApprovedByMeResponse = {
  items: EnvelopeApprovedByMeType[]
  pagination: Pagination
}

export type EnvelopeRejectedByMeType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  rejectedAtUtc: string | null
  name: string
}

export type EnvelopesListRejectedByMeResponse = {
  items: EnvelopeRejectedByMeType[]
  pagination: Pagination
}

export type EnvelopeSignatureLinkRequest = {
  envelopeId: string
  signerId: string
}

export type EnvelopeSignatureLinkResponse = {
  id: string
}

export type PreReviewEnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type PreReviewEnvelopeDataRequestWithId =
  PreReviewEnvelopeDataRequest & {
    id: string
  }
