import React, { useCallback } from 'react'
import Image from 'next/image'
import { useTemplatesData } from '@/hooks/useTemplatesData'
import useTranslation from '@/hooks/useTranslation'
import { FiltersKeyTemplates } from '@/hooks/useTemplatesData/useTemplatesData.types'
import { useAuth, useEnvelope } from '@/providers'
import { InputSearch, Select, Typography } from '@/ui/atoms'
import {
  CardItemTemplate,
  CardItemTemplateSkeleton,
  FilterDropdown,
  Pagination
} from '@/ui/molecules'

const SectionListTemplates: React.FC = () => {
  const { t, isReady } = useTranslation('envelope')

  const { isAdmin, userContext } = useAuth()
  const {
    currentData,
    optionsUser,
    pageNumber,
    setPageNumber,
    pageSize,
    filters,
    handleApplyFilters,
    isLoadingTemplateOwners,
    preparedFilters,
    setPreparedFilters
  } = useTemplatesData({
    type: 'envelopeListTemplate'
  })
  const { setViewType, hasReviewed } = useEnvelope()

  const handleClickCardTemplate = useCallback(
    (id: string) => {
      setViewType({
        type: 'template',
        templateId: id
      })
    },
    [setViewType]
  )

  const isEmpty = currentData.data?.items.length === 0 && !currentData.isLoading

  return (
    <div className="flex flex-col h-full gap-6 lg:gap-8">
      <div className="flex items-center justify-between w-full gap-4">
        <Typography
          variant="title-lg-regular"
          className="text-base text-gray-950 font-display w-max"
          showSkeleton={!t?.sectionListTemplates?.title}
          classNameSkeleton="w-32"
        >
          {t?.sectionListTemplates?.title}
        </Typography>
        {!hasReviewed && (
          <FilterDropdown
            filtersSchema={[
              {
                id: FiltersKeyTemplates.USER,
                type: 'select',
                label: t?.sectionListTemplates?.selectLabelUser,
                initialValue: userContext?.userAccountInformation?.id,
                options: optionsUser,
                showSkeleton: !isReady || isLoadingTemplateOwners,
                hidden: !isAdmin,
                isActive: Boolean(
                  isAdmin &&
                    !!filters?.user &&
                    filters?.user !== userContext?.userAccountInformation?.id
                ),
                disabled: hasReviewed
              },
              {
                id: FiltersKeyTemplates.QUERY,
                type: 'inputSearch',
                label: t?.sectionListTemplates?.searchLabelTemplates,
                placeholder: t?.sectionListTemplates?.searchPlaceholder,
                initialValue: '',
                isActive: Boolean(filters?.query),
                showSkeleton:
                  !isReady ||
                  (isAdmin && isLoadingTemplateOwners) ||
                  !t?.sectionListTemplates?.searchLabelTemplates
              }
            ]}
            values={{
              user:
                (preparedFilters?.user as string) ||
                userContext?.userAccountInformation?.id,
              query: preparedFilters?.query || ''
            }}
            setPreparedValues={setPreparedFilters}
            onFilter={(filters) => {
              setPageNumber(0)
              handleApplyFilters({
                user:
                  filters?.user === userContext?.userAccountInformation?.id
                    ? null
                    : filters?.user,
                query: filters?.query as string
              })
            }}
            showSkeleton={!isReady}
          />
        )}
      </div>
      {isEmpty ? (
        <div className="flex flex-col items-center justify-center gap-2 pt-2 pb-4 lg:py-20">
          <Image
            src="/assets/icons/folder-dashboard.svg"
            alt="Folder Dashboard"
            width={158}
            height={130}
          />
          <div>
            <Typography variant="title-lg-medium" className="text-center">
              {t?.sectionListTemplates?.emptyState?.title}
            </Typography>
            <Typography variant="text-sm-regular" className="text-center">
              {t?.sectionListTemplates?.emptyState?.description}
            </Typography>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-3 md:gap-4 lg:grid-cols-3 xl:grid-cols-4">
            {currentData.isLoading &&
              Array.from({ length: 12 }).map((_, index) => (
                <CardItemTemplateSkeleton key={index} />
              ))}
            {!currentData.isLoading &&
              currentData.data?.items.map(({ id, name, createdAtUtc }) => (
                <CardItemTemplate
                  key={id}
                  id={id}
                  name={name}
                  createdAtUtc={createdAtUtc}
                  handleClickCard={handleClickCardTemplate}
                />
              ))}
          </div>
          <Pagination
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentData?.data?.pagination &&
                currentData?.data?.pagination?.totalItems /
                  currentData?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            totalPages={Math.ceil(
              (currentData.data?.pagination.totalItems || 0) / pageSize
            )}
            pageSize={pageSize}
            totalItems={currentData.data?.pagination.totalItems || 0}
          />
        </div>
      )}
    </div>
  )
}

export default SectionListTemplates
