import React from 'react'
import { useTemplate } from '@/providers/Template'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'
import { SectionDataTemplateDetails } from '@/ui/organisms'

const DrawerTemplateDetails: React.FC = () => {
  const {
    openDrawerTemplateDetails,
    setOpenDrawerTemplateDetails,
    dataDrawerActionField
  } = useTemplate()

  return (
    <Drawer.Drawer
      open={openDrawerTemplateDetails && !dataDrawerActionField.open}
      onOpenChange={(boolean) => {
        if (!boolean) {
          setOpenDrawerTemplateDetails(boolean)
        }
      }}
    >
      <Drawer.DrawerContent className="w-full rounded-none max-h-[95vh]">
        <SectionDataTemplateDetails type="drawer" />
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerTemplateDetails
