import React, { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelope } from '@/providers/index'
import {
  Modal,
  Form,
  RenderFormRow,
  RenderFormField,
  RenderFormActions
} from '..'
import { Button } from '@/ui/atoms/shadcn'

type FormValues = {
  name: string
  privateDescription: string
  publicDescription: string
}

type Props = {
  documentId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ModalEditInformationDocument: React.FC<Props> = ({
  documentId,
  isOpen,
  setIsOpen
}) => {
  const { t } = useTranslation(['envelope', 'validations'], true)
  const { documents, setDocuments } = useEnvelope()

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .max(100, { message: t?.errorMaxLength?.(200) }),
    privateDescription: z
      .string()
      .max(4000, { message: t?.errorMaxLength?.(4000) }),
    publicDescription: z
      .string()
      .max(4000, { message: t?.errorMaxLength?.(4000) })
  })

  const onSubmit = useCallback(
    async (values) => {
      const newDataDocuments = documents?.map((file) => {
        if (file.id === documentId) {
          return {
            ...file,
            name: values?.name,
            privateDescription: values?.privateDescription,
            publicDescription: values?.publicDescription
          }
        }
        return file
      })
      setDocuments(newDataDocuments)

      setIsOpen(false)
    },
    [documentId, documents, setDocuments, setIsOpen]
  )

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      privateDescription: '',
      publicDescription: ''
    }
  })

  useEffect(() => {
    if (documents) {
      const file = documents?.find((file) => file.id === documentId)
      form.reset({
        name: file?.name ? file?.name.replace('.pdf', '') : '',
        privateDescription: file?.privateDescription || '',
        publicDescription: file?.publicDescription || ''
      })
    }
  }, [documentId, documents, form])

  return (
    <Modal
      title={t?.editInformationDocument?.title}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      displayAsDrawerOnMobile
    >
      <Form {...form} onSubmit={onSubmit}>
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          {...form.formState.errors.name}
          {...t?.editInformationDocument?.form?.name}
        />
        <RenderFormField
          control={form.control}
          name="privateDescription"
          type="textarea"
          {...form.formState.errors.privateDescription}
          {...t?.editInformationDocument?.form?.privateDescription}
        />
        <RenderFormField
          control={form.control}
          name="publicDescription"
          type="textarea"
          {...form.formState.errors.publicDescription}
          {...t?.editInformationDocument?.form?.publicDescription}
        />
        <RenderFormActions>
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
            className="w-fit"
            variant="neutral"
            type="button"
          >
            {t?.editInformationDocument?.form?.buttonCancel?.label}
          </Button>
          <Button className="w-fit" type="submit">
            {t?.editInformationDocument?.form?.buttonSubmitSave?.label}
          </Button>
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalEditInformationDocument
