import { useCallback, useEffect, useState } from 'react'
import { SunIcon } from '@heroicons/react/24/outline'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation'
import { ButtonWithTooltip } from '@/ui/molecules'

const SelectTheme = () => {
  const { t } = useTranslation('selectTheme')
  const { theme, setTheme } = usePersistedTheme()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const handleThemeChange = useCallback(() => {
    if (isMounted) {
      setTheme(theme.toLowerCase() === 'dark' ? 'Light' : 'Dark')
    }
  }, [isMounted, setTheme, theme])

  if (!isMounted) {
    return null
  }

  return (
    <ButtonWithTooltip
      type="button"
      variant="unstyled"
      className="text-gray-400"
      onClick={handleThemeChange}
      tooltipText={t?.theme}
    >
      <span className="sr-only">{t?.theme}</span>
      <SunIcon className="w-5 h-5" aria-hidden="true" />
    </ButtonWithTooltip>
  )
}

export default SelectTheme
