import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  Templates,
  CreateTemplateRequest,
  UpdateTemplateRequest,
  UpdateTemplateRequestWithId,
  CreateTemplateResponse,
  TemplatesOwners,
  TemplateResponse,
  MoveTemplateRequestWithId,
  MoveTemplateRequest,
  RenameTemplateRequest,
  RenameTemplateRequestWithId,
  TemplateRestoreRequestWithId,
  TemplateRestoreRequest
} from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useTemplateApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()
  const queryClient = useQueryClient()

  const useTemplateOwners = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.TemplatesOwners],
      queryFn: () =>
        handleApi<void, TemplatesOwners>('/ecm/templates/owners', 'GET'),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useGetTemplate = (id: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.Template, id],
      queryFn: () =>
        handleApi<void, TemplateResponse>(`/ecm/templates/${id}`, 'GET'),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!id
    })
  }

  const useTemplateListAccountUsers = (
    pageNumber = 0,
    pageSize = 10,
    query: string | null,
    id?: string | null,
    allUsers = false,
    enabled = true
  ) => {
    const basePathUserAccount = '/ecm/templates/user-accounts'

    const url = allUsers
      ? '/ecm/templates/accounts'
      : id
        ? `${basePathUserAccount}/${id}`
        : basePathUserAccount

    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (query) {
      queryParams += `&search=${encodeURIComponent(query)}`
    }

    const queryKey = [
      QueryKeys.Templates,
      pageNumber,
      pageSize,
      query,
      id,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      if (!query) return
      const handler = setTimeout(() => {
        setDebouncedQuery(query)
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }, [query])

    const { data, error, isLoading, isSuccess, isError, isFetching } = useQuery(
      {
        queryKey,
        queryFn: () => handleApi<void, Templates>(url + queryParams, 'GET'),
        ...queryConfig,
        enabled:
          !!authMetadata?.accessToken &&
          (debouncedQuery === query || !query) &&
          enabled
      }
    )

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useCreateTemplate = () =>
    useMutation({
      mutationFn: (data: CreateTemplateRequest) => {
        return handleApi<CreateTemplateRequest, CreateTemplateResponse>(
          `/ecm/templates`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Template]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useUpdateTemplate = () =>
    useMutation({
      mutationFn: ({ id, ...data }: UpdateTemplateRequestWithId) => {
        return handleApi<UpdateTemplateRequest, void>(
          `/ecm/templates/${id}`,
          'PUT',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Template]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useDeleteTemplate = () =>
    useMutation({
      mutationFn: (id: string) => handleApi(`/ecm/templates/${id}`, 'DELETE'),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Template]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useMoveTemplate = () =>
    useMutation({
      mutationFn: ({ id, ...data }: MoveTemplateRequestWithId) => {
        return handleApi<MoveTemplateRequest, void>(
          `/ecm/templates/${id}/move`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Template]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useRenameTemplate = () =>
    useMutation({
      mutationFn: ({ id, ...data }: RenameTemplateRequestWithId) => {
        return handleApi<RenameTemplateRequest, void>(
          `/ecm/templates/${id}/rename`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Template]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
      }
    })

  const useTemplateRestore = () =>
    useMutation({
      mutationFn: ({ id, ...data }: TemplateRestoreRequestWithId) =>
        handleApi<TemplateRestoreRequest, void>(
          `/ecm/templates/${id}/restore`,
          'POST',
          data
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RecycleBinListTemplatesAccounts]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RecycleBinTemplatesUserAccounts]
        })
      }
    })

  return {
    useTemplateOwners,
    useGetTemplate,
    useTemplateListAccountUsers,
    useCreateTemplate,
    useUpdateTemplate,
    useDeleteTemplate,
    useMoveTemplate,
    useRenameTemplate,
    useTemplateRestore
  }
}

export default useTemplateApi
