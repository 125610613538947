import React from 'react'
import Link from 'next/link'
import useTranslation from '@/hooks/useTranslation'
import { Modal } from '..'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  isOpen: boolean
  action: 'invite' | 'activate' | null
  onClose: () => void
  onBuyMoreLicenses: () => void
}

const ModalNoLicensedUsers: React.FC<Props> = ({
  isOpen,
  action,
  onClose,
  onBuyMoreLicenses
}) => {
  const { t, isReady } = useTranslation('modalNoLicensedUsers')

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t?.title}>
      <div className="flex flex-col items-center w-full gap-6">
        <div className="flex flex-col w-full gap-1">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700"
            showSkeleton={!isReady}
            classNameSkeleton="w-2/3"
          >
            {t?.description?.(action)}{' '}
            <span
              className="cursor-pointer text-secondary-700"
              onClick={onBuyMoreLicenses}
            >
              {t?.linkBuyMoreLicenses}
            </span>
            {t?.deactivate}{' '}
            <Link href="/users?typeView=active" passHref>
              <a
                className="cursor-pointer text-secondary-700"
                onClick={onClose}
              >
                {t?.linkDeactivateUserActive}
              </a>
            </Link>{' '}
            {t?.or}{' '}
            <Link href="/users?typeView=invite" passHref>
              <a
                className="cursor-pointer text-secondary-700"
                onClick={onClose}
              >
                {t?.linkInvitePendingUsers}
              </a>
            </Link>
            .
          </Typography>
          <div className="flex justify-end w-full gap-3 mt-4">
            <Button variant="neutral" size="sm" onClick={onClose}>
              {t?.buttonCancel}
            </Button>
            <Button
              className="w-fit"
              size="sm"
              type="button"
              variant="secondary"
              onClick={onBuyMoreLicenses}
              showSkeleton={!isReady}
            >
              {t?.buttonBuyMoreLicenses}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalNoLicensedUsers
