import * as React from 'react'
import { cn } from '@/lib/utils'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/ui/atoms/shadcn'

type IconWithTooltipProps = {
  icon: React.ReactElement
  tooltipText: string | React.ReactNode
  tooltipSideOffset?: number
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left'
  className?: string
  classNameContent?: string
}

const IconWithTooltip = React.forwardRef<HTMLDivElement, IconWithTooltipProps>(
  (
    {
      icon,
      tooltipText,
      tooltipSideOffset = 4,
      tooltipSide,
      className,
      classNameContent,
      ...props
    },
    ref
  ) => (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            ref={ref}
            className={cn('inline-flex cursor-pointer h-fit w-fit', className)}
            {...props}
          >
            {icon}
          </div>
        </TooltipTrigger>
        <TooltipContent
          sideOffset={tooltipSideOffset}
          side={tooltipSide}
          className={cn('max-w-sm', classNameContent)}
        >
          {tooltipText}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
)

IconWithTooltip.displayName = 'IconWithTooltip'

export default IconWithTooltip
