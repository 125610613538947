import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import useTranslation from '@/hooks/useTranslation'
import { checkDate } from '@/utils/date'
import { PlanType } from '@/types/global'
import {
  AddonDetails,
  AddonDetailsType,
  ButtonLoadingType,
  CurrentPlanDataType,
  DataDrawerType,
  DrawerType,
  ModalPlanType,
  ModalType,
  PlanItemType,
  PlansContextType,
  Props,
  RecurrencePlanType,
  RecurrenceType,
  ValuesToggles
} from './Plans.types'
import { useAuth } from '../Auth'
import { useLocale } from '../Locale'
import { useQueryParams } from '../QueryParams'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { PlanItem } from '@/hooks/api/billing/useBillingApi/useBillingApi.types'
import { showToast } from '@/ui/atoms/index'
import { formatCurrency, formatData } from '@/utils'

const store = createContext<PlansContextType>({} as PlansContextType)
const { Provider } = store

function Plans({ children }: Props) {
  const { t } = useTranslation('plans')
  const { userContext, refreshProfile } = useAuth()
  const { lang } = useLocale()
  const { pathname, push } = useRouter()

  const [isMounted, setIsMounted] = useState(false)
  const [currentRecurrencePlan, setCurrentRecurrencePlan] =
    useState<RecurrenceType>(null)
  const [addonToggles, setAddonToggles] = useState<ValuesToggles>({
    apiAddon: null,
    whiteLabelAddon: null
  })
  const { getQueryParam, setQueryParam, isReady } = useQueryParams()
  const triggerDrawerTypeOpen = getQueryParam<DrawerType | null>(
    'trigger',
    null
  )
  const refetchUserContextQuery = getQueryParam('refetchUserContext', null)
  const [dataModal, setDataModal] = useState<ModalPlanType>({
    isOpen: false,
    type: null,
    planType: null,
    recurrence: null
  })
  const [dataDrawer, setDataDrawer] = useState<DataDrawerType>({
    isOpen: false,
    type: null,
    planType: null
  })
  const [isButtonLoading, setIsButtonLoading] = useState<ButtonLoadingType>({
    isLoading: false,
    planId: null,
    type: null
  })

  const {
    useBillingGetCurrentSubscription,
    useBillingGetListAvailablePlans,
    useBillingDeleteSubscription,
    useBillingReleaseSubscription,
    useBillingUpdateAddonApiEnvelope,
    useBillingUpdateAddonWhiteLabel,
    useBillingChangeSubscription,
    useBillingGetLinkPendingInvoice,
    useBillingGetLinkChangePaymentMethod
  } = useBillingApi()
  const { mutateAsync: mutateAsyncUpdateAddonApiEnvelope } =
    useBillingUpdateAddonApiEnvelope()
  const { mutateAsync: mutateAsyncUpdateAddonWhiteLabel } =
    useBillingUpdateAddonWhiteLabel()
  const { mutateAsync: mutateAsyncBillingGetLinkChangePaymentMethod } =
    useBillingGetLinkChangePaymentMethod()
  const enablePooling = useMemo(() => {
    return pathname === '/billing/plans'
  }, [pathname])
  const {
    data: dataCurrentSubscription,
    isLoading: isLoadingCurrentSubscription,
    refetch: refetchCurrentSubscription,
    fetchStatus: fetchStatusCurrentSubscription
  } = useBillingGetCurrentSubscription(enablePooling)
  const {
    data: dataListAvailablePlans,
    isLoading: isLoadingListAvailablePlans,
    refetch: refetchListAvailablePlans
  } = useBillingGetListAvailablePlans()
  const { mutateAsync: mutateAsyncDeleteSubscription } =
    useBillingDeleteSubscription()
  const { mutateAsync: mutateAsyncReleaseSubscription } =
    useBillingReleaseSubscription()
  const { mutateAsync: mutateAsyncBillingChangeSubscription } =
    useBillingChangeSubscription()
  const { mutateAsync: mutateAsyncBillingGetLinkPendingInvoice } =
    useBillingGetLinkPendingInvoice()

  useEffect(() => {
    if (
      addonToggles.apiAddon === null &&
      Boolean(dataCurrentSubscription?.id)
    ) {
      setAddonToggles({
        apiAddon: dataCurrentSubscription?.apiAddonEnabled || false,
        whiteLabelAddon:
          dataCurrentSubscription?.whiteLabelAddonEnabled || false
      })
    }
  }, [
    dataCurrentSubscription?.apiAddonEnabled,
    dataCurrentSubscription?.id,
    dataCurrentSubscription?.whiteLabelAddonEnabled,
    addonToggles.apiAddon
  ])

  const currentPlanData: CurrentPlanDataType = useMemo(() => {
    if (
      (isLoadingCurrentSubscription ||
        fetchStatusCurrentSubscription === 'fetching') &&
      !dataCurrentSubscription
    ) {
      return null
    }

    const hasActiveSubscription =
      !!dataCurrentSubscription?.hasActiveSubscription

    if (!hasActiveSubscription) {
      return {
        hasActiveSubscription
      }
    }

    const userSeatProductKey = {
      One: 'oneUserSeatProductPrice',
      Pro: 'proUserSeatProductPrice',
      Max: 'maxUserSeatProductPrice'
    }[dataCurrentSubscription?.userSeatProductType as PlanType]

    return {
      hasActiveSubscription,
      recurrenceType: hasActiveSubscription
        ? dataCurrentSubscription?.recurrence
        : null,
      userSeatProductType: hasActiveSubscription
        ? dataCurrentSubscription?.userSeatProductType
        : null,
      price: dataListAvailablePlans?.items?.find(
        (item) => item?.recurrence === dataCurrentSubscription?.recurrence
      )?.[userSeatProductKey as keyof PlanItem]
    } as CurrentPlanDataType
  }, [
    dataCurrentSubscription,
    dataListAvailablePlans?.items,
    fetchStatusCurrentSubscription,
    isLoadingCurrentSubscription
  ])

  const setTriggerDrawerOpen = useCallback(
    (newValue: DrawerType | null) => {
      setQueryParam('trigger', newValue)
    },
    [setQueryParam]
  )

  useEffect(() => {
    if (
      addonToggles.apiAddon === null &&
      Boolean(dataCurrentSubscription?.id)
    ) {
      setAddonToggles({
        apiAddon: dataCurrentSubscription?.apiAddonEnabled || false,
        whiteLabelAddon:
          dataCurrentSubscription?.whiteLabelAddonEnabled || false
      })
    }

    if (!isMounted && isReady && !!triggerDrawerTypeOpen && !dataModal.isOpen) {
      if (
        triggerDrawerTypeOpen === DrawerType.DRAWER_CHANGE_LICENSED_USERS ||
        triggerDrawerTypeOpen === DrawerType.DRAWER_SIGNER_MFA_CREDITS ||
        triggerDrawerTypeOpen === DrawerType.DRAWER_ENVELOPE_API_CREDITS
      ) {
        setDataDrawer({
          isOpen: true,
          type: triggerDrawerTypeOpen,
          planType: dataModal.planType
        })
        setIsMounted(true)

        return
      }

      setIsMounted(true)
      setTriggerDrawerOpen(null)
    }

    if (refetchUserContextQuery === 'true' && isReady) {
      setTimeout(() => {
        refreshProfile()
        setQueryParam('refetchUserContext', null)
      }, 5000)
    }

    if (currentPlanData === null || !t?.typesOfPlans) {
      return
    }

    if (currentRecurrencePlan === null) {
      if (currentPlanData?.hasActiveSubscription) {
        setCurrentRecurrencePlan(
          currentPlanData.recurrenceType as RecurrenceType
        )
        return
      }

      setCurrentRecurrencePlan(t.typesOfPlans[1]?.value as RecurrenceType)
    }
  }, [
    addonToggles.apiAddon,
    dataCurrentSubscription,
    isMounted,
    isReady,
    triggerDrawerTypeOpen,
    dataModal.isOpen,
    refetchUserContextQuery,
    refreshProfile,
    setQueryParam,
    currentPlanData,
    t.typesOfPlans,
    dataModal.planType,
    currentRecurrencePlan,
    setTriggerDrawerOpen
  ])

  const plans = useMemo(() => {
    const findPlanData = (recurrenceType: RecurrenceType) =>
      dataListAvailablePlans?.items?.find((item) => {
        return item?.recurrence === recurrenceType
      })

    const createPlanObject = (
      planType: PlanType,
      userSeatProductPriceKey: keyof PlanItem,
      emphasis = false
    ): PlanItemType => {
      const planDataMonthly = findPlanData('Monthly') as PlanItem | undefined
      const planDataYearly = findPlanData('Yearly') as PlanItem | undefined

      const getPrice = (planData: PlanItem | undefined, isYearly: boolean) => {
        return planData
          ? isYearly
            ? {
                value:
                  planData[
                    userSeatProductPriceKey as keyof typeof planDataYearly
                  ] / 12,
                currency: planData.currency
              }
            : {
                value: planData[userSeatProductPriceKey],
                currency: planData.currency
              }
          : undefined
      }

      const isCurrentPlan = (recurrenceType: RecurrenceType) =>
        dataCurrentSubscription?.hasActiveSubscription &&
        dataCurrentSubscription?.userSeatProductType === planType &&
        recurrenceType === dataCurrentSubscription?.recurrence

      const planInactive = t?.plans?.[planType]?.planInactive

      const buttonCta = () => {
        if (planInactive) {
          return t?.plans?.[planType]?.buttonPlanAvailableSoon
        }
        if (isCurrentPlan(currentRecurrencePlan)) {
          return dataCurrentSubscription?.hasCancellationScheduled
            ? t?.plans?.[planType]?.buttonCtaPlanReactive
            : t?.plans?.[planType]?.buttonCtaPlanCancel
        }
        return t?.plans?.[planType]?.buttonCtaPlanSubscribe
      }

      const planId = dataListAvailablePlans?.items?.find(
        (item) => item?.recurrence === currentRecurrencePlan
      )?.id

      return {
        ...t?.plans?.[planType],
        planId,
        emphasis,
        buttonCta: buttonCta(),
        planInactive: planInactive,
        isCurrentPlan: isCurrentPlan(currentRecurrencePlan),
        isPlanDeactivated: dataCurrentSubscription?.hasCancellationScheduled,
        subscriptionId: dataCurrentSubscription?.id,
        badgeCurrentPlan: isCurrentPlan(currentRecurrencePlan)
          ? t?.plans?.[planType]?.badgeCurrentPlan
          : undefined,
        price: getPrice(
          currentRecurrencePlan === 'Monthly'
            ? planDataMonthly
            : planDataYearly,
          currentRecurrencePlan === 'Yearly'
        ),
        currentSubscription: dataCurrentSubscription,
        currentRecurrencePlan,
        recurrencePlan: t?.typesOfPlans?.find(
          (item: any) => item.value === currentRecurrencePlan
        ) as RecurrencePlanType,
        pricesAddons: {
          apiAddon: dataListAvailablePlans?.items?.find(
            (item) => item?.recurrence === currentRecurrencePlan
          )?.apiAddonProductPrice,
          whiteLabelAddon: dataListAvailablePlans?.items?.find(
            (item) => item?.recurrence === currentRecurrencePlan
          )?.whiteLabelAddonProductPrice
        }
      }
    }

    const activeSubscription = {
      hasActiveSubscription: currentPlanData?.hasActiveSubscription,
      recurrenceType: currentPlanData?.recurrenceType,
      userSeatProductType: currentPlanData?.userSeatProductType
    }

    const isEmphasisRequired = (planType: PlanType) => {
      const hasActiveSubscription = activeSubscription?.hasActiveSubscription

      const isCurrentActivePlan =
        hasActiveSubscription &&
        activeSubscription.userSeatProductType === planType &&
        activeSubscription.recurrenceType === currentRecurrencePlan

      if (!hasActiveSubscription) {
        return planType === 'One'
      }

      return isCurrentActivePlan
    }

    return [
      createPlanObject(
        'One',
        'oneUserSeatProductPrice',
        isEmphasisRequired('One')
      ),
      createPlanObject(
        'Pro',
        'proUserSeatProductPrice',
        isEmphasisRequired('Pro')
      ),
      createPlanObject(
        'Max',
        'maxUserSeatProductPrice',
        isEmphasisRequired('Max')
      )
    ]
  }, [
    currentPlanData?.hasActiveSubscription,
    currentPlanData?.recurrenceType,
    currentPlanData?.userSeatProductType,
    dataListAvailablePlans?.items,
    t?.plans,
    t?.typesOfPlans,
    currentRecurrencePlan,
    dataCurrentSubscription
  ])

  const isLoading = useMemo(() => {
    return (
      isLoadingCurrentSubscription ||
      isLoadingListAvailablePlans ||
      !t?.plans ||
      plans.length === 0 ||
      (refetchUserContextQuery === 'true' && isReady)
    )
  }, [
    isLoadingCurrentSubscription,
    isLoadingListAvailablePlans,
    isReady,
    plans.length,
    refetchUserContextQuery,
    t?.plans
  ])

  const resetModal = useCallback(() => {
    setDataModal({
      isOpen: false,
      type: null,
      planType: null,
      recurrence: null
    })
  }, [])

  const addonDetails: AddonDetailsType = useMemo(() => {
    if (!dataCurrentSubscription)
      return {
        apiAddon: {} as AddonDetails,
        whiteLabelAddon: {} as AddonDetails
      }

    const calculateProportionalValue = (
      price: number,
      recurrence: 'Monthly' | 'Yearly',
      daysUntilRenewal: number
    ): string | null => {
      const daysInPeriod = recurrence === 'Monthly' ? 30 : 365
      const proportionalValue = (price / daysInPeriod) * daysUntilRenewal
      const finalValue = proportionalValue > price ? price : proportionalValue

      return formatCurrency(finalValue, lang, true)
    }

    const { recurrence, apiAddonPrice, whiteLabelAddonPrice } =
      dataCurrentSubscription

    const renewalDate = DateTime.fromISO(
      userContext?.balanceInformation?.userSeatsLicenseValidUntilAtUtc || ''
    )
    const now = DateTime.local()
    const daysUntilRenewal = Math.ceil(renewalDate.diff(now, 'days').days)

    const apiAddonActivationAvailable = userContext?.balanceInformation
      ?.apiAddonEnabledUntilAtUtc
      ? checkDate(
          userContext?.balanceInformation?.apiAddonEnabledUntilAtUtc as string
        ) === 'future'
      : false
    const whiteLabelAddonActivationAvailable = userContext?.balanceInformation
      ?.whiteLabelAddonEnabledUntilAtUtc
      ? checkDate(
          userContext?.balanceInformation
            ?.whiteLabelAddonEnabledUntilAtUtc as string
        ) === 'future'
      : false

    const proportionalApiAddonPrice = !apiAddonActivationAvailable
      ? calculateProportionalValue(
          dataCurrentSubscription.apiAddonPrice,
          recurrence,
          daysUntilRenewal
        )
      : null
    const proportionalWhiteLabelAddonPrice = !whiteLabelAddonActivationAvailable
      ? calculateProportionalValue(
          dataCurrentSubscription.whiteLabelAddonPrice,
          recurrence,
          daysUntilRenewal
        )
      : null

    return {
      apiAddon: {
        price: formatCurrency(apiAddonPrice, lang),
        recurrence: recurrence,
        activationAvailable: apiAddonActivationAvailable,
        dateActivation: apiAddonActivationAvailable
          ? formatData(
              userContext?.balanceInformation
                ?.apiAddonEnabledUntilAtUtc as string,
              lang,
              false
            )
          : null,
        proportionalPrice: proportionalApiAddonPrice,
        planDateValidUntil: formatData(
          userContext?.balanceInformation
            ?.userSeatsLicenseValidUntilAtUtc as string,
          lang,
          false
        )
      },
      whiteLabelAddon: {
        price: formatCurrency(whiteLabelAddonPrice, lang),
        recurrence: recurrence,
        activationAvailable: whiteLabelAddonActivationAvailable,
        dateActivation: whiteLabelAddonActivationAvailable
          ? formatData(
              userContext?.balanceInformation
                ?.whiteLabelAddonEnabledUntilAtUtc as string,
              lang,
              false
            )
          : null,
        proportionalPrice: proportionalWhiteLabelAddonPrice,
        planDateValidUntil: formatData(
          userContext?.balanceInformation
            ?.userSeatsLicenseValidUntilAtUtc as string,
          lang,
          false
        )
      }
    }
  }, [
    dataCurrentSubscription,
    lang,
    userContext?.balanceInformation?.apiAddonEnabledUntilAtUtc,
    userContext?.balanceInformation?.userSeatsLicenseValidUntilAtUtc,
    userContext?.balanceInformation?.whiteLabelAddonEnabledUntilAtUtc
  ])

  const handleModalConfirmButton = useCallback(
    async (action: 'cancellation' | 'release' | 'changeSubscription') => {
      const checkSubscription = async (
        action: string,
        attempts: number,
        resolve: () => void,
        reject: () => void
      ) => {
        if (attempts >= 3) {
          reject()
          return
        }

        const responseCurrentSubscription = await refetchCurrentSubscription()
        let conditionMet = false

        if (action === 'cancellation') {
          conditionMet =
            !!responseCurrentSubscription?.data?.hasCancellationScheduled
        } else if (action === 'release') {
          conditionMet =
            !responseCurrentSubscription?.data?.hasCancellationScheduled
        } else if (action === 'changeSubscription') {
          conditionMet = !!(
            dataModal.planType &&
            responseCurrentSubscription?.data?.userSeatProductType ===
              dataModal.planType &&
            dataModal?.recurrence ===
              responseCurrentSubscription?.data?.recurrence
          )
        }

        if (conditionMet) {
          resolve()
        } else {
          setTimeout(
            () => {
              checkSubscription(action, attempts + 1, resolve, reject)
            },
            1000 * (attempts + 1)
          )
        }
      }

      setIsButtonLoading({
        isLoading: true,
        planId: dataModal?.planId as string,
        type: 'modalConfirm'
      })

      let actionPromise

      if (action === 'cancellation') {
        actionPromise = mutateAsyncDeleteSubscription({
          id: dataCurrentSubscription?.id
        })
      } else if (action === 'release') {
        actionPromise = mutateAsyncReleaseSubscription({
          id: dataCurrentSubscription?.id
        })
      } else if (
        action === 'changeSubscription' &&
        dataCurrentSubscription?.id &&
        dataModal?.planId &&
        dataModal?.planType
      ) {
        actionPromise = mutateAsyncBillingChangeSubscription({
          id: dataCurrentSubscription?.id,
          planId: dataModal?.planId,
          userSeatType: dataModal.planType as PlanType
        })
      } else {
        return
      }

      actionPromise
        .then(
          () =>
            new Promise((resolve: any, reject: any) => {
              checkSubscription(action, 0, resolve, reject)
            })
        )
        .then(() => {
          setIsButtonLoading({
            isLoading: false,
            planId: null,
            type: null
          })
          resetModal()
          if (action === 'cancellation') {
            showToast.success(t?.cardPlan?.toasts?.successDeleteSubscription)
          } else if (action === 'release') {
            showToast.success(t?.cardPlan?.toasts?.successReleaseSubscription)
          } else if (action === 'changeSubscription') {
            showToast.success(t?.cardPlan?.toasts?.successChangeSubscription)
          }
        })
        .catch(() => {
          setIsButtonLoading({
            isLoading: false,
            planId: null,
            type: null
          })
          resetModal()
          if (action === 'cancellation') {
            showToast.error(t?.cardPlan?.toasts?.errorDeleteSubscription)
          } else if (action === 'release') {
            showToast.error(t?.cardPlan?.toasts?.errorReleaseSubscription)
          } else if (action === 'changeSubscription') {
            showToast.error(t?.cardPlan?.toasts?.errorChangeSubscription)
          }
        })
        .finally(() => {
          setIsButtonLoading({
            isLoading: false,
            planId: null,
            type: null
          })
          resetModal()
        })
    },
    [
      dataModal?.planId,
      dataModal.planType,
      dataModal?.recurrence,
      dataCurrentSubscription?.id,
      refetchCurrentSubscription,
      mutateAsyncDeleteSubscription,
      mutateAsyncReleaseSubscription,
      mutateAsyncBillingChangeSubscription,
      resetModal,
      t?.cardPlan?.toasts?.successDeleteSubscription,
      t?.cardPlan?.toasts?.successReleaseSubscription,
      t?.cardPlan?.toasts?.successChangeSubscription,
      t?.cardPlan?.toasts?.errorDeleteSubscription,
      t?.cardPlan?.toasts?.errorReleaseSubscription,
      t?.cardPlan?.toasts?.errorChangeSubscription
    ]
  )

  const updateAddon = useCallback(
    async (addon: 'apiAddon' | 'whiteLabelAddon', checked: boolean) => {
      setIsButtonLoading({
        isLoading: true,
        planId: dataModal?.planId as string,
        type: 'modalConfirm'
      })

      const mutationFunction =
        addon === 'apiAddon'
          ? mutateAsyncUpdateAddonApiEnvelope
          : mutateAsyncUpdateAddonWhiteLabel
      const toastMessages =
        addon === 'apiAddon'
          ? {
              success:
                t?.cardPlan?.toasts?.successUpdateAddonApiEnvelope?.(checked),
              error:
                t?.cardPlan?.toasts?.errorUpdateAddonApiEnvelope?.(checked),
              loading:
                t?.cardPlan?.toasts?.loadingUpdateAddonApiEnvelope?.(checked)
            }
          : {
              success:
                t?.cardPlan?.toasts?.successUpdateAddonWhiteLabel?.(checked),
              error: t?.cardPlan?.toasts?.errorUpdateAddonWhiteLabel?.(checked),
              loading:
                t?.cardPlan?.toasts?.loadingUpdateAddonWhiteLabel?.(checked)
            }

      try {
        setAddonToggles({ ...addonToggles, [addon]: checked })
        await mutationFunction({
          id: dataCurrentSubscription?.id,
          type: checked ? 'activate' : 'deactivate'
        })

        const maxAttempts = 3
        let attempts = 0

        const interval = setInterval(async () => {
          attempts += 1
          if (attempts >= maxAttempts) {
            clearInterval(interval)
            setIsButtonLoading({
              isLoading: false,
              planId: null,
              type: null
            })
            return
          }

          const responseCurrentSubscription = await refetchCurrentSubscription()

          if (
            responseCurrentSubscription?.data?.apiAddonEnabled === checked ||
            responseCurrentSubscription?.data?.whiteLabelAddonEnabled ===
              checked
          ) {
            setAddonToggles({ ...addonToggles, [addon]: checked })
            clearInterval(interval)
            setIsButtonLoading({
              isLoading: false,
              planId: null,
              type: null
            })
            showToast.success(toastMessages.success)
          }
        }, 5000)
      } catch {
        setAddonToggles({ ...addonToggles, [addon]: !checked })
        setIsButtonLoading({
          isLoading: false,
          planId: null,
          type: null
        })
        resetModal()
        showToast.error(toastMessages.error)
      } finally {
        setIsButtonLoading({
          isLoading: false,
          planId: null,
          type: null
        })

        resetModal()
      }
    },
    [
      addonToggles,
      mutateAsyncUpdateAddonApiEnvelope,
      mutateAsyncUpdateAddonWhiteLabel,
      t?.cardPlan?.toasts,
      dataModal?.planId,
      dataCurrentSubscription?.id,
      refetchCurrentSubscription,
      resetModal
    ]
  )

  const dataModalConfirm = useMemo(() => {
    if (!dataModal.type || !dataModal.isOpen) return null

    const content = {
      [ModalType.CONFIRM_PLAN_CANCELLATION]: {
        title: t?.modalConfirmPlanCancellation?.title,
        description: t?.modalConfirmPlanCancellation?.description(
          formatData(
            userContext?.balanceInformation
              ?.userSeatsLicenseValidUntilAtUtc as string,
            lang,
            false
          )
        ),
        buttonCancel: t?.modalConfirmPlanCancellation?.buttonCancel,
        buttonConfirm: t?.modalConfirmPlanCancellation?.buttonConfirm,
        onConfirm: () => handleModalConfirmButton('cancellation'),
        onCancel: resetModal
      },
      [ModalType.CONFIRM_TOGGLE_ACTIVE_API_ADDON]: {
        title: t?.modalConfirmToggleApiAddon?.title?.(addonToggles.apiAddon),
        description: t?.modalConfirmToggleApiAddon?.description?.(
          addonToggles.apiAddon,
          addonDetails.apiAddon
        ),
        buttonCancel: t?.modalConfirmToggleApiAddon?.buttonCancel,
        buttonConfirm: t?.modalConfirmToggleApiAddon?.buttonConfirm,
        onConfirm: () => {
          const currentDate = DateTime.local()
          const apiAddonEnabledUntilAtUtc = DateTime.fromISO(
            userContext?.balanceInformation?.apiAddonEnabledUntilAtUtc as string
          )

          if (
            !addonToggles.apiAddon &&
            apiAddonEnabledUntilAtUtc < currentDate
          ) {
            setDataModal({
              isOpen: true,
              type: ModalType.MODAL_PENDING_INVOICES
            })
            return
          }

          updateAddon('apiAddon', !addonToggles.apiAddon)
        },
        onCancel: resetModal
      },
      [ModalType.CONFIRM_TOGGLE_ACTIVE_WHITE_LABEL_ADDON]: {
        title: t?.modalConfirmToggleWhiteLabelAddon?.title?.(
          addonToggles.whiteLabelAddon
        ),
        description: t?.modalConfirmToggleWhiteLabelAddon?.description?.(
          addonToggles.whiteLabelAddon,
          addonDetails.whiteLabelAddon
        ),
        buttonCancel: t?.modalConfirmToggleWhiteLabelAddon?.buttonCancel,
        buttonConfirm: t?.modalConfirmToggleWhiteLabelAddon?.buttonConfirm,
        onConfirm: () => {
          const currentDate = DateTime.local()
          const whiteLabelAddonEnabledUntilAtUtc = DateTime.fromISO(
            userContext?.balanceInformation
              ?.whiteLabelAddonEnabledUntilAtUtc as string
          )
          if (
            !addonToggles.whiteLabelAddon &&
            whiteLabelAddonEnabledUntilAtUtc < currentDate
          ) {
            setDataModal({
              isOpen: true,
              type: ModalType.MODAL_PENDING_INVOICES
            })
            return
          }

          return updateAddon('whiteLabelAddon', !addonToggles.whiteLabelAddon)
        },
        onCancel: resetModal
      },
      [ModalType.CONFIRM_PLAN_RELEASE]: {
        title: t?.modalConfirmPlanRelease?.title,
        description: t?.modalConfirmPlanRelease?.description?.(
          formatData(
            userContext?.balanceInformation
              ?.userSeatsLicenseValidUntilAtUtc as string,
            lang,
            false
          )
        ),
        buttonCancel: t?.modalConfirmPlanRelease?.buttonCancel,
        buttonConfirm: t?.modalConfirmPlanRelease?.buttonConfirm,
        onConfirm: () => handleModalConfirmButton('release'),
        onCancel: resetModal
      },
      [ModalType.CONFIRM_PLAN_CHANGE_SUBSCRIPTION]: {
        title: t?.modalConfirmPlanChangeSubscription?.title,
        description: t?.modalConfirmPlanChangeSubscription?.description,
        buttonCancel: t?.modalConfirmPlanChangeSubscription?.buttonCancel,
        buttonConfirm: t?.modalConfirmPlanChangeSubscription?.buttonConfirm,
        onConfirm: () => handleModalConfirmButton('changeSubscription'),
        onCancel: resetModal
      }
    }

    return content[
      dataModal.type as
        | ModalType.CONFIRM_PLAN_CANCELLATION
        | ModalType.CONFIRM_TOGGLE_ACTIVE_API_ADDON
        | ModalType.CONFIRM_TOGGLE_ACTIVE_WHITE_LABEL_ADDON
        | ModalType.CONFIRM_PLAN_RELEASE
        | ModalType.CONFIRM_PLAN_CHANGE_SUBSCRIPTION
    ]
  }, [
    dataModal.type,
    dataModal.isOpen,
    t?.modalConfirmPlanCancellation,
    t?.modalConfirmToggleApiAddon,
    t?.modalConfirmToggleWhiteLabelAddon,
    t?.modalConfirmPlanRelease,
    t?.modalConfirmPlanChangeSubscription?.title,
    t?.modalConfirmPlanChangeSubscription?.description,
    t?.modalConfirmPlanChangeSubscription?.buttonCancel,
    t?.modalConfirmPlanChangeSubscription?.buttonConfirm,
    userContext?.balanceInformation?.userSeatsLicenseValidUntilAtUtc,
    userContext?.balanceInformation?.apiAddonEnabledUntilAtUtc,
    userContext?.balanceInformation?.whiteLabelAddonEnabledUntilAtUtc,
    lang,
    resetModal,
    addonToggles.apiAddon,
    addonToggles.whiteLabelAddon,
    addonDetails.apiAddon,
    addonDetails.whiteLabelAddon,
    handleModalConfirmButton,
    updateAddon
  ])

  const getLinkInvoicePending = useCallback(
    async (id: string) => {
      try {
        const response = await mutateAsyncBillingGetLinkPendingInvoice({
          id
        })
        if (response?.url) {
          window.open(response?.url as string, '_blank')
        }
        return t?.toasts?.successGetLinkPendingInvoice
      } catch {
        return t?.toasts?.errorGetLinkPendingInvoice
      }
    },
    [
      mutateAsyncBillingGetLinkPendingInvoice,
      t?.toasts?.errorGetLinkPendingInvoice,
      t?.toasts?.successGetLinkPendingInvoice
    ]
  )

  const baseUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      const currentDomain = `${window.location.protocol}//${window.location.hostname}`
      if (currentDomain === 'http://localhost') {
        return process.env.NEXT_PUBLIC_APP_URL as string
      }
      return currentDomain
    }
  }, [])

  const getLinkChangePaymentMethod = useCallback(async () => {
    toast.promise(
      mutateAsyncBillingGetLinkChangePaymentMethod({
        returnUrl: `${baseUrl}/billing/plans?refetchUserContext=true`
      }),
      {
        pending: t?.toasts?.loadingGetLinkChangePaymentMethod,
        success: {
          render(response: { url: string }) {
            if (response?.url) {
              push(response?.url)
            }
            return t?.toasts?.successGetLinkChangePaymentMethod
          }
        },
        error: t?.toasts?.errorGetLinkChangePaymentMethod
      }
    )
  }, [
    baseUrl,
    mutateAsyncBillingGetLinkChangePaymentMethod,
    push,
    t?.toasts?.errorGetLinkChangePaymentMethod,
    t?.toasts?.loadingGetLinkChangePaymentMethod,
    t?.toasts?.successGetLinkChangePaymentMethod
  ])

  return (
    <Provider
      value={{
        currentRecurrencePlan,
        setCurrentRecurrencePlan,
        isLoading,
        plans,
        currentPlanData,
        dataCurrentSubscription,
        addonToggles,
        setAddonToggles,
        refetchCurrentSubscription,
        refetchListAvailablePlans,
        triggerDrawerTypeOpen,
        setTriggerDrawerOpen,
        dataModal,
        setDataModal,
        dataDrawer,
        setDataDrawer,
        resetModal,
        isButtonLoading,
        setIsButtonLoading,
        dataModalConfirm,
        getLinkInvoicePending,
        getLinkChangePaymentMethod
      }}
    >
      {children}
    </Provider>
  )
}

const usePlans = () => useContext(store)

export { Plans, usePlans }
