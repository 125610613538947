import React, { useEffect, useState } from 'react'

type Props = {
  pages?: number
}

const PdfLoadingSkeleton: React.FC<Props> = ({ pages = 2 }) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  const randomWidthClass = () => {
    const classes = ['w-full', 'w-1/4', 'w-1/3', 'w-1/2', 'w-2/3', 'w-3/4']
    return classes[Math.floor(Math.random() * classes.length)]
  }

  return (
    <div className="flex flex-col w-full gap-3 px-2">
      {Array.from({ length: pages }).map((_, index) => (
        <div
          className="w-full max-w-2xl p-4 mx-auto bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-black"
          key={index}
        >
          <div className="w-full h-6 mb-4 skeleton"></div>
          {Array.from({ length: 30 }).map((_, index) => (
            <div
              key={index}
              className={`h-4 skeleton mb-2 ${randomWidthClass()}`}
            ></div>
          ))}
          <div className="w-full h-6 mt-4 skeleton"></div>
        </div>
      ))}
    </div>
  )
}

export default PdfLoadingSkeleton
