import { CustomColumn } from '@/types/react-table-config'
import {
  Contacts,
  Contact
} from '@/hooks/api/ecm/useContactApi/useContactApi.types'

export enum FiltersKeyContacts {
  TYPE_FAVORITE = 'typeFavorite',
  QUERY = 'query'
}

export type FiltersContactsType = {
  typeFavorite?: 'favorite' | 'unfavorite' | null
  query?: string | null
}

export type ContactFormType = {
  id?: string
  name: string
  email: string
  description?: string
}

export type ActionModalContactType =
  | 'create'
  | 'update'
  | 'view'
  | 'delete'
  | null

export type ModalActionContactType = {
  isOpen: boolean
  action: ActionModalContactType
  id: string | null
}

export type UseContactsDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable: {
    data: Contacts | null | undefined
    isLoading: boolean
    isSuccess: boolean
    isErrorContacts: boolean
    errorContacts: any
  }
  columns: CustomColumn<Contact>[]
  onSubmitActionModal: (
    data: ContactFormType,
    action: ModalActionContactType['action']
  ) => Promise<void> | void
  modalActionContact: ModalActionContactType
  setModalActionContact: React.Dispatch<
    React.SetStateAction<ModalActionContactType>
  >
  handleDeleteContact: () => void
  isLoadingUserAccountContacts: boolean
  filters: FiltersContactsType | null
  preparedFilters: FiltersContactsType | null
  setPreparedFilters: (filters: FiltersContactsType) => void
  handleApplyFilters: (filters: FiltersContactsType) => void
  isLoadingDeleteContact: boolean
  isLoadingSubmitModalAction: boolean
}
