const formatCurrency = (
  valueInCents: number,
  lang: 'PtBr' | 'EnUs',
  showCentsIfZero = true
): string | null => {
  const localeMap: { [key in 'PtBr' | 'EnUs']: string } = {
    PtBr: 'pt-BR',
    EnUs: 'en-US'
  }

  const currencyMap: { [key in 'PtBr' | 'EnUs']: string } = {
    PtBr: 'BRL',
    EnUs: 'USD'
  }

  if (!lang) {
    return null
  }

  if (!localeMap[lang] || !currencyMap[lang]) {
    throw new Error('Unsupported language code')
  }

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyMap[lang]
  }

  if (!showCentsIfZero && valueInCents % 100 === 0) {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }

  return new Intl.NumberFormat(localeMap[lang], options).format(
    valueInCents / 100
  )
}

export default formatCurrency
