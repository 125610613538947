import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { Modal } from '@/molecules/index'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  isOpen: boolean
  onClose: () => void
  pendingInvoiceId?: string
}

const ModalPendingInvoices: React.FC<Props> = ({
  isOpen,
  onClose,
  pendingInvoiceId
}) => {
  const { t, isReady } = useTranslation('modalPendingInvoices')

  const { useBillingGetLinkPendingInvoice } = useBillingApi()
  const {
    mutateAsync: mutateAsyncBillingGetLinkPendingInvoice,
    isLoading: isLoadingBillingGetLinkPendingInvoice
  } = useBillingGetLinkPendingInvoice()

  const getLinkInvoicePending = useCallback(
    async (id: string) => {
      try {
        const response = await mutateAsyncBillingGetLinkPendingInvoice({
          id
        })
        if (response?.url) {
          window.open(response?.url as string, '_blank')
          // push(response?.url as string)
          return
        }
        onClose()
        return t?.toasts?.successGetLinkPendingInvoice
      } catch {
        return t?.toasts?.errorGetLinkPendingInvoice
      }
    },
    [
      mutateAsyncBillingGetLinkPendingInvoice,
      onClose,
      t?.toasts?.errorGetLinkPendingInvoice,
      t?.toasts?.successGetLinkPendingInvoice
    ]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t?.title}>
      <div className="flex flex-col items-center w-full gap-6">
        <div className="flex flex-col w-full gap-1">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700"
            showSkeleton={!isReady}
            classNameSkeleton="w-2/3"
          >
            {t?.description}
          </Typography>
          <div className="flex justify-end w-full gap-3 mt-4">
            <Button variant="neutral" size="sm" onClick={onClose}>
              {t?.buttonCancel}
            </Button>
            <Button
              className="w-fit"
              size="sm"
              type="button"
              variant="secondary"
              onClick={() =>
                pendingInvoiceId &&
                getLinkInvoicePending(pendingInvoiceId as string)
              }
              showSkeleton={!isReady}
              loading={isLoadingBillingGetLinkPendingInvoice}
            >
              {t?.buttonPay}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalPendingInvoices
