import { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useAuth } from '@/providers/Auth'
import { Typography, Checkbox } from '@/atoms/index'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import { useQueryParams } from '@/providers'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { Form, RenderFormField } from '@/ui/molecules'

export type FormValues = {
  name: string
  email: string
  terms: boolean
}

const RegisterForm = () => {
  const { t, isReady } = useTranslation(['register', 'validations'], true)
  const { loading, createInviteAction, urlFederatedRegister } = useAuth()
  const { push } = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { getQueryParam, isReady: isReadyQueryParams } = useQueryParams()

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(5, { message: t?.errorMinLength?.(5) })
      .max(100, { message: t?.errorMaxLength?.(100) }),
    email: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(5, { message: t?.errorMinLength?.(5) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .email({ message: t?.errorInvalidEmail }),
    terms: z.boolean().refine((value) => value === true, {
      message: t?.errorTerms
    })
  })

  useEffect(() => {
    if (isReadyQueryParams) {
      const clickGoogleRegister = getQueryParam('clickGoogleRegister', null)

      if (clickGoogleRegister) {
        push(urlFederatedRegister)
      }
    }
  }, [getQueryParam, isReadyQueryParams, push, urlFederatedRegister])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      terms: false
    }
  })

  const onSubmit = useCallback(
    async (values) => {
      const token = executeRecaptcha
        ? await executeRecaptcha('external_invite_creation')
        : ''
      createInviteAction({
        ...values,
        recaptchaToken: token
      })
    },
    [createInviteAction, executeRecaptcha]
  )

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.register?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.register?.description}
          </Typography>
        </div>
        <Link href={urlFederatedRegister} passHref>
          <Button
            variant="neutral"
            className="flex items-center justify-center w-full gap-2 py-2 pl-3 pr-3 border border-gray-300 rounded-md shadow bg-accent-100 hover:text-gray-100"
            fullWidth
            showSkeleton={!isReady}
          >
            <Image
              src="/assets/icons/google.svg"
              alt="google"
              width={16}
              height={16}
            />
            <Typography
              type="span"
              variant="text-sm-medium"
              className="text-inherit"
            >
              {t?.register?.buttonGoogle}
            </Typography>
          </Button>
        </Link>
        <div className="inline-flex items-center self-stretch justify-center gap-2">
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
          <Typography
            type="p"
            variant="text-sm-regular"
            className="text-gray-500"
            showSkeleton={!isReady}
          >
            {t?.register?.orContinue}
          </Typography>
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} onSubmit={onSubmit}>
          <>
            <div className="flex flex-col gap-5">
              <RenderFormField
                control={form.control}
                name="name"
                type="input"
                showSkeleton={!isReady}
                {...form.formState.errors.name}
                {...t?.register?.form?.name}
              />
              <RenderFormField
                control={form.control}
                name="email"
                type="input"
                showSkeleton={!isReady}
                {...form.formState.errors.email}
                {...t?.register?.form?.email}
              />
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                  <RenderFormField
                    control={form.control}
                    name="terms"
                    showSkeleton={!isReady}
                    {...form.formState.errors.terms}
                    {...t?.register?.form?.terms}
                    render={({ field }) => (
                      <Checkbox
                        id="terms"
                        name="terms"
                        checked={field.value}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        showSkeleton={!isReady}
                      >
                        <Typography
                          variant="text-sm-regular"
                          className="text-gray-700 cursor-pointer"
                          showSkeleton={!isReady}
                          onClick={() => field.onChange(!field.value)}
                        >
                          {t?.register?.checkTerms}{' '}
                          <Link
                            href="https://signater.com.br/juridico/termos-de-uso"
                            passHref
                          >
                            <a target="_blank" rel="noopener noreferrer">
                              <Typography
                                type="span"
                                variant="text-sm-semibold"
                                className="text-secondary-700"
                              >
                                {t?.register?.terms}
                              </Typography>
                            </a>
                          </Link>
                        </Typography>
                      </Checkbox>
                    )}
                  />
                </div>
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              loading={loading}
              showSkeleton={!isReady}
            >
              {t?.register?.button}
            </Button>
          </>
        </Form>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.loginPrefix}
          </Typography>
          <Link href="/login" passHref>
            <Typography
              variant="text-sm-semibold"
              className="cursor-pointer text-secondary-700"
              showSkeleton={!isReady}
            >
              {t?.login}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  )
}

export default RegisterForm
