import { useCallback, useEffect, useState } from 'react'
import { useViewport } from '../useViewport'
import {
  SCALE_FACTOR_XS,
  BASE_PAGE_HEIGHT,
  BASE_PAGE_WIDTH,
  SCALE_FACTOR_DEFAULT,
  SCALE_FACTOR_LG,
  SCALE_FACTOR_MD,
  SCALE_FACTOR_SM
} from '@/constants/sizesPdf'

export const usePageViewportDocument = () => {
  const { breakpoint } = useViewport()
  const [scaleFactor, setScaleFactor] = useState(SCALE_FACTOR_DEFAULT)

  useEffect(() => {
    switch (breakpoint) {
      case 'xs':
        setScaleFactor(SCALE_FACTOR_XS)
        break
      case 'sm':
        setScaleFactor(SCALE_FACTOR_SM)
        break
      case 'md':
        setScaleFactor(SCALE_FACTOR_MD)
        break
      case 'lg':
        setScaleFactor(SCALE_FACTOR_LG)
        break
      default:
        setScaleFactor(SCALE_FACTOR_DEFAULT)
    }
  }, [breakpoint])

  const getViewportSize = useCallback(
    (
      inputWidth = BASE_PAGE_WIDTH,
      inputHeight = BASE_PAGE_HEIGHT,
      scaleFactorBase?: number | null
    ) => {
      if (scaleFactorBase) {
        return {
          width: inputWidth * scaleFactorBase,
          height: inputHeight * scaleFactorBase
        }
      }
      return {
        width: inputWidth * scaleFactor,
        height: inputHeight * scaleFactor
      }
    },
    [scaleFactor]
  )

  return { getViewportSize, scaleFactor }
}
