import React, { useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useAuth } from '@/providers/Auth'
import { Typography } from '@/atoms/index'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import { Button } from '@/ui/atoms/shadcn'
import { Form, PasswordRequirements, RenderFormField } from '@/ui/molecules'

type FormValues = {
  code: string
  password: string
}

type Props = {
  email: string
}

const RecoveryPassword = ({ email }: Props) => {
  const { t, isReady } = useTranslation(['forgotPassword', 'validations'], true)
  const { loading, recoveryPasswordAction } = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const formSchema = z.object({
    code: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(6, { message: t?.errorCodeLength?.(6) })
      .max(6, { message: t?.errorCodeLength?.(6) }),
    password: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(8, { message: t?.errorCodeLength?.(8) })
      .max(100, { message: t?.errorCodeLength?.(100) })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        {
          message: t?.errorPasswordValidation
        }
      )
  })

  const onSubmit = useCallback(
    async (values) => {
      const token = executeRecaptcha
        ? await executeRecaptcha('password_reset')
        : ''

      await recoveryPasswordAction({
        code: values.code,
        password: values.password,
        email: email,
        recaptchaToken: token
      })
    },
    [email, executeRecaptcha, recoveryPasswordAction]
  )

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
      password: ''
    }
  })
  const valuePassword = useWatch({
    control: form.control,
    name: 'password'
  })

  const feedbackPassword = useMemo(
    () => ({
      minLength: valuePassword.length >= 8,
      oneUpperCase: /^(?=.*[A-Z])/.test(valuePassword),
      oneLowerCase: /^(?=.*[a-z])/.test(valuePassword),
      oneNumber: /^(?=.*\d)/.test(valuePassword),
      oneSpecialCharacter: /^(?=.*[@$!%*?&])/.test(valuePassword)
    }),
    [valuePassword]
  )

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
          >
            {t?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
          >
            {t?.subTitle}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} onSubmit={onSubmit}>
          <RenderFormField
            control={form.control}
            name="code"
            type="tokenInput"
            disabled={false}
            showSkeleton={!isReady}
            {...form.formState.errors.code}
            {...t?.recoveryPassword?.form?.code}
          />
          <RenderFormField
            control={form.control}
            name="password"
            type="password"
            disabled={false}
            showSkeleton={!isReady}
            {...form.formState.errors.password}
            {...t?.recoveryPassword?.form?.password}
          />
          <PasswordRequirements {...feedbackPassword} />
          <Button
            type="submit"
            fullWidth
            loading={loading}
            showSkeleton={!isReady}
          >
            {t?.recoveryPassword?.button}
          </Button>
        </Form>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Link href="/login" passHref>
            <Typography
              variant="text-sm-semibold"
              className="text-center cursor-pointer text-secondary-700"
            >
              {t?.recoveryPassword?.login}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  )
}

export default RecoveryPassword
