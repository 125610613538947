import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import { CommandPalletSearchBarResponse } from '@/hooks/api/platform/useSearchApi/useSearchApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { encodeQueryString } from '@/utils'

const useSearchApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useCommandPalletSearchBar = (value: string) => {
    return useQuery({
      queryKey: [QueryKeys.CommandPalletSearchBar, value],
      queryFn: () =>
        handleApi<void, CommandPalletSearchBarResponse>(
          `/platform/search?key=${encodeQueryString(
            value
          )}&IncludeVaults=true&includeDocuments=true&includeEnvelopes=true&includeTemplates=true&includeContacts=true&includeUserAccounts=true`,
          'GET'
        ),
      ...queryConfig,
      enabled: value.length >= 3 && !!authMetadata?.accessToken
    })
  }

  return {
    useCommandPalletSearchBar
  }
}

export default useSearchApi
