import React, { useMemo } from 'react'
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'
import mixpanel from 'mixpanel-browser'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useLocalStorage from '@/hooks/useLocalStorage'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import HeaderOptionGroup from '@/organisms/HeaderOptionGroup'
import { SandboxAlert } from '@/molecules/index'
import HeaderNavigation from '../HeaderNavigation'
import { cn } from '@/lib/utils'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault/SelectVault'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  setSidebarOpen?: (value: boolean) => void
}

const Header = ({ setSidebarOpen }: Props) => {
  const { t } = useTranslation('header')
  const { theme } = usePersistedTheme()
  const { isSandbox, isLogged } = useAuth()
  const { pathname, query } = useRouter()
  const [, setCurrentVaultId] = useLocalStorage<CurrentVaultId>(
    'currentVaultId',
    null
  )
  const router = useRouter()

  const urlCreateEnvelopeButton = useMemo(() => {
    if (pathname === '/vaults/[id]' && query.id) {
      setCurrentVaultId(query.id as string)
      return `/envelope`
    }

    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('currentVaultId')
    }
    return '/envelope'
  }, [pathname, query.id, setCurrentVaultId])

  const createEnvelope = () => {
    mixpanel.track('Create envelope', { origin: 'header' })
    router.push(urlCreateEnvelopeButton)
  }

  const logoSrc =
    theme === 'dark'
      ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
      : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  return (
    <div className="sticky top-0 z-50 bg-white dark:bg-black">
      <div className="flex items-center w-full h-16 border-b border-gray-200 shadow-sm ">
        <div className="flex items-center w-full container-main shrink-0 gap-x-4 sm:gap-x-6">
          {isLogged && (
            <div className="flex gap-x-4 lg:hidden">
              <button
                type="button"
                className="text-gray-700 dark:text-gray-700"
                onClick={() => setSidebarOpen?.(true)}
              >
                <span className="sr-only">{t?.openSidebar}</span>
                <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <Link href="/">
                <div className="pt-2 cursor-pointer">
                  <Image
                    width={32}
                    height={32}
                    src="/assets/logos/signater/logo-square.png"
                    alt="Signater"
                  />
                </div>
              </Link>
              <div className="items-start hidden sm:flex">
                <div className="flex items-center h-16 pb-2 pr-2 cursor-pointer shrink-0">
                  <Link href="/">
                    <div className="pt-2 cursor-pointer">
                      <Image
                        width={160}
                        height={32}
                        src={logoSrc}
                        alt="Signater"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center self-stretch justify-between flex-1 w-full gap-x-4">
            <div className="flex items-center gap-x-2">
              <div
                className={cn(
                  'flex items-center h-16 pb-2 pr-2 cursor-pointer',
                  isLogged && 'hidden lg:flex'
                )}
              >
                <Link href="/">
                  <div className="pt-2">
                    <Image
                      width={160}
                      height={32}
                      src={logoSrc}
                      alt="Signater"
                    />
                  </div>
                </Link>
              </div>
              {isLogged && (
                <div className="items-center hidden lg:flex">
                  <HeaderNavigation />
                  <div className="flex ml-2">
                    <Button
                      onClick={createEnvelope}
                      className="rounded-3xl whitespace-nowrap"
                    >
                      {t?.buttonCreateEnvelope}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center gap-x-4">
              <HeaderOptionGroup />
            </div>
          </div>
        </div>
      </div>
      {isSandbox && <SandboxAlert>{t?.sandboxMode}</SandboxAlert>}
    </div>
  )
}

export default Header
