import React, { useCallback, useMemo, useState } from 'react'
import useTranslation from '@/hooks/useTranslation'
import Avatar from '@/atoms/Avatar'
import { Modal } from '..'
import { cn } from '@/lib/utils'
import { Alert, Typography } from '@/ui/atoms'
import { Badge } from '@/ui/atoms/shadcn'

export type User = {
  name: string
  imgSrc?: string | null
  email?: string
  isActive?: boolean
}

export type AvatarGroupProps = {
  users: User[]
  size?: 'sm' | 'md' | 'lg'
  max?: number
  showDetails?: boolean
  type?: 'signers' | 'usersGroup'
  infoMessage?: string
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  users,
  showDetails = true,
  size = 'md',
  max = 4,
  infoMessage,
  type = 'signers'
}) => {
  const { t } = useTranslation('avatarGroup')
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const extraUsers = useMemo(() => users.length - max, [users, max])
  const getTooltipContent = useCallback((user) => {
    return `${user.name}${user.email ? `\n${user.email}` : ''}`
  }, [])

  return (
    <>
      <button
        className="flex -space-x-2"
        type="button"
        onClick={() => {
          if (showDetails) setModalIsOpen(true)
        }}
      >
        {users.slice(0, max).map((user, id) => (
          <Avatar
            name={user.name}
            imgSrc={user.imgSrc}
            size={size}
            tooltipContent={getTooltipContent(user)}
            key={`signer-${user.name}-id-${id}`}
          />
        ))}
        {extraUsers > 0 && (
          <Avatar
            value={`+${extraUsers}`}
            size={size}
            tooltipContent={t?.tooltipContentHasMoreUsers?.(extraUsers, type)}
          />
        )}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={t?.modalTitle?.(type)}
        size="sm"
        displayAsDrawerOnMobile
        classesContainer="w-full md:max-w-[400px]"
        classesContent="pt-1"
      >
        <div className="flex flex-col h-full">
          {users.map((user, id) => (
            <div
              className={cn(
                'flex px-1 py-2 items-center gap-1 lg:gap-2',
                users.length - 1 !== id && 'border-b border-gray-200'
              )}
              key={`signer-${user.name}-id-${id}`}
            >
              <Avatar imgSrc={user.imgSrc} name={user.name ?? ''} />
              <div className="flex flex-col flex-1">
                <div
                  className={cn(
                    'flex items-center',
                    type === 'usersGroup' && 'gap-1'
                  )}
                >
                  <Typography variant="text-sm-regular">{user.name}</Typography>
                  {type === 'usersGroup' && (
                    <Badge
                      variant={user.isActive ? 'success' : 'error'}
                      size="xs"
                    >
                      {user.isActive ? t?.active : t?.inactive}
                    </Badge>
                  )}
                </div>
                {user?.email && (
                  <Typography
                    variant="text-xs-regular"
                    className="text-gray-600"
                  >
                    {user.email}
                  </Typography>
                )}
              </div>
            </div>
          ))}
          <div className="pt-2">
            {infoMessage && (
              <Alert
                variant="info"
                title={infoMessage}
                classNameTitle="font-normal"
                size="sm"
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AvatarGroup
