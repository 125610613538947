import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { countries } from 'countries-list'
import { hasFlag } from 'country-flag-icons'
import * as Flags from 'country-flag-icons/react/3x2'
import Gleap from 'gleap'
import Select, { GroupBase, MultiValue, StylesConfig } from 'react-select'
import { useLocale } from '@/providers/Locale'

interface Option {
  value: string
  label: ReactNode
}

const options: Option[] = Object.entries(countries)
  .map(([code, country]) => {
    if (code !== 'US' && code !== 'BR') {
      return undefined
    }
    const exist = hasFlag(code)
    if (!exist) {
      return undefined
    }
    const Component = (Flags as any)[code]

    return {
      value: country.name,
      label: (
        <div className="flex items-center justify-center">
          <Component style={{ display: 'inline-block', minWidth: '25px' }} />
        </div>
      )
    }
  })
  .filter(Boolean) as Option[]

const SelectLang = () => {
  const [isMounted, setIsMounted] = useState(false)
  const { setLang, lang, loading } = useLocale()

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const langFull = useMemo(() => {
    return lang === 'PtBr' ? 'Brazil' : 'United States'
  }, [lang])

  const [langValue, setLangValue] = useState({
    value: langFull,
    label: options.find((option) => option.value === langFull)?.label
  })

  useEffect(() => {
    if (!isMounted) return
    if (langFull !== langValue.value) {
      setLangValue({
        value: langFull,
        label: options.find((option) => option.value === langFull)?.label
      })
    }
  }, [langFull, langValue.value, isMounted, langValue])

  const customStyles: StylesConfig<
    | MultiValue<{ value: string; label: ReactNode }>
    | { value: string; label: ReactNode },
    false,
    GroupBase<Option>
  > = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      borderColor: 'transparent',
      width: '100%',
      cursor: 'pointer',
      border: 'none',
      boxShadow: 'none',
      padding: '0px',
      height: '100%',
      minHeight: '15px',
      background: 'transparent',
      opacity: loading ? 0.5 : 1
    }),
    indicatorSeparator(base) {
      return {
        ...base,
        display: 'none'
      }
    },
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      padding: '3px',
      background: 'transparent',
      opacity: loading ? 0.5 : 1
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
      minWidth: '24px',
      opacity: loading ? 0.5 : 1
    })
  }

  if (!isMounted) {
    return null
  }

  return (
    <Select
      isMulti={false}
      isClearable={false}
      isSearchable={false}
      isDisabled={loading}
      isLoading={loading}
      name="colors"
      options={options}
      classNamePrefix="select"
      value={langValue}
      onChange={(e: any) => {
        setLang(e?.value === 'Brazil' ? 'PtBr' : 'EnUs')
        setLangValue(e as Option)
        Gleap.setLanguage(e?.value === 'Brazil' ? 'pt-BR' : 'en-US')
      }}
      styles={customStyles}
    />
  )
}

export default SelectLang
