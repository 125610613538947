import dynamic from 'next/dynamic'

const TemplateEditor = dynamic(
  () => {
    return import('@/ui/molecules/TemplateEditor/TemplateEditor')
  },
  { ssr: false }
)

const SectionEditorTemplate = () => {
  return (
    <div className="w-full xl:w-[calc(100%-500px)] h-containerFull bg-gray-100 overflow-x-scroll relative shadow-insetUploadEnvelope">
      <TemplateEditor />
    </div>
  )
}

export default SectionEditorTemplate
