export const lineHeight = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '1.1',
    value: '1.1'
  },
  {
    label: '1.2',
    value: '1.2'
  },
  {
    label: '1.3',
    value: '1.3'
  },
  {
    label: '1.4',
    value: '1.4'
  },
  {
    label: '1.5',
    value: '1.5'
  },
  {
    label: '2',
    value: '2'
  }
]
