import { DateTime } from 'luxon'

const formatData = (
  data: string,
  locale: 'PtBr' | 'EnUs',
  includeTime = true
): string => {
  if (!data) return ''
  const dataObj = DateTime.fromISO(data)

  const formatString =
    locale === 'EnUs'
      ? includeTime
        ? 'yyyy-MM-dd hh:mm:ss a'
        : 'yyyy-MM-dd'
      : includeTime
        ? 'dd/MM/yyyy HH:mm:ss'
        : 'dd/MM/yyyy'

  return dataObj
    .setLocale(locale === 'EnUs' ? 'en-US' : 'pt-BR')
    .toFormat(formatString)
}

interface TranslateProps {
  yearsAgo: string
  monthsAgo: string
  weeksAgo: string
  daysAgo: string
  hoursAgo: string
  minutesAgo: string
  secondsAgo: string
}

function formatTimeDistance(date: string, t: TranslateProps): string {
  if (!date) return ''
  const now = DateTime.local()
  const providedDate = DateTime.fromISO(date)
  const diff = now.diff(providedDate, [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds'
  ])

  if (diff.years >= 1) {
    return Math.floor(diff.years) + t?.yearsAgo
  }
  if (diff.months >= 1) {
    return Math.floor(diff.months) + t?.monthsAgo
  }
  if (diff.weeks >= 1) {
    const weeks = Math.floor(diff.weeks)
    const daysAfterWeek = Math.floor(diff.days - weeks * 7)
    if (daysAfterWeek > 0) {
      return `${weeks} ${t?.weeksAgo} e ${daysAfterWeek} ${t?.daysAgo}`
    }
    return weeks + t?.weeksAgo
  }
  if (diff.days >= 1) {
    return Math.floor(diff.days) + t?.daysAgo
  }
  if (diff.hours >= 1) {
    return Math.floor(diff.hours) + t?.hoursAgo
  }
  if (diff.minutes >= 1) {
    return Math.floor(diff.minutes) + t?.minutesAgo
  }
  return Math.floor(diff.seconds) + t?.secondsAgo
}

export type FormatType = 'date' | 'datetime' | 'time'
export type LocaleType = 'PtBr' | 'EnUs'

const formatDate = (
  dateString: string | Date,
  formatType: FormatType,
  locale: LocaleType
): string => {
  if (!dateString) return ''

  const luxonLocale = locale === 'PtBr' ? 'pt-BR' : 'en-US'

  const date = DateTime.fromJSDate(new Date(dateString)).setLocale(luxonLocale)

  let format = ''
  switch (formatType) {
    case 'date':
      format = locale === 'PtBr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
      break
    case 'datetime':
      format =
        locale === 'PtBr' ? 'dd/MM/yyyy HH:mm:ss' : 'MM/dd/yyyy hh:mm:ss a'
      break
    case 'time':
      format = 'HH:mm:ss'
      break
  }

  return date.toFormat(format)
}

const checkDate = (date: string): 'past' | 'future' | 'today' => {
  if (!date) throw new Error('Date not provided')

  const dateObj = DateTime.fromISO(date)
  const today = DateTime.local().startOf('day')
  const comparison = dateObj.startOf('day')

  if (comparison < today) {
    return 'past'
  } else if (comparison > today) {
    return 'future'
  } else {
    return 'today'
  }
}

const getFutureDate = (days: number, locale: LocaleType) => {
  const currentDate = DateTime.local()
  const futureDate = currentDate.plus({ days })
  const luxonLocale = locale === 'EnUs' ? 'en-US' : 'pt-BR'

  const localizedFutureDate = futureDate.setLocale(luxonLocale)

  return localizedFutureDate.toJSDate()
}

export { formatData, formatTimeDistance, formatDate, checkDate, getFutureDate }
