import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useAuth } from '@/providers/Auth'
import { Typography } from '@/atoms/index'
import useAuthApi from '@/hooks/api/auth/useAuthApi/useAuthApi'
import { ValidateInviteResponse } from '@/hooks/api/auth/useAuthApi/useAuthApi.types'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import { useQueryParams } from '@/providers'
import { Button } from '@/ui/atoms/shadcn'
import { Form, PasswordRequirements, RenderFormField } from '@/ui/molecules'

type Props = {
  inviteId: string
}

type FormValues = {
  code: string
  password: string
}

const InviteForm = ({ inviteId }: Props) => {
  const { t, isReady } = useTranslation(['register', 'validations'], true)
  const { loading, registerAction, inviteProps } = useAuth()
  const [validateInviteResponse, setValidateInviteResponse] =
    useState<ValidateInviteResponse | null>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    getQueryParam,
    setQueryParam,
    isReady: isReadyQueryParams
  } = useQueryParams()
  const codeQueryParam = getQueryParam<string>('code', null)
  const { useGetValidateInvite } = useAuthApi()
  const { mutateAsync: getValidateInviteResponse } = useGetValidateInvite()

  useEffect(() => {
    if (!inviteId) return
    getValidateInviteResponse(inviteId)
      .then((response) => {
        setValidateInviteResponse(response)
      })
      .catch((errors) => {
        console.error(errors)
      })
  }, [inviteId, getValidateInviteResponse])

  const formSchema = z.object({
    code: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(6, { message: t?.errorCodeLength?.(6) })
      .max(6, { message: t?.errorCodeLength?.(6) }),
    password: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(8, { message: t?.errorMinLength?.(8) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        {
          message: t?.errorPasswordValidation
        }
      )
  })

  const onSubmit = useCallback(
    async (values) => {
      const token = executeRecaptcha
        ? await executeRecaptcha('register_with_invite')
        : ''
      registerAction(
        {
          code: values.code,
          password: values.password,
          inviteId: inviteId,
          recaptchaToken: token
        },
        validateInviteResponse?.email,
        validateInviteResponse?.type
      )
    },
    [executeRecaptcha, inviteId, registerAction, validateInviteResponse]
  )

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
      password: ''
    }
  })
  const valuePassword = useWatch({
    control: form.control,
    name: 'password'
  })

  useEffect(() => {
    if (isReadyQueryParams && codeQueryParam) {
      form.setValue('code', codeQueryParam)
    }
  }, [codeQueryParam, form, isReadyQueryParams, setQueryParam])

  const feedbackPassword = useMemo(
    () => ({
      minLength: valuePassword.length >= 8,
      oneUpperCase: /^(?=.*[A-Z])/.test(valuePassword),
      oneLowerCase: /^(?=.*[a-z])/.test(valuePassword),
      oneNumber: /^(?=.*\d)/.test(valuePassword),
      oneSpecialCharacter: /^(?=.*[@$!%*?&])/.test(valuePassword)
    }),
    [valuePassword]
  )

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.invite?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.invite?.descriptionPrefix}
            <span className="font-medium text-gray-800">
              {inviteProps?.email}
            </span>
            {t?.invite?.descriptionSuffix}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} onSubmit={onSubmit}>
          <RenderFormField
            control={form.control}
            name="code"
            type="tokenInput"
            disabled={false}
            showSkeleton={!isReady}
            {...form.formState.errors.code}
            {...t?.invite?.form?.code}
          />
          <RenderFormField
            control={form.control}
            name="password"
            type="password"
            disabled={false}
            showSkeleton={!isReady}
            {...form.formState.errors.password}
            {...t?.invite?.form?.password}
          />
          <PasswordRequirements {...feedbackPassword} />
          <Button
            type="submit"
            fullWidth
            loading={loading}
            showSkeleton={!isReady}
          >
            {t?.invite?.button}
          </Button>
        </Form>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.loginPrefix}
          </Typography>
          <Link href="/login" passHref>
            <Typography
              variant="text-sm-semibold"
              className="cursor-pointer text-secondary-700"
              showSkeleton={!isReady}
            >
              {t?.login}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  )
}

export default InviteForm
