import { useCallback, useEffect, useState } from 'react'
import { FunnelIcon } from '@heroicons/react/24/outline'
import useTranslation from '@/hooks/useTranslation'
import FilterVaults from '../FilterVaults'
import ToggleButtonGroup, { ToggleButtonProps } from '../ToggleButtonGroup'
import * as DropdownMenu from '@/atoms/shadcn/DropdownMenu'
import { cn } from '@/lib/utils'
import {
  DatePicker,
  FilterFavorites,
  InputSearch,
  Select,
  SelectGroupOption,
  SelectOption,
  Typography
} from '@/ui/atoms'
import { CustomDatePickerProps } from '@/ui/atoms/DatePicker/DatePicker'
import { Badge, Button } from '@/ui/atoms/shadcn'

export type FilterType =
  | 'toggleButtonGroup'
  | 'select'
  | 'inputSearch'
  | 'filterFavorites'
  | 'filterVaults'
  | 'date'

export type ToggleButtonGroupOptionsType = {
  buttons?: ToggleButtonProps[]
  initialValue?: string
}

export type SearchOptionsType = {
  placeholder?: string
  showSkeleton?: boolean
  timeoutSkeleton?: number
  initialValue?: string
}

export type SelectOptionsType = {
  options?: SelectOption[] | SelectGroupOption[]
}

export type DateOptionsType = Omit<CustomDatePickerProps, 'onChange'>

export type FilterOption = ToggleButtonGroupOptionsType &
  SearchOptionsType &
  SelectOptionsType &
  DateOptionsType & {
    id: string
    type: FilterType
    label: string
    isActive: boolean
    disabled?: boolean
    showSkeleton?: boolean
    hidden?: boolean
  }

type FilterDropdownProps = {
  filtersSchema: FilterOption[]
  setPreparedValues: (filters: any) => void
  values: Record<string, string | string[] | null>
  onFilter: (
    filters: Record<string, string | null>,
    resetPagination?: boolean
  ) => void
  showSkeleton?: boolean
  timeoutSkeleton?: number
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  filtersSchema,
  values,
  setPreparedValues,
  onFilter,
  showSkeleton = false,
  timeoutSkeleton
}) => {
  const { t } = useTranslation('filterDropdown')
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const activeCount = filtersSchema?.filter((filter) => filter.isActive).length

  useEffect(() => {
    const hasDifferencesInValues = Object.keys(values).some(
      (key) => values[key] !== values[key]
    )
    if (hasDifferencesInValues && !hasChanges) {
      setPreparedValues(values)
    }
  }, [hasChanges, setPreparedValues, values])

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoadingSkeleton(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const handleFilter = useCallback(
    (id: string, filter: string | string[] | null) => {
      setHasChanges(true)

      const newFilters = { ...values, [id]: filter }

      setPreparedValues(newFilters)
    },
    [setPreparedValues, values]
  )

  const handleClickApply = useCallback(() => {
    const filtersValues = Object.keys(values).reduce(
      (acc, key) => {
        const value = values[key]
        if (value !== null && value !== '') {
          if (Array.isArray(value)) {
            acc[key] = value.join(',')
          } else {
            acc[key] = value as string
          }
        }
        return acc
      },
      {} as Record<string, string | null>
    )
    onFilter(filtersValues)
    setIsOpen(false)
    setHasChanges(false)
  }, [values, onFilter])

  const handleClickReset = useCallback(() => {
    const initialValues = filtersSchema.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.id]: filter.initialValue
      }),
      {}
    ) as Record<string, string | null>

    onFilter(initialValues, true)
    setPreparedValues(initialValues)
    setIsOpen(false)
  }, [filtersSchema, onFilter, setPreparedValues])

  const renderFilter = useCallback(
    (data: FilterOption, index: number) => {
      const filterValue = values[data.id]
      const filterBy = values['filterBy']
      const hiddenDatePicker =
        data?.type === 'date' && filterBy && filterBy !== 'expiringSoon'

      if (data.hidden || hiddenDatePicker) {
        return null
      }
      switch (data.type) {
        case 'toggleButtonGroup':
          return (
            <ToggleButtonGroup
              key={index}
              label={data?.label}
              buttons={data?.buttons || []}
              currentButton={filterValue as string}
              onToggle={(button) => {
                handleFilter(data?.id, button)
              }}
              disabled={data?.disabled}
              showSkeleton={data?.showSkeleton}
            />
          )
        case 'inputSearch':
          return (
            <InputSearch
              key={index}
              label={data?.label}
              value={filterValue as string}
              onChange={(e) => {
                handleFilter(data.id, e.target.value)
              }}
              onClear={() => handleFilter(data.id, '')}
              placeholder={data?.placeholder}
              showSkeleton={data?.showSkeleton}
              timeoutSkeleton={data?.timeoutSkeleton}
              className="w-full md:w-full"
            />
          )
        case 'select':
          return (
            <Select
              key={index}
              id={data?.id}
              label={data?.label}
              value={filterValue as string}
              onChange={(value) => {
                handleFilter(data.id, value)
              }}
              options={data?.options as SelectOption[] | SelectGroupOption[]}
              placeholder={data?.placeholder}
              disabled={data?.disabled}
              showSkeleton={data?.showSkeleton}
              timeoutSkeleton={data?.timeoutSkeleton}
              className="w-full md:w-full"
            />
          )
        case 'filterFavorites':
          return (
            <FilterFavorites
              key={index}
              typeFavorite={(filterValue as 'favorite' | 'unfavorite') || null}
              onToggleFavorite={(value) =>
                handleFilter(data.id, value as string)
              }
              disabled={data?.disabled}
              label={data?.label}
              showSkeleton={data?.showSkeleton}
              timeoutSkeleton={data?.timeoutSkeleton}
            />
          )
        case 'date':
          return (
            <DatePicker
              onChange={(date) => {
                if (date !== filterValue) {
                  handleFilter(data.id, date as any)
                }
              }}
              defaultValue={
                filterValue ? new Date(filterValue as string) : null
              }
              format={data?.format || 'dd/MM/yyyy HH:mm:ss'}
              name={data?.id}
              {...data}
            />
          )
        case 'filterVaults':
          return (
            <FilterVaults
              id={data.id}
              label={data.label}
              onFilterChange={(vaults) => {
                handleFilter(data.id, vaults)
              }}
              filters={filterValue as string}
            />
          )
        default:
          return <div key={index} />
      }
    },
    [values, handleFilter]
  )

  if (showSkeleton || isLoadingSkeleton) {
    return <div className="h-10 skeleton w-28" />
  }

  return (
    <DropdownMenu.DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.DropdownMenuTrigger asChild>
        <Button
          className="flex items-center px-4 py-2 space-x-2 ring-0"
          variant="outlineSecondary"
        >
          <FunnelIcon className="w-5 h-5" />
          <span>{t?.filter}</span>
          {activeCount > 0 && (
            <Badge
              className="flex items-center justify-center w-5 h-5 ml-2 text-white bg-secondary"
              size="sm"
              shape="round"
            >
              {activeCount}
            </Badge>
          )}
        </Button>
      </DropdownMenu.DropdownMenuTrigger>
      <DropdownMenu.DropdownMenuContent className="p-0 mt-2 border-gray-200 rounded-md shadow-lg bg-accent-100 w-72">
        <div
          className={cn(
            'flex justify-between items-start border-b border-gray-200 px-3 py-2'
          )}
        >
          <Typography variant="text-base-regular" className="font-display">
            {t?.filter}
          </Typography>
        </div>
        <div className="flex flex-col gap-4 px-3 py-4">
          {filtersSchema?.map((filter, index) => renderFilter(filter, index))}
        </div>
        <div
          className={cn(
            'flex justify-between items-center border-t border-gray-200 px-3 py-2'
          )}
        >
          <Button variant="neutral" size="sm" onClick={handleClickReset}>
            {t?.reset}
          </Button>
          <Button size="sm" onClick={handleClickApply}>
            {t?.apply}
          </Button>
        </div>
      </DropdownMenu.DropdownMenuContent>
    </DropdownMenu.DropdownMenu>
  )
}

export default FilterDropdown
