import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { Button } from '@/ui/atoms/shadcn'

interface Props {
  type:
    | 'history'
    | 'dashboard'
    | 'recycleBinEnvelope'
    | 'recycleBinTemplate'
    | 'audits'
    | 'inbox'
    | 'users'
    | 'usersInvite'
    | 'envelopes'
    | 'contacts'
    | 'vaults'
    | 'vault'
    | 'billingPayments'
    | 'profileSignatures'
    | 'profileRubrics'
    | 'searchVault'
    | 'templates'
    | 'profilePendingInvitations'
  handleClickButton?: () => void
}

const EmptyStateTable = ({ type, handleClickButton }: Props) => {
  const { t, isReady } = useTranslation('emptyStateTable')

  const tContent = t?.[type]

  return (
    <div className="flex flex-col items-center justify-center gap-2 h-60">
      {isReady ? (
        <Image
          src={tContent?.icon || '/assets/icons/empty-inbox.svg'}
          alt="Folder Dashboard"
          width={192}
          height={192}
        />
      ) : (
        <div className="w-48 h-48 skeleton" />
      )}
      <div className="flex flex-col items-center justify-center gap-1">
        <Typography
          variant="title-lg-medium"
          className="text-center"
          showSkeleton={!isReady}
        >
          {tContent?.title}
        </Typography>
        <Typography
          variant="text-sm-regular"
          className="text-center"
          showSkeleton={!isReady}
        >
          {tContent?.subtitle}
        </Typography>
      </div>
      {handleClickButton && tContent?.button && (
        <Button
          variant="link"
          onClick={handleClickButton}
          className="pt-5 underline"
        >
          {tContent?.button}
        </Button>
      )}
    </div>
  )
}

export default EmptyStateTable
