import React, { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { Drawer as DrawerVaul } from 'vaul'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useTemplate } from '@/providers/Template'
import { Form, RenderFormActions, RenderFormField } from '..'
import {
  FieldType,
  TypesFieldsOptions
} from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import {
  ActionFieldType,
  DataDrawerActionFieldType
} from '@/providers/Template/Template.types'
import { Input, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  onClose: () => void
  data: DataDrawerActionFieldType
}

function removeAccents(text: string): string {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]+/g, '_')
    .toLowerCase()
}

const DrawerActionField: React.FC<Props> = ({ onClose, data }) => {
  const { t } = useTranslation(['template', 'validations'], true)
  const { currentDataTemplate, handleInsertField, handleSaveField } =
    useTemplate()
  const { fields } = currentDataTemplate
  const { open, action, alias } = data

  const formSchema = z
    .object({
      type: z.string().min(1, { message: t?.requiredField }),
      name: z.string().min(1, { message: t?.requiredField }),
      alias: z
        .string()
        .min(1, { message: t?.requiredField })
        .regex(/^[a-zA-Z0-9_]*$/, {
          message: t?.drawerActionField?.invalidCharsMsg
        })
        .transform((value) => removeAccents(value)),
      isRequired: z
        .boolean()
        .refine((val) => val !== undefined, { message: t?.requiredField }),
      customRegex: z.string().optional().nullable()
    })
    .refine(
      (values) => {
        if (action === ActionFieldType.EDIT) {
          return true
        }
        return !fields?.some((field) => field.name === values.name)
      },
      {
        message: t?.drawerActionField?.uniqueErrorMsg,
        path: ['name']
      }
    )
    .refine(
      (values) => {
        if (values.type === 'Custom') {
          if (!values.customRegex) {
            return false
          }
          try {
            new RegExp(values.customRegex)
            return true
          } catch (e) {
            return false
          }
        }
        return true
      },
      {
        message: t?.drawerActionField?.invalidRegexMsg,
        path: ['customRegex']
      }
    )

  const form = useForm<FieldType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: TypesFieldsOptions.Text,
      name: '',
      description: '',
      alias: '',
      isRequired: false,
      customRegex: ''
    }
  })
  const valueName = useWatch({
    control: form.control,
    name: 'name',
    defaultValue: ''
  })
  const valueAlias = useWatch({
    control: form.control,
    name: 'alias',
    defaultValue: ''
  })

  useEffect(() => {
    if (
      !!alias &&
      valueName === '' &&
      valueAlias === '' &&
      action === ActionFieldType.EDIT
    ) {
      const field = fields?.find((field) => field.alias === alias)
      form.reset(field)
    }
  }, [action, alias, fields, form, valueAlias, valueName])

  const handleClose = useCallback(() => {
    onClose()
    form.reset({
      type: TypesFieldsOptions.Text,
      name: '',
      description: '',
      alias: '',
      isRequired: false,
      customRegex: ''
    })
  }, [form, onClose])

  const onSubmit = useCallback(
    async (values: FieldType) => {
      const fieldId =
        action === ActionFieldType.EDIT && values.id
          ? values.id
          : 'fake-id-' + uuidv4()

      const fieldData = {
        id: fieldId,
        type: values.type,
        name: values.name,
        description: values.description,
        alias: values.alias,
        isRequired: values.isRequired,
        customRegex: values.customRegex
      }

      const alias =
        action !== ActionFieldType.CREATE ? (data.alias as string) : undefined

      handleSaveField(fieldData, alias)

      if (action === ActionFieldType.CREATE_IN_EDITOR) {
        handleInsertField(values.alias)
      }

      handleClose()
    },
    [action, data.alias, handleClose, handleInsertField, handleSaveField]
  )

  const valueType = useWatch({
    control: form.control,
    name: 'type',
    defaultValue: TypesFieldsOptions.Text
  })

  return (
    <DrawerVaul.Root direction="right" open={open}>
      <DrawerVaul.Portal>
        <DrawerVaul.Overlay
          className="fixed inset-0 z-20 bg-black/40"
          onClick={() => {
            handleClose()
          }}
        />
        <DrawerVaul.Content className="bg-white flex flex-col h-[calc(100vh-64px)] w-full sm:w-[400px] fixed bottom-0 top-16 right-0 z-50">
          <div className="flex-1 w-full p-4 overflow-y-auto md:max-w-md">
            <Typography
              variant="text-base-medium"
              className="pb-5 no-underline hover:no-underline"
            >
              {action === ActionFieldType.EDIT
                ? t?.drawerActionField?.titleEdit
                : t?.drawerActionField?.titleCreate}
            </Typography>
            <Form {...form} onSubmit={onSubmit}>
              <RenderFormField
                control={form.control}
                name="type"
                type="select"
                {...t?.drawerActionField?.form?.type}
              />
              <RenderFormField
                control={form.control}
                name="name"
                type="input"
                {...t?.drawerActionField?.form?.name}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value)

                      const baseValueAlias = removeAccents(e.target.value)

                      let newAlias = baseValueAlias
                      const existingAliases = fields
                        ?.filter((field) =>
                          field.alias.startsWith(baseValueAlias)
                        )
                        .map((field) => field.alias.replace(baseValueAlias, ''))
                        .map((suffix) =>
                          suffix.startsWith('_')
                            ? parseInt(suffix.slice(1), 10)
                            : 0
                        )
                        .filter((number) => !isNaN(number))
                        .sort((a, b) => a - b)

                      if (existingAliases?.length || 0 > 0) {
                        const highestNumber =
                          existingAliases?.[existingAliases?.length - 1] || 0
                        newAlias = `${baseValueAlias}_${highestNumber + 1}`
                      }

                      form.setValue('alias', newAlias)
                    }}
                    value={field.value}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <RenderFormField
                control={form.control}
                name="alias"
                type="input"
                {...t?.drawerActionField?.form?.alias}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(removeAccents(e.target.value))
                    }}
                    value={field.value}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <RenderFormField
                control={form.control}
                name="description"
                type="textarea"
                {...t?.drawerActionField?.form?.description}
              />
              {valueType === TypesFieldsOptions.Custom && (
                <RenderFormField
                  control={form.control}
                  name="customRegex"
                  type="input"
                  {...t?.drawerActionField?.form?.customRegex}
                />
              )}
              <RenderFormField
                control={form.control}
                name="isRequired"
                type="toggle"
                {...t?.drawerActionField?.form?.isRequired}
              />

              <RenderFormActions align="between" className="border-0">
                <Button type="button" variant="neutral" onClick={handleClose}>
                  {t?.drawerActionField?.form?.actionsButton?.cancel}
                </Button>
                <Button type="submit">
                  {action === ActionFieldType.CREATE
                    ? t?.drawerActionField?.form?.actionsButton?.create
                    : t?.drawerActionField?.form?.actionsButton?.save}
                </Button>
              </RenderFormActions>
            </Form>
          </div>
        </DrawerVaul.Content>
      </DrawerVaul.Portal>
    </DrawerVaul.Root>
  )
}

export default DrawerActionField
