import React, { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { Modal } from '..'
import useAuthApi from '@/hooks/api/auth/useAuthApi/useAuthApi'
import { UserAccount } from '@/hooks/api/auth/useAuthApi/useAuthApi.types'
import { useAuth } from '@/providers'
import { Typography } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

type LoadingSubmitModalSwitchAccountType = {
  isLoading: boolean
  accountId: string | null
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ModalSwitchAccount: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t, isReady } = useTranslation('headerOptionGroup')
  const [loadingSubmitModalSwitchAccount, setLoadingSubmitModalSwitchAccount] =
    useState<LoadingSubmitModalSwitchAccountType>({
      isLoading: false,
      accountId: null
    })
  const { setUserContext, getUserContextByAccount, userContext } = useAuth()
  const { reload } = useRouter()
  const { useGetListUserAccounts } = useAuthApi()
  const {
    data: dataListUserAccounts,
    isLoading: isLoadingListUserAccounts,
    isFetched: isFetchedListUserAccounts,
    refetch: refetchListUserAccounts,
    isRefetching: isRefetchingListUserAccounts
  } = useGetListUserAccounts()

  useEffect(() => {
    if (isOpen) {
      refetchListUserAccounts()
    }
  }, [isOpen, refetchListUserAccounts])

  const handleChangeAccount = useCallback(
    async (account: UserAccount) => {
      setLoadingSubmitModalSwitchAccount({
        isLoading: true,
        accountId: account.userAccountId
      })
      try {
        const response = await getUserContextByAccount(account.userAccountId)

        if (response?.tenantInformation?.id) {
          setUserContext(response)
          showToast.success(t?.modalSwitchAccount?.toast?.successChangeAccount)

          reload()
        }
      } catch (error) {
        console.error(error)
        showToast.error(t?.modalSwitchAccount?.toast?.errorChangeAccount)
      } finally {
        onClose()
        setLoadingSubmitModalSwitchAccount({
          isLoading: false,
          accountId: null
        })
      }
    },
    [
      getUserContextByAccount,
      onClose,
      reload,
      setUserContext,
      t?.modalSwitchAccount?.toast?.errorChangeAccount,
      t?.modalSwitchAccount?.toast?.successChangeAccount
    ]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t?.modalSwitchAccount?.title}
      size="md"
      displayAsDrawerOnMobile
    >
      <div className="flex flex-col gap-2">
        {isLoadingListUserAccounts ||
        !isFetchedListUserAccounts ||
        !isReady ||
        isRefetchingListUserAccounts ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="w-full h-14 skeleton" />
          ))
        ) : dataListUserAccounts?.items?.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
            <div className="relative w-full h-full max-w-32">
              <Image
                src="/assets/icons/empty-state.svg"
                alt="Folder Dashboard"
                layout="fill"
              />
            </div>
            <Typography
              variant="text-sm-regular"
              className="text-center"
              showSkeleton={!isReady}
            >
              {t?.modalSwitchAccount?.emptyState}
            </Typography>
          </div>
        ) : (
          dataListUserAccounts?.items?.map((account) => (
            <div
              key={account.accountId}
              className="flex items-center justify-between px-4 py-3 border border-gray-300 rounded shadow-sm cursor-pointer dark:bg-gray-50 dark:text-black"
            >
              <Typography variant="text-base-regular" className="flex-1">
                {account.accountName}
              </Typography>
              <Button
                variant="link"
                className="flex items-center gap-1 w-fit"
                onClick={() =>
                  account?.userAccountId !==
                    userContext?.userAccountInformation?.id &&
                  handleChangeAccount(account)
                }
                disabled={
                  account?.userAccountId ===
                  userContext?.userAccountInformation?.id
                }
                loading={
                  loadingSubmitModalSwitchAccount?.accountId ===
                    account?.userAccountId &&
                  loadingSubmitModalSwitchAccount?.isLoading
                }
              >
                {account?.userAccountId ===
                userContext?.userAccountInformation?.id
                  ? t?.modalSwitchAccount?.currentAccount
                  : t?.modalSwitchAccount?.goTo}
              </Button>
            </div>
          ))
        )}
      </div>
    </Modal>
  )
}

export default ModalSwitchAccount
