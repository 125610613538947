import React from 'react'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import SectionReviewEnvelopeInfo from '../SectionReviewEnvelopeInfo'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'

const DrawerReviewEnvelopeInfo: React.FC = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useReviewEnvelope()
  return (
    <Drawer.Drawer
      open={isDrawerOpen}
      onOpenChange={(boolean) => {
        if (!boolean) {
          setIsDrawerOpen(false)
        }
      }}
    >
      <Drawer.DrawerContent className="bg-gray-100 h-[90vh]">
        <div className="relative p-4 overflow-y-scroll">
          <SectionReviewEnvelopeInfo />
        </div>
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerReviewEnvelopeInfo
