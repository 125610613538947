import { useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Modal from 'react-modal'
import { useViewport } from '@/hooks/useViewport'
import { Typography } from '@/atoms/index'
import { cn } from '@/lib/utils'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'

interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  title?: string
  children: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'fit' | 'xl'
  hiddenCloseButton?: boolean
  hiddenHeader?: boolean
  hiddenBorderTop?: boolean
  classesContainerHeader?: string
  classesContainer?: string
  classesContent?: string
  displayAsDrawerOnMobile?: boolean
  maxHeightDrawer?: string
}

const sizeClasses = {
  sm: 'w-1/4',
  md: 'w-full md:w-[580px]',
  lg: 'w-full md:w-1/2 md:max-w-[800px]',
  fit: 'w-fit',
  xl: 'w-3/4'
}

const ModalComponent: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size = 'md',
  hiddenCloseButton = false,
  hiddenHeader = false,
  hiddenBorderTop = false,
  classesContainerHeader,
  classesContent,
  classesContainer,
  displayAsDrawerOnMobile = false,
  maxHeightDrawer = '80vh'
}) => {
  const { breakpoint } = useViewport()

  const shouldRenderAsDrawer = useMemo(() => {
    return (
      displayAsDrawerOnMobile && (breakpoint === 'xs' || breakpoint === 'sm')
    )
  }, [breakpoint, displayAsDrawerOnMobile])

  if (shouldRenderAsDrawer) {
    return (
      <Drawer.Drawer
        open={isOpen}
        onOpenChange={(boolean) => {
          if (!boolean) {
            onClose?.()
          }
        }}
      >
        <Drawer.DrawerContent
          className={cn(
            `bg-gray-50 p-4 max-h-[${maxHeightDrawer}]`,
            classesContainer
          )}
        >
          {!hiddenHeader && (
            <div
              className={cn(
                'flex justify-between items-start pb-2',
                hiddenBorderTop ? '' : 'border-b border-gray-200'
              )}
            >
              {title && (
                <Typography variant="title-xl-regular" className="font-display">
                  {title}
                </Typography>
              )}
            </div>
          )}
          <div
            className={cn(
              'relative overflow-auto max-h-[80vh]',
              !hiddenHeader && 'pt-4'
            )}
          >
            {children}
          </div>
        </Drawer.DrawerContent>
      </Drawer.Drawer>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60 transition-opacity duration-500 ease-in-out delay-150"
      className={cn(
        'relative p-6 bg-white dark:bg-black rounded-2xl z-70 max-w-[90vw]',
        sizeClasses[size],
        'mx-auto shadow transform transition-all duration-500 ease-in-out delay-150 outline-none h-auto',
        isOpen ? 'translate-y-0 opacity-100' : 'translate-y-1/4 opacity-0',
        classesContainer
      )}
      style={{
        content: {
          maxHeight: '98vh'
        }
      }}
    >
      {!hiddenHeader && (
        <div
          className={cn(
            'flex justify-between items-start pt-1 pb-2 pl-1',
            hiddenBorderTop ? '' : 'border-b border-gray-200',
            classesContainerHeader
          )}
          style={{ height: '45px' }}
        >
          {title && (
            <Typography variant="title-xl-medium" className="font-display">
              {title}
            </Typography>
          )}
          {!hiddenCloseButton && (
            <button onClick={onClose} className="text-gray-950">
              <XMarkIcon className="w-6 h-6" />
            </button>
          )}
        </div>
      )}
      <div
        className={cn(
          'w-full overflow-auto px-1 pt-4 max-h-[85vh]',
          classesContent
        )}
      >
        {children}
      </div>
    </Modal>
  )
}

Modal.setAppElement('#__next')

export default ModalComponent
