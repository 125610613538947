import React, { useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import Typography from '@/atoms/Typography'
import { useAuth } from '@/providers'
import { Section } from '@/providers/ReviewEnvelope/ReviewEnvelope.types'
import { Button } from '@/ui/atoms/shadcn'

type InformationCard = {
  id: string
  title: string
  description: string
  hasEmphasis?: boolean
}

type Props = {
  status: 'signed' | 'rejected' | 'failed' | 'editing'
}

const SectionReviewFeedback: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('reviewEnvelope')
  const { isLogged } = useAuth()
  const { currentSection } = useReviewEnvelope()
  const { pathname } = useRouter()

  const feedbackData = {
    signed: t?.sectionReviewFeedback?.signed,
    rejected: t?.sectionReviewFeedback?.rejected,
    failed: t?.sectionReviewFeedback?.failed,
    editing: t?.sectionReviewFeedback?.editing
  }[status]

  const informationCardsData: InformationCard[] =
    t?.sectionReviewFeedback?.informationCardsData

  const refUrl = useMemo(() => {
    if (pathname === '/review/[id]' && currentSection) {
      if (currentSection === Section.FeedbackEditing) {
        return 'review-editing'
      }
      if (currentSection === Section.FeedbackFailed) {
        return 'review-failed'
      }
      if (currentSection === Section.FeedbackRejected) {
        return 'review-rejected'
      }
      if (currentSection === Section.FeedbackSigned) {
        return 'review-signed'
      }
      if (currentSection === Section.SignDocument) {
        return 'review-sign'
      }
      if (currentSection === Section.ValidationMFA) {
        return 'review-mfa'
      }
    }
    return
  }, [currentSection, pathname])

  return (
    <div className="flex flex-col items-center overflow-x-hidden bg-white dark:bg-black h-containerFull">
      <div className="flex flex-col items-center w-full gap-6 px-2 pt-6 pb-8 bg-gray-50">
        <img
          src={feedbackData?.icon}
          alt={feedbackData?.title}
          className="w-36 md:w-44"
        />
        <div className="flex flex-col items-center gap-2">
          <Typography
            variant="title-3xl-medium"
            className="text-center text-gray-700"
          >
            {feedbackData?.title}
          </Typography>
          <Typography
            variant="title-xl-regular"
            className="max-w-xs text-center text-gray-700 md:max-w-none"
          >
            {feedbackData?.description}
          </Typography>
        </div>
      </div>
      <div className="relative flex flex-col items-center w-full max-w-sm gap-4 px-2 pb-6 mt-6 md:max-w-xl md:mt-10 md:pb-20">
        <Typography
          variant="title-3xl-medium"
          className="w-full max-w-md mx-auto text-center text-gray-700 md:max-w-xl"
        >
          {t?.sectionReviewFeedback?.title}
        </Typography>
        <img
          src="/assets/icons/separator-feedback-review.svg"
          alt="Separator"
          className="h-6 absolute top-[52px] sm:top-[60px] md:top-[68px]"
        />
        <Typography
          variant="text-base-medium"
          className="text-center text-gray-700"
        >
          {t?.sectionReviewFeedback?.subtitle}
        </Typography>
        {!isLogged && (
          <Link href={`/register${refUrl && `?ref=${refUrl}`}`}>
            <Button className="py-3.5 w-36 my-4">
              {t?.sectionReviewFeedback?.button}
            </Button>
          </Link>
        )}
      </div>
      <div className="grid max-w-sm grid-cols-1 gap-2 px-2 pb-5 mx-auto sm:max-w-md md:max-w-screen-xl md:grid-cols-2 lg:grid-cols-4 md:pb-10">
        {informationCardsData?.map(
          ({ id, title, description, hasEmphasis }) => (
            <div
              className={`w-full px-4 md:px-6 py-6 md:py-10 flex flex-col gap-2 rounded-md ${
                hasEmphasis ? 'bg-indigo-50 dark:bg-secondary' : ''
              }`}
              key={id}
            >
              <Typography
                variant="title-lg-medium"
                className="text-gray-700 dark:text-gray-700"
              >
                {title}
              </Typography>
              <Typography
                variant="text-base-regular"
                className="text-gray-700 dark:text-gray-700"
              >
                {description}
              </Typography>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default SectionReviewFeedback
