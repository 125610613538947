import React, { Dispatch, SetStateAction } from 'react'
import { Editor } from '@tiptap/react'
import { FieldType } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { VaultResponse } from '@/hooks/api/ecm/useVaultApi/useVaultApi.types'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault/SelectVault'

export type Props = {
  children?: React.ReactNode
}

export type CurrentDataTemplate = {
  id?: string | null
  vaultId?: string | null
  vaultName?: string | null
  name?: string | null
  description?: string | null
  payload?: string | null
  fields?: FieldType[] | null
}

export type CurrentActionTemplateType = {
  id: string | null
  action: 'create' | 'edit' | null
}

export type CurrentTemplateVaultData = {
  vaultId: string | null
  vaultName: string | null
} | null

export type DataModalConfirmType = {
  action: 'delete' | null
  open: boolean
  id: string | null
}

export type TemplateContextType = {
  dataCurrentVault: VaultResponse | null
  editor: Editor | null
  items: any[]
  handleInsertField: (variable: string) => void
  dataDrawerActionField: DataDrawerActionFieldType
  setDataDrawerActionField: Dispatch<DataDrawerActionFieldType>
  handleCloseDrawer: () => void
  currentActionTemplate: CurrentActionTemplateType
  setCurrentActionTemplate: Dispatch<CurrentActionTemplateType>
  currentDataTemplate: CurrentDataTemplate
  setCurrentDataTemplate: Dispatch<CurrentDataTemplate>
  handleDeleteField: () => void
  handleSaveField: (field: FieldType, alias?: string) => void
  currentVaultId: CurrentVaultId
  setCurrentVaultId: React.Dispatch<React.SetStateAction<CurrentVaultId>>
  handleButtonSubmitSave: (values: ValuesDataTemplateType) => void
  changesTemplate: () => {
    changes: Record<string, any>
    hasChanges: boolean
  }
  loadingEditor: boolean
  editable: boolean
  setEditable: Dispatch<SetStateAction<boolean>>
  loadingSubmit: boolean
  isLoadingGetVault: boolean
  openModalSearchVault: boolean
  setOpenModalSearchVault: Dispatch<SetStateAction<boolean>>
  handleSearchVaultCallback: (vault: {
    vaultId: string
    vaultName: string
  }) => void
  dataModalConfirm: DataModalConfirmType
  setDataModalConfirm: Dispatch<DataModalConfirmType>
  openDrawerTemplateDetails: boolean
  setOpenDrawerTemplateDetails: Dispatch<SetStateAction<boolean>>
}

export enum ActionFieldType {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE_IN_EDITOR = 'createInEditor'
}

export type DataDrawerActionFieldType = {
  open: boolean
  action: ActionFieldType
  alias?: string | null
  dataInitial?: {
    name?: string
  } | null
}

export type ValuesDataTemplateType = {
  vault?: {
    value: string | null
    label: string | null
  } | null
  name: string | null
  description: string | null
}
