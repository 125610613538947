import { useState, FC, useEffect, useCallback, useMemo } from 'react'
import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'

type Item = {
  id: string
  label: string
  description: string
}

type RadioGroupPanelProps = {
  items: Item[]
  onChange: (id: string) => void
  value: string
  label?: string
  name?: string
  disabled?: boolean
  showSkeleton?: boolean
}

const RadioGroupPanel: FC<RadioGroupPanelProps> = ({
  items,
  onChange,
  value,
  label,
  name,
  disabled = false,
  showSkeleton = false
}) => {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)

  useEffect(() => {
    if (!items || !value) {
      return
    }
    if (!selectedItem || selectedItem?.id !== value) {
      const item = items.find((item) => item.id === value)
      setSelectedItem(item as Item)
    }
  }, [items, selectedItem, selectedItem?.id, value])

  const handleChange = useCallback(
    (item: Item) => {
      setSelectedItem(item)
      if (onChange) {
        onChange(item.id)
      }
    },
    [onChange]
  )

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-24 min-w-44 skeleton" />
      </div>
    )
  }

  return (
    <RadioGroup value={selectedItem} onChange={handleChange} name={name}>
      {label && (
        <RadioGroup.Label className="w-full text-sm font-medium leading-tight text-gray-700">
          {label}
        </RadioGroup.Label>
      )}
      <div className="-space-y-px rounded-md bg-accent-100 mb-[1px]">
        {items?.map((item, itemIdx) => (
          <RadioGroup.Option
            key={item.id}
            value={item}
            disabled={disabled}
            className={({ checked }) =>
              classNames(
                itemIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                itemIdx === items.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'z-10 border-secondary-200 dark:border-secondary bg-gray-50'
                  : 'border-gray-300',
                'relative flex cursor-pointer border p-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
              )
            }
          >
            {({ active, checked, disabled }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-secondary-700 border-transparent'
                      : 'bg-accent-100 border-gray-300 dark:border-gray-500',
                    active ? 'ring-2 ring-offset-2 ring-secondary-700' : '',
                    'h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                    disabled ? 'opacity-50 cursor-not-allowed' : ''
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-accent-100 w-1.5 h-1.5" />
                </span>
                <span className="flex flex-col ml-3">
                  <RadioGroup.Label
                    as="span"
                    className={`text-gray-900 block text-sm font-medium ${
                      disabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {item.label}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-secondary-700' : 'text-gray-500',
                      'block text-sm',
                      disabled ? 'opacity-50 cursor-not-allowed' : ''
                    )}
                  >
                    {item.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default RadioGroupPanel
