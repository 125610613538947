import React, { useCallback, useMemo, useState } from 'react'
import { Document, DocumentProps, Page } from 'react-pdf'
import { usePageViewportDocument } from '@/hooks/usePageViewportDocument'
import { useViewport } from '@/hooks/useViewport'
import { BASE_PAGE_HEIGHT, BASE_PAGE_WIDTH } from '@/constants/sizesPdf'
import { PageSizeEnvelopeType } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'

type Props = {
  file?: DocumentProps['file']
  pageSizes?: PageSizeEnvelopeType[]
  setLoadingPdf: React.Dispatch<React.SetStateAction<boolean>>
}

const PdfViewDocument: React.FC<Props> = ({
  file,
  pageSizes,
  setLoadingPdf
}) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const { breakpoint, width } = useViewport()
  const { getViewportSize } = usePageViewportDocument()

  const customScale = useMemo(() => {
    if (width <= 380) {
      return 0.5
    }
    if (breakpoint === 'sm') {
      return 0.75
    }
    if (breakpoint === 'xs') {
      return 0.6
    }
    if (breakpoint === 'md') {
      return 0.55
    }
    return null
  }, [breakpoint, width])

  const pageSizesValues = useMemo(() => {
    if (pageSizes) {
      return pageSizes
    }
    // we are using default height and width values for the certified document we don't have the pageSizes values
    return Array.from(new Array(numPages), (_, index) => {
      return {
        page: index + 1,
        width: BASE_PAGE_WIDTH,
        height: BASE_PAGE_HEIGHT
      }
    })
  }, [numPages, pageSizes])

  const renderPage = useCallback(
    (pageItem: PageSizeEnvelopeType) => {
      const { width: scaledWidth, height: scaledHeight } = getViewportSize(
        pageItem?.width,
        pageItem?.height,
        customScale
      )
      return (
        <div
          style={{
            position: 'relative',
            cursor: 'default',
            width: scaledWidth,
            height: scaledHeight
          }}
          className="z-30 mx-auto w-fit"
          key={pageItem.page}
        >
          <Page
            scale={1}
            key={`page_${pageItem.page}`}
            pageNumber={pageItem.page}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={() => {
              setTimeout(() => {
                setLoadingPdf(false)
              }, 1000)
            }}
            onRenderError={() => setLoadingPdf(false)}
            width={scaledWidth}
            height={scaledHeight}
          />
        </div>
      )
    },
    [getViewportSize, customScale, setLoadingPdf]
  )

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages)
      setTimeout(() => {
        setLoadingPdf(false)
      }, 1000)
    },
    [setLoadingPdf]
  )

  if (!file) {
    return null
  }

  return (
    <div className="relative w-full h-full" style={{ minHeight: '100vh' }}>
      <Document
        file={file}
        onLoad={(e) => {
          console.log('onLoad', e)
        }}
        onLoadSuccess={handleDocumentLoadSuccess}
        onLoadError={(e) => {
          console.log('onLoadError', e)
          setLoadingPdf(false)
        }}
        loading={<div style={{ display: 'none' }}></div>}
        className="relative flex flex-col w-full h-full gap-4 py-2 mx-auto"
      >
        {pageSizesValues?.map((page) => renderPage(page))}
      </Document>
    </div>
  )
}

export default PdfViewDocument
