import React from 'react'
import { SidebarNavigationContextItem } from '@/atoms/index'

type Props = {
  items: {
    name: string
    href: string
    icon: any
    current: boolean
  }[]
}

const SidebarNavigationContext: React.FC<Props> = ({ items }) => {
  return (
    <aside className="flex-none hidden w-56 py-8 lg:block">
      <nav className="flex-none px-4 py-3 border border-gray-100 sm:px-6 lg:px-0 bg-accent-100 text-accent-900 rounded-2xl">
        <ul
          role="list"
          className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
        >
          {items.map((item, id) => (
            <SidebarNavigationContextItem
              item={item}
              key={`sidebar-navigation-context-item-${item.name}-${id}`}
            />
          ))}
        </ul>
      </nav>
    </aside>
  )
}

export default SidebarNavigationContext
