import React, { Fragment, useCallback } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCommandPalletSearchBar } from '@/hooks/useCommandPalletSearchBar'
import useTranslation from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

type Project = {
  id: number
  name: string
  url: string
}

type Item = {
  id: string
  text: string
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
  >
  url: string
}

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
}

const CommandPaletteSearchBar = ({ open, setOpen }: Props) => {
  const { t } = useTranslation('commandPalletSearchBar')
  const {
    setQuery,
    dataFiltered,
    isLoading,
    shouldShowResults,
    quickActions,
    dataFilteredPagesSidebar
  } = useCommandPalletSearchBar()
  const { push, pathname } = useRouter()

  const renderItems = useCallback(
    (items: Item[] | undefined) => (
      <ul className="text-sm text-gray-700">
        {items?.map((item) => (
          <Link href={item?.url} key={item.id}>
            <Combobox.Option
              value={item}
              className={({ active }) =>
                cn(
                  'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                  active
                    ? 'bg-primary-700 text-accent-500 dark:text-accent-900'
                    : ''
                )
              }
              onClick={() => {
                if (item.url.includes('/users') && pathname === '/users') {
                  push(item.url)
                }
                setOpen(false)
              }}
            >
              {({ active }) => (
                <>
                  <item.icon
                    className={cn(
                      'h-6 w-6 flex-none',
                      active
                        ? 'text-accent-500 dark:text-accent-900'
                        : 'text-gray-400'
                    )}
                    aria-hidden="true"
                  />
                  <span className="flex-auto ml-3 truncate">{item.text}</span>
                  {active && (
                    <span className="flex-none ml-3 text-accent-500 dark:text-accent-900">
                      {t?.goTo}
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          </Link>
        ))}
      </ul>
    ),
    [pathname, push, setOpen, t?.goTo]
  )

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery('')
      }}
      appear
    >
      <Dialog as="div" className="relative z-60" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-25 bg-slate-950" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 p-4 pt-20 overflow-y-auto sm:p-6 sm:pt-20 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="max-w-2xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl dark:bg-black ring-1 ring-black dark:ring-gray-100 ring-opacity-5">
              <Combobox
                onChange={(item: Project) =>
                  (window.location = item.url as any)
                }
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder={t?.search}
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                <Combobox.Options
                  static
                  className="overflow-y-auto divide-y divide-gray-100 max-h-80 scroll-py-2"
                >
                  {dataFilteredPagesSidebar?.length > 0 && (
                    <li className="p-2">
                      <ul className="text-sm text-gray-700">
                        {dataFilteredPagesSidebar.map((action: any) => {
                          if (!action) return null

                          return (
                            <Link href={action?.url} key={action?.id}>
                              <Combobox.Option
                                value={action}
                                className={({ active }) =>
                                  cn(
                                    'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                                    active
                                      ? 'bg-primary-700 text-accent-500 dark:text-accent-900'
                                      : ''
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <action.icon
                                      className={cn(
                                        'h-6 w-6 flex-none text-accent-500 dark:text-accent-900',
                                        active &&
                                          'text-accent-500 dark:text-accent-900'
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span
                                      className={cn(
                                        'flex-auto ml-3 truncate',
                                        active &&
                                          'text-accent-500 dark:text-accent-900'
                                      )}
                                    >
                                      {action.text}
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                            </Link>
                          )
                        })}
                      </ul>
                    </li>
                  )}
                  {shouldShowResults &&
                    !isLoading &&
                    dataFiltered?.map(({ titleSection, items, id }) => {
                      if ((!items || items.length === 0) && !isLoading)
                        return null
                      return (
                        <li className="p-2" key={id}>
                          <h2 className="px-3 mt-4 mb-2 text-xs font-semibold text-gray-500">
                            {titleSection}
                          </h2>
                          {renderItems(items)}
                        </li>
                      )
                    })}
                  {!shouldShowResults && isLoading && (
                    <div className="flex flex-col gap-2 p-6">
                      <div className="h-4 skeleton w-60"></div>
                      {Array.from(Array(3).keys()).map((_, id) => (
                        <div className="w-full skeleton h-9" key={id}></div>
                      ))}
                    </div>
                  )}
                  <li className="p-2">
                    <h2 className="sr-only">{t?.quickActions}</h2>
                    <ul className="text-sm text-gray-700">
                      {quickActions.map((action) => (
                        <Combobox.Option
                          key={action.id}
                          value={action}
                          className={({ active }) =>
                            cn(
                              'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                              active
                                ? 'bg-primary-700 text-accent-500 dark:text-accent-900'
                                : ''
                            )
                          }
                        >
                          {({ active }) => (
                            <>
                              <action.icon
                                className={cn(
                                  'h-6 w-6 flex-none cursor-pointer',
                                  active
                                    ? 'text-accent-500 dark:text-accent-900'
                                    : 'text-gray-400'
                                )}
                                aria-hidden="true"
                              />
                              <span className="flex-auto ml-3 truncate cursor-pointer">
                                {action.name}
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </li>
                </Combobox.Options>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CommandPaletteSearchBar
