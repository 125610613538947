import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { utcToZonedTime, format as formatDate } from 'date-fns-tz'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelope, useLocale } from '@/providers/index'
import { Lang, VaultType } from '@/types/global'
import {
  DropdownButton,
  IDropdownItem,
  Input,
  SelectVault,
  showToast,
  Textarea
} from '@/atoms/index'
import { SignerEnvelopeStatus } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { cn } from '@/lib/utils'
import {
  CurrentValuesEnvelope,
  SignerEnvelopeInitial
} from '@/providers/Envelope/Envelope.types'
import envelopesT from '@/translations/envelope/index'
import { OptionType } from '@/ui/atoms/SelectVault/SelectVault'
import { Button } from '@/ui/atoms/shadcn'
import {
  Form,
  ModalActionVault,
  ModalHelpInfo,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '@/ui/molecules'

type OpenModalType = {
  isOpen: boolean
  type: 'createVault' | 'helpAboutVault' | null
}

const StepItemDetailsEnvelope = () => {
  const { t, isReady } = useTranslation(
    ['envelope', 'language', 'validations'],
    true
  )
  const [openModal, setOpenModal] = useState<OpenModalType>({
    isOpen: false,
    type: null
  })
  const {
    currentStep,
    handleSaveEnvelope,
    loadingEnvelopeSave,
    currentValuesEnvelope,
    setCurrentValuesEnvelope,
    handleActionStep,
    finishedInitialSetup,
    setCurrentVaultId,
    currentActionEnvelope,
    setFinishedInitialSetup,
    dataEnvelope,
    setSigners,
    setDocuments
  } = useEnvelope()
  const { useCreateVault } = useVaultApi()
  const {
    mutateAsync: mutateAsyncCreateVault,
    isLoading: isLoadingCreateVault
  } = useCreateVault()
  const queryClient = useQueryClient()
  const { lang } = useLocale()

  const formSchema = z.object({
    vault: z.object({
      label: z.string().min(1, t?.requiredField),
      value: z.string().min(1, t?.requiredField)
    }),
    name: z
      .string()
      .min(1, t?.requiredField)
      .min(3, t?.errorMinLength?.(3))
      .max(200, t?.errorMaxLength?.(200)),
    message: z.string().max(4000, t?.errorMaxLength?.(4000)),
    privateDescription: z
      .string()
      .max(4000, t?.errorMaxLength?.(4000))
      .nullable(),
    publicDescription: z
      .string()
      .max(4000, t?.errorMaxLength?.(4000))
      .nullable(),
    reviewReminder: z.boolean(),
    toBePublished: z.boolean().optional().nullable(),
    toBePublishedAtUtc: z.date().optional().nullable(),
    hasExpiration: z.boolean().optional().nullable(),
    expirationReminder: z.boolean(),
    expiresAtUtc: z.date().optional().nullable(),
    language: z.string().optional().nullable()
  })

  const form = useForm<CurrentValuesEnvelope>({
    defaultValues: currentValuesEnvelope,
    resolver: zodResolver(formSchema)
  })
  const valueVault = useWatch({
    control: form.control,
    name: 'vault'
  })
  const buttonShowAdvancedSettings = useWatch({
    control: form.control,
    name: 'buttonShowAdvancedSettings'
  })
  const valueToBePublished = useWatch({
    control: form.control,
    name: 'toBePublished'
  })
  const valueHasExpiration = useWatch({
    control: form.control,
    name: 'hasExpiration'
  })

  useEffect(() => {
    if (
      currentActionEnvelope.action === 'create' &&
      !currentValuesEnvelope?.message &&
      t?.defaultMessage &&
      !finishedInitialSetup
    ) {
      form.setValue('message', t?.defaultMessage)
      form.setValue('markupOrientation', 'Bottom')
      form.setValue('language', 'PtBr')

      setCurrentValuesEnvelope({
        ...currentValuesEnvelope,
        message: t?.defaultMessage,
        markupOrientation: 'Bottom',
        language: lang
      })
      setFinishedInitialSetup(true)

      return
    }
    if (
      currentActionEnvelope.action === 'edit' &&
      currentActionEnvelope.id &&
      dataEnvelope &&
      !currentValuesEnvelope?.name &&
      !finishedInitialSetup
    ) {
      setCurrentValuesEnvelope({
        ...currentValuesEnvelope,
        vault: {
          value: dataEnvelope?.vaultId as string,
          label: dataEnvelope?.vaultName as string
        }
      })
      form.reset({
        ...dataEnvelope,
        vault: {
          value: dataEnvelope?.vaultId as string,
          label: dataEnvelope?.vaultName as string
        }
      })

      const signersData = dataEnvelope?.signers
        ?.map((signer) => {
          const phoneNumberValue = signer.shouldEnforceSmsValidation
            ? `+${String(signer.phoneIdd)}${signer.phoneNumber}`
            : null

          return {
            ...signer,
            role: t?.optionsRoles?.find(
              (role: any) => role.value === signer.role
            ).value,
            shouldEnforceEmailValidation:
              signer.shouldEnforceEmailValidation || false,
            shouldEnforcePasscodeValidation:
              signer.shouldEnforcePasscodeValidation || false,
            passcode: signer.passcode || null,
            passcodeHint: signer.passcodeHint || null,
            shouldEnforceSmsValidation:
              signer.shouldEnforceSmsValidation || false,
            phoneIdd: null,
            phoneNumber: phoneNumberValue,
            signMarks: signer.signMarks,
            isDragDisabled: signer.status === SignerEnvelopeStatus.Approved
          }
        })
        .sort((a, b) => a.index - b.index)

      setSigners(signersData as SignerEnvelopeInitial[])
      setDocuments(dataEnvelope?.documents?.sort((a, b) => a.index - b.index))
      setFinishedInitialSetup(true)
    }
  }, [
    currentActionEnvelope.action,
    currentActionEnvelope.id,
    currentValuesEnvelope,
    dataEnvelope,
    finishedInitialSetup,
    form,
    lang,
    setCurrentValuesEnvelope,
    setCurrentVaultId,
    setDocuments,
    setFinishedInitialSetup,
    setSigners,
    t,
    t?.optionsRoles
  ])

  useEffect(() => {
    if (!currentValuesEnvelope?.language) return

    if (
      currentValuesEnvelope?.language !== lang &&
      currentActionEnvelope?.action === 'edit'
    )
      return

    const message =
      envelopesT[currentValuesEnvelope.language as Lang]?.defaultMessage

    setCurrentValuesEnvelope((prev) => ({
      ...prev,
      message:
        prev?.message === envelopesT?.PtBr?.defaultMessage ||
        prev?.message === envelopesT?.EnUs?.defaultMessage ||
        prev?.message === dataEnvelope?.message
          ? message
          : prev?.message
    }))
    form.reset((prev) => ({
      ...prev,
      message:
        prev?.message === envelopesT?.PtBr?.defaultMessage ||
        prev?.message === envelopesT?.EnUs?.defaultMessage ||
        prev?.message === dataEnvelope?.message
          ? message
          : prev?.message
    }))
  }, [
    currentActionEnvelope?.action,
    currentValuesEnvelope.language,
    dataEnvelope?.message,
    form,
    lang,
    setCurrentValuesEnvelope
  ])

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  const handleSubmitCreateVault = useCallback(
    async (values) => {
      try {
        const response = await mutateAsyncCreateVault({
          name: values.name,
          description: values?.description,
          type: values?.type as VaultType,
          ...(values?.users && {
            userAccountMemberIds: values.userAccountMemberIds
          })
        })

        if (response?.vaultId) {
          form.setValue('vault', {
            value: response.vaultId,
            label: values.name
          })
          setCurrentVaultId(response.vaultId)

          await queryClient.invalidateQueries([QueryKeys.ListVaultsSelect])
        }
      } catch (error) {
        showToast.error(t?.toasts?.errorCreateVault)
      } finally {
        setOpenModal({
          isOpen: false,
          type: null
        })
      }
    },
    [
      form,
      mutateAsyncCreateVault,
      queryClient,
      setCurrentVaultId,
      t?.toasts?.errorCreateVault
    ]
  )

  const onSubmit = useCallback(
    async ({ typeAction, ...values }) => {
      const toBePublishedAtUtcValue = values?.toBePublishedAtUtc
        ? formatDate(
            utcToZonedTime(values?.toBePublishedAtUtc, 'Etc/UTC'),
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            {
              timeZone: 'Etc/UTC'
            }
          )
        : null

      const dataActionEnvelope = {
        ...currentValuesEnvelope,
        ...values,
        vault: values?.vault,
        name: values?.name || '',
        message: values?.message || null,
        reviewReminder: values?.reviewReminder || false,
        expirationReminder: values?.expirationReminder || false,
        expiresAtUtc:
          values?.hasExpiration || false ? values?.expiresAtUtc : null,
        hasExpiration: values?.hasExpiration || false,
        toBePublishedAtUtc:
          values?.toBePublished || false ? toBePublishedAtUtcValue : null,
        language: values?.language,
        privateDescription: values?.privateDescription || '',
        publicDescription: values?.publicDescription || '',
        toBePublished: values?.toBePublished || false,
        signInOrder: true
      }

      setCurrentValuesEnvelope(dataActionEnvelope)
      if (typeAction === 'nextStep') {
        handleActionStep('next')
      }
      if (typeAction === 'save') {
        handleSaveEnvelope({
          values: dataActionEnvelope,
          publish: false
        })
      }
    },
    [
      currentValuesEnvelope,
      handleActionStep,
      handleSaveEnvelope,
      setCurrentValuesEnvelope
    ]
  )

  const dataDropdownItems: IDropdownItem[] =
    currentStepData?.action?.dropdownOptions?.map(
      (option: { key: string; label: string }) => ({
        key: option.key,
        label: option.label
      })
    )

  const showSkeleton = useMemo(
    () => !isReady || !finishedInitialSetup,
    [finishedInitialSetup, isReady]
  )

  return (
    <>
      <Form {...form} onSubmit={onSubmit} className="h-full">
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-4">
            {isReady && finishedInitialSetup ? (
              <>
                <SelectVault
                  name={currentStepData?.form?.vault?.name}
                  label={currentStepData?.form?.vault?.label}
                  value={valueVault as OptionType}
                  onSelected={(option) => {
                    form.setValue('vault', option as OptionType)
                  }}
                  showSkeleton={showSkeleton}
                />
                <RenderFormField
                  control={form.control}
                  type="input"
                  showSkeleton={showSkeleton}
                  {...currentStepData?.form?.name}
                />
                <RenderFormField
                  control={form.control}
                  type="textarea"
                  showSkeleton={showSkeleton}
                  {...currentStepData?.form?.message}
                />
                <div className="flex items-center justify-between">
                  <RenderFormField
                    control={form.control}
                    type="toggle"
                    showSkeleton={showSkeleton}
                    {...currentStepData?.form?.reviewReminder}
                  />
                  <Button
                    variant="outlineSecondary"
                    onClick={() =>
                      form.setValue(
                        'buttonShowAdvancedSettings',
                        !buttonShowAdvancedSettings
                      )
                    }
                    type="button"
                    className="w-fit"
                    showSkeleton={showSkeleton}
                  >
                    {currentStepData?.form?.buttonShowAdvancedSettings?.label}
                    <ChevronDownIcon
                      className={`w-4 h-4 ${
                        buttonShowAdvancedSettings ? 'rotate-180' : ''
                      }`}
                    />
                  </Button>
                </div>
                {buttonShowAdvancedSettings && (
                  <>
                    <div className="w-full bg-gray-200 h-[1px]"></div>
                    <RenderFormRow>
                      <RenderFormField
                        control={form.control}
                        type="toggle"
                        showSkeleton={showSkeleton}
                        {...currentStepData?.form?.toBePublished}
                      />
                      {valueToBePublished && (
                        <RenderFormField
                          control={form.control}
                          type="datepicker"
                          showSkeleton={showSkeleton}
                          {...currentStepData?.form?.toBePublishedAtUtc}
                        />
                      )}
                    </RenderFormRow>
                    <RenderFormRow>
                      <RenderFormField
                        control={form.control}
                        type="toggle"
                        showSkeleton={showSkeleton}
                        {...currentStepData?.form?.hasExpiration}
                      />
                      {valueHasExpiration && (
                        <RenderFormField
                          control={form.control}
                          type="datepicker"
                          showSkeleton={showSkeleton}
                          {...currentStepData?.form?.expiresAtUtc}
                        />
                      )}
                    </RenderFormRow>
                    <RenderFormField
                      control={form.control}
                      type="select"
                      showSkeleton={showSkeleton}
                      {...currentStepData?.form?.language}
                    />
                    <RenderFormField
                      control={form.control}
                      type="textarea"
                      showSkeleton={showSkeleton}
                      {...currentStepData?.form?.privateDescription}
                    />
                    <RenderFormField
                      control={form.control}
                      type="textarea"
                      showSkeleton={showSkeleton}
                      {...currentStepData?.form?.publicDescription}
                    />
                    <RenderFormField
                      control={form.control}
                      type="select"
                      showSkeleton={showSkeleton}
                      {...currentStepData?.form?.markupOrientation}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Input showSkeleton />
                <Input showSkeleton />
                <Textarea showSkeleton />
              </>
            )}
          </div>
          <RenderFormActions
            className={cn(buttonShowAdvancedSettings && 'mt-6')}
          >
            <DropdownButton
              label={currentStepData?.action?.label}
              dropdownItems={dataDropdownItems}
              type="submit"
              placement="top"
              loading={loadingEnvelopeSave}
              showSkeleton={showSkeleton}
              onClick={(key, e) => {
                e.preventDefault()
                e.stopPropagation()
                if (!valueVault?.value || !valueVault) {
                  showToast.warning(t?.toasts?.noVault)
                  setOpenModal({
                    isOpen: true,
                    type: 'helpAboutVault'
                  })

                  return
                }
                const type = key === 'save' ? 'save' : 'nextStep'
                form.handleSubmit((values) =>
                  onSubmit({
                    ...values,
                    typeAction: type
                  })
                )()
              }}
            />
          </RenderFormActions>
        </div>
      </Form>
      <ModalHelpInfo
        isOpen={openModal.isOpen && openModal.type === 'helpAboutVault'}
        onClose={() =>
          setOpenModal({
            isOpen: false,
            type: null
          })
        }
        onContinue={() =>
          setOpenModal({
            isOpen: true,
            type: 'createVault'
          })
        }
        title={t?.modalHelpAboutVault?.title}
        description={t?.modalHelpAboutVault?.description}
        continueButtonText={t?.modalHelpAboutVault?.confirmButtonText}
        status="info"
      />
      <ModalActionVault
        action="createVault"
        isOpen={openModal.isOpen && openModal.type === 'createVault'}
        onClose={() =>
          setOpenModal({
            isOpen: false,
            type: null
          })
        }
        isLoading={isLoadingCreateVault}
        onSubmit={handleSubmitCreateVault}
      />
    </>
  )
}

export default StepItemDetailsEnvelope
