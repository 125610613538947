import React from 'react'
import { cn } from '@/lib/utils'

type Props = {
  className?: string
}

const CardEnvelopeSkeleton: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={cn(
        'border border-gray-300 shadow-sm skeleton rounded-3xl h-20',
        className
      )}
    />
  )
}

export default CardEnvelopeSkeleton
