import React, { useCallback, useMemo } from 'react'
import { Document, Page } from 'react-pdf'
import { usePageViewportDocument } from '@/hooks/usePageViewportDocument'
import { useViewport } from '@/hooks/useViewport'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import SignableMark from './SignableMark'
import { MarkType } from '../PdfViewer/PdfViewer.types'
import { PageSizeEnvelopeType } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { usePublicApi } from '@/hooks/api/public/usePublicApi'
import { cn } from '@/lib/utils'
import { SignatureTourButton } from '@/ui/atoms'

type Props = {
  documentId: string
}

const PdfSignDocument: React.FC<Props> = ({ documentId }) => {
  const { useGetOriginalDocument } = usePublicApi()
  const { data: dataGetOriginalDocument } = useGetOriginalDocument(documentId)

  const {
    marks,
    marksRefs,
    dataReviewEnvelope,
    tourOrderId,
    setTourOrderId,
    nextSignMark,
    setLoadingPdf,
    markClickHandler,
    deleteMarkHandler,
    navigateToMark,
    alreadySigned,
    documents
  } = useReviewEnvelope()
  const { breakpoint, width } = useViewport()
  const { getViewportSize, scaleFactor } = usePageViewportDocument()

  const customScale = useMemo(() => {
    if (width <= 380) {
      return 0.5
    }
    if (breakpoint === 'sm') {
      return 0.75
    }
    if (breakpoint === 'xs') {
      return 0.6
    }
    return scaleFactor
  }, [breakpoint, scaleFactor, width])

  const firstDocument = useMemo(() => {
    if (!documents) return null
    return documentId === documents?.[0].id
  }, [documents, documentId])

  const renderPage = useCallback(
    (pageItem: PageSizeEnvelopeType) => {
      const { width: scaledWidth, height: scaledHeight } = getViewportSize(
        pageItem?.width,
        pageItem?.height,
        customScale
      )

      return (
        <div
          style={{
            position: 'relative',
            cursor: 'default',
            width: scaledWidth,
            height: scaledHeight
          }}
          className={cn(
            'z-20 mx-auto w-fit',
            marks?.length > 0 &&
              'lg:mx-0 lg:-right-20 xl:right-auto xl:mx-auto xl:ml-auto'
          )}
          key={pageItem.page}
        >
          <Page
            scale={1}
            key={`page_${pageItem.page}`}
            pageNumber={pageItem.page}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={() => {
              setTimeout(() => {
                setLoadingPdf(false)
              }, 1000)
            }}
            onRenderError={() => setLoadingPdf(false)}
            width={scaledWidth}
            height={scaledHeight}
          />
          {marks
            ?.filter(
              (mark) =>
                mark.page === pageItem.page && mark.documentId === documentId
            )
            .map((mark, index) => (
              <React.Fragment key={index}>
                <SignableMark
                  onClick={markClickHandler}
                  key={mark.id}
                  mark={mark as MarkType}
                  onDelete={deleteMarkHandler}
                  ref={marksRefs?.current?.[mark.id as string] as any}
                  scaleFactor={customScale}
                  nextMarkSign={nextSignMark?.id === mark.id}
                />
                <SignatureTourButton
                  mark={mark}
                  nextSignMark={nextSignMark}
                  onNavigate={() => navigateToMark(mark.id)}
                  scaleFactor={customScale}
                  firstMark={Boolean(
                    !alreadySigned &&
                      tourOrderId === 0 &&
                      firstDocument &&
                      pageItem.page === 1
                  )}
                />
              </React.Fragment>
            ))}
          {!alreadySigned &&
            tourOrderId === 0 &&
            firstDocument &&
            pageItem.page === 1 &&
            nextSignMark && (
              <SignatureTourButton
                onNavigate={() => {
                  if (nextSignMark && marksRefs) {
                    const markRef = marksRefs.current?.[
                      nextSignMark.id as string
                    ] as any
                    if (markRef) {
                      if (setTourOrderId) {
                        setTourOrderId(1)
                      }
                      markRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                      })
                    }
                  }
                }}
                firstMark
              />
            )}
        </div>
      )
    },
    [
      getViewportSize,
      customScale,
      marks,
      alreadySigned,
      tourOrderId,
      firstDocument,
      setLoadingPdf,
      documentId,
      markClickHandler,
      deleteMarkHandler,
      marksRefs,
      nextSignMark,
      navigateToMark,
      setTourOrderId
    ]
  )

  return (
    <div className="relative w-full h-full">
      <Document
        file={dataGetOriginalDocument as any}
        onLoadSuccess={() => {
          setTimeout(() => {
            setLoadingPdf(false)
          }, 1000)
        }}
        onLoadError={(e) => {
          console.log('onLoadError', e)
          setLoadingPdf(false)
        }}
        loading={<div style={{ display: 'none' }}></div>}
        className="relative flex flex-col w-full h-full gap-4"
      >
        {dataReviewEnvelope?.documents
          ?.find((document) => document.id === documentId)
          ?.pageSizes?.map((page) => renderPage(page))}
      </Document>
    </div>
  )
}

export default PdfSignDocument
