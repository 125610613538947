import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

type TableFilters<T> = {
  filters: T | null
  getFilter: <K extends keyof T>(
    key: K,
    defaultValue: T[K] | null
  ) => T[K] | null
  setFilter: <K extends keyof T>(key: K, value: T[K] | null) => Promise<void>
  setFilters: (newFilters: T) => Promise<void>
  handleApplyFilters: (filters: Partial<T>) => Promise<void>
  handleNewQuery: (key: string, value: string | null) => void
  preparedFilters: T | null
  setPreparedFilters: (filters: T) => void
  isReady: boolean
}

const useTableFilters = <T extends Record<string, any>>(
  initialFilters: T
): TableFilters<T> => {
  const { query, replace, isReady: routerIsReady } = useRouter()
  const [preparedFilters, setPreparedFilters] = useState<T>(
    () => initialFilters
  )
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!routerIsReady || isReady) return

    const hasQuery = Object.keys(query).length > 0
    if (preparedFilters && !hasQuery) {
      const valuesNoEmpty = Object.keys(initialFilters).reduce(
        (acc, key) => {
          if (initialFilters[key] !== null && initialFilters[key] !== '') {
            acc[key] = initialFilters[key]
          }
          return acc
        },
        {} as Record<string, any>
      )

      const hasChanges = Object.keys(valuesNoEmpty).some(
        (key) => valuesNoEmpty[key] !== query[key]
      )

      if (hasChanges) {
        replace({ query: { ...query, ...preparedFilters } }, undefined, {
          shallow: true
        })
        setIsReady(true)
      }
      setIsReady(true)
    }
    if (hasQuery) {
      setPreparedFilters(query as T)
      setIsReady(true)
    }
  }, [
    preparedFilters,
    query,
    routerIsReady,
    replace,
    isReady,
    setPreparedFilters,
    initialFilters
  ])

  useEffect(() => {
    setPreparedFilters(query as T)
  }, [query])

  const handleApplyFilters = useCallback(
    async (filters: Partial<T>) => {
      if (!filters) return

      const newQuery = Object.keys(filters).reduce(
        (acc, key) => {
          const value = filters[key]
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = value
          } else {
            delete acc[key]
          }
          return acc
        },
        { ...query } as Record<string, any>
      )

      await replace({ query: { ...newQuery } }, undefined, {
        shallow: true
      })
    },
    [replace, query]
  )

  const setFilter = useCallback(
    async <K extends keyof T>(key: K, newValue: T[K] | null) => {
      setPreparedFilters((prev) => {
        if (!prev) return { [key]: newValue } as T
        const newFilters = { ...prev }
        if (newValue === null || newValue === '') {
          delete newFilters[key]
        } else {
          newFilters[key] = newValue
        }
        return newFilters
      })
    },
    [setPreparedFilters]
  )

  const setFilters = useCallback(
    async (newFilters: T) => {
      setPreparedFilters(newFilters)
    },
    [setPreparedFilters]
  )

  const getFilter = useCallback(
    <K extends keyof T>(key: K, defaultValue: T[K] | null): T[K] | null => {
      return preparedFilters?.[key] ?? defaultValue
    },
    [preparedFilters]
  )

  const filters = useMemo(() => {
    return query as T | null
  }, [query])

  const handleNewQuery = useCallback(
    (key: string, value: string | null) => {
      if (value === null || value === '') {
        const newQuery = { ...query }
        delete newQuery[key]
        replace({ query: newQuery }, undefined, {
          shallow: true
        })
        return
      }
      const newQuery = { ...query, [key]: value }

      replace({ query: newQuery }, undefined, {
        shallow: true
      })
    },
    [replace, query]
  )

  return {
    filters,
    getFilter,
    setFilter,
    setFilters,
    handleApplyFilters,
    handleNewQuery,
    isReady,
    preparedFilters,
    setPreparedFilters
  }
}

export default useTableFilters
