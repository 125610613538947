import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Draggable from 'react-draggable'
import useTranslation from '@/hooks/useTranslation'
import { generateColor, isColorDark } from '@/utils/colors'
import { SignMarkWithSignerType } from '../PdfViewer.types'
import { HEIGHT_MARK, WIDTH_MARK } from '@/constants/sizesPdf'
import { cn } from '@/lib/utils'

type MarkPropsType = {
  mark: SignMarkWithSignerType
  onPositionChanged: (
    position: {
      x: number
      y: number
    },
    id: string | undefined
  ) => void
  onDelete: (id: string) => void
  hidden: boolean
  scaleFactor: number
}

const EditableMark: React.FC<MarkPropsType> = ({
  mark,
  onPositionChanged,
  onDelete,
  hidden,
  scaleFactor
}) => {
  const { t } = useTranslation('envelope')

  const [position, setPosition] = useState({
    x: mark.x * scaleFactor,
    y: mark.y * scaleFactor
  })
  const nodeRef = useRef<HTMLDivElement>(null)
  const buttonDeleteRef = useRef<HTMLButtonElement>(null)

  const updatePosition = useCallback(() => {
    setPosition({
      x: mark.x * scaleFactor,
      y: mark.y * scaleFactor
    })
  }, [mark.x, mark.y, scaleFactor])

  useEffect(() => {
    updatePosition()
  }, [updatePosition])

  const onControlledDrag = useCallback(
    (e, position) => {
      if (mark.signer.currentSignerHasReviewed) return

      const { x, y } = position
      setPosition({
        x,
        y
      })
    },
    [mark.signer.currentSignerHasReviewed]
  )

  const onDragStop = useCallback(
    (_, data) => {
      if (!data || mark.signer.currentSignerHasReviewed) return

      const adjustedX = parseFloat((data.x / scaleFactor).toFixed(2))
      const adjustedY = parseFloat((data.y / scaleFactor).toFixed(2))

      onPositionChanged({ x: adjustedX, y: adjustedY }, mark.id)
    },
    [
      mark.signer.currentSignerHasReviewed,
      mark.id,
      scaleFactor,
      onPositionChanged
    ]
  )

  useEffect(() => {
    const handleDeleteClick = () => {
      if (mark.signer.currentSignerHasReviewed) return
      onDelete(mark?.id as string)
    }

    const currentButtonDelete = buttonDeleteRef.current

    if (currentButtonDelete) {
      currentButtonDelete.addEventListener('click', handleDeleteClick)
    }

    return () => {
      if (currentButtonDelete) {
        currentButtonDelete.removeEventListener('click', handleDeleteClick)
      }
    }
  }, [buttonDeleteRef, mark, onDelete])

  const color = generateColor(mark?.signer?.name)

  const markTypeValue = t?.marksTypes?.[mark?.type]

  const sizes = useMemo(
    () => ({
      width: WIDTH_MARK * scaleFactor,
      height: HEIGHT_MARK * scaleFactor,
      buttonSize: 20 * scaleFactor,
      iconSize: 18 * scaleFactor,
      fontSize: 12 * scaleFactor
    }),
    [scaleFactor]
  )

  return (
    <Draggable
      nodeRef={nodeRef}
      bounds="parent"
      position={{
        x: Number(position.x),
        y: Number(position.y)
      }}
      onDrag={onControlledDrag}
      onStop={onDragStop}
      defaultPosition={{ x: 0, y: 0 }}
      cancel="button"
      disabled={mark.signer.currentSignerHasReviewed}
    >
      <div
        hidden={hidden}
        ref={nodeRef}
        className={cn(
          'absolute top-0 left-0 flex flex-row-reverse items-center justify-center p-1 m-auto cursor-move select-none opacity-80',
          mark.signer.currentSignerHasReviewed && 'cursor-default opacity-50'
        )}
        style={{
          background: color,
          width: `${sizes.width}px`,
          height: `${sizes.height}px`
        }}
      >
        <button
          className={cn(
            'absolute flex items-center justify-center bg-indigo-100',
            mark.signer.currentSignerHasReviewed && 'hidden'
          )}
          style={{
            top: `-${sizes.buttonSize}px`,
            right: 0,
            width: `${sizes.buttonSize}px`,
            height: `${sizes.buttonSize}px`
          }}
          ref={buttonDeleteRef}
        >
          <XMarkIcon
            aria-label="icon-close"
            className="text-secondary-700"
            style={{
              width: `${sizes.iconSize}px`,
              height: `${sizes.iconSize}px`
            }}
          />
        </button>
        <p
          className={`m-auto flex-row-reverse flex items-center justify-center text-center line-clamp-3 font-bold font-body ${
            isColorDark(color)
              ? 'text-accent-100'
              : 'text-accent-500 dark:text-accent-900'
          }`}
          style={{ fontSize: `${sizes.fontSize}px` }}
        >
          {`${markTypeValue} - ${mark?.signer?.name}`}
        </p>
      </div>
    </Draggable>
  )
}

export default EditableMark
