export const presetColors = [
  '#000000',
  '#343a40',
  '#6c757d',
  '#adb5bd',
  '#ffffff',
  '#641E16',
  '#f28b82',
  '#fbbc04',
  '#ffd700',
  '#228B22',
  '#00ff00',
  '#87CEEB',
  '#000080',
  '#4B0082',
  '#8A2BE2',
  '#FFC0CB',
  '#fed4d6',
  '#40E0D0',
  '#FF7F50',
  '#D2691E'
]
