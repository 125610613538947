import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import Image from 'next/image'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTranslation from '@/hooks/useTranslation'
import { VaultType } from '@/types/global'
import {
  Button,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '../shadcn'
import Typography from '../Typography'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { ModalActionVault } from '@/ui/molecules'

export type OptionType = {
  value: string
  label: string
}

export type CurrentVaultId = string | null

export type PropsType = {
  name: string
  onSelected: (option: OptionType | null) => void
  size?: 'sm' | 'md'
  showSkeleton?: boolean
  fullWidth?: boolean
  label?: string
  className?: string
  value: OptionType | null
  placeholder?: string
  id?: string
  error?: string
  disabled?: boolean
  autoFocus?: boolean
  setAutoFocus?: (focused: boolean) => void
}

const SelectVault: React.FC<PropsType> = ({
  size = 'md',
  showSkeleton,
  className,
  onSelected,
  value,
  name,
  autoFocus,
  error,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('searchVault')
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [isAddingInProgress, setIsAddingInProgress] = useState(false)
  const [currentVaultId, setCurrentVaultId, isLoadingCurrentVaultId] =
    useLocalStorage<CurrentVaultId>('currentVaultId', null)
  const [options, setOptions] = useState<OptionType[]>([])
  const [page, setPage] = useState(0)
  const [fetchPages, setFetchPages] = useState<number[]>([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [valueSearch, setValueSearch] = useState<string | null>(null)
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null)
  const [insertedInitialValue, setInsertedInitialValue] =
    useState<boolean>(false)
  const { useGetListVaultsSelect, useGetVault, useCreateVault } = useVaultApi()
  const { getDataListVaults } = useGetListVaultsSelect()
  const {
    mutateAsync: mutateAsyncCreateVault,
    isLoading: isLoadingCreateVault
  } = useCreateVault()
  const queryClient = useQueryClient()

  const fetchItems = useCallback(
    async (page: number) => {
      try {
        setFetchPages((prevPages) => [...prevPages, page])
        setLoading(true)
        const { items, pagination } = await getDataListVaults(
          valueSearch,
          page,
          10
        )
        const newOptions = items.map((vault) => ({
          value: vault.vaultId,
          label: vault.name
        }))

        setOptions((prevItems) =>
          page === 0 ? newOptions : [...prevItems, ...newOptions]
        )
        setHasMore(pagination?.totalItems > (page + 1) * 10)

        if (page === 0 && !selectedOption?.value && newOptions.length > 0) {
          if (currentVaultId && !insertedInitialValue) {
            const initialOption = newOptions.find(
              (opt) => opt.value === currentVaultId
            )
            if (initialOption) {
              setSelectedOption(initialOption)
              onSelected(initialOption)
            } else {
              setSelectedOption(newOptions[0])
              onSelected(newOptions[0])
            }
            setInsertedInitialValue(true)
          }
        }
      } catch (error) {
        console.error('Error fetching items:', error)
      } finally {
        setLoading(false)
        setIsLoadingSkeleton(false)
      }
    },
    [
      getDataListVaults,
      valueSearch,
      selectedOption?.value,
      currentVaultId,
      insertedInitialValue,
      onSelected
    ]
  )

  useEffect(() => {
    setOptions([])
    setFetchPages([])
    setPage(0)
    setHasMore(true)
  }, [valueSearch])

  useEffect(() => {
    if (fetchPages.includes(page)) return
    fetchItems(page)
  }, [page, fetchItems, fetchPages])

  useEffect(() => {
    if (autoFocus && !selectedOption) {
      setIsOpenPopover(true)
      props?.setAutoFocus?.(false)
    }
  }, [autoFocus, props, selectedOption])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const bottom =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
        e.currentTarget.clientHeight + 20

      if (bottom && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1)
      }
    },
    [hasMore, loading]
  )

  const isEmpty = useMemo(
    () => options.length === 0 && !loading,
    [options, loading]
  )

  const { data: dataGetVault, isFetched: isFetchedGetVault } = useGetVault(
    currentVaultId || null
  )

  useEffect(() => {
    if (
      !isLoadingCurrentVaultId &&
      !insertedInitialValue &&
      !selectedOption?.value &&
      options.length > 0
    ) {
      if (currentVaultId && dataGetVault?.id) {
        setSelectedOption({
          value: dataGetVault.id,
          label: dataGetVault.name || ''
        })
        setInsertedInitialValue(true)
        onSelected({
          value: dataGetVault.id,
          label: dataGetVault.name || ''
        })
        setIsLoadingSkeleton(false)
      } else if (
        !isLoadingCurrentVaultId &&
        !insertedInitialValue &&
        !selectedOption?.value
      ) {
        setSelectedOption(options[0])
        onSelected(options[0])
        setIsLoadingSkeleton(false)
      }
    }

    if (dataGetVault?.id && !insertedInitialValue) {
      setCurrentVaultId(dataGetVault.id)
      setSelectedOption({
        value: dataGetVault.id,
        label: dataGetVault.name || ''
      })
      setInsertedInitialValue(true)
      onSelected({
        value: dataGetVault.id,
        label: dataGetVault.name || ''
      })
    }

    if (value?.value && !insertedInitialValue && !selectedOption?.value) {
      setSelectedOption(value)
      setCurrentVaultId(value.value)
      setInsertedInitialValue(true)
      onSelected(value)
    }
  }, [
    currentVaultId,
    dataGetVault,
    insertedInitialValue,
    isLoadingCurrentVaultId,
    onSelected,
    options,
    selectedOption?.value,
    setCurrentVaultId,
    value
  ])

  const handleSubmitCreateVault = useCallback(
    async (values) => {
      setIsAddingInProgress(true)

      try {
        const response = await mutateAsyncCreateVault({
          name: values.name,
          description: values?.description,
          type: values?.type as VaultType,
          ...(values?.users && {
            userAccountMemberIds: values.userAccountMemberIds
          })
        })

        if (response?.vaultId) {
          setCurrentVaultId(response.vaultId)
          setSelectedOption({
            value: response.vaultId,
            label: values.name
          })
          onSelected?.({
            value: response.vaultId,
            label: values.name
          })

          await queryClient.invalidateQueries([QueryKeys.ListVaultsSelect])
        }
      } catch (error) {
        showToast.error(t?.toasts?.errorCreateVault)
      } finally {
        setOpenModal(false)
        setIsAddingInProgress(false)
      }
    },
    [
      mutateAsyncCreateVault,
      onSelected,
      queryClient,
      setCurrentVaultId,
      t?.toasts?.errorCreateVault
    ]
  )

  const handleCreateOption = useCallback(() => {
    setOpenModal(true)
  }, [])

  const handleSelectOption = useCallback(
    (item: OptionType) => {
      setSelectedOption(item)
      setIsOpenPopover(false)
      onSelected(item)
      setCurrentVaultId(item.value)
    },
    [onSelected, setCurrentVaultId]
  )

  if (showSkeleton || isLoadingSkeleton) {
    const label = size === 'sm' ? 'w-16 h-3' : 'w-24 h-4'
    const select = size === 'sm' ? 'w-16 h-8' : 'w-full min-w-44 h-10'

    return (
      <div className={cn('flex flex-col w-full gap-1', className)}>
        <div className={cn('skeleton', label)} />
        <div className={cn('skeleton', select)} />
      </div>
    )
  }

  return (
    <>
      <div
        className={cn(
          'relative flex flex-col',
          props.label && 'gap-1',
          className
        )}
      >
        {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
        <div className="relative">
          <Popover
            onOpenChange={(open) => {
              if (open && isAddingInProgress) {
                return
              }
              setIsOpenPopover(open)
              if (open) {
                setPage(0)
                setOptions([])
                setFetchPages([])
                setHasMore(true)
              }
            }}
            open={isOpenPopover}
          >
            <PopoverTrigger asChild>
              <div
                className={cn(
                  'flex items-center gap-2 bg-accent-100 text-accent-900 dark:bg-accent-500 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight outline-none cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700 ',
                  error ? 'border-error-500' : '',
                  isAddingInProgress && 'opacity-50',
                  disabled
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700'
                )}
              >
                <div className="flex items-center w-full gap-2 text-ellipsis">
                  <PlusCircledIcon className="w-4 h-4" />
                  <Typography
                    variant="text-sm-regular"
                    className="w-full truncate font-body"
                  >
                    {selectedOption ? selectedOption.label : t?.title}
                  </Typography>
                </div>
              </div>
            </PopoverTrigger>
            <PopoverContent
              className="p-0 z-60 w-[300px] lg:w-[433px] md:w-[333px] xl:w-[518px]"
              align="start"
            >
              <div className="flex items-center h-10 border-b border-gray-200">
                <MagnifyingGlassIcon
                  className="absolute w-5 h-5 text-gray-400 pointer-events-none left-4"
                  aria-hidden="true"
                />
                <input
                  type="text"
                  className="w-full h-10 pl-12 text-sm border-t-0 border-b border-l-0 border-r-0 rounded-t-sm outline-none border-b-gray-200 focus:ring-0 ring-0 hover:border-b-gray-200 focus:border-b-gray-200 placeholder:text-gray-400 dark:bg-accent-500"
                  value={valueSearch ?? ''}
                  onChange={(e) => setValueSearch(e.target.value)}
                  placeholder={props.placeholder || ''}
                />
              </div>
              <div className="overflow-y-auto max-h-60" onScroll={handleScroll}>
                <ul className="flex flex-col gap-[2px] p-2">
                  {options.map((item, index) => {
                    const isSelected = selectedOption?.value === item.value
                    return (
                      <li
                        key={index}
                        className={cn(
                          'cursor-pointer hover:bg-gray-50 hover:text-secondary-700 flex justify-between rounded w-full p-2 text-sm',
                          isSelected ? 'text-secondary-700 bg-gray-50' : ''
                        )}
                        onClick={() => handleSelectOption(item)}
                      >
                        <div className="flex items-center">{item.label}</div>
                        {isSelected && (
                          <CheckIcon className="w-5 h-5 ml-2 text-secondary-700" />
                        )}
                      </li>
                    )
                  })}
                </ul>
                {loading && (
                  <div className="flex flex-col gap-[2px] p-2">
                    {Array.from({ length: 10 }).map((_, index) => (
                      <div className="flex items-center gap-1 p-2" key={index}>
                        <div className="w-4 h-4 skeleton" />
                        <div className="h-4 w-28 skeleton" />
                      </div>
                    ))}
                  </div>
                )}
                {isEmpty && (
                  <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
                    <div className="relative w-full h-full max-w-32">
                      <Image
                        src="/assets/icons/empty-state.svg"
                        alt="Folder Dashboard"
                        layout="fill"
                      />
                    </div>
                    <Typography
                      variant="text-sm-regular"
                      className="text-center"
                    >
                      {t?.noOptions}
                    </Typography>
                  </div>
                )}
              </div>
              <div
                className={cn(
                  'flex flex-col gap-2 justify-between items-center border-t border-gray-200 px-3 py-2'
                )}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  fullWidth
                  onClick={handleCreateOption}
                >
                  {t?.createNewOption}
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {openModal && (
        <ModalActionVault
          action="createVault"
          isOpen={openModal}
          onClose={() =>
            setOpenModal((prev) => {
              return !prev
            })
          }
          isLoading={isLoadingCreateVault}
          onSubmit={handleSubmitCreateVault}
        />
      )}
    </>
  )
}

export default SelectVault
