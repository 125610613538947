import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useTemplate } from '@/providers/Template'
import useTemplateApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { useEnvelope } from '@/providers'

const TemplateEditor = dynamic(
  () => {
    return import('@/ui/molecules/TemplateEditor/TemplateEditor')
  },
  { ssr: false }
)

type Props = {
  id: string
}

const SectionDocumentTemplateEditor: React.FC<Props> = ({ id }) => {
  const { useGetTemplate } = useTemplateApi()
  const { data: dataGetTemplate } = useGetTemplate(id)
  const { editor } = useTemplate()
  const { valuesDocumentTemplateFiller } = useEnvelope()

  useEffect(() => {
    if (dataGetTemplate?.id && editor) {
      editor.commands.setContent(dataGetTemplate?.payload)
    }
    if (valuesDocumentTemplateFiller) {
      if (editor?.commands) {
        Object.keys(valuesDocumentTemplateFiller).forEach((key) => {
          editor.commands.updateFieldValue(
            key,
            valuesDocumentTemplateFiller[key]
          )
        })
      }
    }
  }, [dataGetTemplate, editor, valuesDocumentTemplateFiller])

  return (
    <div className="h-full w-full md:w-[calc(100%-400px)] lg:w-[calc(100%-475px)] xl:w-[calc(100%-560px)] md:h-containerFull bg-gray-100 md:fixed md:overflow-y-scroll md:overflow-x-hidden md:right-0 md:shadow-insetUploadEnvelope">
      <TemplateEditor readonly />
    </div>
  )
}

export default SectionDocumentTemplateEditor
