import { useCallback, useEffect, useMemo, useState } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { CgSpinner } from 'react-icons/cg'
import { toast } from 'react-toastify'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Typography } from '@/atoms/index'
import { Dropdown, type MenuItem } from '@/atoms/index'
import {
  ContactFormType,
  ModalActionContactType,
  FiltersContactsType,
  UseContactsDataResponse,
  FiltersKeyContacts
} from './useContactsData.types'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useContactApi from '@/hooks/api/ecm/useContactApi/useContactApi'
import { Contact } from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { useQueryParams } from '@/providers'
import { showToast } from '@/ui/atoms/index'

const useContactsData = (): UseContactsDataResponse => {
  const { t } = useTranslation('contacts')
  const [modalActionContact, setModalActionContact] =
    useState<ModalActionContactType>({
      isOpen: false,
      action: null,
      id: null
    })
  const [isInsertedInitialFilters, setIsInsertedInitialFilters] =
    useState(false)
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const { getQueryParam, setQueryParam } = useQueryParams()
  const {
    filters,
    setFilter,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersContactsType>({})
  const {
    useUserAccountContacts,
    useCreateContact,
    useActionFavoriteContact,
    useUpdateContact,
    useDeleteContact
  } = useContactApi()

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
    isFetching: isFetchingContacts,
    isError: isErrorContacts,
    error: errorContacts
  } = useUserAccountContacts(
    pageNumber,
    pageSize,
    {
      query: filters?.query || null,
      typeFavorite: filters?.typeFavorite || null
    },
    isInsertedInitialFilters
  )
  const {
    mutateAsync: mutateAsyncCreateContact,
    isLoading: isLoadingCreateContact
  } = useCreateContact()
  const { mutateAsync: mutateAsyncActionFavoriteContact } =
    useActionFavoriteContact()
  const {
    mutateAsync: mutateAsyncUpdateContact,
    isLoading: isLoadingUpdateContact
  } = useUpdateContact()
  const {
    mutateAsync: mutateAsyncDeleteContact,
    isLoading: isLoadingDeleteContact
  } = useDeleteContact()

  useEffect(() => {
    const triggerCreateContact = getQueryParam<string>(
      'triggerCreateContact',
      null
    )
    if (
      (triggerCreateContact &&
        typeof triggerCreateContact === 'string' &&
        triggerCreateContact === 'true') ||
      (triggerCreateContact &&
        typeof triggerCreateContact === 'boolean' &&
        triggerCreateContact)
    ) {
      setModalActionContact({
        isOpen: true,
        action: 'create',
        id: null
      })
      setQueryParam('triggerCreateContact', null)
      return
    }
    const triggerViewContact = getQueryParam<string>('triggerViewContact', null)
    if (triggerViewContact) {
      setModalActionContact({
        isOpen: true,
        action: 'view',
        id: triggerViewContact
      })
      setQueryParam('triggerViewContact', null)
      return
    }
  }, [modalActionContact.action, getQueryParam, setQueryParam])

  const isLoadingUserAccountContacts = useMemo(() => {
    return isFetchingContacts || isLoadingContacts
  }, [isFetchingContacts, isLoadingContacts])

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (!isReady || isInsertedInitialFilters) return

      const query = filters?.query || null
      const typeFavorite = filters?.typeFavorite || null

      await setFilter(FiltersKeyContacts.QUERY, query)
      await setFilter(FiltersKeyContacts.TYPE_FAVORITE, typeFavorite)
      setIsInsertedInitialFilters(true)
    }

    applyInitialFilters()
  }, [
    isReady,
    isInsertedInitialFilters,
    filters?.query,
    filters?.typeFavorite,
    setFilter
  ])

  const currentDataTable = useMemo(() => {
    return {
      data: contacts,
      isLoading: isLoadingContacts || isFetchingContacts,
      isSuccess: isSuccessContacts,
      isErrorContacts,
      errorContacts: errorContacts
    }
  }, [
    contacts,
    errorContacts,
    isErrorContacts,
    isFetchingContacts,
    isLoadingContacts,
    isSuccessContacts
  ])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      isFavorite: { width: '5%', minWidth: '75px' },
      name: { width: '20%', minWidth: '130px' },
      email: { width: '20%', minWidth: '120px' },
      create: { width: '25%', minWidth: '130px' },
      update: { width: '25%', minWidth: '130px' },
      actions: { width: '5%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    return Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<Contact>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.isFavorite || '',
        accessor: 'isFavorite',
        Cell: ({ getValue, row }) => {
          const [isFavorite, setIsFavorite] = useState<boolean>(
            getValue('isFavorite')
          )
          const [isLoading, setIsLoading] = useState<boolean>(false)
          const original = row.original

          const toggleFavorite = async () => {
            const newIsFavorite = !isFavorite
            setIsFavorite(newIsFavorite)

            try {
              setIsLoading(true)
              await mutateAsyncActionFavoriteContact({
                id: original.id,
                action: newIsFavorite ? 'favorite' : 'unfavorite'
              })
              showToast.success(
                newIsFavorite
                  ? t?.toasts?.successFavorite
                  : t?.toasts?.successUnfavorite
              )
            } catch {
              showToast.error(t?.toasts?.errorFavorite)
              setIsFavorite(!newIsFavorite)
            } finally {
              setIsLoading(false)
            }
          }

          return (
            <div className="flex flex-col flex-1">
              <button
                type="button"
                className={`p-4 text-sm font-medium rounded-md text-primary-700`}
                onClick={toggleFavorite}
              >
                {isLoading ? (
                  <CgSpinner className="text-xl text-gray-500 animate-spin" />
                ) : isFavorite ? (
                  <StarIconSolid className="w-5 h-5" />
                ) : (
                  <StarIconOutline className="w-5 h-5" />
                )}
              </button>
            </div>
          )
        },
        ...tableColumnWidth.isFavorite
      },
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer"
              onClick={() => {
                setModalActionContact({
                  isOpen: true,
                  id: valuesOriginal.id as string,
                  action: 'view'
                })
              }}
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.email || '',
        accessor: 'email',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {valuesOriginal.email}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.email
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <div>
                <Avatar
                  imgSrc={valuesOriginal.createdByAvatar}
                  name={valuesOriginal.createdByName ?? ''}
                />
              </div>
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.update || '',
        accessor: 'updatedByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.updatedByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <div>
                <Avatar
                  imgSrc={valuesOriginal.updatedByAvatar}
                  name={valuesOriginal.updatedByName ?? ''}
                />
              </div>
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.update
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'view',
              label: t?.options?.view
            },
            {
              key: 'update',
              label: t?.options?.update
            },
            {
              key: 'delete',
              label: t?.options?.delete
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'view') {
                  setModalActionContact({
                    isOpen: true,
                    action: 'view',
                    id: valuesOriginal.id
                  })
                }
                if (state.key === 'update') {
                  setModalActionContact({
                    isOpen: true,
                    action: 'update',
                    id: valuesOriginal.id
                  })
                }
                if (state.key === 'delete') {
                  setModalActionContact({
                    isOpen: true,
                    action: 'delete',
                    id: valuesOriginal.id
                  })
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    mutateAsyncActionFavoriteContact,
    t?.options?.delete,
    t?.options?.update,
    t?.options?.view,
    t?.tableInformation?.actions,
    t?.tableInformation?.create,
    t?.tableInformation?.email,
    t?.tableInformation?.isFavorite,
    t?.tableInformation?.name,
    t?.tableInformation?.update,
    t?.toasts?.errorFavorite,
    t?.toasts?.successFavorite,
    t?.toasts?.successUnfavorite,
    tableColumnWidth.actions,
    tableColumnWidth.create,
    tableColumnWidth.email,
    tableColumnWidth.isFavorite,
    tableColumnWidth.name,
    tableColumnWidth.update
  ])

  const onSubmitActionModal = useCallback(
    async (
      values: ContactFormType,
      action: ModalActionContactType['action']
    ) => {
      if (action === 'create') {
        try {
          await mutateAsyncCreateContact({
            name: values.name,
            email: values.email,
            description: values.description
          })
          showToast.success(t?.toasts?.successCreate)
        } catch (error) {
          showToast.error(t?.toasts?.errorCreate)
        } finally {
          setModalActionContact({
            isOpen: false,
            action: null,
            id: null
          })
        }
        return
      }
      if (action === 'update') {
        try {
          await mutateAsyncUpdateContact({
            id: modalActionContact?.id as string,
            name: values.name,
            email: values.email,
            description: values.description
          })

          showToast.success(t?.toasts?.successUpdate)
        } catch (error) {
          showToast.error(t?.toasts?.errorUpdate)
        } finally {
          setModalActionContact({
            isOpen: false,
            action: null,
            id: null
          })
        }
        return
      }
    },
    [
      modalActionContact?.id,
      mutateAsyncCreateContact,
      mutateAsyncUpdateContact,
      t?.toasts?.errorCreate,
      t?.toasts?.errorUpdate,
      t?.toasts?.successCreate,
      t?.toasts?.successUpdate
    ]
  )

  const handleDeleteContact = useCallback(async () => {
    try {
      await mutateAsyncDeleteContact(modalActionContact?.id as string)
      showToast.success(t?.toasts?.successDelete)
    } catch (error) {
      showToast.error(t?.toasts?.errorDelete)
    } finally {
      setModalActionContact({
        isOpen: false,
        action: null,
        id: null
      })
    }
  }, [
    modalActionContact?.id,
    mutateAsyncDeleteContact,
    t?.toasts?.errorDelete,
    t?.toasts?.successDelete
  ])

  const isLoadingSubmitModalAction = useMemo(() => {
    return isLoadingCreateContact || isLoadingUpdateContact
  }, [isLoadingCreateContact, isLoadingUpdateContact])

  return {
    currentDataTable,
    columns,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    onSubmitActionModal,
    modalActionContact,
    setModalActionContact,
    handleDeleteContact,
    isLoadingUserAccountContacts,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isLoadingDeleteContact,
    isLoadingSubmitModalAction
  }
}

export default useContactsData
