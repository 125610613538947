import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import useTranslation from '@/hooks/useTranslation'
import Input from '../Input'
import { Button } from '../shadcn'
import { cn } from '@/lib/utils'
import * as Popover from '@/ui/atoms/shadcn/Popover'
import { ButtonWithTooltip } from '@/ui/molecules'
import { presetColors } from '@/utils/editor/options/index'

type Props = {
  icon: React.ReactNode
  title: string
  action: (data?: { color?: string; href?: string; src?: string }) => void
  isActive?: () => boolean
  type?: 'colorPicker' | 'divider' | 'link' | 'image'
  name?: string
}

const MenuItemEditor: React.FC<Props> = ({
  icon,
  title,
  action,
  isActive,
  type,
  name
}) => {
  const { t } = useTranslation('template')

  const [color, setColor] = useState('#000')
  const [link, setLink] = useState('')

  if (type === 'divider') {
    return <div className="bg-gray-500 w-[1px] h-5 mx-1" />
  }

  if (type === 'colorPicker') {
    return (
      <Popover.Popover>
        <Popover.PopoverTrigger>
          <ButtonWithTooltip
            type="button"
            variant="unstyled"
            className={cn(
              'bg-transparent border-0 rounded-sm text-gray-700 cursor-pointer h-7 w-7 p-1 flex items-center justify-center transition-colors hover:bg-gray-200',
              isActive && isActive() ? 'bg-gray-300' : ''
            )}
            tooltipSide="bottom"
            tooltipText={name as string}
          >
            {icon}
          </ButtonWithTooltip>
        </Popover.PopoverTrigger>
        <Popover.PopoverContent className="w-64">
          <div className="flex flex-col gap-5 p-2">
            <div className="flex flex-col gap-3">
              <HexColorPicker
                color={color}
                onChange={setColor}
                className="w-full"
              />
              <div className="grid grid-cols-5 gap-2">
                {presetColors.map((color) => (
                  <button
                    key={color}
                    className="w-8 h-8 border border-gray-200 rounded-sm"
                    style={{ background: color }}
                    onClick={() => setColor(color)}
                  />
                ))}
              </div>
            </div>
            <Button
              variant="secondary"
              className="w-full"
              size="sm"
              onClick={() =>
                action({
                  color
                })
              }
            >
              {t?.templateEditor?.buttonApply}
            </Button>
          </div>
        </Popover.PopoverContent>
      </Popover.Popover>
    )
  }

  if (type === 'image' || type === 'link') {
    return (
      <Popover.Popover>
        <Popover.PopoverTrigger>
          <ButtonWithTooltip
            type="button"
            variant="unstyled"
            className={cn(
              'bg-transparent border-0 rounded-sm text-gray-700 cursor-pointer h-7 w-7 p-1 flex items-center justify-center transition-colors hover:bg-gray-200',
              isActive && isActive() ? 'bg-gray-300' : ''
            )}
            tooltipSide="bottom"
            tooltipText={name as string}
          >
            {icon}
          </ButtonWithTooltip>
        </Popover.PopoverTrigger>
        <Popover.PopoverContent>
          <div className="flex flex-col gap-2 p-2">
            <Input
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="https://"
            />
            <Button
              variant="secondary"
              className="w-full"
              size="sm"
              onClick={() =>
                action({
                  src: link
                })
              }
            >
              {t?.templateEditor?.buttonApply}
            </Button>
          </div>
        </Popover.PopoverContent>
      </Popover.Popover>
    )
  }

  return (
    <ButtonWithTooltip
      type="button"
      variant="unstyled"
      className={cn(
        'bg-transparent border-0 rounded-sm text-gray-700 cursor-pointer h-7 w-7 p-1 flex items-center justify-center transition-colors hover:bg-gray-200',
        isActive && isActive() ? 'bg-gray-300' : ''
      )}
      onClick={action as any}
      tooltipSide="bottom"
      tooltipText={name as string}
    >
      {icon}
    </ButtonWithTooltip>
  )
}

export default MenuItemEditor
