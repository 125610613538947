import React from 'react'
import useTranslation from '@/hooks/useTranslation'
import { Typography } from '@/ui/atoms'

type Props = {
  minLength: boolean
  oneUpperCase: boolean
  oneLowerCase: boolean
  oneNumber: boolean
  oneSpecialCharacter: boolean
}

const PasswordRequirements: React.FC<Props> = ({
  minLength,
  oneUpperCase,
  oneLowerCase,
  oneNumber,
  oneSpecialCharacter
}) => {
  const { t, isReady } = useTranslation('validations')

  if (!isReady)
    return (
      <div className="flex flex-col gap-2">
        <div className="w-48 h-4 skeleton"></div>
        <div className="flex flex-col gap-1">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="w-64 h-3 skeleton"></div>
          ))}
        </div>
      </div>
    )

  return (
    <div className="flex flex-col gap-2">
      <Typography
        variant="text-sm-regular"
        className="text-gray-700"
        showSkeleton={!isReady}
      >
        {t?.passwordRequirements?.title}
      </Typography>
      <ul className="text-sm text-gray-700 list-disc list-inside">
        <li className={minLength ? 'text-success-600 line-through' : ''}>
          {t?.passwordRequirements?.passwordMinLength}
        </li>
        <li className={oneUpperCase ? 'text-success-600 line-through' : ''}>
          {t?.passwordRequirements?.passwordUpperCase}
        </li>
        <li className={oneLowerCase ? 'text-success-600 line-through' : ''}>
          {t?.passwordRequirements?.passwordLowerCase}
        </li>
        <li className={oneNumber ? 'text-success-600 line-through' : ''}>
          {t?.passwordRequirements?.passwordNumber}
        </li>
        <li
          className={oneSpecialCharacter ? 'text-success-600 line-through' : ''}
        >
          {t?.passwordRequirements?.passwordSpecialCharacter}
        </li>
      </ul>
    </div>
  )
}

export default PasswordRequirements
