import React, { FC, useEffect, useState } from 'react'
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'

interface FilterFavoritesProps {
  typeFavorite: 'favorite' | 'unfavorite' | null
  onToggleFavorite: (typeFavorite: 'favorite' | 'unfavorite' | null) => void
  disabled?: boolean
  label?: string
  showSkeleton?: boolean
  timeoutSkeleton?: number
}

const FilterFavorites: FC<FilterFavoritesProps> = ({
  onToggleFavorite,
  disabled,
  typeFavorite,
  showSkeleton = false,
  timeoutSkeleton,
  ...props
}) => {
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(true)

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoadingSkeleton(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const baseButtonClass =
    'px-4 py-2 text-sm font-medium border border-gray-300 focus:z-10 focus:ring-1 focus:ring-primary-700 text-primary bg-accent-100 dark:bg-accent-500 hover:border-primary-700'

  if (showSkeleton || isLoadingSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-20 h-3 md:h-4 skeleton" />
        <div className="h-10 skeleton w-28"></div>
      </div>
    )
  }

  return (
    <div className={cn('relative flex flex-col w-fit', props.label && 'gap-1')}>
      {props.label && <Label>{props.label}</Label>}
      <div className="flex h-10 rounded-md shadow-sm" role="group">
        <button
          type="button"
          className={classNames(
            baseButtonClass,
            'rounded-l-md',
            typeFavorite === 'favorite'
              ? 'dark:bg-primary-700 bg-primary-100 dark:border-primary-700 border-primary-700 dark:text-primary-100 text-primary hover:border-primary-700'
              : typeFavorite === null
                ? 'border-gray-300'
                : 'border-r-0',
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          )}
          onClick={() => {
            if (typeFavorite === 'favorite') {
              onToggleFavorite(null)
            }
            if (typeFavorite === null) {
              onToggleFavorite('favorite')
            }
            if (typeFavorite === 'unfavorite') {
              onToggleFavorite('favorite')
            }
          }}
          disabled={disabled}
        >
          <StarIconSolid className="w-5 h-5" />
        </button>
        <button
          type="button"
          className={classNames(
            baseButtonClass,
            'rounded-r-md',
            typeFavorite === 'unfavorite'
              ? 'dark:bg-primary-700 bg-primary-100 dark:border-primary-700 border-primary-700 dark:text-primary-100 text-primary hover:border-primary-700'
              : 'border-gray-300',
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          )}
          onClick={() => {
            if (typeFavorite === 'unfavorite') {
              onToggleFavorite(null)
            }
            if (typeFavorite === null) {
              onToggleFavorite('unfavorite')
            }
            if (typeFavorite === 'favorite') {
              onToggleFavorite('unfavorite')
            }
          }}
          disabled={disabled}
        >
          <StarIconOutline className="w-5 h-5" />
        </button>
      </div>
    </div>
  )
}

export default FilterFavorites
