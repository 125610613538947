import { UserAccountRole } from '@/types/global'
import { Pagination } from '@/hooks/useApi/useApi.types'

export type Templates = {
  items: Template[]
  pagination: Pagination
}

export type Template = {
  id: string
  createdAtUtc: string
  createdByName: string
  createdByAvatar: string | null
  updatedAtUtc: string | null
  updatedByName: string | null
  updatedByAvatar: string | null
  name: string
  fields: number
}

export enum TypesFieldsOptions {
  BrCep = 'BrCep',
  BrCpf = 'BrCpf',
  BrCnpj = 'BrCnpj',
  DateOnly = 'DateOnly',
  TimeOnly = 'TimeOnly',
  DateTime = 'DateTime',
  Email = 'Email',
  Number = 'Number',
  Text = 'Text',
  Custom = 'Custom'
}

export type FieldType = {
  id?: string | null
  name: string
  description?: string | null
  alias: string
  customRegex?: string | null
  isRequired: boolean
  type: TypesFieldsOptions
}

export type TemplateBase = {
  vaultId: string
  name: string
  description?: string | null
  payload: string
  fields: FieldType[]
}

export type CreateTemplateRequest = TemplateBase

export type CreateTemplateResponse = {
  id: string
}

export type UpdateTemplateRequest = TemplateBase

export type UpdateTemplateRequestWithId = TemplateBase & {
  id: string
}

export type TemplateResponse = {
  id: string
  vaultId: string
  vaultName: string | null
  name: string | null
  description: string | null
  payload: string | null
  fields: FieldType[] | null
}

export type Owner = {
  userAccountId: string
  userAccountName: string
  isUserAccountActive: boolean
  isCurrentUserAccount: boolean
  userAccountRole: UserAccountRole
  userAccountAvatar: string | null
}

export type TemplatesOwners = {
  items: Owner[]
}

export type DataTemplateActiveType = {
  type: 'owner' | 'all'
  id?: string | null
}

export type Option = {
  value: string
  label: string
  avatar?: string | boolean
}

export type MoveTemplateRequest = {
  vaultId: string
}

export type MoveTemplateRequestWithId = MoveTemplateRequest & {
  id: string
}

export type RenameTemplateRequest = {
  name: string
  description?: string | null
}

export type RenameTemplateRequestWithId = RenameTemplateRequest & {
  id: string
}

export type TemplateRestoreRequest = {
  vaultId: string
}

export type TemplateRestoreRequestWithId = TemplateRestoreRequest & {
  id: string
}
