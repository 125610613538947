import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { PlanType } from '@/types/global'
import IconWithTooltip from '../IconWithTooltip'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { useAuth, usePlans } from '@/providers'
import { DrawerType, ModalType } from '@/providers/Plans/Plans.types'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'

const DrawerActionPlan: React.FC = () => {
  const { t, isReady } = useTranslation('plans')
  const {
    refetchCurrentSubscription,
    setTriggerDrawerOpen,
    triggerDrawerTypeOpen,
    dataCurrentSubscription,
    dataDrawer,
    setDataDrawer,
    setDataModal
  } = usePlans()
  const { userContext, refreshProfile } = useAuth()
  const { push } = useRouter()
  const [count, setCount] = useState<number>(1)
  const [isMounted, setIsMounted] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const isLoading = useMemo(() => !isReady, [isReady])
  const planType = useMemo(
    () => dataDrawer?.planType || 'One',
    [dataDrawer?.planType]
  )

  const {
    useBillingGetCheckoutSignerMfaCredits,
    useBillingGetCheckoutApiEnvelope,
    useBillingUpdateUserSeats
  } = useBillingApi()
  const { mutateAsync: mutateAsyncBillingGetCheckoutSignerMfaCredits } =
    useBillingGetCheckoutSignerMfaCredits()
  const { mutateAsync: mutateAsyncBillingGetCheckoutApiEnvelope } =
    useBillingGetCheckoutApiEnvelope()
  const { mutateAsync: mutateAsyncBillingUpdateUserSeats } =
    useBillingUpdateUserSeats()

  const tDrawer = useMemo(() => {
    const content = {
      [DrawerType.DRAWER_SIGNER_MFA_CREDITS]: {
        title: t?.drawerBuySignerMfaCredits?.title,
        description: t?.drawerBuySignerMfaCredits?.description,
        labelCounter: t?.drawerBuySignerMfaCredits?.labelCounter,
        buttonConfirm: t?.drawerBuySignerMfaCredits?.buttonConfirm,
        buttonCancel: t?.drawerBuySignerMfaCredits?.buttonCancel
      },
      [DrawerType.DRAWER_ENVELOPE_API_CREDITS]: {
        title: t?.drawerBuyApiEnvelopes?.title,
        description: t?.drawerBuyApiEnvelopes?.description,
        labelCounter: t?.drawerBuyApiEnvelopes?.labelCounter,
        buttonConfirm: t?.drawerBuyApiEnvelopes?.buttonConfirm,
        buttonCancel: t?.drawerBuyApiEnvelopes?.buttonCancel
      },
      [DrawerType.DRAWER_CHANGE_LICENSED_USERS]: {
        title: t?.drawerChangeLicensedUsers?.title,
        description: t?.drawerChangeLicensedUsers?.description,
        labelCounter: t?.drawerChangeLicensedUsers?.labelCounter,
        buttonConfirm: t?.drawerChangeLicensedUsers?.buttonConfirm,
        buttonCancel: t?.drawerChangeLicensedUsers?.buttonCancel
      }
    }[triggerDrawerTypeOpen as DrawerType]

    return content
  }, [
    t?.drawerBuyApiEnvelopes?.buttonCancel,
    t?.drawerBuyApiEnvelopes?.buttonConfirm,
    t?.drawerBuyApiEnvelopes?.description,
    t?.drawerBuyApiEnvelopes?.labelCounter,
    t?.drawerBuyApiEnvelopes?.title,
    t?.drawerBuySignerMfaCredits?.buttonCancel,
    t?.drawerBuySignerMfaCredits?.buttonConfirm,
    t?.drawerBuySignerMfaCredits?.description,
    t?.drawerBuySignerMfaCredits?.labelCounter,
    t?.drawerBuySignerMfaCredits?.title,
    t?.drawerChangeLicensedUsers?.buttonCancel,
    t?.drawerChangeLicensedUsers?.buttonConfirm,
    t?.drawerChangeLicensedUsers?.description,
    t?.drawerChangeLicensedUsers?.labelCounter,
    t?.drawerChangeLicensedUsers?.title,
    triggerDrawerTypeOpen
  ])

  const onClose = useCallback(() => {
    setTriggerDrawerOpen(null)
    setDataDrawer({
      isOpen: false,
      type: null
    })
    setCount(0)
  }, [setDataDrawer, setTriggerDrawerOpen])

  const data = useMemo(() => {
    const minUserSeatsPerPlanMapping = {
      One: 1,
      Pro: 5,
      Max: 10
    }

    const minUserSeatsPerPlan = minUserSeatsPerPlanMapping[planType as PlanType]
    const userSeatQuantity = dataCurrentSubscription?.userSeatQuantity ?? 0
    const { userSeatsLicensed, userSeatsBeingUsed } =
      userContext.balanceInformation

    const contentMap = {
      [DrawerType.DRAWER_SIGNER_MFA_CREDITS]: {
        step: 10,
        valueInitial: 10,
        min: 10
      },
      [DrawerType.DRAWER_ENVELOPE_API_CREDITS]: {
        step: 10,
        valueInitial: 10,
        min: 10
      },
      [DrawerType.DRAWER_CHANGE_LICENSED_USERS]: {
        step: 1,
        valueInitial: Math.max(
          minUserSeatsPerPlan,
          userSeatsLicensed + 1,
          userSeatQuantity + 1
        ),
        min: Math.max(minUserSeatsPerPlan, userSeatsBeingUsed)
      }
    }

    return contentMap[triggerDrawerTypeOpen as DrawerType]
  }, [
    dataCurrentSubscription?.userSeatQuantity,
    planType,
    triggerDrawerTypeOpen,
    userContext
  ])

  useEffect(() => {
    if (!data?.valueInitial) {
      return
    }
    setCount(data?.valueInitial)
  }, [data?.valueInitial, triggerDrawerTypeOpen])

  const baseUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      const currentDomain = `${window.location.protocol}//${window.location.hostname}`
      if (currentDomain === 'http://localhost') {
        return process.env.NEXT_PUBLIC_APP_URL as string
      }
      return currentDomain
    }
  }, [])

  const handleButtonSubmit = useCallback(async () => {
    if (!dataCurrentSubscription?.id) {
      return
    }
    if (
      triggerDrawerTypeOpen === DrawerType.DRAWER_SIGNER_MFA_CREDITS &&
      count
    ) {
      setLoadingSubmit(true)

      try {
        const data = await mutateAsyncBillingGetCheckoutSignerMfaCredits({
          id: dataCurrentSubscription?.id as string,
          quantity: count,
          cancelUrl:
            `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_CANCEL_URL_SIGNER_MFA}` as string,
          successUrl:
            `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_SUCCESS_URL_SIGNER_MFA}` as string
        })
        if (data) {
          push(data?.url as string)
          showToast.success(t?.drawerBuySignerMfaCredits?.toasts?.success)
          await refreshProfile()
          return
        }
      } catch (error) {
        showToast.error(t?.drawerBuySignerMfaCredits?.toasts?.error)
      } finally {
        setLoadingSubmit(false)
      }

      return
    }
    if (
      triggerDrawerTypeOpen === DrawerType.DRAWER_ENVELOPE_API_CREDITS &&
      count
    ) {
      try {
        const data = await mutateAsyncBillingGetCheckoutApiEnvelope({
          id: dataCurrentSubscription?.id as string,
          quantity: count,
          cancelUrl:
            `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_CANCEL_URL_ENVELOPE_API}` as string,
          successUrl:
            `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_SUCCESS_URL_ENVELOPE_API}` as string
        })
        if (data) {
          push(data?.url as string)
          showToast.success(t?.drawerBuyApiEnvelopes?.toasts?.success)
          await refreshProfile()
          return
        }
      } catch {
        showToast.error(t?.drawerBuyApiEnvelopes?.toasts?.error)
      } finally {
        setLoadingSubmit(false)
      }
      return
    }
    if (
      triggerDrawerTypeOpen === DrawerType.DRAWER_CHANGE_LICENSED_USERS &&
      count
    ) {
      if (count === dataCurrentSubscription?.userSeatQuantity) {
        onClose()
        return
      }
      if (
        dataCurrentSubscription?.pendingInvoiceId &&
        count > dataCurrentSubscription?.userSeatQuantity
      ) {
        onClose()
        setDataModal({
          isOpen: true,
          type: ModalType.MODAL_PENDING_INVOICES
        })
        return
      }

      setLoadingSubmit(true)
      await mutateAsyncBillingUpdateUserSeats({
        id: dataCurrentSubscription?.id as string,
        userSeats: count
      })
        .then(async () => {
          if (!isMounted) return

          let attempts = 0
          const interval = setInterval(async () => {
            if (!isMounted) {
              clearInterval(interval)
              return
            }

            attempts++
            const responseCurrentSubscription =
              await refetchCurrentSubscription()

            if (
              responseCurrentSubscription?.data?.userSeatQuantity === count ||
              attempts >= 3
            ) {
              clearInterval(interval)
              if (
                responseCurrentSubscription?.data?.userSeatQuantity === count
              ) {
                setLoadingSubmit(false)
                showToast.success(t?.drawerChangeLicensedUsers?.toasts?.success)
                refreshProfile()
                onClose()
              } else {
                setLoadingSubmit(false)
                showToast.error(t?.drawerChangeLicensedUsers?.toasts?.error)
                onClose()
                await refreshProfile()
              }
            }
          }, 3000)
        })
        .catch(() => {
          setLoadingSubmit(false)
          showToast.error(t?.drawerChangeLicensedUsers?.toasts?.error)
          onClose()
        })
    }
  }, [
    baseUrl,
    count,
    dataCurrentSubscription?.id,
    dataCurrentSubscription?.pendingInvoiceId,
    dataCurrentSubscription?.userSeatQuantity,
    isMounted,
    mutateAsyncBillingGetCheckoutApiEnvelope,
    mutateAsyncBillingGetCheckoutSignerMfaCredits,
    mutateAsyncBillingUpdateUserSeats,
    onClose,
    push,
    refetchCurrentSubscription,
    refreshProfile,
    setDataModal,
    t?.drawerBuyApiEnvelopes?.toasts?.error,
    t?.drawerBuyApiEnvelopes?.toasts?.success,
    t?.drawerBuySignerMfaCredits?.toasts?.error,
    t?.drawerBuySignerMfaCredits?.toasts?.success,
    t?.drawerChangeLicensedUsers?.toasts?.error,
    t?.drawerChangeLicensedUsers?.toasts?.success,
    triggerDrawerTypeOpen
  ])

  const onClick = useCallback(
    (action: 'decrement' | 'increment') => {
      let change = 0
      if (action === 'increment') {
        change = data.step
      } else if (action === 'decrement') {
        change = -data.step
        if (count + change < data?.min) {
          return
        }
      }

      setCount((prev) => Math.max(data?.min, prev + change))
    },
    [count, data?.step, data?.min]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputVal = parseInt(e.target.value, 10)

      if (isNaN(inputVal)) {
        inputVal = 0
      }

      if (
        triggerDrawerTypeOpen === DrawerType.DRAWER_SIGNER_MFA_CREDITS ||
        triggerDrawerTypeOpen === DrawerType.DRAWER_ENVELOPE_API_CREDITS
      ) {
        if (inputVal < data?.min && inputVal !== 0) {
          inputVal = data?.min
        } else {
          inputVal = Math.round(inputVal / data?.min) * data?.min
        }
      }

      const newQuantity = Math.max(data?.min, inputVal)
      setCount(newQuantity)
    },
    [data?.min, triggerDrawerTypeOpen]
  )

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <Drawer.Drawer
      open={
        dataDrawer.isOpen &&
        (triggerDrawerTypeOpen === DrawerType.DRAWER_SIGNER_MFA_CREDITS ||
          triggerDrawerTypeOpen === DrawerType.DRAWER_ENVELOPE_API_CREDITS ||
          triggerDrawerTypeOpen === DrawerType.DRAWER_CHANGE_LICENSED_USERS)
      }
      onOpenChange={(boolean) => {
        if (!boolean) {
          onClose()
        }
      }}
    >
      <Drawer.DrawerContent>
        <div className="w-full max-w-sm mx-auto">
          <Drawer.DrawerHeader>
            {isLoading ? (
              <div className="w-40 h-6 skeleton"></div>
            ) : (
              <Drawer.DrawerTitle>{tDrawer?.title}</Drawer.DrawerTitle>
            )}
            {isLoading ? (
              <div className="h-5 w-72 skeleton"></div>
            ) : (
              <Drawer.DrawerDescription>
                {tDrawer?.description}
              </Drawer.DrawerDescription>
            )}
          </Drawer.DrawerHeader>
          <div className="p-4 pb-0">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center space-x-2">
                {isLoading ? (
                  <>
                    <div className="w-10 h-10 rounded-full skeleton"></div>
                    <div className="w-20 h-20 skeleton"></div>
                    <div className="w-10 h-10 rounded-full skeleton"></div>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full shrink-0"
                      onClick={() => onClick('decrement')}
                      disabled={count <= data?.min}
                    >
                      <MinusIcon className="w-4 h-4" />
                      <span className="sr-only">Decrease</span>
                    </Button>
                    <div className="flex-1 text-center">
                      <input
                        type="number"
                        id="quantity-input"
                        className="bg-transparent border-0 text-center text-accent-900 font-bold tracking-tighter text-7xl focus:ring-transparent focus:ring-1 focus:outline-none focus:border-none block w-full py-2.5 px-1"
                        required
                        value={count}
                        onChange={handleChange}
                      />
                      <div className="flex items-center justify-center">
                        <span className="text-[0.70rem] uppercase text-muted-foreground flex items-center justify-center">
                          {tDrawer?.labelCounter}
                        </span>
                        {triggerDrawerTypeOpen ===
                          DrawerType.DRAWER_CHANGE_LICENSED_USERS && (
                          <IconWithTooltip
                            icon={
                              <InformationCircleIcon className="w-4 h-4 ml-1 text-primary" />
                            }
                            tooltipText={t?.modalSelectFeaturesBeforePurchase?.tooltips?.counterUsersMin(
                              data?.min
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full shrink-0"
                      onClick={() => onClick('increment')}
                    >
                      <PlusIcon className="w-4 h-4" />
                      <span className="sr-only">Increase</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <Drawer.DrawerFooter>
            <Button
              onClick={handleButtonSubmit}
              loading={loadingSubmit}
              showSkeleton={isLoading}
              fullWidth
            >
              {tDrawer?.buttonConfirm}
            </Button>
            <Button
              variant="outline"
              onClick={onClose}
              showSkeleton={isLoading}
              fullWidth
            >
              {tDrawer?.buttonCancel}
            </Button>
          </Drawer.DrawerFooter>
        </div>
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerActionPlan
