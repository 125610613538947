import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'

interface UseUrlState {
  <T>(key: string, defaultValue: T): [T, (newValue: T | null) => void, boolean]
  <T>(
    key: string,
    defaultValue: null
  ): [T | null, (newValue: T | null) => void, boolean]
}

const useUrlState: UseUrlState = <T>(key: string, defaultValue: T | null) => {
  const { query, replace, isReady: routerIsReady } = useRouter()
  const [state, setState] = useState<T | null>(defaultValue)
  const [isReady, setIsReady] = useState(false)

  const setURLState = useCallback(
    (newValue: T | null, updateURL = true) => {
      if (!routerIsReady) return

      if (newValue === null && updateURL) {
        const { [key]: removed, ...restQuery } = query
        replace({ query: restQuery }, undefined, { shallow: true })
      } else if (updateURL && newValue !== null) {
        const valueAsString =
          typeof newValue === 'string' || Array.isArray(newValue)
            ? newValue
            : String(newValue)
        replace({ query: { ...query, [key]: valueAsString } }, undefined, {
          shallow: true
        })
      }

      setState(newValue)
    },
    [key, query, replace, routerIsReady]
  )

  const parseBoolean = (value: string): boolean => {
    return value.toLowerCase() === 'true'
  }

  useEffect(() => {
    if (routerIsReady) {
      const urlValue = query[key]
      if (urlValue === undefined && defaultValue !== null) {
        setURLState(defaultValue, true)
      } else {
        let parsedValue: T | null = defaultValue
        if (urlValue !== undefined) {
          parsedValue = urlValue as unknown as T
          if (typeof defaultValue === 'boolean') {
            parsedValue = parseBoolean(urlValue as string) as unknown as T
          }
        }
        setState(parsedValue)
      }
      setIsReady(true)
    } else {
      setIsReady(false)
    }
  }, [routerIsReady, key, query, defaultValue, setURLState])

  return [state, setURLState, isReady]
}

export default useUrlState
