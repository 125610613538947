import { useCallback, useEffect, useMemo, useState } from 'react'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelope } from '@/providers/index'
import { AreaSignersToSign, DrawerEnvelope, PdfViewer } from '@/molecules/index'
import { DropdownButton, IDropdownItem } from '@/atoms/index'
import { PageSizeEnvelopeType } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { cn } from '@/lib/utils'
import { StepType } from '@/providers/Envelope/Envelope.types'
import { Select } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

const StepItemSignersEnvelope = () => {
  const [valueSelectedDocument, setValueSelectedDocument] = useState<any>(null)
  const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {
    currentStep,
    noSigners,
    setDocument,
    documents,
    document,
    handleSaveEnvelope,
    loadingEnvelopeSave,
    setLoadingPdfByDocumentId,
    handleActionStep,
    setSelectedSignerMarkData,
    valuesSelectDocuments,
    currentValuesEnvelope,
    isCompactDevice,
    handlePublishEnvelope,
    alreadySaved,
    currentActionEnvelope
  } = useEnvelope()

  const { t } = useTranslation('envelope')

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  const dataDropdownItems: IDropdownItem[] =
    currentStepData?.action?.dropdownOptions?.map(
      (option: { key: string; label: string }) => ({
        key: option.key,
        label: option.label
      })
    )

  useEffect(() => {
    if (valuesSelectDocuments && !valueSelectedDocument && !hasBeenUpdated) {
      const newValue = documents[0]?.name

      if (newValue === valueSelectedDocument) return
      setLoadingPdfByDocumentId({
        documentId: documents[0]?.id as string,
        loading: true
      })
      setValueSelectedDocument(newValue)
      const selectedDocument = documents?.find(
        (file) => file.id === documents[0]?.id
      )

      setDocument({
        id: selectedDocument?.id as string,
        index: selectedDocument?.index as number,
        name: selectedDocument?.name as string,
        privateDescription: selectedDocument?.privateDescription as string,
        publicDescription: selectedDocument?.publicDescription as string,
        pageSizes: selectedDocument?.pageSizes as PageSizeEnvelopeType[]
      })
    }
  }, [
    documents,
    hasBeenUpdated,
    setDocument,
    setLoadingPdfByDocumentId,
    valueSelectedDocument,
    valuesSelectDocuments
  ])

  const handleClickCardSignMark = useCallback(
    (signer) => {
      setSelectedSignerMarkData(signer)
      setIsDrawerOpen(false)
    },
    [setSelectedSignerMarkData]
  )

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col">
          {isCompactDevice && (
            <div className="sticky z-40 -top-4 ">
              <div
                className={cn(
                  'flex gap-2 justify-center w-full bg-gray-100 h-auto p-4 md:p-0 md:px-4 lg:px-0 md:bg-transparent flex-col sm:flex-row ',
                  valuesSelectDocuments.length > 1
                    ? 'justify-end items-end'
                    : 'justify-center md:pb-0 items-center'
                )}
              >
                {valuesSelectDocuments.length > 1 && (
                  <Select
                    id="select-document"
                    label={currentStepData?.selectLabel}
                    placeholder={currentStepData?.selectPlaceholder}
                    options={valuesSelectDocuments}
                    value={valueSelectedDocument}
                    onChange={(value) => {
                      setValueSelectedDocument(value)
                      setHasBeenUpdated(true)
                      const selectedDocument = documents?.find(
                        (file) => file.name === value
                      )

                      if (document?.id !== selectedDocument?.id) {
                        setDocument({
                          id: selectedDocument?.id as string,
                          index: selectedDocument?.index as number,
                          name: selectedDocument?.name as string,
                          privateDescription:
                            selectedDocument?.privateDescription as string,
                          publicDescription:
                            selectedDocument?.publicDescription as string,
                          pageSizes:
                            selectedDocument?.pageSizes as PageSizeEnvelopeType[]
                        })

                        setLoadingPdfByDocumentId({
                          documentId: selectedDocument?.id as string,
                          loading: true
                        })
                      }
                    }}
                    className="w-full max-w-none md:w-full"
                  />
                )}
                <Button
                  onClick={() => setIsDrawerOpen(true)}
                  className={`md:hidden ${
                    valuesSelectDocuments.length > 1 ? 'w-full sm:w-fit' : ''
                  }`}
                >
                  {currentStepData?.buttonCreateSignMark}
                </Button>
              </div>
            </div>
          )}
          <div className="justify-center hidden px-4 pb-4 md:flex lg:px-0">
            <AreaSignersToSign
              handleClickCardSignMark={handleClickCardSignMark}
            />
          </div>
          <div className="flex justify-center h-full p-2 md:hidden bg-gray-50">
            <PdfViewer />
          </div>
        </div>
        <div
          className={cn(
            'fixed bottom-0 z-40 flex items-center justify-between w-full pt-8 border-t border-gray-300 md:relative md:bottom-auto bg-accent-100 md:bg-transparent',
            currentStep.stepName === StepType.SIGNATURE_MARKER &&
              isCompactDevice
              ? 'p-4'
              : ''
          )}
        >
          <Button
            onClick={() => {
              handleActionStep('previous')
            }}
            className="w-fit"
            variant="neutral"
          >
            {currentStepData?.buttonBack}
          </Button>
          <DropdownButton
            label={
              currentValuesEnvelope.toBePublished
                ? currentStepData?.action?.labelSchedule
                : currentStepData?.action?.labelPublish
            }
            dropdownItems={dataDropdownItems}
            type="submit"
            placement="top"
            loading={loadingEnvelopeSave}
            onClick={(key, e) => {
              e.preventDefault()
              e.stopPropagation()
              if (alreadySaved) {
                handlePublishEnvelope(currentActionEnvelope?.id as string)
                return
              }
              if (key === 'save') {
                handleSaveEnvelope({
                  publish: false
                })
                return
              }
              if (key === 'main') {
                if (documents.length === 0 || noSigners) {
                  const messageToast =
                    documents.length === 0
                      ? t?.toasts?.noDocuments
                      : t?.toasts?.noSigners
                  showToast.error(messageToast)
                  return
                }
                handleSaveEnvelope({
                  publish: true
                })
                return
              }
            }}
          />
        </div>
      </div>
      <DrawerEnvelope
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        handleClickCardSignMark={handleClickCardSignMark}
      />
    </>
  )
}

export default StepItemSignersEnvelope
