import { Dispatch, SetStateAction } from 'react'
import { Lang, MarkupOrientationType } from '@/types/global'
import {
  DocumentEnvelope,
  EnvelopeRequest,
  SignerEnvelope,
  MarkTypeEnvelope,
  EnvelopeDataResponse
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { SignMarkWithSignerType } from '@/molecules/PdfViewer/PdfViewer.types'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault/SelectVault'

export type Props = {
  children?: React.ReactNode
}

export type IEnvelope = Omit<EnvelopeRequest, 'documents' | 'signers'>

export interface IFile {
  file?: File
  documentId: string
  name?: string
  description?: string
}

export type SignerEnvelopeInitial = Omit<SignerEnvelope, 'role'> & {
  id: string
  role: string
  isDragDisabled?: boolean
}

export type CurrentActionEnvelope = EnvelopeRequest & {
  vaultName?: string | null
}

export type LoadingPdfByDocumentIdType = {
  documentId: string | null
  loading: boolean
}

export type CurrentValuesEnvelope = {
  vault?: {
    value: string
    label: string
  }
  // vaultName?: string | null
  name: string
  message?: string | null
  reviewReminder?: boolean | null
  buttonShowAdvancedSettings?: boolean | null
  toBePublished?: boolean | null
  toBePublishedAtUtc?: Date | string | null
  hasExpiration?: boolean | null
  expirationReminder?: boolean | null
  expiresAtUtc?: string | null
  language?: Lang | null
  privateDescription?: string | null
  publicDescription?: string | null
  signInOrder?: boolean | null
  markupOrientation?: MarkupOrientationType
}

export type ViewType = {
  type: 'envelope' | 'template'
  templateId: string | null
}

export enum StepType {
  DETAILS = 'details',
  DOCUMENTS = 'documents',
  SIGNERS = 'signers',
  SIGNATURE_MARKER = 'signatureMarker'
}

export type CurrentStepType = {
  stepNumber: number
  stepName: StepType
}

export type ActionStepType = 'next' | 'previous'

export type ModalConfirmErrorPublishType = {
  open: boolean
  typeError:
    | 'Envelopes'
    | 'SignerMfaCredits'
    | 'ApiEnvelopes'
    | 'PublicationDateBeforeNow'
    | 'ExpirationDateBeforeNow'
    | 'RemoveSchedule'
    | null
}

export type SaveEnvelopeType = {
  values?: CurrentValuesEnvelope
  publish?: boolean
  removeSchedule?: boolean
}

export type EnvelopeContextType = {
  currentStep: CurrentStepType
  setCurrentStep: (step: CurrentStepType) => void
  canAddSignature: boolean
  setCanAddSignature: (canAddSignature: boolean) => void
  selectedSignerMarkData: ISelectedSignerMarkData
  setSelectedSignerMarkData: Dispatch<SetStateAction<ISelectedSignerMarkData>>
  document: DocumentEnvelope | null
  setDocument: Dispatch<SetStateAction<DocumentEnvelope | null>>
  noSigners: boolean
  currentVaultId: CurrentVaultId
  setCurrentVaultId: Dispatch<SetStateAction<CurrentVaultId>>
  documents: DocumentEnvelope[]
  setDocuments: Dispatch<SetStateAction<DocumentEnvelope[]>>
  marks: SignMarkWithSignerType[]
  handleDeleteSigner: (signerId: string) => void
  signers: SignerEnvelopeInitial[]
  setSigners: Dispatch<SetStateAction<SignerEnvelopeInitial[]>>
  isLoadingCreateEnvelope: boolean
  currentActionEnvelope: CurrentActionEnvelopeType
  setCurrentActionEnvelope: Dispatch<SetStateAction<CurrentActionEnvelopeType>>
  loadingEnvelopeSave: boolean
  loadingPdfByDocumentId: LoadingPdfByDocumentIdType
  setLoadingPdfByDocumentId: Dispatch<
    SetStateAction<LoadingPdfByDocumentIdType>
  >
  currentValuesEnvelope: CurrentValuesEnvelope
  setCurrentValuesEnvelope: Dispatch<SetStateAction<CurrentValuesEnvelope>>
  viewType: ViewType
  setViewType: Dispatch<SetStateAction<ViewType>>
  currentSignerAction: CurrentSignerActionType
  setCurrentSignerAction: Dispatch<SetStateAction<CurrentSignerActionType>>
  currentSignerContact: CurrentSignerContact | null
  setCurrentSignerContact: Dispatch<SetStateAction<CurrentSignerContact | null>>
  isCompactDevice: boolean
  handleActionStep: (action: ActionStepType) => void
  stepsOrder: StepType[]
  valuesSelectDocuments: {
    id: string
    value: string
    label: string
  }[]
  handleSaveEnvelope: (data: SaveEnvelopeType) => Promise<void>
  hasReviewed: boolean
  modalConfirmErrorPublish: ModalConfirmErrorPublishType
  setModalConfirmErrorPublish: Dispatch<
    SetStateAction<ModalConfirmErrorPublishType>
  >
  handlePublishEnvelope: (id: string) => void
  alreadySaved: boolean
  finishedInitialSetup: boolean
  setFinishedInitialSetup: Dispatch<SetStateAction<boolean>>
  modalHelpInfo: ModalHelpInfoType
  setModalHelpInfo: Dispatch<SetStateAction<ModalHelpInfoType>>
  dataEnvelope: EnvelopeDataResponse | null | undefined
  valuesDocumentTemplateFiller: Record<string, string>
  setValuesDocumentTemplateFiller: Dispatch<
    SetStateAction<Record<string, string>>
  >
}

type InputType =
  | 'input'
  | 'textarea'
  | 'toggle'
  | 'select'
  | 'button'
  | 'datepicker'

interface IOption {
  label: string
  value: string
}

export interface IFormItemBase {
  id: string
  type: InputType
  name: string
  label: string
}

interface IInputItem extends IFormItemBase {
  type: 'input'
  typeInput?: 'text' | 'password' | 'email'
  placeholder?: string
  required?: boolean
  readonly?: boolean
  mask?: string
}

interface ITextareaItem extends IFormItemBase {
  type: 'textarea'
  placeholder?: string
  required?: boolean
}

interface IToggleItem extends IFormItemBase {
  type: 'toggle'
  defaultValue: boolean
}

interface ISelectItem extends IFormItemBase {
  type: 'select'
  options: IOption[]
  size?: 'sm' | 'md' | 'lg'
}

interface IButtonItem extends IFormItemBase {
  type: 'button'
}

interface ILineItem {
  id: string
  type: 'line'
  align?: 'start' | 'center' | 'end' | 'spaceBetween'
  borderTop?: boolean
  items: (IInputItem | IToggleItem | IButtonItem)[]
}

interface IConditionalItem {
  id: string
  type: 'conditional'
  showIfField: string
  showIfValue: boolean
  name?: string
  label?: string
  placeholder?: string
  defaultValue?: string | boolean
  items: (IInputItem | IToggleItem | ISelectItem | ITextareaItem)[]
}

interface IDatePickerItem extends IFormItemBase {
  type: 'datepicker'
  defaultValue?: string
  placeholder?: string
  allowPastDates?: boolean
}

export type IItemForm =
  | IInputItem
  | ITextareaItem
  | IToggleItem
  | ISelectItem
  | IButtonItem
  | ILineItem
  | IConditionalItem
  | IDatePickerItem

export type IForm = IItemForm[]

export interface IStep {
  key: number
  label: string
  form: IForm
}

export interface ISelectedSignerMarkData {
  id: string | null
  type: MarkTypeEnvelope | null
}

export interface CurrentSignerActionType {
  id: string | null
  action: 'create' | 'update' | 'delete' | 'createWithContact' | null
  isOpen: boolean
}

export interface CurrentActionEnvelopeType {
  id: string | null
  action: 'create' | 'edit' | null
}

export type CurrentSignerContact = {
  name: string
  email: string
}

export type ModalHelpInfoType = {
  open: boolean
  type: 'errorTooManyRequests' | 'errorCreateEnvelope' | null
}
