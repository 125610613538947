import { toast as originalToast, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const generateToastIdByMessage = (message: string) => {
  return message.replace(/\s/g, '-').toLowerCase()
}

const showToastOriginal = (
  message: string,
  type: 'success' | 'error' | 'info' | 'warning',
  options: ToastOptions = {}
) => {
  const toastId = generateToastIdByMessage(message)

  if (originalToast.isActive(toastId)) {
    originalToast.update(toastId, {
      render: message,
      type: type,
      ...options
    })
    return
  }

  return originalToast[type](message, {
    toastId: toastId,
    ...options
  })
}

export const showToast = {
  success: (message: string, options: ToastOptions = {}) =>
    showToastOriginal(message, 'success', options),
  error: (message: string, options: ToastOptions = {}) =>
    showToastOriginal(message, 'error', options),
  info: (message: string, options: ToastOptions = {}) =>
    showToastOriginal(message, 'info', options),
  warning: (message: string, options: ToastOptions = {}) =>
    showToastOriginal(message, 'warning', options)
}
