import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import useApi from '@/hooks/useApi'
import {
  GetEnvelopeResponse,
  HtmlToPdfRequest,
  ApproveSignMarkRequest,
  DocumentDownloadParams,
  GetEnvelopeRequestWithId,
  GetEnvelopeRequest
} from './usePublicApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const usePublicApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useApproveSignMark = () =>
    useMutation({
      mutationFn: ({
        signatureLinkId,
        signMarkId,
        ...data
      }: ApproveSignMarkRequest) => {
        return handleApi<{ recaptchaToken: string }, void>(
          `/envelopes/signature-link/${signatureLinkId}/sign-mark/${signMarkId}`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ApproveSignMark]
        })
      }
    })

  const useHtmlToPdf = () =>
    useMutation({
      mutationFn: (data: HtmlToPdfRequest) => {
        return handleApi<HtmlToPdfRequest, string>('/html-to-pdf', 'POST', data)
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.HtmlToPdf]
        })
      }
    })

  const useGetEnvelope = ({ id, ...data }: GetEnvelopeRequestWithId) => {
    return useQuery({
      queryKey: [QueryKeys.PublicEnvelope, id],
      queryFn: () =>
        handleApi<GetEnvelopeRequest, GetEnvelopeResponse>(
          `/envelopes/${id}`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      ...queryConfig,
      enabled: !!id && !!data?.recaptchaToken
    })
  }

  const useGetOriginalDocument = (id: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.OriginalDocument, id],
      queryFn: () =>
        handleApi<void, void>(`/documents/${id}/original`, 'GET', undefined, {
          requireAuth: false,
          responseType: 'blob'
        }),
      ...queryConfig,
      enabled: !!id
    })
  }

  const useGetSignedDocument = (id: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.SignedDocument, id],
      queryFn: () =>
        handleApi<void, void>(`/documents/${id}/signed`, 'GET', undefined, {
          requireAuth: false,
          responseType: 'blob'
        }),
      ...queryConfig,
      enabled: !!id
    })
  }

  const useGetCertificateEnvelope = (id: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.CertificateEnvelope, id],
      queryFn: () =>
        handleApi<void, void>(
          `/envelopes/${id}/certificate`,
          'GET',
          undefined,
          {
            requireAuth: false,
            responseType: 'blob'
          }
        ),
      ...queryConfig,
      enabled: !!id
    })
  }

  const useDownloadSignedDocument = () => {
    return useMutation(
      async ({ id, name }: DocumentDownloadParams) => {
        const response = await handleApi<void, Blob>(
          `/documents/${id}/signed`,
          'GET',
          undefined,
          {
            requireAuth: true,
            responseType: 'blob'
          }
        )

        return { data: response, name }
      },
      {
        onSuccess: ({ data, name }) => {
          if (data) {
            const blob = new Blob([data], { type: 'application/pdf' })
            saveAs(blob, `${name}.pdf`)
          }
        },
        onError: (error) => {
          console.error('Failed to download document:', error)
        }
      }
    )
  }

  const useDownloadDocumentOriginal = () => {
    return useMutation(
      async ({ id, name }: DocumentDownloadParams) => {
        const response = await handleApi<void, Blob>(
          `/documents/${id}/original`,
          'GET',
          undefined,
          {
            requireAuth: true,
            responseType: 'blob'
          }
        )

        return { data: response, name }
      },
      {
        onSuccess: ({ data, name }) => {
          if (data) {
            const blob = new Blob([data], { type: 'application/pdf' })
            saveAs(blob, `${name}.pdf`)
          }
        },
        onError: (error) => {
          console.error('Failed to download document:', error)
        }
      }
    )
  }

  const useDownloadCertificateEnvelope = () => {
    return useMutation(
      async (id: string) => {
        const response = await handleApi<void, Blob>(
          `/envelopes/${id}/certificate`,
          'GET',
          undefined,
          {
            requireAuth: true,
            responseType: 'blob'
          }
        )

        return response
      },
      {
        onSuccess: (data) => {
          if (data) {
            const blob = new Blob([data], { type: 'application/pdf' })
            saveAs(blob, 'envelope_certificate.pdf')
          }
        },
        onError: (error) => {
          console.error('Failed to download certificate:', error)
        }
      }
    )
  }

  return {
    useGetOriginalDocument,
    useApproveSignMark,
    useHtmlToPdf,
    useGetEnvelope,
    useGetSignedDocument,
    useDownloadSignedDocument,
    useDownloadDocumentOriginal,
    useGetCertificateEnvelope,
    useDownloadCertificateEnvelope
  }
}

export default usePublicApi
