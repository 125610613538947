import { v4 as uuidv4 } from 'uuid'
import { HEIGHT_MARK, WIDTH_MARK } from '@/constants/sizesPdf'
import { DocumentEnvelope } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'

export const createMark = (
  e: any,
  page: number,
  selectedSigner: string | null,
  type = 'Signature',
  scaleFactor: number
) => {
  const rect = e.target.getBoundingClientRect()
  const markSize = {
    width: WIDTH_MARK,
    height: HEIGHT_MARK
  }

  let newMarkX = (e.clientX - rect.left) / scaleFactor
  let newMarkY = (e.clientY - rect.top) / scaleFactor

  newMarkX = Math.max(
    0,
    Math.min(newMarkX, rect.width / scaleFactor - markSize.width)
  )
  newMarkY = Math.max(
    0,
    Math.min(newMarkY, rect.height / scaleFactor - markSize.height)
  )

  return {
    id: 'fake-id-' + uuidv4(),
    page: page,
    type: type,
    x: parseFloat(newMarkX.toFixed(2)),
    y: parseFloat(newMarkY.toFixed(2)),
    signer: selectedSigner
  }
}

export const createOrUpdateMark = (
  document: DocumentEnvelope | null,
  mark: {
    id?: string
    type?: string
    page?: number
    x?: number
    y?: number
    rotation?: number
  }
) => {
  return {
    id: mark?.id || 'fake-id-' + uuidv4(),
    documentId: document?.id,
    type: mark?.type,
    page: mark?.page,
    x: mark?.x,
    y: mark?.y,
    rotation: mark?.rotation
  }
}
