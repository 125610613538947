const NotificationToggleSkeleton: React.FC = () => {
  return (
    <div className="flex flex-row items-center justify-between flex-1 gap-2 p-4 border rounded-lg">
      <div className="flex flex-col space-y-0.5 flex-1">
        <div className="w-24 h-6 mb-1 skeleton" />
        <div className="w-full h-4 max-w-96 skeleton" />
      </div>
      <div className="w-16 h-6 skeleton" />
    </div>
  )
}

export default NotificationToggleSkeleton
