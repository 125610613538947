import React, { memo, useCallback } from 'react'
import dynamic from 'next/dynamic'
import { Document, Page, pdfjs } from 'react-pdf'
import { usePageViewportDocument } from '@/hooks/usePageViewportDocument'
import useTranslation from '@/hooks/useTranslation'
import { createMark, createOrUpdateMark } from '@/utils/index'
import { useEnvelope } from '@/providers/Envelope'
import { SignMarkWithSignerType } from './PdfViewer.types'
import EditableMark from '../PdfViewer/editor/EditableMark'
import {
  PageSizeEnvelopeType,
  SignerEnvelope,
  SignMarkEnvelopeType
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { usePublicApi } from '@/hooks/api/public/usePublicApi'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'

const PdfLoadingSkeleton = dynamic(
  () => {
    return import('@/molecules/PdfLoadingSkeleton')
  },
  { ssr: false }
)

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js'

const PdfViewer: React.FC = () => {
  const {
    selectedSignerMarkData,
    document,
    noSigners,
    marks,
    setSigners,
    setSelectedSignerMarkData,
    loadingPdfByDocumentId,
    setLoadingPdfByDocumentId
  } = useEnvelope()

  const { t } = useTranslation('envelope')

  const { useGetOriginalDocument } = usePublicApi()
  const {
    data: dataGetOriginalDocument,
    isLoading: isLoadingGetOriginalDocument
  } = useGetOriginalDocument(document?.id as string)

  const { getViewportSize, scaleFactor } = usePageViewportDocument()

  const onPositionChangedHandler = useCallback(
    (
      position: {
        x: number
        y: number
      },
      id: string | undefined
    ) => {
      const currentMark = marks?.find(
        (e: SignMarkWithSignerType) => e.id === id
      )

      if (
        !position ||
        Number(currentMark?.x) === Number(position.x) ||
        Number(currentMark?.y) === Number(position.y)
      )
        return

      const newMovedMark = {
        ...currentMark,
        x: position.x,
        y: position.y
      }

      const dataNewSignMark = createOrUpdateMark(document, newMovedMark)

      setSigners((prevState: any) => {
        if (!prevState) return
        return prevState.map((signer: SignerEnvelope) => {
          return {
            ...signer,
            signMarks: signer?.signMarks?.map(
              (signMark: SignMarkEnvelopeType) => {
                if (signMark.id === dataNewSignMark.id) {
                  return dataNewSignMark
                }
                return signMark
              }
            )
          }
        })
      })
      showToast.success(t?.toasts?.successUpdateMark)
    },
    [document, marks, setSigners, t?.toasts?.successUpdateMark]
  )

  const onDeleteHandler = useCallback(
    (id: string) => {
      setSigners((prevState: any) => {
        if (!prevState) return
        return prevState.map((signer: SignerEnvelope) => {
          return {
            ...signer,
            signMarks: signer?.signMarks?.filter(
              (signMark: SignMarkEnvelopeType) => signMark.id !== id
            )
          }
        })
      })
      showToast.success(t?.toasts?.successDeleteMark)
    },
    [setSigners, t?.toasts?.successDeleteMark]
  )

  const addMark = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
      page: number
    ) => {
      if (!selectedSignerMarkData || noSigners) return

      e.stopPropagation()
      e.preventDefault()

      const signatureMark = createMark(
        e,
        page,
        selectedSignerMarkData?.id,
        selectedSignerMarkData?.type || 'Signature',
        scaleFactor
      )

      const newMark = createOrUpdateMark(document, signatureMark)

      setSigners((prevState: any) => {
        if (!prevState) return
        return prevState.map((signer: SignerEnvelope) => {
          if (signer.id === selectedSignerMarkData?.id) {
            return {
              ...signer,
              signMarks: [...(signer?.signMarks || {}), newMark]
            }
          }
          return signer
        })
      })

      setSelectedSignerMarkData({
        id: null,
        type: null
      })

      showToast.success(t?.toasts?.successAddMark)
    },
    [
      document,
      noSigners,
      scaleFactor,
      selectedSignerMarkData,
      setSelectedSignerMarkData,
      setSigners,
      t?.toasts?.successAddMark
    ]
  )

  const renderPage = useCallback(
    (pageItem: PageSizeEnvelopeType) => {
      const { width: scaledWidth, height: scaledHeight } = getViewportSize(
        pageItem?.width,
        pageItem?.height
      )

      return (
        <div
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()

            if (e.button === 0 && selectedSignerMarkData?.id)
              addMark(e, pageItem.page)
          }}
          onMouseUp={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{
            position: 'relative',
            cursor: selectedSignerMarkData?.id ? 'crosshair' : 'default',
            width: scaledWidth,
            height: scaledHeight
          }}
          className="z-30 mx-auto w-fit debug-breakpoint"
          key={pageItem.page}
        >
          <Page
            scale={1}
            key={`page_${pageItem.page}`}
            pageNumber={pageItem.page}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onRenderError={() =>
              setLoadingPdfByDocumentId({
                documentId: document?.id as string,
                loading: false
              })
            }
            width={scaledWidth}
            height={scaledHeight}
          />
          {marks
            ?.filter(
              (mark) =>
                mark.page === pageItem.page && mark.documentId === document?.id
            )
            .map((mark) => (
              <EditableMark
                hidden={!selectedSignerMarkData?.id}
                key={mark.id}
                mark={mark}
                onPositionChanged={onPositionChangedHandler}
                onDelete={onDeleteHandler}
                scaleFactor={scaleFactor}
              />
            ))}
        </div>
      )
    },
    [
      addMark,
      document?.id,
      getViewportSize,
      marks,
      onDeleteHandler,
      onPositionChangedHandler,
      scaleFactor,
      selectedSignerMarkData?.id,
      setLoadingPdfByDocumentId
    ]
  )

  return (
    <div className="pb-14">
      {(isLoadingGetOriginalDocument ||
        (loadingPdfByDocumentId?.loading &&
          loadingPdfByDocumentId?.documentId)) && (
        <div className="w-full min-w-[390px] sm:min-w-[500px] md:min-w-[390px]">
          <PdfLoadingSkeleton pages={4} />
        </div>
      )}
      <Document
        onMouseUp={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        file={dataGetOriginalDocument as any}
        onLoadSuccess={() => {
          setTimeout(() => {
            setLoadingPdfByDocumentId({
              documentId: document?.id as string,
              loading: false
            })
          }, 1000)
        }}
        onLoadError={(e) => console.log('error', e)}
        renderMode="canvas"
        className={cn(
          'relative flex flex-col w-auto h-full gap-4 py-2 mx-auto',
          loadingPdfByDocumentId?.loading && 'hidden'
        )}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {selectedSignerMarkData?.id && (
          <div className="inset-0 z-10 hidden bg-black opacity-50 lg:fixed"></div>
        )}
        {document?.pageSizes?.map((page) => renderPage(page))}
      </Document>
    </div>
  )
}

export default memo(PdfViewer)
