export const BASE_PAGE_WIDTH = 595 // before was 612
export const BASE_PAGE_HEIGHT = 842 // before was 792

export const SCALE_FACTOR_DEFAULT = 1 // 0.857
export const SCALE_FACTOR_LG = 0.857 // 0.857
export const SCALE_FACTOR_MD = 0.714 // 0.857
export const SCALE_FACTOR_SM = 0.571 // 0.857
export const SCALE_FACTOR_XS = 0.571 // 0.857

export const WIDTH_MARK = 160
export const HEIGHT_MARK = 60
