import React from 'react'

type Props = {
  text: string
}

const Divider: React.FC<Props> = ({ text }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex-grow border-t border-gray-200"></div>
      <span className="mx-4 text-sm text-gray-400">{text}</span>
      <div className="flex-grow border-t border-gray-200"></div>
    </div>
  )
}

export default Divider
