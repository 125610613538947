import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useTranslation from '@/hooks/useTranslation'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { Textarea } from '@/atoms/index'
import { Modal } from '..'
import { Button } from '@/ui/atoms/shadcn'

const ModalRejectEnvelope: React.FC = () => {
  const { t } = useTranslation('reviewEnvelope')
  const [valueTextarea, setValueTextarea] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    rejectHandler,
    openActionModal,
    setOpenActionModal,
    isLoadingRejectEnvelope
  } = useReviewEnvelope()

  return (
    <Modal
      isOpen={openActionModal.open && openActionModal.type === 'rejectEnvelope'}
      title={t?.modalRejectEnvelope?.title}
      onClose={() => setOpenActionModal({ open: false, type: null })}
    >
      <div className="pt-2 pb-4">
        <Textarea
          value={valueTextarea}
          onChange={(e) => setValueTextarea(e.target.value)}
          label={t?.modalRejectEnvelope?.labelTextarea}
          maxLength={t?.modalRejectEnvelope?.maxLengthTextarea}
        />
      </div>
      <div className="flex justify-end gap-2 pt-4 mt-2 border-t border-gray-300">
        <Button
          onClick={() => setOpenActionModal({ open: false, type: null })}
          variant="neutral"
          type="button"
        >
          {t?.modalRejectEnvelope?.buttonCancel}
        </Button>
        <Button
          onClick={async () => {
            const recaptchaToken = executeRecaptcha
              ? await executeRecaptcha('signer_rejection')
              : ''

            if (recaptchaToken) {
              await rejectHandler({ reason: valueTextarea, recaptchaToken })
              setOpenActionModal({ open: false, type: null })
            }
          }}
          type="button"
          loading={isLoadingRejectEnvelope}
        >
          {t?.modalRejectEnvelope?.buttonReject}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalRejectEnvelope
