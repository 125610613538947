import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import useTranslation from '@/hooks/useTranslation'
import formatCurrency from '@/utils/currency'
import { Lang } from '@/types/global'
import { cn } from '@/lib/utils'
import { useAuth, usePlans } from '@/providers'
import {
  DrawerType,
  ModalType,
  PlanItemType
} from '@/providers/Plans/Plans.types'
import { Toggle, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = Omit<PlanItemType, 'recurrence'>

const CardPlan: React.FC<Props> = ({
  planType,
  emphasis,
  title,
  description,
  price,
  buttonCta,
  features,
  recurrencePlan,
  recommendedLabel,
  badgeCurrentPlan,
  isCurrentPlan,
  isPlanDeactivated,
  planInactive,
  planId
}) => {
  const [isMounted, setIsMounted] = useState(true)
  const { t, isReady } = useTranslation('plans')
  const { userContext } = useAuth()
  const {
    dataCurrentSubscription,
    setDataModal,
    setTriggerDrawerOpen,
    isButtonLoading,
    addonToggles,
    setDataDrawer,
    getLinkChangePaymentMethod
  } = usePlans()

  const currencyToLang = {
    BRL: 'PtBr',
    USD: 'EnUs'
  }[price?.currency] as Lang

  const handleClickButton = useCallback(async () => {
    if (!isMounted) return
    if (isCurrentPlan) {
      if (!isPlanDeactivated) {
        setDataModal({
          isOpen: true,
          type: ModalType.CONFIRM_PLAN_CANCELLATION,
          planType,
          planId,
          recurrence: recurrencePlan?.value
        })
        return
      }
      setDataModal({
        isOpen: true,
        type: ModalType.CONFIRM_PLAN_RELEASE,
        planType,
        planId,
        recurrence: recurrencePlan?.value
      })
      return
    }
    if (dataCurrentSubscription?.pendingInvoiceId) {
      setDataModal({
        isOpen: true,
        type: ModalType.MODAL_PENDING_INVOICES
      })
      return
    }
    if (dataCurrentSubscription?.id) {
      setDataModal({
        isOpen: true,
        type: ModalType.CONFIRM_PLAN_CHANGE_SUBSCRIPTION,
        planType,
        planId,
        recurrence: recurrencePlan?.value
      })
      return
    }

    setDataModal({
      isOpen: true,
      type: ModalType.MODAL_SELECT_FEATURES_BEFORE_PURCHASE,
      planType,
      planId,
      recurrence: recurrencePlan?.value
    })
  }, [
    isMounted,
    isCurrentPlan,
    dataCurrentSubscription?.pendingInvoiceId,
    dataCurrentSubscription?.id,
    setDataModal,
    planType,
    planId,
    recurrencePlan?.value,
    isPlanDeactivated
  ])

  const buttonVariant = useMemo(() => {
    if (isCurrentPlan && !isPlanDeactivated) {
      return 'destructive'
    }
    if (isCurrentPlan && isPlanDeactivated) {
      return 'secondary'
    }
    return emphasis ? 'secondary' : 'outlineSecondary'
  }, [emphasis, isCurrentPlan, isPlanDeactivated])

  const handleIncreaseUserClick = useCallback(() => {
    if (!dataCurrentSubscription?.userSeatQuantity) return

    const isUserSeatQuantityExceeded =
      dataCurrentSubscription.userSeatQuantity >
      userContext?.balanceInformation?.userSeatsBeingUsed

    const hasPendingInvoice = () => !!dataCurrentSubscription.pendingInvoiceId

    const openDrawerChangeLicensedUsers = () => {
      setTriggerDrawerOpen(DrawerType.DRAWER_CHANGE_LICENSED_USERS)
      setDataDrawer({
        isOpen: true,
        type: DrawerType.DRAWER_CHANGE_LICENSED_USERS,
        planType: planType
      })
    }

    const openModalPendingInvoices = () => {
      setDataModal({
        isOpen: true,
        type: ModalType.MODAL_PENDING_INVOICES
      })
    }

    if (isUserSeatQuantityExceeded) {
      openDrawerChangeLicensedUsers()
      return
    }

    if (hasPendingInvoice()) {
      openModalPendingInvoices()
      return
    }

    openDrawerChangeLicensedUsers()
  }, [
    dataCurrentSubscription,
    planType,
    setDataDrawer,
    setDataModal,
    setTriggerDrawerOpen,
    userContext?.balanceInformation
  ])

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <>
      <div
        key={planType}
        className={cn(
          emphasis ? 'ring-2 ring-secondary-700' : 'ring-1 ring-gray-200',
          'rounded-3xl p-7 xl:px-6 bg-accent-100'
        )}
      >
        <div className="relative flex items-center justify-center gap-2">
          <h3
            id={planType}
            className={classNames(
              emphasis ? 'text-secondary-700' : 'text-gray-900',
              'text-center text-2xl font-semibold leading-8'
            )}
          >
            {title}
          </h3>
          {badgeCurrentPlan ? (
            <p className="absolute px-2 py-1 text-xs font-semibold leading-5 text-white rounded-full bg-secondary-700 bottom-12">
              {badgeCurrentPlan}
            </p>
          ) : null}
          {emphasis && recommendedLabel && !badgeCurrentPlan ? (
            <p className="absolute px-2 py-1 text-xs font-semibold leading-5 text-white rounded-full bg-secondary-700 bottom-12">
              {recommendedLabel}
            </p>
          ) : null}
        </div>
        <p className="mt-3 text-sm leading-6 text-center text-gray-600 whitespace-nowrap">
          {description}
        </p>
        <p className="flex items-baseline justify-center mt-4 gap-x-1">
          <span className="text-2xl font-bold tracking-tight text-center text-gray-900">
            {currencyToLang &&
              formatCurrency(price?.value, currencyToLang, false)}
          </span>
          <span className="text-sm font-semibold leading-6 text-gray-600">
            {recurrencePlan?.priceSuffix}
          </span>
        </p>
        {recurrencePlan?.annualDisclaimer && (
          <p className="mt-2 text-sm leading-6 text-center text-gray-600">
            {recurrencePlan?.annualDisclaimer}
          </p>
        )}
        <Button
          onClick={handleClickButton}
          loading={
            isButtonLoading.isLoading &&
            isButtonLoading.planId === planId &&
            isButtonLoading.type === 'buttonCta'
          }
          variant={buttonVariant}
          disabled={planInactive}
          fullWidth
          className="px-3 py-2 mt-6 text-sm font-semibold text-center lg:px-3 lg:py-2"
        >
          {buttonCta}
        </Button>
        {features?.length ? (
          <ul
            role="list"
            className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
          >
            {features?.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon
                  className="flex-none w-5 h-6 text-secondary-700"
                  aria-hidden="true"
                />
                {feature}
              </li>
            ))}
          </ul>
        ) : !planInactive && isCurrentPlan ? (
          <ul
            role="list"
            className="flex flex-col gap-4 mt-8 text-sm text-gray-600"
          >
            <li className="flex items-center gap-2">
              <Button
                variant="link"
                onClick={getLinkChangePaymentMethod}
                className="px-0 py-0 text-sm underline lg:px-0 lg:py-0 h-fit"
                showSkeleton={!isReady}
              >
                {t?.cardPlan?.labelChangePaymentMethod}
              </Button>
            </li>
            <li className="flex items-center gap-2">
              <Typography variant="text-sm-regular" showSkeleton={!isReady}>
                <span className="font-semibold">
                  {userContext?.balanceInformation?.signerMfaCredits
                    ? userContext?.balanceInformation?.signerMfaCredits
                    : t?.cardPlan?.none}
                </span>{' '}
                {t?.cardPlan?.signerMfaCredits?.(
                  userContext?.balanceInformation?.signerMfaCredits
                )}
              </Typography>
              <Button
                variant="link"
                onClick={() => {
                  setTriggerDrawerOpen(DrawerType.DRAWER_SIGNER_MFA_CREDITS)
                  setDataDrawer({
                    isOpen: true,
                    type: DrawerType.DRAWER_SIGNER_MFA_CREDITS,
                    planType: planType
                  })
                }}
                className="px-0 py-0 text-sm underline lg:px-0 lg:py-0 h-fit"
                showSkeleton={!isReady}
              >
                {t?.cardPlan?.buttonBuy}
              </Button>
            </li>
            <li className="flex items-center gap-2">
              <Typography variant="text-sm-regular" showSkeleton={!isReady}>
                <span className="font-semibold">
                  {dataCurrentSubscription?.userSeatQuantity
                    ? dataCurrentSubscription?.userSeatQuantity
                    : t?.cardPlan?.none}
                </span>{' '}
                {t?.cardPlan?.userSeatsLicensed?.(
                  dataCurrentSubscription?.userSeatQuantity
                )}
              </Typography>
              <Button
                variant="link"
                onClick={handleIncreaseUserClick}
                className="px-0 py-0 text-sm underline lg:px-0 lg:py-0 h-fit"
                showSkeleton={!isReady}
              >
                {t?.cardPlan?.buttonChange}
              </Button>
            </li>
            <li className="flex items-center gap-2">
              <Typography
                variant="text-sm-regular"
                className="opacity-70"
                showSkeleton={!isReady}
              >
                <span className="font-semibold">
                  {userContext?.balanceInformation?.apiEnvelopes
                    ? userContext?.balanceInformation?.apiEnvelopes
                    : t?.cardPlan?.none}
                </span>{' '}
                {t?.cardPlan?.apiEnvelopes?.(
                  userContext?.balanceInformation?.apiEnvelopes
                )}
              </Typography>
              <Button
                variant="link"
                // onClick={() => {
                //   setTriggerDrawerOpen(DrawerType.DRAWER_ENVELOPE_API_CREDITS)
                //   setDataDrawer({
                //     isOpen: true,
                //     type: DrawerType.DRAWER_ENVELOPE_API_CREDITS,
                //     planType: planType
                //   })
                // }}
                className="px-0 py-0 text-sm underline lg:px-0 lg:py-0 h-fit disabled:hover:underline"
                showSkeleton={!isReady}
                disabled
              >
                {t?.cardPlan?.buttonBuy}
              </Button>
            </li>
            <li className="flex items-center gap-2">
              <Typography
                variant="text-sm-regular"
                showSkeleton={!isReady}
                className="cursor-not-allowed opacity-70"
              >
                <span className="font-semibold">
                  {dataCurrentSubscription?.apiAddonEnabled}
                </span>{' '}
                {t?.cardPlan?.labelToggleActiveApiAddon(
                  addonToggles.apiAddon || false
                )}
              </Typography>
              <Toggle
                name="apiAddon"
                checked={addonToggles.apiAddon || false}
                onChange={() => {
                  setDataModal({
                    isOpen: true,
                    type: ModalType.CONFIRM_TOGGLE_ACTIVE_API_ADDON
                  })
                }}
                disabled
              />
            </li>
            <li className="flex items-center gap-2">
              <Typography
                variant="text-sm-regular"
                showSkeleton={!isReady}
                className="cursor-not-allowed opacity-70"
              >
                <span className="font-semibold">
                  {dataCurrentSubscription?.whiteLabelAddonEnabled}
                </span>{' '}
                {t?.cardPlan?.labelToggleActiveWhiteLabelAddon(
                  addonToggles.whiteLabelAddon || false
                )}
              </Typography>
              <Toggle
                name="whiteLabelAddon"
                checked={addonToggles.whiteLabelAddon || false}
                onChange={() => {
                  setDataModal({
                    isOpen: true,
                    type: ModalType.CONFIRM_TOGGLE_ACTIVE_WHITE_LABEL_ADDON
                  })
                }}
                disabled
              />
            </li>
          </ul>
        ) : null}
      </div>
    </>
  )
}

export default CardPlan
