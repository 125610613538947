export const fontSize = [
  {
    label: '11pt',
    value: '11pt'
  },
  {
    label: '12pt',
    value: '12pt'
  },
  {
    label: '14pt',
    value: '14pt'
  },
  {
    label: '16pt',
    value: '16pt'
  },
  {
    label: '18pt',
    value: '18pt'
  },
  {
    label: '20pt',
    value: '20pt'
  },
  {
    label: '22pt',
    value: '22pt'
  },
  {
    label: '24pt',
    value: '24pt'
  },
  {
    label: '26pt',
    value: '26pt'
  },
  {
    label: '28pt',
    value: '28pt'
  },
  {
    label: '30pt',
    value: '30pt'
  }
]
