import React from 'react'
import { RadioGroup } from '@headlessui/react'
import useTranslation from '@/hooks/useTranslation'
import {
  CardPlan,
  CardPlanSkeleton,
  DrawerActionPlan,
  ModalConfirm,
  ModalPendingInvoices,
  ModalSelectFeaturesBeforePurchase
} from '@/molecules/index'
import { cn } from '@/lib/utils'
import { usePlans } from '@/providers'
import { ModalType } from '@/providers/Plans/Plans.types'
import { Alert, Link, Typography } from '@/ui/atoms'

const PlansList: React.FC = () => {
  const { t } = useTranslation('plans')

  const {
    isLoading,
    currentPlanData,
    plans,
    currentRecurrencePlan,
    setCurrentRecurrencePlan,
    dataModal,
    isButtonLoading,
    dataModalConfirm,
    dataDrawer,
    getLinkInvoicePending,
    dataCurrentSubscription,
    resetModal
  } = usePlans()

  const renderModal = () => {
    if (!dataModal.isOpen) return null

    switch (dataModal.type) {
      case ModalType.MODAL_SELECT_FEATURES_BEFORE_PURCHASE:
        return <ModalSelectFeaturesBeforePurchase />
      case ModalType.MODAL_PENDING_INVOICES:
        return (
          <ModalPendingInvoices
            isOpen={dataModal.isOpen}
            onClose={resetModal}
            pendingInvoiceId={
              dataCurrentSubscription?.pendingInvoiceId as string
            }
          />
        )
      default:
        if (dataModalConfirm) {
          return (
            <ModalConfirm
              isOpen={true}
              onClose={dataModalConfirm.onCancel}
              title={dataModalConfirm.title}
              description={dataModalConfirm.description}
              onConfirm={dataModalConfirm.onConfirm}
              cancelButtonText={dataModalConfirm.buttonCancel}
              confirmButtonText={dataModalConfirm.buttonConfirm}
              loadingSubmit={
                isButtonLoading.isLoading &&
                isButtonLoading.type === 'modalConfirm'
              }
            />
          )
        }
    }
  }

  const renderDrawer = () => dataDrawer.isOpen && <DrawerActionPlan />

  return (
    <div className="w-full mx-auto max-w-7xl">
      {!!dataCurrentSubscription?.pendingInvoiceId && (
        <Alert title={t?.alertPendingInvoice?.title} variant="warning">
          <Typography variant="text-sm-regular" className="text-inherit">
            {t?.alertPendingInvoice?.descriptionPrefix}
            <Link
              onClick={() =>
                dataCurrentSubscription?.pendingInvoiceId &&
                getLinkInvoicePending(dataCurrentSubscription?.pendingInvoiceId)
              }
              variant="secondary"
            >
              {t?.alertPendingInvoice?.descriptionAction}
            </Link>
            {t?.alertPendingInvoice?.descriptionSuffix}
          </Typography>
        </Alert>
      )}
      <div className="flex justify-center mt-4">
        <RadioGroup
          value={currentRecurrencePlan}
          onChange={setCurrentRecurrencePlan}
          className="grid grid-cols-2 p-1 text-xs font-semibold leading-5 text-center rounded-full gap-x-1 ring-1 ring-inset ring-gray-200 bg-accent-100"
        >
          {isLoading
            ? Array.from({ length: 2 })?.map((_, index) => (
                <RadioGroup.Option
                  value={index}
                  key={index}
                  className="rounded-full px-2.5 py-1"
                >
                  <div className="w-20 h-6 rounded-full skeleton"></div>
                </RadioGroup.Option>
              ))
            : t?.typesOfPlans?.map((option: any) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option.value}
                  className={cn(
                    currentRecurrencePlan === option.value
                      ? 'bg-secondary-700 text-white'
                      : 'text-gray-500 bg-white dark:bg-black',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )}
                >
                  {option.label}
                </RadioGroup.Option>
              ))}
        </RadioGroup>
      </div>
      <div className="grid max-w-md grid-cols-1 gap-8 mx-auto mt-10 isolate sm:grid-cols-1 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
        {isLoading
          ? Array.from({ length: 3 })?.map((_, index) => (
              <CardPlanSkeleton key={index} />
            ))
          : plans.map((plan, id) => {
              return (
                <CardPlan
                  key={`${plan.planId}-${id}`}
                  {...plan}
                  features={
                    !currentPlanData?.hasActiveSubscription ? plan.features : []
                  }
                />
              )
            })}
      </div>
      {renderModal()}
      {renderDrawer()}
    </div>
  )
}

export default PlansList
