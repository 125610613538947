import React from 'react'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'

type TimelineItemProps = {
  user: string
  action: string
  time: string
  icon?: React.ReactNode
  isLastItem: boolean
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  user,
  action,
  time,
  icon,
  isLastItem
}) => {
  return (
    <li className="relative flex gap-x-2">
      <div
        className={cn(
          'absolute left-0 top-0 flex w-6 justify-center',
          !isLastItem ? '-bottom-6' : ''
        )}
      >
        <div
          className={cn('w-px bg-border', isLastItem ? 'h-6' : 'h-full')}
        ></div>
      </div>
      <div className="relative flex items-center justify-center flex-none w-6 h-6 bg-white dark:bg-transparent text-foreground/40">
        {icon ? (
          <div className="p-1 bg-white border border-gray-300 rounded-full">
            {icon}
          </div>
        ) : (
          <div className="bg-widget h-1.5 w-1.5 rounded-full ring-1 ring-gray-300"></div>
        )}
      </div>
      <div className="flex flex-col flex-1">
        <Typography
          variant="text-xs-medium"
          className="leading-4 tracking-wide text-gray-800"
        >
          {user}
        </Typography>
        <Typography
          variant="text-xs-regular"
          className="leading-4 py-0.5 text-gray-500"
        >
          {action}
        </Typography>
      </div>
      <Typography
        variant="text-xs-regular"
        className="self-center text-gray-500 max-w-20 lg:max-w-none text-end"
      >
        {time}
      </Typography>
    </li>
  )
}

type TimelineProps = {
  items: {
    user: string
    action: string
    time: string
    icon?: React.ReactNode
  }[]
}

const Timeline: React.FC<TimelineProps> = ({ items }) => {
  return (
    <section style={{ opacity: 1 }}>
      <ul role="list" className="p-4 space-y-6">
        {items?.map((item, index) => (
          <TimelineItem
            key={index}
            user={item.user}
            action={item.action}
            time={item.time}
            icon={item.icon}
            isLastItem={index === items.length - 1}
          />
        ))}
      </ul>
    </section>
  )
}

export default Timeline
