import React, { Dispatch, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  Form,
  Modal,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '..'
import { useInviteApi } from '@/hooks/api/administration/useInviteApi'
import { RoleType } from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import { BillingCurrentSubscriptionResponse } from '@/hooks/api/billing/useBillingApi/useBillingApi.types'
import { ModalInviteUserType } from '@/hooks/useUsersData/useUsersData.types'
import { useAuth } from '@/providers'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

type ValuesType = {
  name: string
  email: string
  role: RoleType
}

type Props = {
  isOpen: boolean
  onClose: () => void
  setModalNoLicensedUsers: (open: boolean) => void
  setModalInviteUser: Dispatch<ModalInviteUserType>
  setModalPendingInvoices: Dispatch<boolean>
  dataUser?: {
    name: string
    email: string
    role: RoleType
  } | null
  dataCurrentSubscription: BillingCurrentSubscriptionResponse | null | undefined
}

const ModalInviteUser: React.FC<Props> = ({
  isOpen,
  onClose,
  setModalNoLicensedUsers,
  setModalInviteUser,
  setModalPendingInvoices,
  dataUser,
  dataCurrentSubscription
}) => {
  const { t, isReady } = useTranslation(
    ['modalInviteUser', 'validations'],
    true
  )
  const { refreshProfile } = useAuth()
  const { useCreateUserInvite } = useInviteApi()
  const {
    mutateAsync: mutateAsyncCreateUserInvite,
    isLoading: isLoadingCreateUserInvite
  } = useCreateUserInvite()
  const { push } = useRouter()

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(5, { message: t?.errorMinLength?.(5) })
      .max(100, { message: t?.errorMaxLength?.(100) }),
    email: z
      .string()
      .min(5, { message: t?.errorMinLength?.(5) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .email({ message: t?.errorInvalidEmail }),
    role: z.enum(['User', 'Administrator'])
  })

  const form = useForm<ValuesType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      role: 'User'
    }
  })

  useEffect(() => {
    if (dataUser?.name) {
      form.setValue('name', dataUser.name)
    }
  }, [dataUser, form])

  return (
    <Modal
      isOpen={isOpen}
      title={t?.title}
      onClose={() => {
        onClose()
        form.reset()
      }}
      displayAsDrawerOnMobile
    >
      <Form<ValuesType>
        {...form}
        onSubmit={async (valuesSubmit) => {
          try {
            await mutateAsyncCreateUserInvite({
              name: valuesSubmit.name,
              email: valuesSubmit.email,
              role: valuesSubmit.role
            })
            await refreshProfile()
            showToast.success(t?.toast?.success)
            onClose()
            form.reset()
            push('/users?typeView=invite')
          } catch (error: any) {
            // Verificar se o usuário possui pendingInvoiceId
            if (dataCurrentSubscription?.pendingInvoiceId) {
              setModalPendingInvoices(true)
              onClose()
              return
            }
            const status = error?.response?.status
            if (status === 402) {
              setModalNoLicensedUsers(true)
              setModalInviteUser({
                isOpen: false,
                data: {
                  name: valuesSubmit.name,
                  email: valuesSubmit.email,
                  role: valuesSubmit.role
                }
              })
              return
            }

            showToast.error(t?.toast?.error)
          } finally {
            onClose()
          }
        }}
      >
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          showSkeleton={!isReady}
          {...form.formState.errors.name}
          {...t?.form?.name}
        />
        <RenderFormField
          control={form.control}
          name="email"
          type="input"
          showSkeleton={!isReady}
          {...form.formState.errors.email}
          {...t?.form?.email}
        />
        <RenderFormField
          control={form.control}
          name="role"
          type="select"
          showSkeleton={!isReady}
          {...form.formState.errors.role}
          {...t?.form?.role}
        />
        <RenderFormActions>
          <Button
            onClick={onClose}
            className="w-fit"
            variant="neutral"
            type="button"
            showSkeleton={!isReady}
          >
            {t?.form?.buttonCancel?.label}
          </Button>
          <Button
            className="w-fit"
            type="submit"
            loading={isLoadingCreateUserInvite}
            showSkeleton={!isReady}
          >
            {t?.form?.buttonSubmitSave?.label}
          </Button>
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalInviteUser
