import React from 'react'
import SectionDocumentTemplateEditor from '../SectionDocumentTemplateEditor'
import SectionSignDocumentArea from '../SectionSignDocumentArea'
import SectionUploadFileEnvelope from '../SectionUploadFileEnvelope'
import { useEnvelope } from '@/providers'

const SectionEnvelopeDocumentsManager: React.FC = () => {
  const { viewType, currentStep, hasReviewed } = useEnvelope()

  const renderSection = () => {
    if (viewType.type === 'template') {
      return (
        <SectionDocumentTemplateEditor id={viewType.templateId as string} />
      )
    }

    if (hasReviewed) {
      return <SectionSignDocumentArea />
    }

    if (currentStep.stepNumber <= 2) {
      return <SectionUploadFileEnvelope />
    }

    return <SectionSignDocumentArea />
  }

  return <>{renderSection()}</>
}

export default SectionEnvelopeDocumentsManager
