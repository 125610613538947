import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ArrowUpTrayIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import Image from 'next/image'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md'
import { toast } from 'react-toastify'
import useTranslation from '@/hooks/useTranslation'
import api from '@/services/api'
import { useAuth, useEnvelope, useLocale } from '@/providers/index'
import {
  ButtonWithTooltip,
  ConfirmPop,
  ModalEditInformationDocument,
  RenderFormActions
} from '@/molecules/index'
import {
  Divider,
  DropdownButton,
  IDropdownItem,
  LoadingSpinner,
  Typography
} from '@/atoms/index'
import SectionListTemplates from '../SectionListTemplates'
import { DocumentEnvelope } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

const SectionUploadFileEnvelope: React.FC = () => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false)
  const [documentIdEdit, setDocumentIdEdit] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { lang } = useLocale()

  const { t, isReady } = useTranslation('envelope')

  const {
    documents,
    setDocuments,
    setDocument,
    document,
    currentStep,
    handleActionStep,
    loadingEnvelopeSave,
    hasReviewed,
    handleSaveEnvelope,
    isCompactDevice
  } = useEnvelope()
  const { authMetadata, userContext } = useAuth()

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  const handleEditFileName = useCallback(
    (document: DocumentEnvelope) => {
      if (hasReviewed) {
        return
      }
      setDocumentIdEdit(document.id)
      setIsOpenModalEdit(true)
    },
    [hasReviewed]
  )

  const handleDocumentsChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (hasReviewed) {
        event.preventDefault()
        return
      }

      const uploadedDocuments = Array.from(event.target.files || [])

      if (documents.length + uploadedDocuments.length > 10) {
        showToast.error(t?.uploadArea?.errorMaxFiles)
        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }
        return
      }

      if (uploadedDocuments.length > 0) {
        const isValidFile = uploadedDocuments.every(
          (file) => file.type === 'application/pdf' && file.size <= 26214400 // 25MB
        )
        if (!isValidFile) {
          showToast.error(t?.uploadArea?.errorSizeFile)
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
          }
          return
        }

        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }

        setIsLoading(true)

        const uploadPromises = uploadedDocuments.map((file) => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('language', lang)
          formData.append('markupOrientation', 'Bottom')

          return api.post('/ecm/documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authMetadata?.accessToken}`,
              ...(userContext?.userAccountInformation?.id && {
                'X-Signater-UserAccountId':
                  userContext?.userAccountInformation?.id
              })
            }
          })
        })

        try {
          const responses = await toast.promise(Promise.all(uploadPromises), {
            pending: t?.toasts?.loadingUploadingFiles,
            success: t?.toasts?.successUploadingFiles,
            error: t?.toasts?.errorUploadingFiles
          })

          responses.forEach((response) => {
            setDocuments((prevState: any) => {
              if (!prevState) {
                return [
                  {
                    index: '1',
                    id: response.data.documentId,
                    name: response.data.name,
                    description: response.data.description,
                    pageSizes: response.data.pageSizes
                  }
                ]
              }
              const prevDocuments = prevState.filter(
                (file: any) => file.name !== response.data.name
              )

              const newDocuments = [
                ...prevDocuments,
                {
                  id: response.data.documentId,
                  name: response.data.name,
                  description: response.data.description,
                  pageSizes: response.data.pageSizes
                }
              ].map((file, index) => ({
                ...file,
                index: (index + 1).toString()
              }))

              return newDocuments
            })
          })
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [
      authMetadata?.accessToken,
      documents.length,
      hasReviewed,
      lang,
      setDocuments,
      t?.toasts?.errorUploadingFiles,
      t?.toasts?.loadingUploadingFiles,
      t?.toasts?.successUploadingFiles,
      t?.uploadArea?.errorMaxFiles,
      t?.uploadArea?.errorSizeFile,
      userContext?.userAccountInformation?.id
    ]
  )

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || hasReviewed) return

      const items = Array.from(documents)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)

      const updatedItems = items.map((file, index) => ({
        ...file,
        index: index
      }))

      setDocuments(updatedItems)
    },
    [documents, hasReviewed, setDocuments]
  )

  const handleRemoveFile = useCallback(
    (id: string) => {
      if (hasReviewed) {
        return
      }

      const newDocuments = documents.filter((file) => file.id !== id)

      const updatedDocuments = newDocuments.map((file, index) => ({
        ...file,
        index: index
      }))

      setDocuments(updatedDocuments)
    },
    [documents, hasReviewed, setDocuments]
  )

  const handleOnDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      if (hasReviewed) {
        e.preventDefault()
        return
      }

      e.preventDefault()

      const uploadedDocuments = Array.from(e.dataTransfer.files)

      const eventMock = {
        target: {
          files: uploadedDocuments
        }
      } as any

      await handleDocumentsChange(eventMock)
    },
    [handleDocumentsChange, hasReviewed]
  )

  const handleOnDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (hasReviewed) {
        return
      }
      e.preventDefault()
    },
    [hasReviewed]
  )

  const handleOnDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (hasReviewed) {
        return
      }
      e.preventDefault()
    },
    [hasReviewed]
  )

  useEffect(() => {
    if (documents?.[0]?.id && documents?.[0]?.id !== document?.id) {
      setDocument({
        id: documents?.[0]?.id,
        name: documents?.[0]?.name?.replace('.pdf', '') || '',
        privateDescription: documents?.[0]?.privateDescription || '',
        publicDescription: documents?.[0]?.publicDescription || '',
        pageSizes: documents?.[0]?.pageSizes || [],
        index: documents?.[0]?.index || 1
      })
    }
  }, [document?.id, document?.name, documents, setDocument])

  const dataDropdownItems: IDropdownItem[] =
    currentStepData?.action?.dropdownOptions?.map(
      (option: { key: string; label: string }) => ({
        key: option.key,
        label: option.label
      })
    )

  return (
    <>
      <div className="h-auto md:h-containerFull md:bg-gray-100 md:fixed md:overflow-y-scroll md:overflow-x-hidden md:right-0 md:shadow-insetUploadEnvelope w-full md:w-[calc(100%-375px)] lg:w-[calc(100%-475px)] xl:w-[calc(100%-560px)] md:pt-2 mb-8">
        <div
          className="flex flex-col h-full md:p-8"
          onDrop={handleOnDrop}
          onDragOver={handleOnDragOver}
          onDragEnter={handleOnDragEnter}
        >
          <label
            htmlFor={!hasReviewed ? 'fileInput' : undefined}
            className={cn(
              'flex flex-col items-center justify-center w-full gap-2 px-6 py-8 border border-dashed rounded-lg cursor-pointer border-secondary-200 hover:border-secondary-700 bg-secondary-50 dark:bg-gray-50 dark:border-gray-600 hover:dark:border-gray-950 transition-all duration-500',
              hasReviewed && 'cursor-not-allowed opacity-50'
            )}
            onClick={(e) => hasReviewed && e.preventDefault()}
          >
            {isLoading ? (
              <div className="h-[100px] flex items-center justify-center w-full">
                <LoadingSpinner />
              </div>
            ) : (
              !documents?.length && (
                <div className="flex items-center justify-center w-full rounded-sm">
                  <Image
                    src="/assets/icons/empty-no-documents.svg"
                    alt="Upload"
                    width={139.17}
                    height={85}
                  />
                </div>
              )
            )}
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={handleDocumentsChange}
              ref={inputFileRef}
              className="hidden"
              id="fileInput"
              disabled={hasReviewed}
            />
            <div className="flex flex-col items-center justify-center gap-2 mt-5">
              <Button
                variant="secondary"
                className="w-fit"
                onClick={() => inputFileRef.current?.click()}
                disabled={isLoading}
              >
                <ArrowUpTrayIcon className="w-5 h-5 text-white" />
                {t?.uploadArea?.buttonAddDocuments}
              </Button>
              <div className="flex items-center justify-center gap-4 w-fit">
                <div className="flex flex-col items-center">
                  <Typography
                    variant="title-lg-regular"
                    className="text-base text-center text-gray-950 font-display w-fit dark:text-gray-700"
                    showSkeleton={!isReady}
                  >
                    {t?.uploadArea?.labelDragDocuments}
                  </Typography>
                  <Typography
                    variant="text-sm-regular"
                    className="text-center text-gray-400 dark:text-gray-600 w-fit"
                    showSkeleton={!isReady}
                  >
                    {t?.uploadArea?.fileSize}
                  </Typography>
                </div>
              </div>
            </div>
            {documents.length > 0 && (
              <div className="w-full max-w-xs pt-4 sm:max-w-sm md:max-w-md lg:max-w-lg">
                {!hasReviewed && (
                  <ConfirmPop
                    title={t?.popConfirmDocumentAll?.title}
                    description={t?.popConfirmDocumentAll?.description}
                    confirmButtonText={
                      t?.popConfirmDocumentAll?.confirmButtonText
                    }
                    cancelButtonText={
                      t?.popConfirmDocumentAll?.cancelButtonText
                    }
                    onConfirm={() => {
                      setDocuments([])
                      if (inputFileRef.current) {
                        inputFileRef.current.value = ''
                      }
                    }}
                    disabled={hasReviewed}
                  >
                    <Button
                      variant="ghost"
                      className="py-2.5 bg-gray-300 hover:bg-gray-400 font-medium"
                      type="button"
                      showSkeleton={!isReady}
                      disabled={hasReviewed}
                    >
                      {t?.uploadArea?.removeAll}
                    </Button>
                  </ConfirmPop>
                )}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="documents">
                    {(provided) => (
                      <ul
                        className="w-full h-full mt-2 overflow-x-hidden md:max-h-40"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {documents.map((document, index) => (
                          <Draggable
                            key={document.id}
                            draggableId={`draggableId-${document.id}`}
                            index={index}
                            isDragDisabled={hasReviewed}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={cn(
                                  'flex items-center justify-between w-full gap-2 p-2 px-4 my-2 text-gray-700 bg-white dark:bg-gray-200 border border-gray-300 rounded-md lg:p-3',
                                  hasReviewed
                                    ? 'cursor-not-allowed opacity-50'
                                    : 'cursor-move'
                                )}
                              >
                                <div className="flex items-center flex-1 w-full h-auto min-w-0">
                                  <Typography
                                    variant="text-xs-medium"
                                    className="text-gray-700 dark:text-gray-700"
                                  >
                                    {index + 1}
                                  </Typography>
                                  <MdDragIndicator className="w-5 h-5 ml-2 text-gray-400" />
                                  <p className="flex-1 ml-2 overflow-hidden truncate">
                                    {document.name}
                                  </p>
                                </div>
                                <div className="flex flex-shrink-0 w-20 gap-2">
                                  <ButtonWithTooltip
                                    variant="ghost"
                                    disabled={hasReviewed}
                                    size="icon"
                                    type="button"
                                    tooltipText={
                                      t?.uploadArea?.editDocumentName
                                    }
                                    className="dark:bg-gray-300 hover:dark:bg-gray-400"
                                    onClick={() => handleEditFileName(document)}
                                  >
                                    <PencilSquareIcon className="w-5 h-5" />
                                  </ButtonWithTooltip>
                                  <ConfirmPop
                                    title={t?.popConfirmDocument?.title}
                                    description={
                                      t?.popConfirmDocument?.description
                                    }
                                    confirmButtonText={
                                      t?.popConfirmDocument?.confirmButtonText
                                    }
                                    cancelButtonText={
                                      t?.popConfirmDocument?.cancelButtonText
                                    }
                                    onConfirm={() => {
                                      handleRemoveFile(document.id)
                                    }}
                                    disabled={hasReviewed}
                                  >
                                    <ButtonWithTooltip
                                      variant="ghost"
                                      disabled={hasReviewed}
                                      size="icon"
                                      type="button"
                                      className="dark:bg-gray-300 hover:dark:bg-gray-400"
                                      tooltipText={
                                        t?.uploadArea?.removeDocument
                                      }
                                    >
                                      <TrashIcon className="w-5 h-5" />
                                    </ButtonWithTooltip>
                                  </ConfirmPop>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </label>
          <div className="py-5">
            <Divider text={t?.uploadArea?.or} />
          </div>
          <SectionListTemplates />
        </div>
        {isOpenModalEdit && (
          <ModalEditInformationDocument
            isOpen={isOpenModalEdit}
            setIsOpen={setIsOpenModalEdit}
            documentId={documentIdEdit as string}
          />
        )}
      </div>
      <RenderFormActions align="between" className="md:hidden">
        <Button
          onClick={() => {
            handleActionStep('previous')
          }}
          variant="neutral"
          className="w-fit"
        >
          {currentStepData?.buttonBack}
        </Button>
        <DropdownButton
          label={currentStepData?.action?.label}
          type="submit"
          dropdownItems={dataDropdownItems}
          loading={loadingEnvelopeSave}
          placement="top"
          onClick={(key, e) => {
            e.preventDefault()
            e.stopPropagation()
            if (key === 'save') {
              handleSaveEnvelope({
                publish: false
              })
              return
            }
            if (
              !documents.length ||
              (documents.length === 0 && isCompactDevice)
            ) {
              showToast.warning(t?.toasts?.noDocuments, {
                toastId: 'noDocuments'
              })
              return
            }
            if (key === 'main') {
              handleActionStep('next')
              return
            }
          }}
        />
      </RenderFormActions>
    </>
  )
}

export default SectionUploadFileEnvelope
