import { Dispatch } from 'react'
import { PlanType } from '@/types/global'
import { BillingCurrentSubscriptionResponse } from '@/hooks/api/billing/useBillingApi/useBillingApi.types'

export type Props = {
  children: React.ReactNode
}

export type RecurrenceType = 'Monthly' | 'Yearly' | null

export type RecurrencePlanType = {
  value: RecurrenceType
  label: string
  priceSuffix: string
  pricePrefix: string
  annualDisclaimer?: string
}

export type PlanItemType = {
  planId: string
  planType: PlanType
  emphasis?: boolean
  title: string
  description: string
  price: {
    value: number
    currency: 'BRL' | 'USD'
  }
  buttonCta: string
  planInactive: boolean
  recurrencePlan: RecurrencePlanType
  features: string[]
  recurrence: RecurrencePlanType // Omit
  recommendedLabel?: string
  badgeCurrentPlan?: string
  isCurrentPlan: boolean
  isPlanDeactivated: boolean
  subscriptionId?: string
  currentSubscription?: BillingCurrentSubscriptionResponse
  pricesAddons?: {
    apiAddon: number
    whiteLabelAddon: number
  }
}

export type CurrentPlanDataType = {
  hasActiveSubscription?: boolean
  recurrenceType?: RecurrenceType
  userSeatProductType?: string
  price?: number
} | null

export enum ModalType {
  MODAL_SELECT_FEATURES_BEFORE_PURCHASE = 'select-features-before-purchase',
  CONFIRM_TOGGLE_ACTIVE_API_ADDON = 'envelope-api-credit-addon',
  CONFIRM_TOGGLE_ACTIVE_WHITE_LABEL_ADDON = 'white-label-addon',
  CONFIRM_PLAN_CANCELLATION = 'confirm-plan-cancellation',
  CONFIRM_PLAN_RELEASE = 'confirm-plan-release',
  CONFIRM_PLAN_CHANGE_SUBSCRIPTION = 'confirm-plan-change-subscription',
  MODAL_PENDING_INVOICES = 'pending-invoices'
}

export enum DrawerType {
  DRAWER_CHANGE_LICENSED_USERS = 'change-licensed-users',
  DRAWER_SIGNER_MFA_CREDITS = 'signer-mfa-credits',
  DRAWER_ENVELOPE_API_CREDITS = 'envelope-api-credit'
}

export type ModalPlanType = {
  isOpen: boolean
  type: ModalType | null
  subscriptionId?: string | null
  planType?: PlanType | null
  planId?: string | null
  recurrence?: RecurrenceType
}

export type DataDrawerType = {
  isOpen: boolean
  type: DrawerType | null
  planType?: PlanType | null
}

export type ButtonLoadingType = {
  isLoading: boolean
  planId: string | null
  type: 'modalConfirm' | 'buttonCta' | null
}

export type PlansContextType = {
  currentRecurrencePlan: RecurrenceType
  setCurrentRecurrencePlan: Dispatch<RecurrenceType>
  isLoading: boolean
  plans: PlanItemType[]
  currentPlanData: CurrentPlanDataType | null
  dataCurrentSubscription?: BillingCurrentSubscriptionResponse | null
  addonToggles: ValuesToggles
  setAddonToggles: Dispatch<ValuesToggles>
  refetchCurrentSubscription: any
  refetchListAvailablePlans: any
  triggerDrawerTypeOpen: DrawerType | null
  setTriggerDrawerOpen: (newValue: DrawerType | null) => void
  dataModal: ModalPlanType
  setDataModal: Dispatch<ModalPlanType>
  dataDrawer: DataDrawerType
  setDataDrawer: Dispatch<DataDrawerType>
  resetModal: () => void
  isButtonLoading: ButtonLoadingType
  setIsButtonLoading: Dispatch<ButtonLoadingType>
  dataModalConfirm: {
    title: string
    description: string
    buttonCancel: string
    buttonConfirm: string
    onConfirm: () => void
    onCancel: () => void
  } | null
  getLinkInvoicePending: (id: string) => Promise<void>
  getLinkChangePaymentMethod: () => Promise<void>
}

export type ValuesToggles = {
  apiAddon: boolean | null
  whiteLabelAddon: boolean | null
}

export type AddonDetails = {
  price: string | null
  recurrence: Exclude<RecurrenceType, null>
  activationAvailable: boolean
  dateActivation: string | null
  proportionalPrice: string | null
  planDateValidUntil: string | null
}

export type AddonDetailsType = {
  apiAddon: AddonDetails
  whiteLabelAddon: AddonDetails
}
