import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useAuth, useEnvelope } from '@/providers/index'
import {
  StepItemDetailsEnvelope,
  StepItemSignatureMarkerEnvelope,
  StepItemSignersEnvelope,
  Steps,
  SectionUploadFileEnvelope
} from '..'
import { cn } from '@/lib/utils'
import { StepType } from '@/providers/Envelope/Envelope.types'
import { ModalErrorPublishEnvelope } from '@/ui/molecules'

const SectionStepsEnvelope: React.FC = () => {
  const { t } = useTranslation('envelope')
  const {
    currentStep,
    stepsOrder,
    modalConfirmErrorPublish,
    setModalConfirmErrorPublish,
    handleSaveEnvelope,
    loadingEnvelopeSave,
    setCurrentStep,
    isCompactDevice
  } = useEnvelope()
  const { isSandbox } = useAuth()
  const { push } = useRouter()

  const stepsContent = useMemo(
    () => ({
      [StepType.DETAILS]: <StepItemDetailsEnvelope />,
      [StepType.DOCUMENTS]: <SectionUploadFileEnvelope />,
      [StepType.SIGNERS]: <StepItemSignersEnvelope />,
      [StepType.SIGNATURE_MARKER]: <StepItemSignatureMarkerEnvelope />
    }),
    []
  )

  const stepsItems = useMemo(
    () =>
      stepsOrder.map((step, index) => ({
        id: index + 1,
        label: t?.steps?.[step]?.label
      })),
    [stepsOrder, t?.steps]
  )

  return (
    <div
      className={cn(
        'w-full md:w-[375px] lg:w-[475px] xl:w-[560px] flex flex-col gap-6 bg-accent-100 dark:bg-gray-50 overflow-y-scroll overflow-x-hidden fixed p-4',
        currentStep.stepName === StepType.SIGNATURE_MARKER &&
          isCompactDevice &&
          'px-0',
        isSandbox ? 'h-containerFullWithAlert' : 'h-containerFull'
      )}
    >
      <Steps
        steps={stepsItems}
        activeStep={currentStep.stepNumber}
        classNameContainer="gap-6"
      />
      <div className="flex flex-col justify-between flex-1 h-auto">
        {stepsContent?.[currentStep.stepName]}
      </div>
      <ModalErrorPublishEnvelope
        isOpen={modalConfirmErrorPublish.open}
        typeError={modalConfirmErrorPublish.typeError}
        onConfirm={() => {
          if (modalConfirmErrorPublish.typeError === 'Envelopes') {
            push('/billing/plans')
          }
          if (modalConfirmErrorPublish.typeError === 'SignerMfaCredits') {
            push('/billing/plans?trigger=signer-mfa-credits')
          }
          if (modalConfirmErrorPublish.typeError === 'ApiEnvelopes') {
            push('/billing/plans?trigger=envelope-api-credit')
          }
          if (
            modalConfirmErrorPublish.typeError === 'PublicationDateBeforeNow' ||
            modalConfirmErrorPublish.typeError === 'ExpirationDateBeforeNow'
          ) {
            // setShowAdvancedSettings(true)
            setCurrentStep({ stepNumber: 1, stepName: StepType.DETAILS })
          }
          if (modalConfirmErrorPublish.typeError === 'RemoveSchedule') {
            handleSaveEnvelope({
              publish: false,
              removeSchedule: true
            })
          }
          setModalConfirmErrorPublish({ open: false, typeError: null })
        }}
        onClose={() => {
          setModalConfirmErrorPublish({ open: false, typeError: null })
        }}
        loading={loadingEnvelopeSave}
      />
    </div>
  )
}

export default SectionStepsEnvelope
