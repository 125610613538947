import React, { useCallback } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useTranslation from '@/hooks/useTranslation'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { Input, TokenInput } from '@/atoms/index'
import Typography from '@/atoms/Typography'
import { cn } from '@/lib/utils'
import {
  MFAStatus,
  TStatusMFA
} from '@/providers/ReviewEnvelope/ReviewEnvelope.types'
import { Button } from '@/ui/atoms/shadcn'
import { IconWithTooltip } from '@/ui/molecules'

const SectionReviewValidationMfa: React.FC = () => {
  const { t } = useTranslation('reviewEnvelope')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    mfaValidationSchema,
    currentSigner,
    validateMFA,
    isValidateButtonDisabled,
    setMfaData,
    setMfaStatus,
    mfaData,
    isLoadingApproveEnvelope,
    isSandboxEnvelope,
    resendMFA
  } = useReviewEnvelope()

  return (
    <div className="flex justify-center w-full h-full px-4 py-8">
      <div className="w-full max-w-lg py-5 mx-auto bg-white shadow px-7 md:px-14 md:py-10 rounded-3xl dark:bg-black">
        <Typography variant="title-2xl-medium" className="text-center">
          {t?.sectionReviewValidationMfa?.title}
        </Typography>
        <Typography variant="text-sm-regular" className="mt-2 text-center">
          {t?.sectionReviewValidationMfa?.subtitle}
        </Typography>
        {mfaValidationSchema &&
          Object.keys(mfaValidationSchema).map((key) => {
            const mfaKey = key as keyof TStatusMFA

            if (
              (mfaKey === 'emailCode' &&
                !currentSigner?.shouldEnforceEmailValidation) ||
              (mfaKey === 'smsCode' &&
                !currentSigner?.shouldEnforceSmsValidation) ||
              (mfaKey === 'passcode' &&
                !currentSigner?.shouldEnforcePasscodeValidation)
            ) {
              return null
            }

            const validation = mfaValidationSchema?.[mfaKey]
            const isDisabled = mfaValidationSchema?.[mfaKey]?.isDisabled

            return (
              <div
                key={mfaKey}
                className={cn(
                  'flex flex-col py-5 gap-2 border-b border-gray-200',
                  validation?.isLastItem && 'border-b-0'
                )}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Typography
                        variant="text-sm-medium"
                        className="text-gray-700 dark:text-gray-700"
                      >
                        {validation?.title}
                      </Typography>
                      {validation?.tooltipInfo && (
                        <IconWithTooltip
                          icon={
                            <InformationCircleIcon className="w-4 h-4 text-gray-700" />
                          }
                          tooltipText={validation?.tooltipInfo}
                        />
                      )}
                    </div>
                    {mfaKey !== 'passcode' && (
                      <div className="flex items-center gap-1">
                        <Button
                          variant="link"
                          size="sm"
                          className="pr-0 underline bg-transparent border-none text-secondary hover:text-secondary-700 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 2xl:pr-0 hover:bg-transparent disabled:bg-transparent hover:disabled:underline"
                          onClick={async () => {
                            const recaptchaToken = executeRecaptcha
                              ? await executeRecaptcha(
                                  mfaKey === 'emailCode'
                                    ? 'signer_email_mfa_request'
                                    : 'signer_sms_mfa_request'
                                )
                              : ''

                            if (recaptchaToken) {
                              resendMFA(mfaKey, recaptchaToken)
                            }
                          }}
                          fullWidth
                          disabled={isDisabled}
                        >
                          {validation?.resend}
                        </Button>
                        {validation?.tooltipResent && (
                          <IconWithTooltip
                            icon={
                              <InformationCircleIcon className="w-4 h-4 ml-1 text-secondary hover:text-secondary-700" />
                            }
                            tooltipText={validation?.tooltipResent}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {validation?.type === 'token' ? (
                    <TokenInput
                      length={6}
                      autoFocus={validation?.autoFocus}
                      value={mfaData[mfaKey] || ''}
                      onChange={(value) => {
                        setMfaData((prev) => ({
                          ...prev,
                          [mfaKey]: value
                        }))
                        if (value.length < 6) {
                          setMfaStatus((prev) => ({
                            ...prev,
                            [mfaKey]: MFAStatus.Pending
                          }))
                        }
                        if (value.length === 6) {
                          setMfaStatus((prev) => ({
                            ...prev,
                            [mfaKey]: MFAStatus.Inserted
                          }))
                        }
                      }}
                    />
                  ) : (
                    <Input
                      autoFocus={validation?.autoFocus}
                      value={mfaData[mfaKey]}
                      onChange={(e) => {
                        setMfaData((prev) => ({
                          ...prev,
                          [mfaKey]: e.target.value
                        }))
                      }}
                      onBlur={(e) => {
                        setMfaData((prev) => ({
                          ...prev,
                          [mfaKey]: e.target.value
                        }))
                        setMfaStatus((prev) => ({
                          ...prev,
                          [mfaKey]: MFAStatus.Inserted
                        }))
                      }}
                    />
                  )}
                  {validation?.sendTo &&
                    mfaKey !== 'passcode' &&
                    !isSandboxEnvelope && (
                      <Typography
                        variant="text-xs-regular"
                        className="text-gray-700 dark:text-gray-700"
                      >
                        {validation?.sendTo}
                      </Typography>
                    )}

                  {!!validation?.passcodeHint && mfaKey === 'passcode' && (
                    <div className="">
                      <Typography
                        variant="text-xs-regular"
                        className="text-gray-700 dark:text-gray-700"
                      >
                        {validation?.passcodeHint || ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        <Button
          onClick={async () => {
            const recaptchaToken = executeRecaptcha
              ? await executeRecaptcha('signer_approval')
              : ''
            if (recaptchaToken) {
              validateMFA(recaptchaToken)
            }
          }}
          disabled={isValidateButtonDisabled}
          fullWidth
          className="mt-4"
          loading={isLoadingApproveEnvelope}
        >
          {t?.sectionReviewValidationMfa?.button}
        </Button>
      </div>
    </div>
  )
}

export default SectionReviewValidationMfa
