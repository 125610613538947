const generateColor = (value: string) => {
  let sum = 0

  for (let i = 0; i < value.length; i++) {
    sum += value.charCodeAt(i)
  }

  return '#' + ((sum * 1234567) & 0xffffff).toString(16).padEnd(6, '0')
}

const isColorDark = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness < 128
}

export { generateColor, isColorDark }
