import React from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  status: 'success' | 'error'
}

const SectionUnsubscribeFeedback: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('unsubscribe')
  const { push } = useRouter()

  const feedbackData = {
    success: t?.sectionFeedback?.success,
    error: t?.sectionFeedback?.error
  }[status]

  return (
    <div className="flex flex-col items-center w-full gap-6">
      <img
        src={feedbackData?.icon}
        alt={feedbackData?.title}
        className="w-28 md:w-36"
      />
      <div className="flex flex-col items-center gap-2">
        <Typography
          variant="title-2xl-medium"
          className="text-center text-gray-700"
        >
          {feedbackData?.title}
        </Typography>
        <Typography
          variant="text-base-regular"
          className="max-w-sm text-center text-gray-700 md:max-w-none"
        >
          {feedbackData?.subtitle}
        </Typography>
        <Button onClick={() => push('/')} className="w-full mt-4 md:w-60">
          {feedbackData?.button}
        </Button>
      </div>
    </div>
  )
}

export default SectionUnsubscribeFeedback
