import React, { useEffect, useState } from 'react'
import { useTemplate } from '@/providers/Template'
import { BubbleMenuEditor, MenuItemEditor } from '@/ui/atoms'
import * as Select from '@/ui/atoms/shadcn/Select'
import {
  optionsFontSize,
  optionsLineHeight,
  optionsFontFamily
} from '@/utils/editor/options/index'

const MenuTemplateEditor: React.FC = () => {
  const [fontFamily, setFontFamily] = useState('Arial')
  const [fontSize, setFontSize] = useState('12pt')
  const [lineHeight, setLineHeight] = useState('1.5')

  const { editor, items } = useTemplate()

  useEffect(() => {
    const updateFontSize = () => {
      if (editor) {
        const selection = editor.state.selection
        const attrs =
          editor.state.storedMarks ||
          (selection.$from.marks && selection.$from.marks()) ||
          []
        const currentFontSize = attrs.find(
          (attr) => attr.type.name === 'textStyle'
        )?.attrs.fontSize
        const currentLineHeight = attrs.find(
          (attr) => attr.type.name === 'textStyle'
        )?.attrs.lineHeight
        const currentFontFamily = attrs.find(
          (attr) => attr.type.name === 'textStyle'
        )?.attrs.fontFamily

        if (currentFontSize) {
          setFontSize(currentFontSize)
        }
        if (currentLineHeight) {
          setLineHeight(currentLineHeight)
        }
        if (currentFontFamily) {
          setFontFamily(currentFontFamily)
        }
      }
    }

    document.addEventListener('mousemove', updateFontSize)

    return () => {
      document.removeEventListener('mousemove', updateFontSize)
    }
  }, [editor])

  if (!editor) return null

  return (
    <>
      <div className="sticky z-10 flex flex-wrap items-center flex-none gap-1 p-1 bg-white top-1 w-fit drop-shadow-md rounded-xl">
        <Select.Select
          value={fontSize}
          onValueChange={(value) => {
            setFontSize(value)
            editor.chain().focus().setFontSize(value).run()
          }}
        >
          <Select.SelectTrigger className="w-fit min-w-8 h-9">
            <Select.SelectValue />
          </Select.SelectTrigger>
          <Select.SelectContent>
            <Select.SelectGroup>
              {optionsFontSize?.map((item: any) => {
                return (
                  <Select.SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </Select.SelectItem>
                )
              })}
            </Select.SelectGroup>
          </Select.SelectContent>
        </Select.Select>
        <Select.Select
          value={lineHeight}
          onValueChange={(value) => {
            setLineHeight(value)
            editor.chain().focus().setLineHeight(value).run()
          }}
        >
          <Select.SelectTrigger className="w-fit min-w-8 h-9">
            <Select.SelectValue />
          </Select.SelectTrigger>
          <Select.SelectContent>
            <Select.SelectGroup>
              {optionsLineHeight?.map((item: any) => {
                return (
                  <Select.SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </Select.SelectItem>
                )
              })}
            </Select.SelectGroup>
          </Select.SelectContent>
        </Select.Select>
        <Select.Select
          value={fontFamily}
          onValueChange={(value) => {
            setFontFamily(value)
            editor.chain().focus().setFontFamily(value).run()
          }}
        >
          <Select.SelectTrigger className="w-max min-w-8 h-9 ">
            <Select.SelectValue />
          </Select.SelectTrigger>
          <Select.SelectContent>
            <Select.SelectGroup>
              {optionsFontFamily?.map((item: any) => {
                return (
                  <Select.SelectItem
                    key={item.value}
                    value={item.value}
                    style={{ fontFamily: item.value }}
                  >
                    {item.label}
                  </Select.SelectItem>
                )
              })}
            </Select.SelectGroup>
          </Select.SelectContent>
        </Select.Select>
        {items?.map((item, index) => (
          <MenuItemEditor
            key={index}
            action={item.action as any}
            icon={item.icon}
            title={item.title as string}
            isActive={item.isActive}
            type={item.type}
            name={item.name as string}
          />
        ))}
      </div>
      <BubbleMenuEditor />
    </>
  )
}

export default MenuTemplateEditor
