import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useTemplate } from '@/providers/Template'
import useDocument from '@/hooks/api/ecm/useDocumentApi/useDocumentApi'
import useTemplateApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { cn } from '@/lib/utils'
import { useEnvelope, useLocale } from '@/providers'
import { DatePicker, Input, InputWithMask, Typography } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import {
  DrawerEnvelope,
  Form,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '@/ui/molecules'
import {
  FormItem,
  FormControl,
  FormMessage,
  FormField
} from '@/ui/molecules/shadcn'
import { formatDate } from '@/utils'

type Fields = {
  [key: string]: any
}

type Props = {
  id: string
}

const SectionDocumentTemplateFiller: React.FC<Props> = ({ id }) => {
  const { t, isReady } = useTranslation(['envelope', 'validations'], true)
  const [initialFieldsFilled, setInitialFieldsFilled] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { useGetTemplate } = useTemplateApi()
  const { useCreateDocumentWithTemplate } = useDocument()
  const { data: dataGetTemplate, isLoading: isLoadingGetTemplate } =
    useGetTemplate(id)
  const {
    currentValuesEnvelope,
    setViewType,
    setDocuments,
    documents,
    setValuesDocumentTemplateFiller
  } = useEnvelope()
  const {
    mutateAsync: mutateAsyncCreateDocumentWithTemplate,
    isLoading: isLoadingCreateDocumentWithTemplate
  } = useCreateDocumentWithTemplate()
  const { editor } = useTemplate()
  const { lang } = useLocale()

  const dataFields = useMemo(() => {
    if (dataGetTemplate?.id && dataGetTemplate?.fields) {
      const baseFieldsForm = dataGetTemplate?.fields.map((field) => {
        const type = {
          Text: 'input',
          Number: 'number',
          BrCep: 'input',
          BrCnpj: 'input',
          BrCpf: 'input',
          DateOnly: 'datepicker',
          TimeOnly: 'datepicker',
          DateTime: 'datepicker',
          Email: 'email',
          Custom: 'input'
        }

        const mask = {
          BrCep: '99999-999',
          BrCnpj: '99.999.999/9999-99',
          BrCpf: '999.999.999-99',
          Custom: field.customRegex
        }

        const fieldName = `field-${field.alias}`

        return {
          id: fieldName,
          name: fieldName,
          label: field.name,
          placeholder: field.name,
          type: type[field.type as keyof typeof type],
          typeField: field.type,
          required: field.isRequired,
          ...(field.type === 'Custom' &&
            field.customRegex && {
              patternRegex: mask[field.type as keyof typeof mask]
            }),
          ...((field.type === 'BrCep' ||
            field.type === 'BrCnpj' ||
            field.type === 'BrCpf') && {
            mask: mask[field.type as keyof typeof mask]
          }),
          ...(field.type === 'DateOnly' && {
            format: 'dd/MM/yyyy',
            onlyDate: true,
            noInitialDate: true
          }),
          ...(field.type === 'TimeOnly' && {
            format: 'HH:mm:ss',
            onlyTime: true,
            noInitialDate: true
          }),
          ...(field.type === 'DateTime' && {
            format: 'dd/MM/yyyy HH:mm:ss',
            noInitialDate: true
          })
        }
      })

      return [...baseFieldsForm, t?.documentTemplateFiller?.buttonForm].filter(
        Boolean
      )
    }
    return []
  }, [
    dataGetTemplate?.fields,
    dataGetTemplate?.id,
    t?.documentTemplateFiller?.buttonForm
  ])

  const generateValidationSchemaField = useCallback(
    (field: any) => {
      if (field?.type === 'input') {
        if (field?.type === 'Custom') {
          if (field?.required) {
            return z
              .string()
              .regex(new RegExp(field.customRegex), {
                message: t?.errorInvalidCustomField
              })
              .min(1, { message: t?.requiredField })
          }
          return z.string().regex(new RegExp(field.customRegex), {
            message: t?.errorInvalidCustomField
          })
        }

        if (field?.typeField === 'BrCpf') {
          if (field?.required) {
            return z
              .string()
              .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, {
                message: t?.brCpfInvalid
              })
              .min(1, { message: t?.requiredField })
          }
          return z.string().regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, {
            message: t?.brCpfInvalid
          })
        }
        if (field?.typeField === 'BrCnpj') {
          if (field?.required) {
            return z
              .string()
              .regex(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
                message: t?.brCnpjInvalid
              })
              .min(1, { message: t?.requiredField })
          }
          return z.string().regex(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
            message: t?.brCnpjInvalid
          })
        }
        if (field?.typeField === 'BrCep') {
          if (field?.required) {
            return z
              .string()
              .regex(/^\d{5}-\d{3}$/, { message: t?.brCepInvalid })
              .min(1, { message: t?.requiredField })
          }
          return z.string().regex(/^\d{5}-\d{3}$/, { message: t?.brCepInvalid })
        }
        if (field?.required) {
          return z
            .string({
              message: t?.requiredField
            })
            .min(1, { message: t?.requiredField })
        }
        return z.string()
      }
      if (field?.type === 'number') {
        if (field?.required) {
          return z
            .string()
            .regex(/^\d+$/, { message: t?.errorInvalidNumber })
            .min(1, { message: t?.requiredField })
        }
        return z.string().regex(/^\d+$/, { message: t?.errorInvalidNumber })
      }
      if (field?.type === 'email') {
        if (field?.required) {
          return z
            .string()
            .email(t?.errorInvalidEmail)
            .min(1, { message: t?.requiredField })
        }
        return z.string().email(t?.errorInvalidEmail)
      }
      if (field?.type === 'datepicker') {
        if (field?.required) {
          return z
            .date({
              required_error: 'Required error field',
              message: t?.errorInvalidDate
            })
            .refine((date) => !isNaN(date.getTime()), {
              message: t?.errorInvalidDate
            })
        }
        return z.date().refine((date) => !isNaN(date.getTime()), {
          message: t?.errorInvalidDate
        })
      }
      return z.string()
    },
    [
      t?.brCepInvalid,
      t?.brCnpjInvalid,
      t?.brCpfInvalid,
      t?.errorInvalidCustomField,
      t?.errorInvalidDate,
      t?.errorInvalidEmail,
      t?.errorInvalidNumber,
      t?.requiredField
    ]
  )

  const formSchema = useMemo(() => {
    if (dataFields.length) {
      const shapeFields = dataFields.reduce((acc, field) => {
        acc[field.name] = generateValidationSchemaField(field)
        return acc
      }, {})

      return z.object({
        name: z
          .string()
          .min(3, { message: t?.errorMinLength?.(3) })
          .max(200, { message: t?.errorMaxLength?.(200) })
          .min(1, { message: t?.requiredField }),
        privateDescription: z
          .string()
          .max(4000, { message: t?.errorMaxLength?.(4000) })
          .optional(),
        publicDescription: z
          .string()
          .max(4000, { message: t?.errorMaxLength?.(4000) })
          .optional(),
        ...shapeFields
      })
    }
    return z.object({
      name: z
        .string()
        .min(3, { message: t?.errorMinLength?.(3) })
        .max(200, { message: t?.errorMaxLength?.(200) })
        .min(1, { message: t?.requiredField }),
      privateDescription: z
        .string()
        .max(4000, { message: t?.errorMaxLength?.(4000) })
        .optional(),
      publicDescription: z
        .string()
        .max(4000, { message: t?.errorMaxLength?.(4000) })
        .optional()
    })
  }, [dataFields, generateValidationSchemaField, t])

  const defaultValues = useMemo(() => {
    const valuesDataFields = dataFields?.reduce((acc, field) => {
      acc[field.name] = field.type === 'datepicker' ? null : ''
      return acc
    }, {})
    return {
      name: '',
      privateDescription: '',
      publicDescription: '',
      language: currentValuesEnvelope?.language || 'PtBr',
      markupOrientation: 'Bottom',
      ...valuesDataFields
    }
  }, [currentValuesEnvelope?.language, dataFields])

  const form = useForm<any>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    let isMounted = true
    if (
      currentValuesEnvelope?.language &&
      !initialFieldsFilled &&
      t?.documentTemplateFiller
    ) {
      form.setValue('language', currentValuesEnvelope.language)
      form.setValue('markupOrientation', 'Bottom')
      if (isMounted) {
        setInitialFieldsFilled(true)
      }
    }

    return () => {
      isMounted = false
    }
  }, [
    currentValuesEnvelope,
    form,
    initialFieldsFilled,
    t?.documentTemplateFiller
  ])

  const showSkeleton = useMemo(
    () => !initialFieldsFilled || !isReady || isLoadingGetTemplate,
    [initialFieldsFilled, isLoadingGetTemplate, isReady]
  )

  return (
    <>
      <div
        className={cn(
          'w-full h-containerFull md:w-[400px] lg:w-[475px] xl:w-[560px] p-4 md:p-8 flex flex-col gap-8 md:overflow-y-scroll md:overflow-x-hidden bg-accent-100 dark:bg-gray-50 md:fixed'
        )}
      >
        <Form
          className="flex-1"
          {...form}
          onSubmit={async (valuesSubmit) => {
            const fields = Object.entries(valuesSubmit).reduce(
              (acc, [key, value]) => {
                if (key.startsWith('field-')) {
                  acc[key] = value
                }
                return acc
              },
              {} as Fields
            )

            try {
              const response = await mutateAsyncCreateDocumentWithTemplate({
                templateId: id,
                name: valuesSubmit.name,
                privateDescription: valuesSubmit.privateDescription,
                publicDescription: valuesSubmit.publicDescription,
                language: valuesSubmit.language,
                markupOrientation: valuesSubmit.markupOrientation,
                fields:
                  dataGetTemplate?.fields?.map((field) => {
                    const value = fields[`field-${field.alias}`]

                    return {
                      fieldId: field.id as string,
                      value: value
                    }
                  }) || []
              })

              if (response?.documentId) {
                const lastDocument = documents[documents.length - 1]
                setDocuments([
                  ...documents,
                  {
                    id: response.documentId,
                    name: response.name,
                    privateDescription: response.privateDescription,
                    publicDescription: response.publicDescription,
                    pageSizes: response.pageSizes,
                    index: lastDocument?.index ? lastDocument.index + 1 : 0
                  }
                ])
                setViewType({
                  type: 'envelope',
                  templateId: null
                })
                form.reset(defaultValues)
              }

              showToast.success(t?.toasts?.successCreateDocumentWithTemplate)
            } catch {
              showToast.error(t?.toasts?.errorCreateDocumentWithTemplate)
            }
          }}
        >
          <div className="flex flex-col flex-1 gap-4">
            <div className="flex flex-col gap-4">
              <Typography
                variant="text-base-medium"
                className="no-underline hover:no-underline"
                showSkeleton={showSkeleton}
              >
                {t?.documentTemplateFiller?.titleInformationDocument}
              </Typography>
              <div className="flex flex-col gap-4">
                <RenderFormRow columns={1}>
                  <RenderFormField
                    name="name"
                    type="input"
                    className="flex-none"
                    showSkeleton={showSkeleton}
                    {...t?.documentTemplateFiller?.form?.name}
                  />
                </RenderFormRow>
                <RenderFormRow columns={1}>
                  <RenderFormField
                    name="privateDescription"
                    type="textarea"
                    className="flex-none"
                    showSkeleton={showSkeleton}
                    {...t?.documentTemplateFiller?.form?.privateDescription}
                  />
                </RenderFormRow>
                <RenderFormRow columns={1}>
                  <RenderFormField
                    name="publicDescription"
                    type="textarea"
                    className="flex-none"
                    showSkeleton={showSkeleton}
                    {...t?.documentTemplateFiller?.form?.publicDescription}
                  />
                </RenderFormRow>
                <RenderFormRow columns={1}>
                  <RenderFormField
                    name="language"
                    type="select"
                    className="flex-none"
                    showSkeleton={showSkeleton}
                    {...t?.documentTemplateFiller?.form?.language}
                  />
                </RenderFormRow>
                <RenderFormRow columns={1}>
                  <RenderFormField
                    name="markupOrientation"
                    type="select"
                    className="flex-none"
                    showSkeleton={showSkeleton}
                    {...t?.documentTemplateFiller?.form?.markupOrientation}
                  />
                </RenderFormRow>
              </div>
            </div>
            <div className="flex flex-col h-full gap-5">
              <div className="flex flex-col flex-1 gap-5">
                {Boolean(dataFields?.length) && (
                  <>
                    <Typography
                      variant="text-base-medium"
                      className="no-underline hover:no-underline"
                      showSkeleton={showSkeleton}
                    >
                      {t?.documentTemplateFiller?.titleFillInFields}
                    </Typography>
                    {dataFields?.map((item: any) => {
                      return (
                        <FormField
                          control={form.control}
                          name={item.name}
                          render={({ field, fieldState }) => {
                            const errorMessage = fieldState.error?.message
                            const fieldName = item.name?.replace('field-', '')

                            const renderField = () => {
                              if (item.type === 'datepicker') {
                                return (
                                  <DatePicker
                                    {...field}
                                    {...item}
                                    onSelected={(date) => {
                                      if (editor?.commands) {
                                        const formatDatepicker =
                                          dataFields?.find(
                                            (item) => item.name === field.name
                                          )?.format
                                        const valueFormatted = {
                                          'dd/MM/yyyy': formatDate(
                                            date,
                                            'date',
                                            lang
                                          ),
                                          'HH:mm:ss': formatDate(
                                            date,
                                            'time',
                                            lang
                                          ),
                                          'dd/MM/yyyy HH:mm:ss': formatDate(
                                            date,
                                            'datetime',
                                            lang
                                          )
                                        }[
                                          formatDatepicker as
                                            | 'dd/MM/yyyy'
                                            | 'HH:mm:ss'
                                            | 'dd/MM/yyyy HH:mm:ss'
                                        ]

                                        setValuesDocumentTemplateFiller(
                                          (prev) => ({
                                            ...prev,
                                            [fieldName]: valueFormatted
                                          })
                                        )

                                        if (editor?.commands) {
                                          editor.commands.updateFieldValue(
                                            fieldName,
                                            valueFormatted
                                          )
                                        }
                                      }
                                    }}
                                    showSkeleton={showSkeleton}
                                    error={errorMessage}
                                  />
                                )
                              }
                              if (item.type === 'input' && !!item.mask) {
                                return (
                                  <InputWithMask
                                    {...field}
                                    {...item}
                                    customMask={item.mask}
                                    onChange={(e) => {
                                      form.setValue(
                                        field.name,
                                        e.target.value,
                                        {
                                          shouldValidate: true
                                        }
                                      )
                                      setValuesDocumentTemplateFiller(
                                        (prev) => ({
                                          ...prev,
                                          [fieldName]: e.target.value
                                        })
                                      )
                                      if (editor?.commands) {
                                        editor.commands.updateFieldValue(
                                          fieldName,
                                          e.target.value
                                        )
                                      }
                                    }}
                                    type={
                                      item.type === 'number' ? 'number' : 'text'
                                    }
                                    showSkeleton={showSkeleton}
                                    error={errorMessage}
                                  />
                                )
                              }
                              return (
                                <Input
                                  {...field}
                                  {...item}
                                  onChange={(e) => {
                                    form.setValue(field.name, e.target.value, {
                                      shouldValidate: true
                                    })
                                    setValuesDocumentTemplateFiller((prev) => ({
                                      ...prev,
                                      [fieldName]: e.target.value
                                    }))
                                    if (editor?.commands) {
                                      editor.commands.updateFieldValue(
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  }}
                                  type={
                                    item.type === 'number' ? 'number' : 'text'
                                  }
                                  showSkeleton={showSkeleton}
                                  error={errorMessage}
                                />
                              )
                            }
                            return (
                              <FormItem className={cn('flex-1')} id={item.name}>
                                <FormControl>{renderField()}</FormControl>
                                <FormMessage />
                              </FormItem>
                            )
                          }}
                          key={item?.id}
                        />
                      )
                    })}
                  </>
                )}
              </div>
              <RenderFormActions className="pb-4">
                <Button
                  onClick={() => {
                    setViewType({
                      type: 'envelope',
                      templateId: null
                    })
                    form.reset(defaultValues)
                  }}
                  className="w-fit"
                  variant="neutral"
                  type="button"
                >
                  {t?.documentTemplateFiller?.buttonCancel}
                </Button>
                <div className="flex items-center gap-2">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      setIsDrawerOpen(true)
                    }}
                    className="md:hidden"
                  >
                    {t?.documentTemplateFiller?.buttonPreviewDocument}
                  </Button>
                  <Button
                    type="submit"
                    loading={isLoadingCreateDocumentWithTemplate}
                  >
                    {t?.documentTemplateFiller?.buttonSubmit}
                  </Button>
                </div>
              </RenderFormActions>
            </div>
          </div>
        </Form>
      </div>
      <DrawerEnvelope
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  )
}

export default SectionDocumentTemplateFiller
