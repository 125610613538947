import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { LoggedUserAccountContext } from '@/types/global'
import {
  AuthMetadataRequest,
  AuthMetadataResponse,
  GetListUserAccountsResponse,
  LoginGoogleRequest,
  RefreshTokenResponseRequest,
  RegisterGoogleRequest,
  ValidateInviteResponse
} from '@/hooks/api/auth/useAuthApi/useAuthApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useAuthApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { authMetadata } = useAuth()

  const useRequestAuthMetadata = () =>
    useMutation({
      mutationFn: (data: AuthMetadataRequest) =>
        handleApi<AuthMetadataRequest, AuthMetadataResponse>(
          `/auth/login`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RequestAuthMetadata]
        })
      }
    })

  const useGetAuthContextByToken = () =>
    useMutation({
      mutationFn: (accessToken: string) =>
        handleApi<void, LoggedUserAccountContext>(
          `/auth/user-account-context`,
          'GET',
          undefined,
          {
            requireAuth: false,
            accessToken
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserContext]
        })
      }
    })

  const useRequestRefreshToken = () =>
    useMutation({
      mutationFn: (data: RefreshTokenResponseRequest) =>
        handleApi<RefreshTokenResponseRequest, AuthMetadataResponse>(
          `/auth/refresh`,
          'POST',
          data,
          {
            checkToken: true
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RequestAuthMetadata]
        })
      }
    })

  const useRegisterGoogle = () =>
    useMutation({
      mutationFn: (data: RegisterGoogleRequest) =>
        handleApi<RegisterGoogleRequest, AuthMetadataResponse>(
          `/auth/register/google`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RegisterGoogle]
        })
      }
    })

  const useLoginGoogle = () =>
    useMutation({
      mutationFn: (data: LoginGoogleRequest) =>
        handleApi<LoginGoogleRequest, AuthMetadataResponse>(
          `/auth/login/code`,
          'POST',
          data,
          {
            requireAuth: false
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.LoginGoogle]
        })
      }
    })

  const useGetListUserAccountsByToken = () =>
    useMutation({
      mutationFn: (accessToken: string) =>
        handleApi<void, GetListUserAccountsResponse>(
          `/auth/user-account-contexts`,
          'GET',
          undefined,
          {
            requireAuth: false,
            accessToken
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ListUserAccounts]
        })
      }
    })

  const useGetListUserAccounts = (enabled = true) =>
    useQuery({
      queryKey: [QueryKeys.ListUserAccounts, enabled],
      queryFn: () =>
        handleApi<void, GetListUserAccountsResponse>(
          `/auth/user-account-contexts`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && enabled
    })

  const useGetUserAccountContext = () =>
    useMutation({
      mutationFn: (accountId: string) =>
        handleApi<void, LoggedUserAccountContext>(
          `/auth/user-account-context`,
          'GET',
          undefined,
          {
            headers: {
              'X-Signater-UserAccountId': accountId
            }
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserContext]
        })
      }
    })

  const useGetValidateInvite = () =>
    useMutation({
      mutationFn: (inviteId: string) =>
        handleApi<void, ValidateInviteResponse>(
          `/auth/invite/${inviteId}/validate`,
          'GET',
          undefined,
          {
            requireAuth: false
          }
        )
    })

  return {
    useRequestAuthMetadata,
    useRequestRefreshToken,
    useGetAuthContextByToken,
    useRegisterGoogle,
    useLoginGoogle,
    useGetListUserAccountsByToken,
    useGetListUserAccounts,
    useGetUserAccountContext,
    useGetValidateInvite
  }
}

export default useAuthApi
