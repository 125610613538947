import React from 'react'
import { Modal } from '..'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  isOpen: boolean
  title: string
  description: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  onClose: () => void
  loadingSubmit?: boolean
  variantConfirmButton?:
    | 'default'
    | 'secondary'
    | 'destructive'
    | 'neutral'
    | 'ghost'
}

const ModalConfirm: React.FC<Props> = ({
  isOpen,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onClose,
  loadingSubmit,
  variantConfirmButton = 'destructive'
}) => {
  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} size="md">
      <div className="mt-2">
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div className="flex justify-end w-full gap-3 mt-4">
        <Button variant="neutral" size="sm" onClick={onClose}>
          {cancelButtonText}
        </Button>
        <Button
          variant={variantConfirmButton}
          size="sm"
          onClick={onConfirm}
          loading={loadingSubmit}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirm
