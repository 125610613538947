import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from '@tanstack/react-query'
import useTranslation from '@/hooks/useTranslation'
import { PlanType } from '@/types/global'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { BillingCurrentSubscriptionResponse } from '@/hooks/api/billing/useBillingApi/useBillingApi.types'
import { useAuth } from '@/providers'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'

type Props = {
  isOpen: boolean
  onClose: () => void
  onFinished?: (status: {
    success: boolean
    action: 'openModalPendingInvoices' | null
  }) => void
  dataCurrentSubscription: BillingCurrentSubscriptionResponse | null | undefined
  refetchCurrentSubscription: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<BillingCurrentSubscriptionResponse | null, unknown>
  >
}

const DrawerPurchaseUserLicenses: React.FC<Props> = ({
  isOpen,
  onClose,
  onFinished,
  dataCurrentSubscription,
  refetchCurrentSubscription
}) => {
  const { t, isReady } = useTranslation('usersPage')
  const { userContext, refreshProfile } = useAuth()
  const [count, setCount] = useState<number>(1)
  const [isMounted, setIsMounted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const planType = useMemo(
    () => dataCurrentSubscription?.userSeatProductType || 'One',
    [dataCurrentSubscription?.userSeatProductType]
  )
  const { useBillingUpdateUserSeats } = useBillingApi()
  const {
    mutateAsync: mutateAsyncBillingUpdateUserSeats,
    isLoading: isLoadingBillingUpdateUserSeats
  } = useBillingUpdateUserSeats()

  const data = useMemo(() => {
    const minUserSeatsPerPlanMapping = {
      One: 1,
      Pro: 5,
      Max: 10
    }

    const minUserSeatsPerPlan = minUserSeatsPerPlanMapping[planType as PlanType]
    const userSeatQuantity = dataCurrentSubscription?.userSeatQuantity ?? 0
    const { userSeatsLicensed, userSeatsBeingUsed } =
      userContext.balanceInformation

    const valueInitial = Math.max(
      minUserSeatsPerPlan,
      userSeatsLicensed + 1,
      userSeatQuantity + 1
    )
    const min = Math.max(minUserSeatsPerPlan, userSeatsBeingUsed + 1)

    return {
      step: 1,
      valueInitial: valueInitial,
      min: min
    }
  }, [
    dataCurrentSubscription?.userSeatQuantity,
    planType,
    userContext.balanceInformation
  ])

  useEffect(() => {
    if (!data?.valueInitial) {
      return
    }
    setCount(data?.valueInitial)
  }, [data?.valueInitial])

  const handleButtonSubmit = useCallback(async () => {
    if (count === dataCurrentSubscription?.userSeatQuantity) {
      onClose()
      return
    }

    if (!dataCurrentSubscription?.id) {
      return
    }

    setIsLoading(true)
    await mutateAsyncBillingUpdateUserSeats({
      id: dataCurrentSubscription?.id as string,
      userSeats: count
    }).then(async () => {
      if (!isMounted) return

      let attempts = 0
      const interval = setInterval(async () => {
        if (!isMounted) {
          clearInterval(interval)
          return
        }

        attempts++
        const responseCurrentSubscription = await refetchCurrentSubscription()
        if (
          responseCurrentSubscription?.data?.userSeatQuantity === count ||
          attempts >= 3
        ) {
          clearInterval(interval)
          if (responseCurrentSubscription?.data?.userSeatQuantity === count) {
            showToast.success(t?.toasts?.successBuyMoreLicensesUser)
            onFinished?.({
              success: true,
              action: null
            })
            refreshProfile()
            onClose()
            setIsLoading(false)
          } else {
            showToast.error(t?.toasts?.errorBuyMoreLicensesUser)
            onFinished?.({
              success: false,
              action: null
            })
            refreshProfile()
            onClose()
            setIsLoading(false)
          }
        }
      }, 3000)
    })
  }, [
    count,
    dataCurrentSubscription?.id,
    dataCurrentSubscription?.userSeatQuantity,
    isMounted,
    mutateAsyncBillingUpdateUserSeats,
    onClose,
    onFinished,
    refetchCurrentSubscription,
    refreshProfile,
    t?.toasts?.errorBuyMoreLicensesUser,
    t?.toasts?.successBuyMoreLicensesUser
  ])

  const onClick = useCallback(
    (action: 'decrement' | 'increment') => {
      let change = 0
      if (action === 'increment') {
        change = data.step
      } else if (action === 'decrement') {
        change = -data.step
        if (count + change < data?.min) {
          return
        }
      }

      setCount((prev) => Math.max(data?.min, prev + change))
    },
    [count, data?.step, data?.min]
  )

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = parseInt(e.target.value, 10)

    if (isNaN(inputVal)) {
      inputVal = 0
    }

    setCount(inputVal)
  }, [])

  const handleBlur = useCallback(() => {
    if (count < data?.min) {
      setCount(data?.min)
    }
  }, [count, data?.min])

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <Drawer.Drawer
      open={isOpen}
      onOpenChange={(boolean) => {
        if (!boolean) {
          onClose()
        }
      }}
    >
      <Drawer.DrawerContent>
        <div className="w-full max-w-sm mx-auto">
          <Drawer.DrawerHeader>
            {!isReady ? (
              <div className="w-40 h-6 skeleton"></div>
            ) : (
              <Drawer.DrawerTitle>
                {t?.drawerChangeLicensedUsers?.title}
              </Drawer.DrawerTitle>
            )}
            {!isReady ? (
              <div className="h-5 w-72 skeleton"></div>
            ) : (
              <Drawer.DrawerDescription>
                {t?.drawerChangeLicensedUsers?.description(data?.min - 1)}
              </Drawer.DrawerDescription>
            )}
          </Drawer.DrawerHeader>
          <div className="p-4 pb-0">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center space-x-2">
                {!isReady ? (
                  <>
                    <div className="w-10 h-10 rounded-full skeleton"></div>
                    <div className="w-20 h-20 skeleton"></div>
                    <div className="w-10 h-10 rounded-full skeleton"></div>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full shrink-0"
                      onClick={() => onClick('decrement')}
                      disabled={count <= data?.min}
                    >
                      <MinusIcon className="w-4 h-4" />
                      <span className="sr-only">Decrease</span>
                    </Button>
                    <div className="flex-1 text-center">
                      <input
                        type="number"
                        id="quantity-input"
                        className="bg-transparent border-0 text-center text-accent-900 font-bold tracking-tighter text-7xl focus:ring-transparent focus:ring-1 focus:outline-none focus:border-none block w-full py-2.5 px-1"
                        required
                        value={count}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <Button
                      variant="outline"
                      size="icon"
                      className="w-8 h-8 rounded-full shrink-0"
                      onClick={() => onClick('increment')}
                    >
                      <PlusIcon className="w-4 h-4" />
                      <span className="sr-only">Increase</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <Drawer.DrawerFooter>
            <Button
              onClick={handleButtonSubmit}
              loading={isLoadingBillingUpdateUserSeats || isLoading}
              showSkeleton={!isReady}
              fullWidth
            >
              {t?.drawerChangeLicensedUsers?.buttonConfirm}
            </Button>
            <Button
              variant="outline"
              onClick={onClose}
              showSkeleton={!isReady}
              fullWidth
            >
              {t?.drawerChangeLicensedUsers?.buttonCancel}
            </Button>
          </Drawer.DrawerFooter>
        </div>
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerPurchaseUserLicenses
