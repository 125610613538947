import React from 'react'
import { NodeSelection } from '@tiptap/pm/state'
import { BubbleMenu } from '@tiptap/react'
import useTranslation from '@/hooks/useTranslation'
import { useTemplate } from '@/providers/Template'
import { Button } from '../shadcn'
import { ActionFieldType } from '@/providers/Template/Template.types'

const BubbleMenuEditor = () => {
  const { t } = useTranslation('template')
  const { editor, setDataDrawerActionField } = useTemplate()

  if (!editor) {
    return null
  }

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100, zIndex: 10 }}
      shouldShow={(props) => {
        const state = editor.state
        const { from } = state.selection
        let selectedNode

        if (state.selection instanceof NodeSelection) {
          selectedNode = state.selection.node
        } else {
          const resolvedPos = state.doc.resolve(from)
          const node = resolvedPos.nodeAfter || resolvedPos.nodeBefore
          selectedNode = node
        }

        if (selectedNode) {
          if (
            selectedNode.type.name === 'field' ||
            selectedNode.type.name === 'image'
          ) {
            return false
          }
        }
        if (props.state.selection?.from === props.state.selection?.to) {
          return false
        }

        return true
      }}
    >
      <Button
        onClick={() => {
          const selectedText = editor.state.doc.textBetween(
            editor.state.selection.from,
            editor.state.selection.to,
            ' '
          )

          setDataDrawerActionField({
            open: true,
            action: ActionFieldType.CREATE_IN_EDITOR,
            alias: null,
            dataInitial: {
              name: selectedText
            }
          })
        }}
        size="sm"
      >
        {t?.bubbleMenu?.buttonCreateField}
      </Button>
    </BubbleMenu>
  )
}

export default BubbleMenuEditor
