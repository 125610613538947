import React, { Dispatch, SetStateAction } from 'react'
import { useContactsModalCreateSignerData } from '@/hooks/useContactsModalCreateSignerData'
import useTranslation from '@/hooks/useTranslation'
import { Table } from '@/organisms/index'
import { FilterFavorites, InputSearch } from '@/atoms/index'
import { EmptyStateTable, Modal } from '..'
import { Contact } from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { ModalActionContactType } from '@/hooks/useContactsData/useContactsData.types'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  isOpen: boolean
  onClose: () => void
  onAddContact: (contact: Contact) => void
  setModalActionContact: Dispatch<SetStateAction<ModalActionContactType>>
}

const ModalCreateSignerWithContact: React.FC<Props> = ({
  isOpen,
  onClose,
  onAddContact,
  setModalActionContact
}) => {
  const { t, isReady } = useTranslation('contacts')

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    isLoadingUserAccountContacts,
    handleChangeQueryFilter,
    handleToggleFavorite,
    filtersContacts
  } = useContactsModalCreateSignerData({ onAddContact, onClose })

  return (
    <Modal
      title={t?.titleModalCreateSignerWithContact}
      isOpen={isOpen}
      onClose={onClose}
      classesContainer="max-w-[98vw]"
      size="lg"
    >
      <div className="flex flex-col h-full gap-2">
        <div className="flex flex-col w-full gap-2 lg:items-end lg:flex-row">
          <div className="flex w-full gap-2">
            <div className="w-full">
              <InputSearch
                value={filtersContacts?.query || ''}
                onChange={(e) => handleChangeQueryFilter(e.target.value)}
                onClear={() => handleChangeQueryFilter(null)}
                placeholder={t?.filterQuery?.placeholder}
                label={t?.filterQuery?.label}
                showSkeleton={isLoadingUserAccountContacts || !isReady}
              />
            </div>
            <FilterFavorites
              label={t?.filterFavoritesLabel}
              typeFavorite={filtersContacts?.typeFavorite}
              onToggleFavorite={handleToggleFavorite}
              disabled={isLoadingUserAccountContacts}
              showSkeleton={isLoadingUserAccountContacts || !isReady}
            />
          </div>
          <Button
            variant="secondary"
            onClick={() => {
              setModalActionContact({
                isOpen: true,
                action: 'create',
                id: null
              })
            }}
            className="h-10 px-10 md:px-4"
            showSkeleton={isLoadingUserAccountContacts || !isReady}
          >
            {t?.buttonCreate}
          </Button>
        </div>

        {currentDataTable?.isLoading ||
        (currentDataTable?.isSuccess &&
          currentDataTable?.data &&
          currentDataTable?.data?.items?.length > 0) ? (
          <div className="min-h-[250px] h-full flex justify-center items-center">
            <Table<Contact>
              columns={columns}
              data={currentDataTable?.data?.items || []}
              fetchData={setPageNumber}
              canPreviousPage={pageNumber > 0}
              canNextPage={
                (currentDataTable?.data?.pagination &&
                  currentDataTable?.data?.pagination?.totalItems /
                    currentDataTable?.data?.pagination?.pageSize >
                    pageNumber + 1) ||
                false
              }
              pagination={currentDataTable?.data?.pagination}
              isLoading={currentDataTable?.isLoading}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        ) : (
          <EmptyStateTable type="contacts" />
        )}
      </div>
    </Modal>
  )
}

export default ModalCreateSignerWithContact
