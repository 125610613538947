import React from 'react'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { cn } from '@/lib/utils'
import { MarkType } from '@/ui/molecules/PdfViewer/PdfViewer.types'

type Props = {
  mark?: MarkType
  nextSignMark?: MarkType
  onNavigate: () => void
  firstMark: boolean
  scaleFactor?: number
}

const SignatureTourButton: React.FC<Props> = ({
  mark,
  nextSignMark,
  onNavigate,
  firstMark,
  scaleFactor
}) => {
  const { t } = useTranslation('reviewEnvelope')
  const { breakpoint } = useViewport()

  if (firstMark) {
    return (
      <button
        className="bg-primary text-accent-500 dark:text-accent-100 text-sm lg:text-base font-bold rounded-md h-[34px] lg:h-[46px] items-center justify-center border-none cursor-pointer px-2 z-20 hidden lg:flex"
        onClick={onNavigate}
        style={{
          position: 'absolute',
          left: breakpoint === 'lg' ? '-85px' : '-115px',
          top: '0px'
        }}
      >
        {t?.startSign}
        <div
          className="bg-primary absolute top-[5px] lg:top-[10px] right-auto -left-5 rotate-180  lg:left-auto lg:-right-6 lg:rotate-0"
          style={{
            width: '25px',
            height: '25px',
            clipPath: 'polygon(0% 0%, 80% 50%, 0% 100%, 0% 0%)'
          }}
        />
      </button>
    )
  }

  if (nextSignMark && mark && mark?.id === nextSignMark?.id) {
    return (
      <button
        className={cn(
          'bg-primary text-accent-500 dark:text-accent-100 text-sm lg:text-base font-bold rounded-md w-[65px] lg:w-[86px] h-[34px] lg:h-[46px] items-center justify-center opacity-0 px-2 hidden lg:flex',
          mark.order === nextSignMark.order ? 'opacity-100' : ''
        )}
        style={{
          position: 'absolute',
          left: breakpoint === 'lg' ? '-85px' : '-115px',
          top: scaleFactor ? `${nextSignMark.y * scaleFactor}px` : '0px'
        }}
        onClick={onNavigate}
      >
        {t.nextSign}
        <div
          className="bg-primary absolute top-[5px] lg:top-[10px] right-auto -left-5 rotate-180 lg:left-auto lg:-right-6 lg:rotate-0"
          style={{
            width: '25px',
            height: '25px',
            clipPath: 'polygon(0% 0%, 80% 50%, 0% 100%, 0% 0%)'
          }}
        />
      </button>
    )
  }
  return null
}

export default SignatureTourButton
