import React, { useMemo } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useViewport } from '@/hooks/useViewport'
import Typography from '@/atoms/Typography'
import { cn } from '@/lib/utils'

type StepItem = {
  id: number
  label: string
}

interface StepsProps {
  steps: StepItem[]
  activeStep: number
  classNameContainer?: string
}

const Steps: React.FC<StepsProps> = ({
  steps,
  activeStep,
  classNameContainer
}) => {
  const { breakpoint } = useViewport()

  const showOnlyActiveLabel = useMemo(() => {
    return (
      breakpoint === 'xs' ||
      breakpoint === 'sm' ||
      breakpoint === 'md' ||
      breakpoint === 'lg'
    )
  }, [breakpoint])

  return (
    <div
      className={cn(
        'flex items-center justify-center gap-3 pt-2 md:py-0',
        classNameContainer
      )}
    >
      {steps?.map(({ id, label }) => {
        const isActive = id === activeStep
        const isCompleted = Number(activeStep) > id

        return (
          <div
            key={id}
            className={`flex items-center gap-2 ${
              isCompleted ? 'opacity-80' : ''
            }`}
          >
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-lg ${
                isActive
                  ? 'bg-warning-500 text-white dark:text-black'
                  : isCompleted
                    ? 'bg-warning-300 text-white dark:text-black'
                    : 'bg-primary-100 dark:bg-primary-100 text-warning-500 dark:text-primary-700'
              }`}
            >
              {isCompleted ? <CheckIcon className="w-4 h-4" /> : id}
            </div>
            {(!showOnlyActiveLabel || isActive) && (
              <Typography
                variant="text-base-medium"
                className="text-gray-700 dark:text-gray-700"
              >
                {label}
              </Typography>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Steps
