import { useCallback, useMemo, useRef } from 'react'
import {
  useReactTable,
  Column,
  getPaginationRowModel,
  flexRender,
  getCoreRowModel,
  createColumnHelper
} from '@tanstack/react-table'
import useTranslation from '@/hooks/useTranslation'
import {
  CustomColumn,
  Pagination as PaginationType
} from '@/types/react-table-config'
import { Pagination } from '@/molecules/index'
import { Select } from '@/ui/atoms'

interface TableProps<T extends object> {
  columns: CustomColumn<T>[]
  data: T[]
  fetchData: (page: number) => void
  canPreviousPage: boolean
  canNextPage: boolean
  pageSize: number
  setPageSize: (pageSize: number) => void
  pageNumber: number
  setPageNumber: (pageNumber: number) => void
  pagination?: PaginationType
  isLoading?: boolean
}

function SkeletonRow({ columns }: { columns: number }) {
  return (
    <tr className="w-full">
      {Array.from({ length: columns }).map((_, index) => (
        <td key={index} className="px-3 py-2 text-xs whitespace-nowrap">
          <div className="w-full h-8 skeleton"></div>
        </td>
      ))}
    </tr>
  )
}

function Table<T extends object>({
  columns,
  data,
  fetchData,
  canPreviousPage,
  canNextPage,
  isLoading,
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
  pagination
}: TableProps<T>) {
  const { t, isReady } = useTranslation('table')

  const containerTableRef = useRef<HTMLDivElement>(null)

  const totalPages = Math.ceil((pagination?.totalItems || 0) / pageSize)

  const columnHelper = createColumnHelper<T>()
  const columnsFormatted = columns.map((column) => {
    return columnHelper.accessor(column.accessor, {
      id: column.accessor,
      cell: column.Cell,
      header: column.Header,
      meta: {
        width: column.width,
        minWidth: column.minWidth,
        noInternalPadding: column.noInternalPadding
      }
    })
  }) as unknown as Column<T>[]

  const table = useReactTable({
    columns: columnsFormatted,
    data,
    pageCount: totalPages,
    state: {
      pagination: {
        pageIndex: pageNumber,
        pageSize
      }
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const changePage = useCallback(
    (page: number) => {
      setPageNumber(page)
      fetchData(page)
    },
    [fetchData, setPageNumber]
  )

  const pageSizeOptions = useMemo(
    () => [
      { value: '5', label: '5' },
      { value: '10', label: '10' },
      { value: '20', label: '20' },
      { value: '50', label: '50' }
    ],
    []
  )

  return (
    <div className="flex flex-col w-full h-full gap-6" ref={containerTableRef}>
      <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-md">
        <table className="w-full divide-y divide-gray-200 table-auto dark:divide-gray-200">
          <thead>
            {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
              <tr key={`headerGroup-${headerGroupIndex}`}>
                {headerGroup.headers.map((header, headerIndex) => (
                  <th
                    key={`header-${headerGroupIndex}-${headerIndex}`}
                    colSpan={header.colSpan}
                    style={{
                      width: header.column.columnDef?.meta?.width,
                      minWidth: header.column.columnDef?.meta?.minWidth
                    }}
                    className={`text-left text-sm font-medium text-gray-500 py-3 px-1 ${
                      header.column.columnDef?.meta?.noInternalPadding
                        ? 'px-0'
                        : ''
                    }`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: pageSize }).map((_, index) => (
                  <SkeletonRow key={index} columns={columns.length} />
                ))
              : table.getRowModel().rows.map((row, rowIndex) => (
                  <tr
                    className="border-b border-gray-200"
                    key={`row-${rowIndex}`}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => (
                      <td
                        style={{
                          width: cell.column.columnDef?.meta?.width,
                          minWidth: cell.column.columnDef?.meta?.minWidth
                        }}
                        className={`whitespace-pre-wrap text-xs py-3 px-1 ${
                          cell.column.columnDef?.meta?.noInternalPadding
                            ? 'px-0'
                            : ''
                        }`}
                        key={`cell-${rowIndex}-${cellIndex}`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col-reverse items-center justify-between gap-4 mt-4 md:flex-row">
        <div className="flex justify-center w-full md:justify-start">
          <Select
            id="page-size"
            label={t?.pagination?.itemsPerLine}
            value={
              pageSizeOptions?.find(
                (option) => option.value === String(pageSize)
              )?.value || '10'
            }
            onChange={(value) => {
              setPageNumber(0)

              const valueNumber = Number(value)
              setPageSize(valueNumber || 0)
            }}
            options={pageSizeOptions}
            placeholder={t?.selectPlaceholder}
            size="sm"
            className="w-fit"
            showSkeleton={isLoading || !isReady}
            inline
          />
        </div>
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          setPageNumber={changePage}
          pageNumber={pageNumber}
          totalPages={totalPages}
          pageSize={pageSize}
          totalItems={pagination?.totalItems || 0}
          showSkeleton={isLoading || !isReady}
        />
      </div>
    </div>
  )
}

export default Table
