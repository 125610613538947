import React, { useCallback, useMemo, useRef, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {
  PencilIcon,
  XMarkIcon,
  ArrowUpTrayIcon
} from '@heroicons/react/24/solid'
import { CiKeyboard } from 'react-icons/ci'
import SignatureCanvas from 'react-signature-canvas'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { SignatureStyle, SignatureType } from '@/types/constants'
import { Checkbox, Select } from '@/atoms/index'
import { IconWithTooltip, Modal } from '..'
import { SuccessCallbackData } from '@/hooks/useSignaturesData/useSignaturesData.types'
import { cn } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  open: boolean
  onClose: () => void
  type: SignatureType.Rubric | SignatureType.Signature
  onSuccessCallback: (data: SuccessCallbackData) => void | Promise<void>
  hiddenSaveMyProfile?: boolean
  page?: 'profileSignaturesPage' | 'reviewEnvelopePage'
}

const optionsFonts = [
  { value: 'Dancing Script', label: 'Dancing Script' },
  { value: 'Indie Flower', label: 'Indie Flower' },
  { value: 'Alex Brush', label: 'Alex Brush' },
  { value: 'Fuggles', label: 'Fuggles' },
  { value: 'Kristi', label: 'Kristi' },
  { value: 'Tangerine', label: 'Tangerine' }
]

const ModalCreateSignature: React.FC<Props> = ({
  open,
  onClose,
  type,
  onSuccessCallback,
  hiddenSaveMyProfile = false,
  page = 'profileSignaturesPage'
}) => {
  const { t } = useTranslation('profileSignatures')
  const { breakpoint } = useViewport()
  const [isLoading, setIsLoading] = useState(false)

  const tabs = useMemo(() => {
    return [
      {
        id: SignatureStyle.Simulated,
        name: t?.modalSignature?.tabs?.typed,
        icon: CiKeyboard,
        current: true
      },
      {
        id: SignatureStyle.Draw,
        name: t?.modalSignature?.tabs?.drawn,
        icon: PencilIcon,
        current: false
      },
      {
        id: SignatureStyle.Upload,
        name: t?.modalSignature?.tabs?.uploaded,
        icon: ArrowUpTrayIcon
      }
    ]
  }, [
    t?.modalSignature?.tabs?.drawn,
    t?.modalSignature?.tabs?.typed,
    t?.modalSignature?.tabs?.uploaded
  ])

  const [current, setCurrent] = useState(tabs[0])
  const [signatureText, setSignatureText] = useState<string | null>(null)
  const [selectedFont, setSelectedFont] = useState<string>(
    optionsFonts?.[0]?.value
  )
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [valueCheckboxSaveMyProfile, setValueCheckboxSaveMyProfile] =
    useState(false)
  const sigCanvasRef = useRef<SignatureCanvas>(null)

  const clearCanvas = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear()
    }
  }

  const handleCloseReset = useCallback(() => {
    onClose()
    setSignatureText('')
    setUploadedImage(null)
    setCurrent(tabs[0])
    setValueCheckboxSaveMyProfile(false)
    clearCanvas()
  }, [onClose, tabs])

  const title = useMemo(() => {
    if (type === SignatureType.Rubric) {
      return t?.modalSignature?.titleRubric
    }

    if (type === SignatureType.Signature) {
      return t?.modalSignature?.titleSignature
    }
  }, [t?.modalSignature?.titleRubric, t?.modalSignature?.titleSignature, type])

  const handleSaveSignature = useCallback(
    async (
      signatureType: SignatureType.Rubric | SignatureType.Signature,
      signatureStyle:
        | SignatureStyle.Draw
        | SignatureStyle.Simulated
        | SignatureStyle.Upload,

      fontTypedSignature?: string,
      signatureTypedText = ''
    ) => {
      setIsLoading(true)

      if (signatureStyle === SignatureStyle.Draw && sigCanvasRef.current) {
        const signatureDataURL = sigCanvasRef.current
          .getTrimmedCanvas()
          .toDataURL('image/png')

        if (
          signatureType === SignatureType.Rubric ||
          signatureType === SignatureType.Signature
        ) {
          await onSuccessCallback({
            signatureType,
            signatureStyle,
            signatureImage: signatureDataURL,
            fontTypedSignature,
            saveMyProfile: valueCheckboxSaveMyProfile
          })
          handleCloseReset()
        }
      }

      if (signatureStyle === SignatureStyle.Simulated && signatureTypedText) {
        const canvas = document.createElement('canvas')
        const maxWidth = 200
        let fontSize = 36
        canvas.width = maxWidth

        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.font = `${fontSize}px ${selectedFont}, cursive`
          let textWidth = ctx.measureText(signatureTypedText).width

          while (textWidth > maxWidth && fontSize > 10) {
            fontSize--
            ctx.font = `${fontSize}px ${selectedFont}, cursive`
            textWidth = ctx.measureText(signatureTypedText).width
          }

          canvas.height = fontSize + 20
          ctx.font = `${fontSize}px ${selectedFont}, cursive`
          ctx.textBaseline = 'middle'
          ctx.textAlign = 'center'
          ctx.fillStyle = 'black'

          ctx.fillText(signatureTypedText, maxWidth / 2, canvas.height / 2)

          const dataUrl = canvas.toDataURL('image/png')

          await onSuccessCallback({
            signatureType,
            signatureStyle,
            signatureImage: dataUrl,
            signatureText: signatureTypedText || '',
            fontTypedSignature: optionsFonts
              ?.find((option) => option.value === selectedFont)
              ?.label?.replace(' ', ''),
            saveMyProfile: valueCheckboxSaveMyProfile
          })
          handleCloseReset()
        }
      }

      if (signatureStyle === SignatureStyle.Upload && uploadedImage) {
        await onSuccessCallback({
          signatureType,
          signatureStyle,
          signatureImage: uploadedImage,
          saveMyProfile: valueCheckboxSaveMyProfile
        })
        handleCloseReset()
      }

      setIsLoading(false)
    },
    [
      handleCloseReset,
      onSuccessCallback,
      selectedFont,
      uploadedImage,
      valueCheckboxSaveMyProfile
    ]
  )

  const handleImageUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          showToast.error(t?.toasts?.maxSize)
          return
        }

        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
        if (!allowedTypes.includes(file.type)) {
          showToast.error(t?.toasts?.formatInvalid)
          return
        }

        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          if (img.width > 800 || img.height > 300) {
            showToast.error(t?.toasts?.maxDimensions)
            return
          }

          const reader = new FileReader()
          reader.onloadend = () => {
            setUploadedImage(reader.result as string)
          }
          reader.readAsDataURL(file)
        }
      }
    },
    [t?.toasts?.formatInvalid, t?.toasts?.maxDimensions, t?.toasts?.maxSize]
  )

  const tabContent = useMemo(() => {
    const tabsContent = {
      [SignatureStyle.Simulated]: (
        <div className="relative flex items-center h-40 p-3 border border-gray-300 rounded-md shadow-sm">
          <div className="relative w-full mb-6 h-fit">
            <XMarkIcon className="absolute bottom-0.5 left-1 h-4 w-4 text-secondary-700 cursor-pointer" />
            <input
              onChange={(e) => {
                setSignatureText(e.target.value)
              }}
              value={signatureText || ''}
              className={`w-full border-transparent border-b border-b-gray-300 dark:border-b-gray-300 pl-6 pr-2 pt-2 pb-0 text-2xl leading-5 bg-transparent dark:bg-transparent text-black dark:text-white focus:border-transparent focus:outline-none focus:shadow-none focus:ring-0 focus:border-b focus:border-b-secondary-700 placeholder:font-body placeholder-gray-400 dark:placeholder-gray-400 placeholder:text-lg`}
              style={{ fontFamily: selectedFont + ', cursive' }}
              placeholder={t?.modalSignature?.placeholder}
            />
          </div>
          <div className="absolute flex justify-end gap-4 bottom-2 right-2">
            <Button variant="ghost" onClick={() => setSignatureText('')}>
              {t?.modalSignature?.clear}
            </Button>
            <Select
              id="select-font"
              value={selectedFont}
              onChange={setSelectedFont}
              placeholder={t?.modalSignature?.selectFont}
              options={optionsFonts}
              className="max-w-44 w-44"
              menuPlacement={
                breakpoint === 'xs' || breakpoint === 'sm' ? 'top' : 'bottom'
              }
            />
          </div>
        </div>
      ),
      [SignatureStyle.Draw]: (
        <div className="relative flex justify-center w-full h-40 mx-auto border border-gray-300 rounded-md">
          <SignatureCanvas
            ref={sigCanvasRef}
            backgroundColor="white"
            canvasProps={{
              className: 'w-full h-full absolute rounded-md'
            }}
          />
          <XMarkIcon className="absolute bottom-[58px] left-6 h-4 w-4 text-secondary-700 cursor-pointer" />
          <div className="w-11/12 h-[1px] absolute bottom-14 mx-auto block bg-gray-300"></div>
          <Button
            variant="ghost"
            className="absolute bottom-2 right-2"
            onClick={() => clearCanvas()}
          >
            {t?.modalSignature?.clear}
          </Button>
        </div>
      ),
      [SignatureStyle.Upload]: (
        <div className="relative flex items-center justify-center w-full h-40 border border-gray-300 rounded-md">
          {uploadedImage ? (
            <img
              src={uploadedImage}
              alt="Uploaded Signature"
              className="max-h-40"
            />
          ) : (
            <Button
              variant="link"
              className="underline"
              onClick={() => document.getElementById('file-upload')?.click()}
            >
              {t?.modalSignature?.upload}
            </Button>
          )}
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          {uploadedImage && (
            <Button
              variant="ghost"
              className="absolute bottom-2 right-2"
              onClick={() => setUploadedImage(null)}
            >
              {t?.modalSignature?.clear}
            </Button>
          )}
        </div>
      )
    } as any
    return tabsContent[current.id]
  }, [
    breakpoint,
    current.id,
    handleImageUpload,
    selectedFont,
    signatureText,
    t?.modalSignature?.clear,
    t?.modalSignature?.placeholder,
    t?.modalSignature?.selectFont,
    t?.modalSignature?.upload,
    uploadedImage
  ])

  return (
    <Modal
      isOpen={open}
      onClose={handleCloseReset}
      title={title}
      classesContainer="max-w-[98vw]"
    >
      <div className="flex flex-col gap-6 mt-2">
        <div className="flex">
          {tabs.map((tab, index) => (
            <a
              key={index}
              role="tab"
              onClick={() => {
                setCurrent(tab)
              }}
              className={cn(
                'px-4 py-2 flex items-center gap-x-2 text-sm font-medium cursor-pointer',
                tab.id === current.id
                  ? 'border-b-2 border-secondary-700 bg-secondary-100 dark:bg-black text-secondary-700 cursor-auto'
                  : 'text-gray-500'
              )}
            >
              <tab.icon
                className={cn(
                  tab.id === current.id
                    ? 'text-secondary-700'
                    : 'text-gray-500',
                  tab.id === SignatureStyle.Draw ? 'h-4 w-4' : 'h-5 w-5'
                )}
                aria-hidden="true"
              />
              {tab.name}
            </a>
          ))}
        </div>
        {tabContent}
        <div
          className={cn(
            'flex pt-3 mt-2 border-t border-gray-300',
            hiddenSaveMyProfile ? 'justify-end' : 'justify-between gap-4'
          )}
        >
          <div
            className={cn(
              'flex items-center gap-1',
              hiddenSaveMyProfile && 'hidden'
            )}
          >
            <Checkbox
              name="saveMyProfile"
              label={t?.modalSignature?.labelCheckboxSaveMyProfile}
              checked={valueCheckboxSaveMyProfile}
              onChange={(event) =>
                setValueCheckboxSaveMyProfile(event.target.checked)
              }
            />
            <IconWithTooltip
              icon={
                <InformationCircleIcon className="w-4 h-4 text-secondary" />
              }
              tooltipText={t?.modalSignature?.tooltipCheckboxSaveMyProfile}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button
              variant="neutral"
              onClick={() => {
                handleCloseReset()
                setCurrent(tabs[0])
              }}
            >
              {t?.modalSignature?.cancel}
            </Button>
            <Button
              onClick={async () => {
                await handleSaveSignature(
                  type as SignatureType.Rubric | SignatureType.Signature,
                  current.id,
                  selectedFont,
                  signatureText
                )
              }}
              loading={isLoading}
            >
              {page === 'profileSignaturesPage'
                ? t?.modalSignature?.save
                : t?.modalSignature?.apply}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCreateSignature
