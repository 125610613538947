import React, { useRef, useState } from 'react'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import Image from 'next/image'
import { CgSpinner } from 'react-icons/cg'
import useTranslation from '@/hooks/useTranslation'
import Typography from '../Typography'
import { showToast } from '@/ui/atoms/index'

type Props = {
  url: string | null
  loading: boolean
  onFileValidated: (
    file: File,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>
  onFileDeleted: () => Promise<void>
}

const UploadAvatar: React.FC<Props> = ({
  url,
  loading,
  onFileValidated,
  onFileDeleted
}) => {
  const { t } = useTranslation('uploadAvatar')
  const inputRef = useRef<HTMLInputElement>(null)

  const [loadingInternal, setLoadingInternal] = useState(false)

  const beforeUpload = (file: File) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    const isLessThan200KB = file.size / 1024 < 200

    if (!isJpgOrPng) {
      showToast.error(t?.toastFormatInvalid)
      setLoadingInternal(false)
      return false
    }
    if (!isLessThan200KB) {
      showToast.error(t?.toastMaxSize)
      setLoadingInternal(false)
      return false
    }

    return true
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingInternal(true)
    const file = event.target.files ? event.target.files[0] : null

    if (file && beforeUpload(file)) {
      onFileValidated(file, setLoadingInternal)
    }
  }

  const handleDelete = async () => {
    if (inputRef.current) inputRef.current.value = ''
    await onFileDeleted()
    setLoadingInternal(false)
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-32 h-32 bg-gray-200 rounded-full" />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <label
        className={`relative bg-gray-200 rounded-full border-2  border-gray-400 w-32 h-32 flex justify-center items-center cursor-pointer ${
          url ? 'bg-accent-100 dark:bg-accent-900' : 'border-dashed'
        }`}
      >
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
          ref={inputRef}
        />

        {url ? (
          <>
            <Image
              src={url}
              alt="Avatar"
              className="rounded-full"
              layout="fill"
            />
            <div className="absolute flex items-center justify-center w-32 h-32 transition-all rounded-full group bg-gray-500/20 md:bg-transparent md:hover:bg-gray-500/20">
              <div className="flex items-center gap-2">
                <button
                  className="p-2 text-gray-300 rounded-full cursor-pointer bg-gray-800/80 dark:bg-gray-800/80 md:text-transparent md:bg-transparent md:hover:bg-gray-600 md:group-hover:text-gray-300 md:group-hover:bg-gray-800"
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    handleDelete()
                  }}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
                <button
                  className="p-2 text-gray-300 rounded-full cursor-pointer bg-gray-800/80 md:text-transparent md:bg-transparent md:hover:bg-gray-600 md:group-hover:text-gray-300 md:group-hover:bg-gray-800"
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    inputRef.current?.click()
                  }}
                >
                  <PencilIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center">
            {loadingInternal || loading ? (
              <CgSpinner className="w-5 h-5 animate-spin" />
            ) : (
              <PlusIcon className="w-5 h-5 text-gray-500" />
            )}
            <Typography variant="text-sm-regular" className="text-gray-500">
              Upload
            </Typography>
          </div>
        )}
      </label>
    </div>
  )
}

export default UploadAvatar
