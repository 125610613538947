import React, { useCallback, useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  Form,
  Modal,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '..'
import useTemplateApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { showToast } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type RenameTemplateType = {
  name: string
  description?: string | null
}

type Props = {
  isOpen: boolean
  onClose: () => void
  id: string
}

const ModalRenameTemplate: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const { t, isReady } = useTranslation(['template', 'validations'], true)

  const { useRenameTemplate, useGetTemplate } = useTemplateApi()
  const {
    mutateAsync: mutateAsyncRenameTemplate,
    isLoading: isLoadingRenameTemplate
  } = useRenameTemplate()
  const { data: dataTemplate, isFetched: isFetchedGetTemplate } =
    useGetTemplate(id)

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z
      .string()
      .max(4000, { message: t?.errorMaxLength?.(4000) })
      .optional()
  })

  const form = useForm<RenameTemplateType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: ''
    }
  })

  useEffect(() => {
    if (id && dataTemplate?.name) {
      form.reset({
        name: dataTemplate.name,
        description: dataTemplate?.description || ''
      })
    }
  }, [dataTemplate?.description, dataTemplate?.name, form, id])

  const onSubmit = useCallback(
    async (values) => {
      try {
        await mutateAsyncRenameTemplate({
          id,
          name: values.name,
          description: values.description
        })
        onClose()
        showToast.success(t?.toasts?.successRenameTemplate)
      } catch (error) {
        showToast.error(t?.toasts?.errorRenameTemplate)
      }
    },
    [
      mutateAsyncRenameTemplate,
      id,
      t?.toasts?.errorRenameTemplate,
      t?.toasts?.successRenameTemplate,
      onClose
    ]
  )

  const showSkeleton = useMemo(
    () => !isReady || !isFetchedGetTemplate,
    [isFetchedGetTemplate, isReady]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t?.modalRenameTemplate?.title}
      displayAsDrawerOnMobile
    >
      <Form<RenameTemplateType> {...form} onSubmit={onSubmit}>
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          showSkeleton={showSkeleton}
          {...form.formState.errors.name}
          {...t?.modalRenameTemplate?.form?.name}
        />
        <RenderFormField
          control={form.control}
          name="description"
          type="textarea"
          showSkeleton={showSkeleton}
          {...form.formState.errors.description}
          {...t?.modalRenameTemplate?.form?.description}
        />
        <RenderFormActions>
          <Button
            onClick={() => {
              onClose()
              form.reset()
            }}
            className="w-fit"
            variant="neutral"
            type="button"
            showSkeleton={showSkeleton}
          >
            {t?.modalRenameTemplate?.form?.buttonCancel?.label}
          </Button>
          <Button
            className="w-fit"
            type="submit"
            showSkeleton={showSkeleton}
            loading={isLoadingRenameTemplate}
          >
            {t?.modalRenameTemplate?.form?.buttonSubmitSave?.label}
          </Button>
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalRenameTemplate
