import React from 'react'
import { cn } from '@/lib/utils'
import { useEnvelope, useLocale } from '@/providers'
import { Typography } from '@/ui/atoms'
import { formatData } from '@/utils'

type Props = {
  id: string
  name: string
  createdAtUtc: string
  handleClickCard: (id: string) => void
  tSectionListTemplates?: {
    createdIn: string
  }
}

const CardItemTemplate: React.FC<Props> = ({
  id,
  name,
  createdAtUtc,
  handleClickCard,
  tSectionListTemplates
}) => {
  const { lang } = useLocale()
  const { hasReviewed } = useEnvelope()

  return (
    <div
      key={id}
      className={cn(
        'flex flex-col gap-2 p-3 lg:p-4 transition-shadow bg-gray-100 md:bg-white md:dark:bg-gray-50 rounded-sm shadow cursor-pointer min-h-[50px] lg:min-h-[80px]',
        hasReviewed ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg'
      )}
      onClick={(e) => {
        if (hasReviewed) {
          e.preventDefault()
          return
        }
        handleClickCard(id)
      }}
    >
      <Typography
        variant="text-xs-regular"
        className="w-full break-words line-clamp-2"
      >
        {name}
      </Typography>
      <Typography
        variant="text-xs-regular"
        className="text-[10px] lg:text-[9px]"
      >
        {tSectionListTemplates?.createdIn} {formatData(createdAtUtc, lang)}
      </Typography>
    </div>
  )
}

export default CardItemTemplate
