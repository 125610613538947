import React, { useCallback, memo } from 'react'
import Image from 'next/image'
import { generateColor } from '@/utils/colors'
import { cn } from '@/lib/utils'
import * as AvatarShadcn from '@/ui/atoms/shadcn/Avatar'
import * as Tooltip from '@/ui/atoms/shadcn/Tooltip'

type Props = {
  name?: string
  value?: string
  size?: 'sm' | 'md' | 'lg'
  imgSrc?: string | null
  tooltipContent?: string
  className?: string
}

const Avatar: React.FC<Props> = memo(
  ({ name = '', value, size = 'md', imgSrc, tooltipContent, className }) => {
    const getInitials = useCallback((name: string) => {
      if (!name) return ''
      const nameParts = name.split(' ').filter(Boolean)
      const firstNameInitial = nameParts[0].charAt(0).toUpperCase()
      const lastNameInitial =
        nameParts.length > 1
          ? nameParts[nameParts.length - 1].charAt(0).toUpperCase()
          : ''
      return firstNameInitial + lastNameInitial
    }, [])

    const initials = getInitials(name || '')
    const bgColor = generateColor(initials)

    const styles = !value && !imgSrc ? { backgroundColor: bgColor } : {}

    const AvatarBase = () => (
      <AvatarShadcn.Avatar size={size}>
        {imgSrc && !value ? (
          <Image src={imgSrc} alt={name || 'Avatar'} layout="fill" />
        ) : initials && !value ? (
          <AvatarShadcn.AvatarFallback style={styles}>
            {initials}
          </AvatarShadcn.AvatarFallback>
        ) : value ? (
          <AvatarShadcn.AvatarFallback
            className={cn(
              'bg-gray-700 text-accent-100 dark:bg-gray-300',
              className
            )}
          >
            {value}
          </AvatarShadcn.AvatarFallback>
        ) : null}
      </AvatarShadcn.Avatar>
    )

    if (tooltipContent) {
      return (
        <Tooltip.TooltipProvider delayDuration={50}>
          <Tooltip.Tooltip>
            <Tooltip.TooltipTrigger>
              <AvatarBase />
            </Tooltip.TooltipTrigger>
            <Tooltip.TooltipContent className="text-left">
              {tooltipContent}
            </Tooltip.TooltipContent>
          </Tooltip.Tooltip>
        </Tooltip.TooltipProvider>
      )
    }

    return <AvatarBase />
  }
)

Avatar.displayName = 'Avatar'

export default Avatar
