import React, { useCallback, useMemo } from 'react'
import Link from 'next/link'
import useTranslation from '@/hooks/useTranslation'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { Checkbox } from '@/atoms/index'
import Typography from '@/atoms/Typography'
import { Modal } from '..'
import { Button } from '@/ui/atoms/shadcn'

const ModalAcceptTermsEnvelope: React.FC = () => {
  const { t, isReady } = useTranslation('reviewEnvelope')

  const {
    acceptTerms,
    setAcceptTerms,
    setOpenActionModal,
    openActionModal,
    currentSigner
  } = useReviewEnvelope()

  const handleCheckAcceptTerms = useCallback(() => {
    if (acceptTerms) {
      setOpenActionModal({
        open: false,
        type: null
      })
    }
  }, [setOpenActionModal, acceptTerms])

  const loading = useMemo(() => {
    if (!t?.modalAcceptTerms || !isReady || !currentSigner?.name) return true
    return false
  }, [currentSigner?.name, isReady, t?.modalAcceptTerms])

  const isOpen = useMemo(
    () => openActionModal.open && openActionModal.type === 'acceptTerms',
    [openActionModal]
  )

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-30 w-full h-full min-h-screen backdrop-blur-sm"></div>
      )}
      <Modal
        isOpen={isOpen}
        size="fit"
        hiddenHeader
        classesContainer="w-[380px] sm:w-[430px]"
      >
        <div className="flex flex-col items-center w-full gap-6 py-4">
          <div className="flex flex-col items-center w-full gap-2">
            <Typography
              variant="title-2xl-medium"
              className="text-center text-gray-700"
              showSkeleton={loading}
              classNameSkeleton="w-full"
            >
              {t?.modalAcceptTerms?.title?.(currentSigner?.name)}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-center text-gray-700"
              showSkeleton={loading}
              classNameSkeleton="w-2/3"
            >
              {t?.modalAcceptTerms?.subtitle}
            </Typography>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-8">
            <Checkbox
              checked={acceptTerms}
              onClick={() => setAcceptTerms(!acceptTerms)}
              name="accept-terms"
              showSkeleton={loading}
            >
              {t?.modalAcceptTerms?.prefix}{' '}
              <Link
                href="https://signater.com.br/juridico/termos-de-uso#termos-signatarios"
                passHref
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold text-secondary-700"
                >
                  {t?.modalAcceptTerms?.link}
                </a>
              </Link>
            </Checkbox>
            <Button
              onClick={handleCheckAcceptTerms}
              disabled={!acceptTerms}
              showSkeleton={loading}
              fullWidth
            >
              {t?.modalAcceptTerms?.button}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalAcceptTermsEnvelope
