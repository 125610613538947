import React, { ComponentProps, forwardRef } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import useTranslation from '@/hooks/useTranslation'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'

interface Props extends ComponentProps<'input'> {
  maskType?: 'cpf' | 'cnpj' | 'cep'
  customMask?: string | (string | RegExp)[]
  showPasswordToggle?: boolean
  error?: string
  label?: string
  showSkeleton?: boolean
}

const masks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  cep: '99999-999'
}

const InputWithMask = forwardRef<HTMLInputElement, Props>(
  function InputWithMask(
    {
      type = 'text',
      error,
      label,
      customMask,
      id,
      name,
      value,
      onChange,
      onBlur,
      disabled,
      maskType,
      showSkeleton,
      required = true,
      ...props
    },
    ref
  ) {
    const { t } = useTranslation('validations')

    const mask = disabled ? undefined : maskType ? masks[maskType] : customMask

    if (showSkeleton) {
      return (
        <div className="flex flex-col w-full gap-1">
          <div className="w-24 h-3 md:h-4 skeleton" />
          <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
        </div>
      )
    }

    return (
      <div
        className={cn(
          'relative w-full flex justify-end flex-col  gap-1',
          error && 'border-error-500 hover:border-error-500'
        )}
      >
        <Label htmlFor={id}>
          {label}
          {!required && label && (
            <span className="text-xs font-light leading-3">
              ({t?.optional})
            </span>
          )}
        </Label>
        <InputMask
          mask={mask || ''}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={props.placeholder}
        >
          {(inputProps: InputMaskProps) => (
            <input
              className={cn(
                'bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight block outline-none',
                error
                  ? 'border-error-500 dark:border-error-500 focus:border-error-500 dark:focus:border-error-500 focus:ring-error-500 dark:focus:ring-error-500 hover:border-error-500 dark:hover:border-error-500'
                  : 'hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700',
                disabled
                  ? 'opacity-50 cursor-not-allowed hover:border-gray-300 focus:border-gray-300'
                  : ''
              )}
              type={type}
              ref={ref}
              id={id}
              name={name}
              disabled={disabled}
              {...inputProps}
              {...props}
            />
          )}
        </InputMask>
        {error && (
          <div className="absolute bottom-0 right-0 flex items-center py-3 pr-3 pointer-events-none">
            <FaExclamationCircle
              className="w-4 h-4 text-error-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    )
  }
)

export default InputWithMask
