import React from 'react'
import PdfLoadingSkeleton from '../PdfLoadingSkeleton'
import { PdfViewDocument } from '../PdfViewDocument'
import { CurrentDocumentFileType } from '@/hooks/usePublicEnvelopeViewData/usePublicEnvelopeViewData.types'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'

type Props = {
  loadingPdf: boolean
  setLoadingPdf: React.Dispatch<React.SetStateAction<boolean>>
  currentDocumentFile: CurrentDocumentFileType
}

const SectionViewDocument: React.FC<Props> = ({
  loadingPdf,
  setLoadingPdf,
  currentDocumentFile
}) => {
  return (
    <div className="flex flex-col flex-1">
      {(loadingPdf || !currentDocumentFile) && (
        <div className="w-full">
          <PdfLoadingSkeleton pages={4} />
        </div>
      )}
      <div
        className={cn(
          'flex flex-col gap-2 items-center w-full',
          loadingPdf || !currentDocumentFile ? 'opacity-0' : 'opacity-100'
        )}
      >
        <div className="flex flex-col items-center">
          <Typography variant="text-base-medium" className="text-gray-800">
            {currentDocumentFile?.name?.replace('.pdf', '')}
          </Typography>
          {currentDocumentFile?.description && (
            <Typography variant="text-sm-regular" className="text-gray-800">
              {currentDocumentFile?.description}
            </Typography>
          )}
        </div>
        <PdfViewDocument
          file={currentDocumentFile?.file}
          pageSizes={currentDocumentFile?.pageSizes}
          setLoadingPdf={setLoadingPdf}
        />
      </div>
    </div>
  )
}

export default SectionViewDocument
