import React from 'react'
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { Modal } from '..'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

interface ModalHelpInfoProps {
  isOpen: boolean
  onClose: () => void
  onContinue?: () => void
  title: string
  description: string
  status: 'error' | 'alert' | 'info' | 'help'
  icon?: React.ReactNode
  continueButtonText?: string
}

const statusIcons = {
  error: <ExclamationCircleIcon className="w-8 h-8 text-red-500" />,
  alert: <ExclamationCircleIcon className="w-8 h-8 text-yellow-500" />,
  info: <InformationCircleIcon className="w-8 h-8 text-blue-500" />,
  help: <QuestionMarkCircleIcon className="w-8 h-8 text-blue-500" />
}

const ModalHelpInfo: React.FC<ModalHelpInfoProps> = ({
  isOpen,
  onClose,
  onContinue,
  title,
  description,
  status,
  continueButtonText = 'Continue',
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <div className="">
        <div className="flex items-center gap-2 mb-4">
          {/* {props.icon || statusIcons[status]} */}
          <Typography>{description}</Typography>
        </div>
        <div className="flex justify-end gap-2">
          <Button onClick={onContinue}>{continueButtonText}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalHelpInfo
