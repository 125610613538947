import React, { useState, useEffect, ReactNode, ErrorInfo } from 'react'
import * as Sentry from '@sentry/nextjs'

type Props = {
  children: ReactNode
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    const errorInfoFormatted = { componentStack: errorInfo.componentStack }
    Sentry.captureException(error, { extra: errorInfoFormatted })
    setHasError(true)
  }

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      handleError(event.error, { componentStack: '' })
    }

    window.addEventListener('error', errorHandler)
    return () => window.removeEventListener('error', errorHandler)
  }, [])

  if (hasError) {
    return <h1>Algo deu errado.</h1>
  }

  return <>{children}</>
}

export default ErrorBoundary
