import { useCallback, useEffect, useMemo, useState } from 'react'
import { MagnifyingGlassIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { cn } from '@/lib/utils'
import { Checkbox, Typography } from '@/ui/atoms'
import {
  Badge,
  Button,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/ui/atoms/shadcn'

export type OptionType = {
  value: string
  label: string
}

export type PropsType = {
  id: string
  filters: string
  onFilterChange: (filter: string) => void
  label?: string
  className?: string
}

const FilterVaults: React.FC<PropsType> = ({
  filters,
  onFilterChange,
  className,
  ...props
}) => {
  const { t } = useTranslation('filterDropdown')
  const [options, setOptions] = useState<OptionType[]>([])
  const [page, setPage] = useState(0)
  const [fetchPages, setFetchPages] = useState<number[]>([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [valueSearch, setValueSearch] = useState<string | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([])
  const [openFilter, setOpenFilter] = useState(false)
  const { useGetListVaultsSelect } = useVaultApi()
  const { getDataListVaults } = useGetListVaultsSelect()

  const fetchItems = useCallback(
    async (page: number) => {
      setFetchPages((prevPages) => [...prevPages, page])
      setLoading(true)
      const { items, pagination } = await getDataListVaults(
        valueSearch,
        page,
        10
      )
      const newOptions = items.map((vault) => ({
        value: vault.vaultId,
        label: vault.name
      }))

      setOptions((prevItems) =>
        page === 0 ? newOptions : [...prevItems, ...newOptions]
      )
      setHasMore(pagination?.totalItems > (page + 1) * 10)
      setLoading(false)
    },
    [getDataListVaults, valueSearch]
  )

  useEffect(() => {
    setOptions([])
    setFetchPages([])
    setPage(0)
    setHasMore(true)
  }, [valueSearch])

  useEffect(() => {
    if (fetchPages.includes(page)) return
    fetchItems(page)
  }, [page, fetchItems, fetchPages])

  useEffect(() => {
    const vaultsArray = filters?.split(',')
    if (vaultsArray) {
      setSelectedOptions(
        vaultsArray.map((vault) => {
          const label = options.find((option) => option.value === vault)?.label
          return {
            value: vault,
            label: label ?? vault
          }
        })
      )
    }
  }, [filters, options])

  const handleCheckboxChange = useCallback((option: OptionType) => {
    setSelectedOptions((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.value === option.value
      )
      if (isSelected) {
        return prevSelected.filter(
          (selected) => selected.value !== option.value
        )
      } else {
        return [...prevSelected, option]
      }
    })
  }, [])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const bottom =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
        e.currentTarget.clientHeight + 20

      if (bottom && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1)
      }
    },
    [hasMore, loading]
  )

  const handleClickReset = useCallback(() => {
    setSelectedOptions([])
  }, [])

  const handleClickConfirm = useCallback(() => {
    const vaultsArray = selectedOptions.map((option) => option.value)
    onFilterChange(vaultsArray.join(','))
    setOpenFilter(false)
  }, [onFilterChange, selectedOptions])

  const isEmpty = useMemo(
    () => options.length === 0 && !loading,
    [options, loading]
  )

  return (
    <div
      className={cn(
        'relative flex flex-col',
        props.label && 'gap-1',
        className
      )}
    >
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <div className="relative">
        <Popover
          onOpenChange={(open) => {
            setOpenFilter(open)
            if (open) {
              setPage(0)
              setOptions([])
              setFetchPages([])
              setHasMore(true)
            }
            if (!open) {
              setSelectedOptions([])
            }
          }}
          open={openFilter}
        >
          <PopoverTrigger asChild>
            <div className="flex items-center gap-2 bg-accent-100 text-accent-900 dark:bg-accent-500 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight outline-none cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700">
              <div className="flex items-center gap-2">
                <PlusCircledIcon className="w-4 h-4" />
                {t?.filterVaults?.title}
              </div>
              {!!selectedOptions?.length && (
                <Badge
                  variant="outlineSecondary"
                  size="xs"
                  className="font-normal rounded-sm"
                >
                  {t?.filterVaults?.selected(selectedOptions?.length)}
                </Badge>
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-[250px] p-0 z-60" align="start">
            <div className="flex items-center h-10 border-b border-gray-200">
              <MagnifyingGlassIcon
                className="absolute w-5 h-5 text-gray-400 pointer-events-none left-4"
                aria-hidden="true"
              />
              <input
                type="text"
                className="w-full h-10 pl-12 text-sm border-t-0 border-b border-l-0 border-r-0 rounded-t-sm outline-none border-b-gray-200 focus:ring-0 ring-0 hover:border-b-gray-200 focus:border-b-gray-200 placeholder:text-gray-400 dark:bg-accent-500"
                value={valueSearch ?? ''}
                onChange={(e) => setValueSearch(e.target.value)}
                placeholder={t?.filterVaults?.search}
              />
            </div>
            <div className="overflow-y-auto max-h-60" onScroll={handleScroll}>
              <ul>
                {options.map((item, index) => (
                  <label
                    key={index}
                    htmlFor={item.value.toString()}
                    className="flex items-center w-full px-2 py-1 cursor-pointer"
                  >
                    <Checkbox
                      name={item.value}
                      id={item.value.toString()}
                      variant="secondary"
                      checked={selectedOptions.some(
                        (selected) => selected.value === item.value
                      )}
                      onChange={() => handleCheckboxChange(item)}
                      label={item.label}
                    />
                  </label>
                ))}
              </ul>
              {loading && (
                <div className="flex flex-col gap-1 p-2">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <div className="flex items-center gap-1" key={index}>
                      <div className="w-4 h-4 skeleton" />
                      <div className="w-20 h-4 skeleton" />
                    </div>
                  ))}
                </div>
              )}
              {isEmpty && (
                <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
                  <div className="relative w-full h-full max-w-32">
                    <Image
                      src="/assets/icons/empty-state.svg"
                      alt="Folder Dashboard"
                      layout="fill"
                    />
                  </div>
                  <Typography variant="text-sm-regular" className="text-center">
                    {t?.filterVaults?.empty}
                  </Typography>
                </div>
              )}
            </div>
            <div
              className={cn(
                'flex justify-between items-center border-t border-gray-200 px-3 py-2'
              )}
            >
              <Button variant="neutral" size="sm" onClick={handleClickReset}>
                {t?.filterVaults?.clear}
              </Button>
              <Button size="sm" onClick={handleClickConfirm}>
                {t?.filterVaults?.confirm}
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}

export default FilterVaults
