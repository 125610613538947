import React from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import useTranslation from '@/hooks/useTranslation'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { SignatureType } from '@/types/constants'
import {
  DrawerReviewEnvelopeInfo,
  ModalAcceptTermsEnvelope,
  ModalCreateSignature,
  ModalListSignatures,
  ModalRejectEnvelope,
  PdfLoadingSkeleton,
  PdfSignDocument,
  SectionReviewEnvelopeInfo
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { cn } from '@/lib/utils'
import { useAuth } from '@/providers'
import { Button } from '@/ui/atoms/shadcn'

const SectionReviewSignDocument: React.FC = () => {
  const { t, isReady } = useTranslation('reviewEnvelope')
  const { isLogged } = useAuth()

  const {
    loadingPdf,
    signHandler,
    openActionModal,
    setOpenActionModal,
    onDialogClose,
    activeMark,
    nextSignMark,
    navigateToNextSignMark,
    documents,
    setIsDrawerOpen,
    isLoadingApproveEnvelope,
    alreadySigned,
    marksRefs,
    setTourOrderId
  } = useReviewEnvelope()

  return (
    <div className="relative flex justify-center w-full h-full gap-5 px-4 py-8 mx-auto md:max-w-screen-xl pb-28">
      <div className="relative flex flex-col flex-1 w-full basis-2/4 md:basis-3/5 md:flex">
        {loadingPdf && (
          <div className="w-full">
            <PdfLoadingSkeleton pages={4} />
          </div>
        )}
        {documents?.map((document, index) => (
          <div
            className={cn(
              'flex flex-col gap-2 items-center w-full',
              loadingPdf ? 'opacity-0' : 'opacity-100',
              index !== 0 && 'mt-8'
            )}
            key={document.id}
          >
            <div className="flex flex-col items-center">
              <Typography variant="text-base-medium" className="text-gray-800">
                {document?.name?.replace('.pdf', '') ||
                  document?.fileName?.replace('.pdf', '')}
              </Typography>
              {document?.description && (
                <Typography variant="text-sm-regular" className="text-gray-800">
                  {document?.description}
                </Typography>
              )}
            </div>
            <PdfSignDocument documentId={document.id} />
          </div>
        ))}
      </div>
      <div className="hidden basis-2/4 md:basis-2/5 md:flex">
        <SectionReviewEnvelopeInfo />
      </div>
      <div className="fixed bottom-0 z-50 flex justify-center w-full gap-4 p-6 bg-white border-t border-gray-200 shadow-sm md:gap-8 dark:bg-black">
        <Button
          variant="outlineDestructive"
          size="lg"
          onClick={() =>
            setOpenActionModal({
              open: true,
              type: 'rejectEnvelope'
            })
          }
          className="px-4 md:px-16 lg:px-16"
          showSkeleton={!isReady}
        >
          {t?.buttonReject}
        </Button>
        {nextSignMark ? (
          <Button
            size="lg"
            onClick={() => {
              if (!alreadySigned && nextSignMark && marksRefs) {
                const markRef = marksRefs.current?.[
                  nextSignMark.id as string
                ] as any
                if (markRef) {
                  if (setTourOrderId) {
                    setTourOrderId(1)
                  }
                  markRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                  })
                }
                return
              }
              navigateToNextSignMark()
            }}
            className="w-full px-16 cursor-pointer md:w-fit md:px-16 lg:px-16"
            showSkeleton={!isReady}
          >
            {nextSignMark && !alreadySigned ? t?.buttonStart : t?.buttonNext}
          </Button>
        ) : (
          <Button
            size="lg"
            onClick={signHandler}
            loading={isLoadingApproveEnvelope}
            className="px-5 cursor-pointer sm:px-8 md:px-16 lg:px-16"
            showSkeleton={!isReady}
          >
            {t?.buttonAccept}
          </Button>
        )}
        <Button
          variant="outlineSecondary"
          size="lg"
          onClick={() => setIsDrawerOpen(true)}
          className="px-2 py-2 sm:pr-4 md:hidden"
          childClassName="gap-1"
          showSkeleton={!isReady}
        >
          <EllipsisVerticalIcon className="w-6 h-6" />
          <span className="hidden sm:flex">{t?.buttonOptions}</span>
        </Button>
      </div>
      <ModalRejectEnvelope />
      <ModalAcceptTermsEnvelope />
      <ModalListSignatures
        open={
          openActionModal.open &&
          (openActionModal.type === 'signatureList' ||
            openActionModal.type === 'rubricList')
        }
        onClose={() => setOpenActionModal({ open: false, type: null })}
        type={openActionModal.type as 'signatureList' | 'rubricList'}
        onAddSignature={onDialogClose}
        onHandleClickCancel={(type) => {
          setOpenActionModal({
            open: true,
            type: type === 'signature' ? 'createSignature' : 'createRubric'
          })
        }}
      />
      <ModalCreateSignature
        open={
          openActionModal.open &&
          (openActionModal.type === 'createSignature' ||
            openActionModal.type === 'createRubric')
        }
        onClose={() => setOpenActionModal({ open: false, type: null })}
        type={
          activeMark?.type as SignatureType.Rubric | SignatureType.Signature
        }
        onSuccessCallback={onDialogClose}
        hiddenSaveMyProfile={!isLogged}
        page="reviewEnvelopePage"
      />
      <DrawerReviewEnvelopeInfo />
    </div>
  )
}

export default SectionReviewSignDocument
