import React from 'react'
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { Draggable } from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md'
import useTranslation from '@/hooks/useTranslation'
import { useTemplate } from '@/providers/Template'
import ButtonWithTooltip from '../ButtonWithTooltip'
import ConfirmPop from '../ConfirmPop'
import { FieldType } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { ActionFieldType } from '@/providers/Template/Template.types'
import { Typography } from '@/ui/atoms'
import * as Tooltip from '@/ui/atoms/shadcn/Tooltip'

type Props = {
  field: FieldType
  index: number
}

const ItemFieldTemplate: React.FC<Props> = ({ field, index }) => {
  const { t } = useTranslation('template')

  const { handleInsertField, setDataDrawerActionField, setDataModalConfirm } =
    useTemplate()

  return (
    <Draggable draggableId={`${field.alias}`} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="px-6 py-4 overflow-hidden bg-white border rounded-md shadow-md border-gray-50 group"
        >
          <div className="flex items-center justify-between space-x-2">
            <div className="flex items-center flex-1 min-w-0 gap-1">
              <div className="flex items-center h-auto">
                <Typography
                  variant="text-xs-medium"
                  className="text-gray-700 dark:text-gray-700"
                >
                  {index + 1}
                </Typography>
                <MdDragIndicator className="w-5 h-5 text-gray-400" />
              </div>
              <Typography variant="text-base-regular">
                {field.name}{' '}
                <Tooltip.TooltipProvider delayDuration={50}>
                  <Tooltip.Tooltip>
                    <Tooltip.TooltipTrigger asChild>
                      <span className="font-bold">
                        {field.isRequired && '*'}
                      </span>
                    </Tooltip.TooltipTrigger>
                    <Tooltip.TooltipContent>
                      {t?.tooltip?.requiredField}
                    </Tooltip.TooltipContent>
                  </Tooltip.Tooltip>
                </Tooltip.TooltipProvider>
              </Typography>
              <Typography
                variant="text-sm-regular"
                className="text-gray-500 truncate"
              >
                {field.description}
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <ButtonWithTooltip
                variant="ghost"
                size="icon"
                tooltipText={t?.listFieldsTooltip?.delete}
                type="button"
                onClick={() =>
                  setDataModalConfirm({
                    action: 'delete',
                    open: true,
                    id: field.alias
                  })
                }
              >
                <TrashIcon className="w-4 h-4" />
              </ButtonWithTooltip>
              <ButtonWithTooltip
                variant="ghost"
                size="icon"
                tooltipText={t?.listFieldsTooltip?.edit}
                onClick={() => {
                  setDataDrawerActionField({
                    open: true,
                    action: ActionFieldType.EDIT,
                    alias: field.alias
                  })
                }}
                type="button"
              >
                <PencilSquareIcon className="w-4 h-4" />
              </ButtonWithTooltip>
              <ButtonWithTooltip
                variant="ghost"
                size="icon"
                tooltipText={t?.listFieldsTooltip?.add}
                onClick={() => {
                  handleInsertField(field.alias)
                }}
                type="button"
              >
                <DocumentPlusIcon className="w-4 h-4" />
              </ButtonWithTooltip>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  )
}

export default ItemFieldTemplate
