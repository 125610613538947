import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  ActionFavoriteVault,
  CanDeleteVaultResponse,
  CreateVaultRequest,
  CreateVaultResponse,
  FilterVaultsType,
  Owners,
  RenameVaultRequest,
  RenameVaultRequestWithId,
  UpdateVaultRequest,
  UpdateVaultRequestWithId,
  VaultMembersResponse,
  VaultResponse,
  VaultsAccountsResponse,
  VaultsListResponse
} from '@/hooks/api/ecm/useVaultApi/useVaultApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useVaultApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()
  const queryClient = useQueryClient()

  const useVaultOwners = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.VaultsOwners],
      queryFn: () => handleApi<void, Owners>('/ecm/vaults/owners', 'GET'),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useUserAccountVaults = (
    filterVault: FilterVaultsType,
    id: string | null,
    pageNumber = 0,
    pageSize = 10,
    enabled = true
  ) => {
    const url = id
      ? `/ecm/vaults/user-accounts/${id}`
      : `/ecm/vaults/user-accounts`
    const { query, typeFavorite } = filterVault

    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (query) {
      queryParams += `&search=${encodeURIComponent(query)}`
    }
    if (typeFavorite !== null) {
      queryParams += `&isFavorite=${typeFavorite === 'favorite'}`
    }

    const queryKey = [
      QueryKeys.UserAccountVaults,
      id,
      pageNumber,
      pageSize,
      query,
      typeFavorite
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedQuery(query)
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }, [query])

    const { error, isLoading, isFetching, ...rest } = useQuery({
      queryKey,
      queryFn: () =>
        handleApi<void, VaultsAccountsResponse>(url + queryParams, 'GET'),
      ...queryConfig,
      enabled:
        !!authMetadata?.accessToken &&
        (debouncedQuery === query || !query) &&
        enabled
    })

    return {
      error,
      isLoading: isLoading || isFetching,
      isFetching,
      ...rest
    }
  }

  const useGetListVaultsSelect = () => {
    const queryClient = useQueryClient()

    const getDataListVaults = async (
      query: string | null,
      pageNumber = 0,
      pageSize = 10
    ) => {
      let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
      if (query) {
        queryParams += `&search=${encodeURIComponent(query)}`
      }

      return queryClient.fetchQuery<VaultsAccountsResponse>(
        [QueryKeys.ListVaultsSelect, pageNumber, pageSize, query],
        async () => {
          const response = await handleApi<void, VaultsAccountsResponse>(
            '/ecm/vaults/user-accounts' + queryParams,
            'GET'
          )

          if (!response) {
            throw new Error('No data found')
          }

          return response
        }
      )
    }

    return { getDataListVaults }
  }

  const useAllAccountUsersVaults = (
    filterVault: FilterVaultsType,
    pageNumber = 0,
    pageSize = 10,
    enabled = true
  ) => {
    const url = `/ecm/vaults/accounts`
    const { query, typeFavorite } = filterVault

    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (query) {
      queryParams += `&search=${encodeURIComponent(query)}`
    }
    if (typeFavorite !== null) {
      queryParams += `&isFavorite=${typeFavorite === 'favorite'}`
    }

    const queryKey = [
      QueryKeys.AllAccountUsersVaults,
      pageNumber,
      pageSize,
      query,
      typeFavorite
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedQuery(query)
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }, [query])

    const { data, error, isLoading, isSuccess, isError, isFetching } = useQuery(
      {
        queryKey,
        queryFn: () =>
          handleApi<void, VaultsAccountsResponse>(url + queryParams, 'GET'),
        ...queryConfig,
        enabled:
          !!authMetadata?.accessToken &&
          (debouncedQuery === query || !query) &&
          enabled
      }
    )

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useCreateVault = () =>
    useMutation({
      mutationFn: (data: CreateVaultRequest) =>
        handleApi<CreateVaultRequest, CreateVaultResponse>(
          '/ecm/vaults',
          'POST',
          data
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountVaults]
        })
      }
    })

  const useActionFavoriteVault = () =>
    useMutation({
      mutationFn: ({ action, id }: ActionFavoriteVault) => {
        return handleApi<ActionFavoriteVault, void>(
          `/ecm/vaults/${id}/${action}`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountVaults]
        })
      }
    })

  const useUpdateVault = () =>
    useMutation({
      mutationFn: ({ id, ...data }: UpdateVaultRequestWithId) => {
        return handleApi<UpdateVaultRequest, void>(
          `/ecm/vaults/${id}`,
          'PATCH',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountVaults]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
      }
    })

  const useGetVault = (id?: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.Vault, id],
      queryFn: () => handleApi<void, VaultResponse>(`/ecm/vaults/${id}`, 'GET'),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!id
    })
  }

  const useGetVaultsList = (
    id: string | null,
    pageNumber = 0,
    pageSize = 10,
    excludeEnvelopes = false,
    excludeTemplates = false,
    search: string | null,
    enabled = true
  ) => {
    const url = `/ecm/vaults/${id}/list`

    let queryParams = `?pageNumber=${
      pageNumber + 1
    }&pageSize=${pageSize}&excludeEnvelopes=${excludeEnvelopes}&excludeTemplates=${excludeTemplates}`
    if (search) {
      queryParams += `&search=${encodeURIComponent(search)}`
    }

    const queryKey = [
      QueryKeys.VaultsList,
      id,
      excludeEnvelopes,
      excludeTemplates,
      search,
      pageNumber,
      pageSize,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(search)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedQuery(search)
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }, [search])

    const { data, error, isLoading, isSuccess, isError, isFetching } = useQuery(
      {
        queryKey,
        queryFn: () =>
          handleApi<void, VaultsListResponse>(url + queryParams, 'GET'),
        ...queryConfig,
        enabled:
          !!authMetadata?.accessToken &&
          (debouncedQuery === search || !search) &&
          enabled
      }
    )

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useDeleteVault = () =>
    useMutation({
      mutationFn: (id: string) => handleApi(`/ecm/vaults/${id}`, 'DELETE'),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountVaults]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
      }
    })

  const useGetCanDeleteVault = () => {
    const getCanDeleteVault = async (id: string) => {
      return await handleApi<void, CanDeleteVaultResponse>(
        `/ecm/vaults/${id}/remove`,
        'GET'
      )
    }

    return { getCanDeleteVault }
  }

  const useRenameVault = () =>
    useMutation({
      mutationFn: (data: RenameVaultRequestWithId) => {
        const { id, ...request } = data
        return handleApi<RenameVaultRequest, void>(
          `/ecm/vaults/${id}`,
          'PATCH',
          request
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountVaults]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VaultsList]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Vault]
        })
      }
    })

  const useGetListUsersSelect = () => {
    const queryClient = useQueryClient()

    const getDataListUsers = async (
      query: string | null,
      pageNumber = 0,
      pageSize = 10
    ) => {
      let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
      if (query) {
        queryParams += `&search=${encodeURIComponent(query)}`
      }

      return queryClient.fetchQuery<VaultsAccountsResponse>(
        [QueryKeys.ListUsersSelect, pageNumber, pageSize, query],
        async () => {
          const response = await handleApi<void, VaultsAccountsResponse>(
            '/ecm/vaults/accounts' + queryParams,
            'GET'
          )

          if (!response) {
            throw new Error('No data found')
          }

          return response
        }
      )
    }

    return { getDataListUsers }
  }

  const useVaultMembers = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.VaultsMembers],
      queryFn: () =>
        handleApi<void, VaultMembersResponse>('/ecm/vaults/members', 'GET'),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  return {
    useVaultOwners,
    useUserAccountVaults,
    useCreateVault,
    useActionFavoriteVault,
    useUpdateVault,
    useGetVault,
    useDeleteVault,
    useGetCanDeleteVault,
    useAllAccountUsersVaults,
    useGetVaultsList,
    useRenameVault,
    useGetListVaultsSelect,
    useGetListUsersSelect,
    useVaultMembers
  }
}

export default useVaultApi
