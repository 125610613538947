import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTemplate } from '@/providers/Template'
import { FieldType } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { ItemFieldTemplate } from '@/ui/molecules/index'

const ListFieldsTemplate: React.FC = () => {
  const { currentDataTemplate, setCurrentDataTemplate, currentActionTemplate } =
    useTemplate()

  const handleDragEnd = (e: any) => {
    if (!e.destination) return
    const tempData = Array.from(currentDataTemplate?.fields || [])
    const [sourceData] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, sourceData)
    setCurrentDataTemplate({
      ...currentDataTemplate,
      fields: tempData
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-1">
        {(provider) => (
          <ul
            ref={provider.innerRef}
            {...provider.droppableProps}
            role="list"
            className="space-y-3"
          >
            {currentDataTemplate?.fields?.length === undefined &&
            currentActionTemplate.action === 'edit' ? (
              <>
                {Array.from({ length: 5 }, (_, index) => (
                  <div className="w-full h-[65px] skeleton" key={index}></div>
                ))}
              </>
            ) : (
              currentDataTemplate?.fields?.map((field: FieldType, id) => (
                <ItemFieldTemplate
                  key={`${field.alias}_${id}`}
                  field={field}
                  index={id}
                />
              ))
            )}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ListFieldsTemplate
