import React, { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import ReactSelect, { GroupBase, OptionProps, Props } from 'react-select'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import Avatar from '../Avatar'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'

type CustomOptionProps = OptionProps<any, false, GroupBase<any>> & {
  avatar?: string | null
}

const CustomOption: React.FC<CustomOptionProps & { size?: 'sm' | 'md' }> = ({
  size = 'md',
  ...props
}) => {
  const paddingSize = size === 'sm' ? 'p-2' : 'p-2.5'
  const textSize = size === 'sm' ? 'text-xs' : 'text-sm'
  const iconSize = size === 'sm' ? 'w-4 h-4' : 'w-5 h-5'

  return (
    <div
      className={cn(
        'cursor-pointer hover:bg-gray-50 hover:text-secondary-700 flex justify-between mb-1 rounded',
        textSize,
        paddingSize,
        props.isSelected ? 'text-secondary-700 bg-gray-50' : ''
      )}
      {...props.innerProps}
    >
      <div className="flex items-center gap-2.5">
        {props?.data?.avatar && (
          <Avatar
            name={props.label}
            imgSrc={
              typeof props.data.avatar !== 'boolean'
                ? props.data.avatar
                : undefined
            }
            size="sm"
          />
        )}
        {props.label}
      </div>
      {props.isSelected && (
        <CheckIcon className={`${iconSize} ml-2 text-secondary-700`} />
      )}
    </div>
  )
}

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group>

export type PropsType = SelectProps<any> & {
  size?: 'sm' | 'md'
  showSkeleton?: boolean
  timeoutSkeleton?: number
  fullWidth?: boolean
  error?: string
  label?: string
  className?: string
}

const SelectSearch: React.FC<PropsType> = ({
  size = 'md',
  showSkeleton,
  timeoutSkeleton,
  error,
  className,
  ...props
}) => {
  const { t } = useTranslation('validations')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { breakpoint } = useViewport()

  const isMobile = breakpoint === 'sm'

  const textSize =
    size === 'sm' ? (isMobile ? 'text-xs' : 'text-sm') : 'text-sm'

  const controlHeight = size === 'sm' ? '32px' : '38px'
  const controlMinHeight = size === 'sm' ? '32px' : '38px'
  const dropdownIndicatorPaddingY = size === 'sm' ? '0' : '8px'
  const dropdownIndicatorPaddingX = size === 'sm' ? '4px' : '8px'

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoading(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  if (showSkeleton || isLoading) {
    const label = {
      sm: 'w-16 h-3',
      md: 'w-24 h-4'
    }[size || 'md']
    const select = {
      sm: 'w-16 h-8',
      md: 'w-full min-w-44 h-10'
    }[size || 'md']

    return (
      <div className={cn('flex flex-col w-full gap-1', className)}>
        <div className={cn('skeleton', label)} />
        <div className={cn('skeleton', select)} />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'w-full relative flex flex-col',
        props.fullWidth ? 'max-w-full' : 'w-full md:w-56',
        error ? 'border-error-500' : '',
        props.label && 'gap-1',
        className
      )}
    >
      {props.label && (
        <Label htmlFor={props.id}>
          {props.label}
          {!props.required && props.label && (
            <span className="text-xs font-light leading-3">
              ({t?.optional})
            </span>
          )}
        </Label>
      )}
      <div className="relative">
        <ReactSelect
          {...props}
          classNamePrefix="select"
          classNames={{
            indicatorSeparator() {
              return `hidden`
            },
            indicatorsContainer() {
              return 'text-accent-900 placeholder:text-gray-400 cursor-pointer'
            },
            container(props) {
              return cn(
                'bg-accent-100 dark:bg-accent-500 text-accent-900 placeholder:text-accent-500 ring-0 border border-gray-300 hover:border-primary-200 rounded-md shadow-sm cursor-pointer',
                props.isFocused
                  ? 'border border-primary-700 hover:border-primary-700 ring-1 ring-primary-700'
                  : '',
                props.isDisabled
                  ? 'opacity-50 cursor-not-allowed hover:border-gray-300 focus:border-gray-300'
                  : '',
                error ? 'border-error-500 hover:border-error-500' : ''
              )
            },
            menuList() {
              return `${textSize} bg-accent-100 text-accent-900 rounded shadow-sm overflow-hidden p-2`
            },
            singleValue() {
              return `${textSize} text-accent-100 placeholder:text-gray-400 cursor-pointer`
            },
            placeholder() {
              return `${textSize} text-accent-900 placeholder:text-gray-400 cursor-pointer`
            }
          }}
          menuPortalTarget={document.body}
          styles={{
            ...props.styles,
            menuPortal: (base) => ({ ...base, zIndex: 50 }),
            singleValue: (base) => ({
              ...base,
              color: 'inherit'
            }),
            menu: (base) => ({
              ...base,
              width: '100%',
              left: 'auto',
              right: '0'
            }),
            control: (base, state) => ({
              ...base,
              width: '100%',
              border: 'none',
              borderRadius: '0.375rem',
              backgroundColor: 'transparent',
              height: controlHeight,
              minHeight: controlMinHeight,
              boxShadow: 'none !important',
              borderColor: state.isFocused ? 'transparent' : base.borderColor,
              '&:hover': {
                borderColor: state.isFocused ? 'transparent' : base.borderColor
              }
            }),
            dropdownIndicator(base) {
              return {
                ...base,
                paddingLeft: dropdownIndicatorPaddingX,
                paddingRight: dropdownIndicatorPaddingX,
                paddingTop: dropdownIndicatorPaddingY,
                paddingBottom: dropdownIndicatorPaddingY
              }
            }
          }}
          components={{
            Option: (optionProps) => (
              <CustomOption {...optionProps} size={size} />
            ),
            ...props.components
          }}
          placeholder={props.placeholder || ''}
          isSearchable={true}
        />
        {error && (
          <div className="absolute left-0 mt-1 text-xs text-error-500">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectSearch
