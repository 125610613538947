import React, { useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { VariantProps, cva } from 'class-variance-authority'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useTemplate } from '@/providers/Template'
import {
  ActionFieldType,
  ValuesDataTemplateType
} from '@/providers/Template/Template.types'
import { SelectVault, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { Form, ListFieldsTemplate, RenderFormField } from '@/ui/molecules'

const containerVariants = cva(
  'flex flex-col justify-between gap-8 relative overflow-x-hidden bg-accent-100 dark:bg-gray-50 ',
  {
    variants: {
      type: {
        default:
          'p-4 md:px-8 md:py-4 w-full sm:w-[500px] h-containerFull max-h-containerFull',
        drawer: 'p-4 md:px-8 md:py-4 w-full h-full'
      }
    }
  }
)

export type Props = VariantProps<typeof containerVariants>

const SectionDataTemplateDetails: React.FC<Props> = ({ type = 'default' }) => {
  const { t, isReady } = useTranslation(['template', 'validations'], true)
  const [finishedInitialSetup, setFinishedInitialSetup] = useState(false)
  const {
    dataCurrentVault,
    currentDataTemplate,
    setCurrentDataTemplate,
    setDataDrawerActionField,
    handleButtonSubmitSave,
    currentActionTemplate,
    loadingSubmit
  } = useTemplate()

  const formSchema = z.object({
    vault: z.object({
      value: z.string(),
      label: z.string()
    }),
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z.string().max(4000, { message: t?.errorMaxLength?.(4000) })
  })

  const form = useForm<ValuesDataTemplateType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vault: null,
      name: null,
      description: null
    }
  })
  const valueVault = useWatch({
    control: form.control,
    name: 'vault'
  })

  useEffect(() => {
    if (
      currentActionTemplate.action === 'edit' &&
      !finishedInitialSetup &&
      // !valueVault?.value &&
      currentDataTemplate.vaultId
    ) {
      form.reset({
        vault: {
          value: currentDataTemplate.vaultId,
          label: currentDataTemplate.vaultName || ''
        },
        name: currentDataTemplate.name || '',
        description: currentDataTemplate.description || ''
      })
      setFinishedInitialSetup(true)
      return
    }

    if (
      currentActionTemplate.action === 'create' &&
      !finishedInitialSetup &&
      !valueVault?.value &&
      !valueVault?.label
    ) {
      const vault =
        dataCurrentVault?.id && dataCurrentVault?.name
          ? {
              value: dataCurrentVault?.id || '',
              label: dataCurrentVault?.name || ''
            }
          : null

      form.reset((values) => ({
        ...values,
        vault: vault,
        name: '',
        description: ''
      }))

      setFinishedInitialSetup(true)
      return
    }
  }, [
    currentDataTemplate,
    currentActionTemplate.action,
    dataCurrentVault,
    setCurrentDataTemplate,
    finishedInitialSetup,
    currentActionTemplate,
    valueVault?.value,
    valueVault?.label,
    form,
    valueVault
  ])

  const showSkeleton = useMemo(() => {
    return (
      (currentActionTemplate.action === 'edit' && !currentDataTemplate.id) ||
      !t?.form?.name ||
      !isReady
    )
  }, [currentActionTemplate.action, currentDataTemplate.id, isReady, t?.form])

  return (
    <Form
      {...form}
      onSubmit={handleButtonSubmitSave}
      className={containerVariants({
        type: type
      })}
    >
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-5">
          <Typography
            variant="text-base-medium"
            className="no-underline hover:no-underline"
            showSkeleton={showSkeleton}
          >
            {t?.accordion?.dataTemplate?.title}
          </Typography>
          <RenderFormField
            control={form.control}
            name="vault"
            type="select"
            {...t?.form?.vault}
            render={({ field, fieldState }) => (
              <SelectVault
                name="vault"
                label={t?.form?.[0]?.label}
                placeholder={t?.form?.[0]?.placeholder}
                value={field.value}
                onSelected={(option) => form.setValue('vault', option)}
                error={fieldState.error?.message}
                showSkeleton={showSkeleton}
              />
            )}
          />
          <RenderFormField
            control={form.control}
            name="name"
            type="input"
            showSkeleton={showSkeleton}
            {...t?.form?.name}
          />
          <RenderFormField
            control={form.control}
            name="description"
            type="textarea"
            showSkeleton={showSkeleton}
            {...t?.form?.description}
          />
          {/* </Form> */}
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <Typography
              variant="text-base-medium"
              className="hover:no-underline"
              showSkeleton={showSkeleton}
            >
              {t?.accordion?.fieldsTemplate?.title}
            </Typography>
            <Button
              onClick={() =>
                setDataDrawerActionField({
                  open: true,
                  action: ActionFieldType.CREATE,
                  alias: null
                })
              }
              showSkeleton={showSkeleton}
              type="button"
            >
              {t?.accordion?.fieldsTemplate?.buttonCreateField}
            </Button>
          </div>
          {(currentDataTemplate?.fields?.length || 0) > 0 && (
            <ListFieldsTemplate />
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-3 pt-8 border-t border-gray-300">
        <Button
          type="submit"
          loading={loadingSubmit}
          showSkeleton={showSkeleton}
        >
          {t?.buttonSubmitSave}
        </Button>
      </div>
    </Form>
  )
}

export default SectionDataTemplateDetails
