import React, { FocusEventHandler } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import PhoneInput, {
  DefaultInputComponentProps
} from 'react-phone-number-input'
import useTranslation from '@/hooks/useTranslation'
import { useLocale } from '@/providers/Locale'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'

type Props = DefaultInputComponentProps & {
  label?: string
  onChange: (value: string) => void
  error?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  showSkeleton?: boolean
}

const InputPhone: React.FC<Props> = ({
  error,
  label,
  showSkeleton,
  required = true,
  ...props
}) => {
  const { t } = useTranslation('validations')
  const { lang } = useLocale()

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        error && 'border-error-500 hover:border-error-500'
      )}
    >
      <Label htmlFor={props.id}>
        {label}
        {!required && label && (
          <span className="text-xs font-light leading-3">({t?.optional})</span>
        )}
      </Label>
      <PhoneInput
        {...props}
        className={cn(
          'bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 border border-gray-300 hover:border-primary focus:border-secondary w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight block outline-none',
          props.disabled &&
            'opacity-50 cursor-not-allowed hover:border-gray-300 focus:border-gray-300',
          label && 'mt-1',
          error && 'border-error-500 hover:border-error-500'
        )}
        numberInputProps={{
          className: 'bg-accent-100 dark:bg-accent-500'
        }}
        defaultCountry={lang === 'PtBr' ? 'BR' : 'US'}
        onChange={(value) => {
          props.onChange(value || '')
        }}
      />
      {error && (
        <div className="absolute bottom-0 right-0 flex items-center py-3 pr-3 pointer-events-none">
          <FaExclamationCircle
            className="w-4 h-4 text-error-500"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  )
}

export default InputPhone
