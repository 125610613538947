import React from 'react'
import SectionDocumentTemplateFiller from '../SectionDocumentTemplateFiller'
import SectionStepsEnvelope from '../SectionStepsEnvelope'
import { useEnvelope } from '@/providers'

const SectionEnvelopeDetails: React.FC = () => {
  const { viewType } = useEnvelope()

  if (viewType.type === 'template') {
    return <SectionDocumentTemplateFiller id={viewType.templateId as string} />
  }
  return <SectionStepsEnvelope />
}

export default SectionEnvelopeDetails
