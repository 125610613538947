export const fontFamily = [
  {
    label: 'Arial',
    value: 'Arial'
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman'
  },
  {
    label: 'Courier New',
    value: 'Courier New'
  },
  {
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    label: 'Verdana',
    value: 'Verdana'
  },
  {
    label: 'Tahoma',
    value: 'Tahoma'
  },
  {
    label: 'Trebuchet MS',
    value: 'Trebuchet MS'
  }
]
