import React from 'react'
import AreaSignersToSign from '../AreaSignersToSign'
import { cn } from '@/lib/utils'
import { useEnvelope } from '@/providers'
import { ISelectedSignerMarkData } from '@/providers/Envelope/Envelope.types'
import * as Drawer from '@/ui/molecules/shadcn/Drawer'
import { SectionDocumentTemplateEditor } from '@/ui/organisms'

type Props = {
  isOpen: boolean
  onClose: () => void
  handleClickCardSignMark?: (
    selectedSignerMarkData: ISelectedSignerMarkData
  ) => void
}

const DrawerEnvelope: React.FC<Props> = ({
  isOpen,
  onClose,
  handleClickCardSignMark
}) => {
  const { viewType } = useEnvelope()

  return (
    <Drawer.Drawer
      open={isOpen}
      onOpenChange={(boolean) => {
        if (!boolean) {
          onClose()
        }
      }}
    >
      <Drawer.DrawerContent
        className={cn(
          'bg-gray-50 md:bg-gray-100',
          viewType.type === 'envelope' ? 'h-[50vh]' : 'h-[85vh]'
        )}
      >
        <div className="relative pt-4 overflow-y-scroll">
          {viewType.type === 'envelope' && handleClickCardSignMark && (
            <div className="p-4">
              <AreaSignersToSign
                handleClickCardSignMark={handleClickCardSignMark}
              />
            </div>
          )}
          {viewType.type === 'template' && (
            <SectionDocumentTemplateEditor id={viewType.templateId as string} />
          )}
        </div>
      </Drawer.DrawerContent>
    </Drawer.Drawer>
  )
}

export default DrawerEnvelope
