import React, { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import useTranslation from '@/hooks/useTranslation'
import {
  Form,
  Modal,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '..'
import { useUserAccountApi } from '@/hooks/api/administration/useUserAccountApi'
import { useAuth } from '@/providers'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

type UpdateRoleType = {
  role: string | null
}

type Props = {
  id: string
  role: string
  isOpen: boolean
  onClose: () => void
}

const ModalUpdateRoleUser: React.FC<Props> = ({
  id,
  role,
  isOpen,
  onClose
}) => {
  const { t, isReady } = useTranslation('usersPage')

  const form = useForm<UpdateRoleType>({
    defaultValues: {
      role: null
    }
  })
  const { refreshProfile } = useAuth()
  const { useUserAccountsChangeRole } = useUserAccountApi()
  const {
    mutateAsync: mutateAsyncUserAccountsChangeRole,
    isLoading: isLoadingUserAccountsChangeRole
  } = useUserAccountsChangeRole()

  const valueRole = useWatch({
    control: form.control,
    name: 'role',
    defaultValue: null
  })

  useEffect(() => {
    if (role && !valueRole) {
      form.reset({ role: role })
    }
  }, [form, role, valueRole])

  const onSubmit = useCallback(
    async (values) => {
      try {
        await mutateAsyncUserAccountsChangeRole({
          id: id,
          role: values.role
        })
        await refreshProfile()
        showToast.success(t?.modalUpdateRole?.toasts?.successUpdateRole)
        onClose()
        form.reset()
      } catch {
        showToast.error(t?.modalUpdateRole?.toasts?.errorUpdateRole)
      }
    },
    [
      form,
      id,
      mutateAsyncUserAccountsChangeRole,
      onClose,
      refreshProfile,
      t?.modalUpdateRole?.toasts?.errorUpdateRole,
      t?.modalUpdateRole?.toasts?.successUpdateRole
    ]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        form.reset()
      }}
      title={t?.modalUpdateRole?.title}
      displayAsDrawerOnMobile
    >
      <Form<UpdateRoleType> {...form} onSubmit={onSubmit}>
        <RenderFormField
          control={form.control}
          name="role"
          type="select"
          options={t?.modalUpdateRole?.form?.role?.options}
          showSkeleton={!isReady}
          {...form.formState.errors.role}
          {...t?.modalUpdateRole?.form?.role}
        />
        <RenderFormActions>
          <Button
            onClick={() => {
              onClose()
              form.reset()
            }}
            className="w-fit"
            variant="neutral"
            type="button"
            showSkeleton={!isReady}
          >
            {t?.modalUpdateRole?.form?.buttonCancel?.label}
          </Button>
          <Button
            className="w-fit"
            type="submit"
            showSkeleton={!isReady}
            loading={isLoadingUserAccountsChangeRole}
          >
            {t?.modalUpdateRole?.form?.buttonSubmitSave?.label}
          </Button>
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalUpdateRoleUser
