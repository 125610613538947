import React from 'react'
import AvatarGroup from '../AvatarGroup'
import { AvatarGroupProps, User } from '../AvatarGroup/AvatarGroup'
import IconWithTooltip from '../IconWithTooltip'
import { cn } from '@/lib/utils'
import { Icon, IconProps } from '@/ui/atoms/index'
import { Badge } from '@/ui/atoms/shadcn'
import { BadgeProps } from '@/ui/atoms/shadcn/Badge/Badge'

type Props = BadgeProps &
  AvatarGroupProps & {
    classNameBadge?: string
    label: string
    users: User[]
    icon: React.ComponentType<{ className?: string }>
  }

const BadgeAvatarGroup: React.FC<Props> = ({
  classNameBadge,
  label,
  users,
  max,
  size,
  tooltip,
  icon,
  infoMessage,
  ...props
}) => {
  return (
    <Badge
      className={cn(classNameBadge, 'flex items-center gap-2')}
      size={size}
      {...props}
    >
      <div className="flex items-center gap-1">
        {label}
        <IconWithTooltip
          icon={<Icon icon={icon} size={size as IconProps['size']} />}
          tooltipText={tooltip}
        />
      </div>
      {!!users?.length && (
        <AvatarGroup
          users={users}
          max={max}
          size={size}
          infoMessage={infoMessage}
          type="usersGroup"
        />
      )}
    </Badge>
  )
}

export default BadgeAvatarGroup
