import React, { useMemo } from 'react'
import useTranslation from '@/hooks/useTranslation'
import { ModalConfirm } from '@/ui/molecules'

interface ModalErrorPublishEnvelopeProps {
  isOpen: boolean
  typeError: string | null
  loading?: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalErrorPublishEnvelope: React.FC<ModalErrorPublishEnvelopeProps> = ({
  isOpen,
  typeError,
  loading = false,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation('envelope')

  const tModalConfirmErrorPublish = useMemo(
    () => t?.modalConfirmErrorPublish?.[typeError || 'Envelopes'],
    [t?.modalConfirmErrorPublish, typeError]
  )

  return (
    <ModalConfirm
      isOpen={isOpen}
      title={tModalConfirmErrorPublish?.title}
      description={tModalConfirmErrorPublish?.description}
      confirmButtonText={tModalConfirmErrorPublish?.confirmButtonText}
      cancelButtonText={tModalConfirmErrorPublish?.cancelButtonText}
      onConfirm={onConfirm}
      onClose={onClose}
      variantConfirmButton="secondary"
      loadingSubmit={loading}
    />
  )
}

export default ModalErrorPublishEnvelope
