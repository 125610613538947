import { ComponentProps, forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'

const checkboxVariants = cva('w-4 h-4 border-gray-300 rounded cursor-pointer', {
  variants: {
    variant: {
      default:
        'text-primary-700 focus:border-primary-700 focus:ring-primary-700',
      secondary:
        'text-secondary-700 focus:border-secondary-700 focus:ring-secondary-700',
      destructive:
        'text-destructive-700 focus:border-destructive-700 focus:ring-destructive-700'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type Props = ComponentProps<'input'> &
  VariantProps<typeof checkboxVariants> & {
    label?: string
    children?: React.ReactNode
    showSkeleton?: boolean
  }

const Checkbox = forwardRef<HTMLInputElement, Props>(function Checkbox(
  { label, name, children, variant, showSkeleton = false, ...props },
  ref
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event)
  }

  if (showSkeleton) {
    return (
      <div className="flex items-center gap-1">
        <div className="w-4 h-4 skeleton" />
        <div className="w-20 h-4 skeleton" />
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <input
        id={name}
        name={name}
        type="checkbox"
        className={checkboxVariants({ variant })}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
      <label
        htmlFor={name}
        className="block ml-2 text-sm leading-6 text-gray-700 cursor-pointer"
      >
        {label || children}
      </label>
    </div>
  )
})

export default Checkbox
