import NotificationToggleSkeleton from './NotificationToggleSkeleton'
import { cn } from '@/lib/utils'
import { Toggle, Typography } from '@/ui/atoms'

type Props = {
  name: string
  title: string
  description: string
  showSkeleton?: boolean
  disabled?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
}

const NotificationToggle: React.FC<Props> = ({
  name,
  title,
  description,
  showSkeleton = false,
  disabled = false,
  onChange,
  checked
}) => {
  if (showSkeleton) {
    return <NotificationToggleSkeleton />
  }

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-between gap-2 p-4 border rounded-lg flex-1',
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      )}
    >
      <div className="flex flex-col space-y-0.5 flex-1">
        <Typography
          variant="text-sm-semibold"
          className="text-gray-700 dark:text-gray-700"
          type="p"
        >
          {title}
        </Typography>
        <Typography
          variant="text-xs-regular"
          className="text-gray-700 dark:text-gray-700"
          type="p"
        >
          {description}
        </Typography>
      </div>
      <Toggle
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

export default NotificationToggle
