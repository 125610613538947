import { CurrencyDollarIcon } from '@heroicons/react/24/outline'

const optionsRoles = [
  {
    label: 'Signatory',
    value: 'Sign'
  },
  {
    label: 'Approver',
    value: 'Approve'
  },
  {
    label: 'Recognizer',
    value: 'Recognize'
  },
  {
    label: 'Witness',
    value: 'Testify'
  },
  {
    label: 'Confirm Receipt',
    value: 'ConfirmReceiving'
  },
  {
    label: 'Endorse in Black',
    value: 'EndorseInBlack'
  },
  {
    label: 'Endorse in White',
    value: 'EndorseInWhite'
  }
]

const optionsMarkupOrientation = [
  {
    value: 'None',
    label: 'None'
  },
  {
    value: 'Top',
    label: 'Top'
  },
  {
    value: 'Left',
    label: 'Left Margin'
  },
  {
    value: 'Right',
    label: 'Right Margin'
  },
  {
    value: 'Bottom',
    label: 'Footer'
  }
]

const optionsLanguage = [
  {
    label: 'Portuguese',
    value: 'PtBr'
  },
  {
    label: 'English',
    value: 'EnUs'
  }
]

const content = {
  steps: {
    details: {
      label: 'Details',
      form: {
        vault: {
          id: 'vault',
          type: 'selectVault',
          name: 'vault',
          label: 'Vault',
          placeholder: 'Select the destination vault',
          readonly: true
        },
        name: {
          id: 'name',
          type: 'input',
          name: 'name',
          label: 'Envelope Name'
        },
        message: {
          id: 'message',
          type: 'textarea',
          name: 'message',
          label: 'Message',
          tooltipInfo: 'Message',
          maxLength: 4000,
          required: false
        },
        reviewReminder: {
          id: 'reviewReminder',
          type: 'toggle',
          name: 'reviewReminder',
          label: 'Signature Reminder',
          defaultValue: true,
          tooltipInfo: 'Signature Reminder'
        },
        buttonShowAdvancedSettings: {
          id: 'buttonShowAdvancedSettings',
          type: 'button',
          name: 'buttonShowAdvancedSettings',
          label: 'Settings'
        },
        toBePublished: {
          id: 'toBePublished',
          type: 'toggle',
          name: 'toBePublished',
          defaultValue: false,
          label: 'Schedule Publication',
          tooltipInfo: 'Schedule Publication'
        },
        toBePublishedAtUtc: {
          id: 'toBePublishedAtUtc',
          type: 'datepicker',
          name: 'toBePublishedAtUtc',
          label: 'Publish On',
          placeholder: 'MM/DD/YYYY',
          allowPastDates: false,
          nextDayStart: true
        },
        hasExpiration: {
          id: 'hasExpiration',
          type: 'toggle',
          name: 'hasExpiration',
          defaultValue: false,
          label: 'Has Expiration',
          tooltipInfo: 'Has Expiration'
        },
        expiresAtUtc: {
          id: 'expiresAtUtc',
          type: 'datepicker',
          name: 'expiresAtUtc',
          label: 'Expires On',
          placeholder: 'MM/DD/YYYY',
          allowPastDates: false,
          nextDayStart: true
        },
        expirationReminder: {
          id: 'expirationReminder',
          type: 'toggle',
          name: 'expirationReminder',
          label: 'Expiration Reminder',
          tooltipInfo: 'Expiration Reminder',
          defaultValue: true
        },
        language: {
          id: 'language',
          type: 'select',
          name: 'language',
          label: 'Language',
          options: optionsLanguage
        },
        privateDescription: {
          id: 'privateDescription',
          type: 'textarea',
          name: 'privateDescription',
          label: 'Private Description',
          tooltipInfo: 'Private Description',
          maxLength: 4000,
          required: false
        },
        publicDescription: {
          id: 'publicDescription',
          type: 'textarea',
          name: 'publicDescription',
          label: 'Public Description',
          tooltipInfo: 'Public Description',
          maxLength: 4000,
          required: false
        },
        markupOrientation: {
          id: 'markupOrientation',
          type: 'select',
          name: 'markupOrientation',
          label: 'Markup Orientation',
          tooltipInfo:
            'Markups will be inserted on all pages of the documents in this envelope, positioned at the chosen location. These markups will contain information about the envelope, such as its unique identifier and instructions on how to validate it, including a link via QR code.',
          options: optionsMarkupOrientation
        }
      },
      tooltipInfoIsSandbox:
        'Attention: This is an envelope created in testing mode, therefore no reminder email will be sent',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Next',
        dropdownOptions: [
          {
            label: 'Save',
            key: 'save'
          }
        ]
      }
    },
    signers: {
      label: 'Signatories',
      buttonAddSigner: {
        label: 'Add signatory',
        dropdownOptions: [
          {
            key: 'addContacts',
            label: 'Add from contacts'
          },
          {
            key: 'addMyself',
            label: 'Add myself'
          }
        ]
      },
      table: {
        signers: 'Signatories',
        role: 'Role: ',
        mfa: 'MFA',
        actions: 'Actions'
      },
      addSigner: 'Add signatories to send the envelope',
      emptyStateSigner: 'No signatories added',
      labelToggleSignatureInOrder: 'Signature in order',
      buttonBack: 'Back',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Next',
        dropdownOptions: [
          {
            label: 'Save',
            key: 'save'
          }
        ]
      }
    },
    signatureMarker: {
      label: 'Signatures',
      selectLabel: 'Select a document',
      selectPlaceholder: 'Select',
      buttonOpenDocument: 'Open document',
      buttonBack: 'Back',
      buttonCreateSignMark: 'Add signature mark',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        labelPublish: 'Publish',
        labelSchedule: 'Schedule Publication',
        dropdownOptions: [
          {
            label: 'Save',
            key: 'save'
          }
        ]
      }
    },
    documents: {
      label: 'Documents',
      addDocuments: 'Add documents',
      fileSize: 'Maximum size of 25MB per document',
      removeAll: 'Remove all',
      buttonSave: 'Save',
      errorSizeFile: 'The file must be a PDF and be no larger than 25MB',
      errorMaxFiles: 'Maximum of 10 documents exceeded',
      or: 'or create from a template',
      buttonBack: 'Back',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Next',
        dropdownOptions: [
          {
            label: 'Save',
            key: 'save'
          }
        ]
      }
    }
  },
  modalConfirmRemoveSchedule: {
    title: 'Remove publishing schedule?',
    description:
      'To save the envelope, the publishing schedule date will be removed.',
    confirmButtonText: 'Remove and save',
    cancelButtonText: 'Cancel'
  },
  modalActionSigner: {
    title: (action: 'create' | 'update') =>
      action === 'create' ? 'Add Signer' : 'Update Signer',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Name',
        placeholder: "Signer's Name"
      },
      email: {
        id: 'email',
        type: 'input',
        name: 'email',
        label: 'Email',
        placeholder: "Signer's Email"
      },
      role: {
        id: 'role',
        type: 'select',
        name: 'role',
        label: 'Role',
        placeholder: "Signer's Role",
        options: optionsRoles,
        required: false
      },
      title: {
        id: 'title',
        type: 'input',
        name: 'title',
        label: 'Title',
        placeholder: 'e.g., Contractor',
        required: false
      },
      shouldEnforceEmailValidation: {
        id: 'shouldEnforceEmailValidation',
        type: 'toggle',
        name: 'shouldEnforceEmailValidation',
        defaultValue: true,
        label: 'Require Email Authentication'
      },
      shouldEnforcePasscodeValidation: {
        id: 'shouldEnforcePasscodeValidation',
        type: 'toggle',
        name: 'shouldEnforcePasscodeValidation',
        defaultValue: false,
        label: 'Require Passcode Authentication'
      },
      passcode: {
        id: 'passcode',
        type: 'input',
        name: 'passcode',
        label: 'Passcode',
        placeholder: `Signer's Passcode`
      },
      passcodeHint: {
        id: 'passcodeHint',
        type: 'input',
        name: 'passcodeHint',
        label: 'Passcode Hint',
        placeholder: `Signer's Passcode Hint`,
        tooltipInfo: 'Passcode Hint',
        maxLength: 100
      },
      shouldEnforceSmsValidation: {
        id: 'shouldEnforceSmsValidation',
        type: 'toggle',
        name: 'shouldEnforceSmsValidation',
        defaultValue: false,
        label: 'Require SMS Authentication',
        tooltipInfoIcon: CurrencyDollarIcon,
        tooltipInfo: '1 Authentication Credit',
        tooltipInfoPrefix:
          'The signer will need to validate their phone number by receiving a code at the time of signing the envelope. This is an advanced authentication method and costs 1 authentication credit, which will be charged when the envelope is published. ',
        tooltipInfoLink: 'click here',
        tooltipInfoSuffix: ' to purchase advanced authentication credits.',
        tooltipLink: '/billing/plans?trigger=signer-mfa-credits'
      },
      phoneNumber: {
        id: 'phoneNumber',
        type: 'inputPhone',
        name: 'phoneNumber',
        label: 'Phone',
        placeholder: `Signer's Phone`
      },
      buttonCancel: {
        id: 'buttonCancel',
        type: 'button',
        name: 'buttonCancel',
        label: 'Cancel'
      },
      buttonSubmitSave: {
        id: 'buttonSubmitSave',
        type: 'button',
        name: 'buttonSubmitSave',
        label: 'Save'
      }
    }
  },
  role: 'Role',
  defaultMessage: 'Click in the button below to review this envelope.',
  updateSignerTitle: 'Update Signatory',
  uploadArea: {
    labelDragDocuments:
      'Or drag and drop your documents here to create an envelope',
    buttonAddDocuments: 'Add documents',
    fileSize: 'Maximum size of 25MB per document',
    removeAll: 'Remove all',
    buttonSave: 'Save',
    errorSizeFile: 'The file must be a PDF and be no larger than 25MB',
    errorMaxFiles: 'Maximum of 10 documents exceeded',
    or: 'or create from a template',
    editDocumentName: 'Edit document',
    removeDocument: 'Remove document'
  },
  editInformationDocument: {
    title: 'Edit document',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Name',
        placeholder: 'Document name'
      },
      privateDescription: {
        id: 'privateDescription',
        type: 'textarea',
        name: 'privateDescription',
        label: 'Private Description',
        placeholder: 'Private description of the document',
        maxLength: 4000,
        tooltipInfo: 'Private description',
        required: false
      },
      publicDescription: {
        id: 'publicDescription',
        type: 'textarea',
        name: 'publicDescription',
        label: 'Public Description',
        placeholder: 'Public description of the document',
        maxLength: 4000,
        tooltipInfo: 'Public description',
        required: false
      },
      buttonCancel: {
        id: 'buttonCancel',
        type: 'button',
        name: 'buttonCancel',
        label: 'Cancel'
      },
      buttonSubmitSave: {
        id: 'buttonSubmitSave',
        type: 'button',
        name: 'buttonSubmitSave',
        label: 'Save'
      }
    },
    requiredName: 'Document name is mandatory'
  },
  marksTypes: {
    Rubric: 'Rubric',
    Signature: 'Signature'
  },
  modalConfirmErrorPublish: {
    Envelopes: {
      title: 'Error publishing envelope',
      description: 'You no longer have envelopes for publication',
      confirmButtonText: 'Buy envelopes',
      cancelButtonText: 'Cancel'
    },
    SignerMfaCredits: {
      title: 'Error publishing envelope',
      description:
        'You no longer have authentication credits for the publication',
      confirmButtonText: 'Buy credits',
      cancelButtonText: 'Cancel'
    },
    ApiEnvelopes: {
      title: 'Error publishing envelope',
      description: 'You do not have api envelopes for publishing',
      confirmButtonText: 'Buy api envelopes',
      cancelButtonText: 'Cancel'
    },
    PublicationDateBeforeNow: {
      title: 'Invalid publication date',
      description:
        'The publication date cannot be earlier than the current date',
      confirmButtonText: 'Update date',
      cancelButtonText: 'Cancel'
    },
    ExpirationDateBeforeNow: {
      title: 'Invalid expiration date',
      description:
        'The expiration date cannot be earlier than the current date',
      confirmButtonText: 'Update date',
      cancelButtonText: 'Cancel'
    },
    RemoveSchedule: {
      title: 'Remove publishing schedule?',
      description:
        'To save the envelope, the publishing schedule date will be removed.',
      confirmButtonText: 'Remove and save',
      cancelButtonText: 'Cancel'
    }
  },
  modalHelpErrorTooManyRequests: {
    title: 'Too Many Requests',
    description:
      'There was an error saving your envelope, please contact our support team',
    continueButtonText: 'Close'
  },
  modalErrorCreateEnvelope: {
    title: 'Error creating envelope',
    description:
      'There was an error saving your envelope, please contact our support team',
    continueButtonText: 'Close'
  },
  toasts: {
    noName: 'Required envelope name',
    noVault: 'Required vault',
    noDocuments: 'No documents added',
    noSigners: 'No signers added',
    successAddMark: 'Mark added successfully',
    successUpdateMark: 'Mark updated successfully',
    successDeleteMark: 'Mark deleted successfully',
    successPublishEnvelope: 'Envelope published successfully',
    errorPublishEnvelope: (
      shouldBuy: 'Envelopes' | 'SignerMfaCredits' | 'ApiEnvelopes'
    ) =>
      shouldBuy === 'Envelopes'
        ? 'You no longer have envelopes for publication'
        : shouldBuy === 'SignerMfaCredits'
          ? 'You no longer have authentication credits for publishing'
          : shouldBuy === 'ApiEnvelopes'
            ? 'You do not have api envelopes for publishing'
            : 'Error publishing envelope',
    successCreateEnvelope: 'Envelope created successfully',
    errorCreateEnvelope:
      'There was an error saving your envelope, please contact our support team',
    errorGetVault: 'Error retrieving vault',
    successUpdateEnvelope: 'Envelope updated successfully',
    errorUpdateEnvelope: 'Error updating envelope',
    loadingUploadingFiles: 'Uploading file(s)...',
    successUploadingFiles: 'File(s) uploaded successfully',
    errorUploadingFiles: 'Error uploading file(s)',
    loadingCreateDocumentWithTemplate: 'Creating document...',
    successCreateDocumentWithTemplate: 'Document created successfully',
    errorCreateDocumentWithTemplate: 'Error creating document',
    errorTooManyRequests: 'Too many requests, try again later'
  },
  tooltipInfo: {
    editSigner: 'Edit signatory',
    deleteSigner: 'Delete signatory',
    successCreateEnvelope: 'Envelope created successfully',
    errorCreateEnvelope: 'Error creating envelope',
    tooltipLockClosed: (isLocked: boolean) =>
      isLocked
        ? 'Password authentication active'
        : 'Password authentication inactive',
    tooltipEnvelope: (isLocked: boolean) =>
      isLocked
        ? 'Email authentication active'
        : 'Email authentication inactive',
    tooltipDevicePhoneMobile: (isLocked: boolean) =>
      isLocked ? 'SMS authentication active' : 'SMS authentication inactive'
  },
  optionsRoles,
  popConfirmSigner: {
    title: 'Delete signatory',
    description: 'Are you sure you want to delete this signatory?',
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel'
  },
  popConfirmDocumentAll: {
    title: 'Delete documents',
    description: 'Are you sure you want to delete all documents?',
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel'
  },
  popConfirmDocument: {
    title: 'Delete document',
    description: 'Are you sure you want to delete this document?',
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel'
  },
  sectionListTemplates: {
    title: 'My templates',
    searchPlaceholder: 'Search for model',
    searchPlaceholderUser: 'Search user',
    menuItems: {
      edit: 'Edit',
      delete: 'Remove'
    },
    createdIn: 'Created in',
    emptyState: {
      title: 'No templates found',
      description: 'Create a new template and it will appear here'
    },
    selectLabelUser: 'Select a user',
    searchLabelTemplates: 'Search template'
  },
  documentTemplateFiller: {
    titleInformationDocument: 'Document Information',
    titleFillInFields: 'Fill in the Form Fields',
    buttonSubmit: 'Next',
    buttonCancel: 'Cancel',
    buttonPreviewDocument: 'Preview Document',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Name',
        placeholder: 'Document Name'
      },
      privateDescription: {
        id: 'privateDescription',
        type: 'textarea',
        name: 'privateDescription',
        label: 'Private Description',
        placeholder: 'Private document description',
        maxLength: 4000,
        required: false
      },
      publicDescription: {
        id: 'publicDescription',
        type: 'textarea',
        name: 'publicDescription',
        label: 'Public Description',
        placeholder: 'Public document description',
        maxLength: 4000,
        required: false
      },
      language: {
        id: 'language',
        type: 'select',
        name: 'language',
        label: 'Language',
        options: optionsLanguage
      },
      markupOrientation: {
        id: 'markupOrientation',
        type: 'select',
        name: 'markupOrientation',
        label: 'Markup Orientation',
        options: optionsMarkupOrientation
      }
    }
  },
  modalHelpAboutVault: {
    title: 'Vault is required',
    description:
      'To save an envelope you must select a destination vault. If you do not have a vault, create a new one or select an existing one.',
    confirmButtonText: 'Create vault'
  }
}

export default content
