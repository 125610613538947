import { useCallback, useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelope } from '@/providers/index'
import {
  Form,
  Modal,
  RenderFormActions,
  RenderFormField,
  RenderFormRow
} from '..'
import {
  CurrentSignerContact,
  CurrentSignerActionType
} from '@/providers/Envelope/Envelope.types'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  action: 'create' | 'update' | null
  isOpen: boolean
  setCurrentSignerAction: (data: CurrentSignerActionType) => void
  editId?: string | null
  contact?: CurrentSignerContact | null
}

type FormValues = {
  name: string
  email: string
  role: string
  title?: string
  shouldEnforceEmailValidation?: boolean
  shouldEnforcePasscodeValidation?: boolean
  passcode?: string
  passcodeHint?: string
  shouldEnforceSmsValidation?: boolean
  phoneNumber?: string | null
}

const ModalActionSigner = ({
  action,
  isOpen,
  setCurrentSignerAction,
  editId,
  contact
}: Props) => {
  const { t, isReady } = useTranslation(['envelope', 'validations'], true)
  const { setSigners, signers } = useEnvelope()

  const formSchema = z
    .object({
      name: z
        .string()
        .min(3, { message: t?.errorMinLength?.(3) })
        .max(200, { message: t?.errorMaxLength?.(200) })
        .min(1, { message: t?.requiredField }),
      email: z
        .string()
        .email({ message: t?.errorInvalidEmail })
        .min(1, { message: t?.requiredField }),
      role: z.string().min(1, { message: t?.requiredField }),
      title: z.string().max(200, { message: t?.errorMaxLength?.(200) }),
      shouldEnforceEmailValidation: z.boolean().optional(),
      shouldEnforcePasscodeValidation: z.boolean().optional(),
      shouldEnforceSmsValidation: z.boolean().optional(),
      phoneNumber: z.string().nullable(),
      passcode: z.string(),
      passcodeHint: z.string().optional()
    })
    .refine(
      (data) => {
        if (data.shouldEnforceSmsValidation) {
          if (!data.phoneNumber) return false
          const regex = /^\+?[0-9]{1,3}\s?[0-9]{1,3}\s?[0-9]{4,5}\s?[0-9]{4}$/

          const isValidPhoneNumber =
            regex.test(data.phoneNumber) &&
            data.phoneNumber.length >= 10 &&
            data.phoneNumber.length <= 15

          return isValidPhoneNumber
        }
        return true
      },
      {
        message: t?.errorInvalidPhoneNumber,
        path: ['phoneNumber']
      }
    )
    .refine(
      (data) => {
        if (data.shouldEnforcePasscodeValidation) {
          if (!data.passcode) return false

          return data.passcode.length >= 1 && data.passcode.length <= 100
        }
        return true
      },
      {
        message: t?.errorInvalidPassword,
        path: ['passcode']
      }
    )

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (action === 'create') {
        setSigners((prev: any) => {
          if (!prev)
            return [
              {
                id: 'fake-id-' + uuidv4(),
                ...values,
                signMarks: []
              }
            ]

          return [
            ...(prev || []),
            {
              id: 'fake-id-' + uuidv4(),
              ...values,
              signMarks: []
            }
          ]
        })

        setCurrentSignerAction({
          action: null,
          isOpen: false,
          id: null
        })

        return
      }
      if (action === 'update') {
        setSigners((prev: any) => {
          if (!prev)
            return [
              {
                ...values,
                signMarks: []
              }
            ]

          return [
            ...(prev?.map((signer: any) => ({
              ...signer,
              ...(signer.id === editId && {
                ...values,
                signMarks: []
              })
            })) || [])
          ]
        })

        setCurrentSignerAction({
          action: null,
          isOpen: false,
          id: null
        })
      }
    },
    [action, editId, setCurrentSignerAction, setSigners]
  )

  const showSkeleton = useMemo(() => !isReady, [isReady])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      role: 'Sign',
      title: '',
      shouldEnforceEmailValidation: true,
      shouldEnforcePasscodeValidation: false,
      passcode: '',
      passcodeHint: '',
      shouldEnforceSmsValidation: false,
      phoneNumber: null
    }
  })
  const valueShouldEnforcePasscodeValidation = useWatch({
    control: form.control,
    name: 'shouldEnforcePasscodeValidation'
  })
  const valueShouldEnforceSmsValidation = useWatch({
    control: form.control,
    name: 'shouldEnforceSmsValidation'
  })

  useEffect(() => {
    if (action === 'update' && editId) {
      const dataSigner = signers?.find((signer) => signer.id === editId)
      if (dataSigner) {
        form.reset({
          name: dataSigner?.name || '',
          email: dataSigner?.email || '',
          role: dataSigner?.role || 'Sign',
          title: dataSigner?.title || '',
          shouldEnforceEmailValidation:
            dataSigner?.shouldEnforceEmailValidation || true,
          shouldEnforcePasscodeValidation:
            dataSigner?.shouldEnforcePasscodeValidation || false,
          passcode: dataSigner?.passcode || '',
          passcodeHint: dataSigner?.passcodeHint || '',
          shouldEnforceSmsValidation:
            dataSigner?.shouldEnforceSmsValidation || false,
          phoneNumber: dataSigner?.phoneNumber || null
        })

        return
      }
    }

    if (action === 'create' && contact) {
      form.reset((values) => ({
        ...values,
        name: contact?.name || '',
        email: contact?.email || ''
      }))
    }
  }, [action, contact, t?.modalActionSigner?.form, editId, signers, form])

  return (
    <Modal
      title={t?.modalActionSigner?.title?.(action)}
      isOpen={isOpen}
      onClose={() => {
        setCurrentSignerAction({
          action: null,
          isOpen: false,
          id: null
        })
      }}
      classesContainer="max-w-[98vw]"
    >
      <Form<FormValues> {...form} onSubmit={onSubmit}>
        <RenderFormRow gap={3}>
          <RenderFormField
            control={form.control}
            name="name"
            type="input"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.name}
          />
          <RenderFormField
            control={form.control}
            name="email"
            type="input"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.email}
          />
        </RenderFormRow>
        <RenderFormRow gap={3}>
          <RenderFormField
            control={form.control}
            name="role"
            type="select"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.role}
          />
          <RenderFormField
            control={form.control}
            name="title"
            type="input"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.title}
          />
        </RenderFormRow>
        <RenderFormRow gap={3}>
          <RenderFormField
            control={form.control}
            name="shouldEnforceEmailValidation"
            type="toggle"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.shouldEnforceEmailValidation}
          />
        </RenderFormRow>
        <RenderFormRow gap={3}>
          <RenderFormField
            control={form.control}
            name="shouldEnforcePasscodeValidation"
            type="toggle"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.shouldEnforcePasscodeValidation}
          />
        </RenderFormRow>
        {valueShouldEnforcePasscodeValidation && (
          <RenderFormRow gap={3}>
            <RenderFormField
              control={form.control}
              name="passcode"
              type="input"
              showSkeleton={showSkeleton}
              {...t?.modalActionSigner?.form?.passcode}
            />
            <RenderFormField
              control={form.control}
              name="passcodeHint"
              type="input"
              showSkeleton={showSkeleton}
              {...t?.modalActionSigner?.form?.passcodeHint}
            />
          </RenderFormRow>
        )}
        <RenderFormRow gap={3}>
          <RenderFormField
            control={form.control}
            name="shouldEnforceSmsValidation"
            type="toggle"
            showSkeleton={showSkeleton}
            {...t?.modalActionSigner?.form?.shouldEnforceSmsValidation}
          />
          {valueShouldEnforceSmsValidation && (
            <RenderFormField
              control={form.control}
              name="phoneNumber"
              type="inputPhone"
              showSkeleton={showSkeleton}
              {...t?.modalActionSigner?.form?.phoneNumber}
            />
          )}
        </RenderFormRow>
        <RenderFormActions>
          <Button
            onClick={() => {
              setCurrentSignerAction({
                action: null,
                isOpen: false,
                id: null
              })
            }}
            className="w-fit"
            variant="neutral"
            type="button"
          >
            {t?.modalActionSigner?.form?.buttonCancel?.label}
          </Button>
          <Button className="w-fit" type="submit">
            {t?.modalActionSigner?.form?.buttonSubmitSave?.label}
          </Button>
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalActionSigner
