import React, { useCallback, useMemo } from 'react'
import useTranslation from '@/hooks/useTranslation'
import { EmptyStateTable, Modal } from '..'
import { VaultItemType } from '@/hooks/api/ecm/useVaultApi/useVaultApi.types'
import useModalSearchVaultData from '@/hooks/useModalSearchVaultData/useModalSearchVaultData'
import { ActionType } from '@/hooks/useModalSearchVaultData/useModalSearchVaultData.types'
import { useAuth } from '@/providers'
import { Select } from '@/ui/atoms'
import { Table } from '@/ui/organisms'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSearchVaultCallback: (
    vault: { vaultId: string; vaultName: string },
    templateId?: string | null
  ) => void
  actionType?: ActionType
  id?: string | null
}

const ModalSearchVault: React.FC<Props> = ({
  isOpen,
  onClose,
  onSearchVaultCallback,
  actionType = 'envelope',
  id
}) => {
  const { t, isReady } = useTranslation('searchVault')
  const { isAdmin } = useAuth()

  const handleClose = useCallback(
    (vault: { vaultId: string; vaultName: string }) => {
      onSearchVaultCallback(vault, id)
    },
    [id, onSearchVaultCallback]
  )

  const {
    columns,
    currentDataTable,
    handleResetModal,
    optionsUser,
    handleSelectOption,
    selectedUserOption,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber
  } = useModalSearchVaultData(handleClose, actionType, isOpen)

  const title = useMemo(() => {
    if (actionType === 'restoreRecycleBin') {
      return t?.modalModalSearchVault?.titleRestoreRecycleBin
    }
    if (actionType === 'moveEnvelope') {
      return t?.modalModalSearchVault?.titleMoveEnvelope
    }
    return t?.modalModalSearchVault?.title
  }, [
    actionType,
    t?.modalModalSearchVault?.title,
    t?.modalModalSearchVault?.titleMoveEnvelope,
    t?.modalModalSearchVault?.titleRestoreRecycleBin
  ])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      classesContent="pt-0"
      classesContainer="max-w-[98vw]"
    >
      <div className="flex flex-col">
        {isAdmin && (
          <div className="flex items-center justify-end w-full border-b border-gray-200 bg-accent-100">
            <Select
              id="select-user"
              options={optionsUser}
              value={selectedUserOption}
              onChange={handleSelectOption}
              className="w-full lg:w-52"
              placeholder={t?.selectPlaceholderUser}
              showSkeleton={
                currentDataTable?.isLoading || !isReady || !selectedUserOption
              }
              timeoutSkeleton={500}
            />
          </div>
        )}
        {currentDataTable?.isLoading ||
        (currentDataTable?.isSuccess &&
          currentDataTable?.data &&
          currentDataTable?.data?.items?.length > 0) ? (
          <div className="min-h-[150px] flex justify-center items-center">
            <Table<VaultItemType>
              columns={columns}
              data={(currentDataTable?.data?.items as VaultItemType[]) || []}
              fetchData={setPageNumber}
              canPreviousPage={pageNumber > 0}
              canNextPage={
                (currentDataTable?.data?.pagination &&
                  currentDataTable?.data?.pagination?.totalItems /
                    currentDataTable?.data?.pagination?.pageSize >
                    pageNumber + 1) ||
                false
              }
              pagination={currentDataTable?.data?.pagination}
              isLoading={currentDataTable?.isLoading}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        ) : (
          <EmptyStateTable
            type="searchVault"
            handleClickButton={handleResetModal}
          />
        )}
      </div>
    </Modal>
  )
}

export default ModalSearchVault
