import { useMemo, useState } from 'react'
import { useContactsData } from '@/hooks/useContactsData'
import useTranslation from '@/hooks/useTranslation'
import { useAuth, useEnvelope } from '@/providers/index'
import {
  EmptyStateSigner,
  ModalActionContact,
  ModalActionSigner,
  ModalCreateSignerWithContact
} from '@/molecules/index'
import { DropdownButton, IDropdownItem, Toggle } from '@/atoms/index'
import TableSignersDragAndDrop from '../TableSignersDragAndDrop'
import { Contact } from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { SignerEnvelopeStatus } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

const StepItemSignersEnvelope = () => {
  const [toggleSignatureInOrder, setToggleSignatureInOrder] = useState(true)

  const { userContext } = useAuth()

  const {
    currentStep,
    setCurrentValuesEnvelope,
    documents,
    noSigners,
    signers,
    loadingEnvelopeSave,
    currentSignerAction,
    setCurrentSignerAction,
    currentSignerContact,
    setCurrentSignerContact,
    handleActionStep,
    hasReviewed,
    handleSaveEnvelope,
    currentValuesEnvelope,
    currentActionEnvelope
  } = useEnvelope()

  const {
    modalActionContact,
    setModalActionContact,
    onSubmitActionModal: onSubmitActionModalContact
  } = useContactsData()

  const { t } = useTranslation('envelope')

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  const dataSigners = useMemo(() => {
    return signers?.map((signer: any, id: number) => {
      if (signer.id)
        return {
          ...signer,
          isDragDisabled: signer.status === SignerEnvelopeStatus.Approved
        }
      return {
        ...signer,
        isDragDisabled: signer.status === SignerEnvelopeStatus.Approved,
        id: String(id + 1)
      }
    })
  }, [signers])

  const dataDropdownItems: IDropdownItem[] =
    currentStepData?.action?.dropdownOptions?.map(
      (option: { key: string; label: string }) => ({
        key: option.key,
        label: option.label
      })
    )

  const hasSigners = (dataSigners?.length || 0) > 0

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div
        className={
          hasSigners ? '' : 'h-full flex items-center justify-center w-full'
        }
      >
        <div className="w-full">
          <div className="w-full">
            {hasSigners ? (
              <TableSignersDragAndDrop
                draggable={
                  toggleSignatureInOrder ||
                  Boolean(
                    currentActionEnvelope.action === 'edit' &&
                      currentValuesEnvelope.signInOrder
                  )
                }
              />
            ) : (
              <EmptyStateSigner />
            )}
          </div>
          {(currentSignerAction.action === 'create' ||
            currentSignerAction.action === 'update') &&
            currentSignerAction.isOpen && (
              <ModalActionSigner
                isOpen={currentSignerAction.isOpen}
                setCurrentSignerAction={setCurrentSignerAction}
                editId={currentSignerAction.id}
                action={currentSignerAction.action}
                contact={currentSignerContact}
              />
            )}
          {currentSignerAction.action === 'createWithContact' &&
            modalActionContact.action !== 'create' &&
            currentSignerAction.isOpen &&
            !modalActionContact?.id && (
              <ModalCreateSignerWithContact
                isOpen={
                  currentSignerAction.action === 'createWithContact' &&
                  currentSignerAction.isOpen
                }
                onClose={() => {
                  setCurrentSignerAction({
                    id: null,
                    action: null,
                    isOpen: false
                  })
                  setCurrentSignerContact(null)
                }}
                onAddContact={(contact: Contact) => {
                  setCurrentSignerContact({
                    name: contact.name,
                    email: contact.email
                  })
                  setCurrentSignerAction({
                    id: null,
                    action: 'create',
                    isOpen: true
                  })
                }}
                setModalActionContact={setModalActionContact}
              />
            )}
          {modalActionContact?.isOpen &&
            modalActionContact.action !== 'delete' && (
              <ModalActionContact
                id={modalActionContact.id}
                isOpen={modalActionContact?.isOpen}
                action={modalActionContact.action}
                onClose={() =>
                  setModalActionContact({
                    isOpen: false,
                    action: null,
                    id: null
                  })
                }
                setModalActionContact={setModalActionContact}
                onSubmit={onSubmitActionModalContact}
              />
            )}
          <div
            className={`flex items-center mt-10 ${
              hasReviewed
                ? 'justify-end'
                : dataSigners?.length > 0
                  ? 'justify-between gap-1'
                  : 'justify-center'
            }`}
          >
            {(dataSigners?.length || 0) > 0 && !hasReviewed && (
              <Toggle
                checked={toggleSignatureInOrder}
                onChange={(checked) => {
                  setToggleSignatureInOrder(checked)
                  setCurrentValuesEnvelope((prev: any) => {
                    if (!prev) return null
                    return {
                      ...prev,
                      signInOrder: checked
                    }
                  })
                }}
                name="toggleSignatureInOrder"
                label={currentStepData?.labelToggleSignatureInOrder}
                defaultChecked={toggleSignatureInOrder}
                className="w-40 lg:w-full"
              />
            )}
            <DropdownButton
              label={currentStepData?.buttonAddSigner?.label}
              type="submit"
              dropdownItems={currentStepData?.buttonAddSigner?.dropdownOptions}
              variant="secondaryOutline"
              buttonMainClassName="px-2 md:px-4 w-max"
              onClick={(key, e) => {
                e.preventDefault()
                e.stopPropagation()
                if (key === 'main') {
                  setCurrentSignerContact(null)
                  setCurrentSignerAction({
                    id: null,
                    action: 'create',
                    isOpen: true
                  })
                  return
                }
                if (key === 'addContacts') {
                  setCurrentSignerAction({
                    id: null,
                    action: 'createWithContact',
                    isOpen: true
                  })
                  return
                }
                if (key === 'addMyself') {
                  setCurrentSignerContact({
                    name: userContext?.userAccountInformation?.name,
                    email: userContext?.userAccountInformation?.email
                  })
                  setCurrentSignerAction({
                    id: null,
                    action: 'create',
                    isOpen: true
                  })
                  return
                }
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`flex justify-between items-center border-t border-gray-300 pt-8 mt-8 gap-3`}
      >
        <Button
          onClick={() => {
            handleActionStep('previous')
          }}
          variant="neutral"
          className="w-fit"
        >
          {currentStepData?.buttonBack}
        </Button>
        <DropdownButton
          label={currentStepData?.action?.label}
          type="submit"
          dropdownItems={dataDropdownItems}
          loading={loadingEnvelopeSave}
          placement="top"
          onClick={(key, e) => {
            e.preventDefault()
            e.stopPropagation()
            if (key === 'save') {
              handleSaveEnvelope({
                publish: false
              })
              return
            }
            if (key === 'main') {
              if (documents.length === 0 || noSigners) {
                const messageToast =
                  documents.length === 0
                    ? t?.toasts?.noDocuments
                    : t?.toasts?.noSigners
                showToast.warning(messageToast)
                return
              }

              handleActionStep('next')
              return
            }
          }}
        />
      </div>
    </div>
  )
}

export default StepItemSignersEnvelope
