import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  ActionFavoriteContact,
  ContactResponse,
  Contacts,
  CreateContactRequest,
  UpdateContactRequest,
  UpdateContactRequestWithId
} from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { FiltersContactsType } from '@/hooks/useContactsData/useContactsData.types'

const useContactApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()
  const queryClient = useQueryClient()

  const useUserAccountContacts = (
    pageNumber = 0,
    pageSize = 10,
    filtersContacts: FiltersContactsType,
    enabled = true
  ) => {
    const url = '/ecm/contacts'
    const { query = '', typeFavorite } = filtersContacts

    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (query) {
      queryParams += `&search=${encodeURIComponent(query)}`
    }
    if (typeFavorite !== null) {
      queryParams += `&isFavorite=${typeFavorite === 'favorite'}`
    }

    const queryKey = [
      QueryKeys.UserAccountContacts,
      pageNumber,
      pageSize,
      query,
      typeFavorite,
      enabled
    ]

    const [debouncedQuery, setDebouncedQuery] = useState(query)

    useEffect(() => {
      if (!query) return
      const handler = setTimeout(() => {
        setDebouncedQuery(query)
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }, [query])

    const { data, error, isLoading, isSuccess, isError, isFetching } = useQuery(
      {
        queryKey,
        queryFn: () => handleApi<void, Contacts>(url + queryParams, 'GET'),
        ...queryConfig,
        enabled:
          !!authMetadata?.accessToken &&
          (debouncedQuery === query || !query) &&
          enabled
      }
    )

    return {
      data,
      error,
      isLoading: isLoading || isFetching,
      isSuccess,
      isError,
      isFetching
    }
  }

  const useCreateContact = () =>
    useMutation({
      mutationFn: (data: CreateContactRequest) => {
        return handleApi<CreateContactRequest, void>(
          `/ecm/contacts`,
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountContacts]
        })
      }
    })

  const useActionFavoriteContact = () =>
    useMutation({
      mutationFn: ({ action, id }: ActionFavoriteContact) => {
        return handleApi<ActionFavoriteContact, void>(
          `/ecm/contacts/${id}/${action}`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountContacts]
        })
      }
    })

  const useUpdateContact = () =>
    useMutation({
      mutationFn: ({ id, ...data }: UpdateContactRequestWithId) => {
        return handleApi<UpdateContactRequest, void>(
          `/ecm/contacts/${id}`,
          'PUT',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountContacts]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Contact]
        })
      }
    })

  const useGetContact = (id?: string | null) => {
    return useQuery({
      queryKey: [QueryKeys.Contact, id],
      queryFn: () =>
        handleApi<void, ContactResponse>(`/ecm/contacts/${id}`, 'GET'),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!id
    })
  }

  const useDeleteContact = () =>
    useMutation({
      mutationFn: (id: string) => handleApi(`/ecm/contacts/${id}`, 'DELETE'),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountContacts]
        })
      }
    })

  return {
    useUserAccountContacts,
    useCreateContact,
    useActionFavoriteContact,
    useUpdateContact,
    useGetContact,
    useDeleteContact
  }
}

export default useContactApi
