import React from 'react'
import CardEnvelopeSkeleton from './CardEnvelopeSkeleton'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'

type Item = {
  title: string | React.ReactNode
  value: string | React.ReactNode
}

type Props = {
  title?: string | React.ReactNode
  children?: React.ReactNode
  items?: Item[]
  className?: string
  emptyState?: boolean
  emptyStateTitle?: string
  emptyStateMessage?: string
  showSkeleton?: boolean
  classNameSkeleton?: string
  classNameRow?: string
}

const CardEnvelope: React.FC<Props> = ({
  title,
  children,
  items,
  className,
  emptyState = false,
  emptyStateTitle = 'No Data Available',
  emptyStateMessage = 'There is no information to display here at the moment.',
  showSkeleton = false,
  classNameSkeleton,
  classNameRow
}) => {
  if (showSkeleton) {
    return <CardEnvelopeSkeleton className={classNameSkeleton} />
  }
  return (
    <section className="flex flex-col bg-white border border-gray-300 shadow-sm dark:bg-gray-50 dark:text-black rounded-xl">
      {typeof title === 'string' ? (
        <Typography variant="text-base-medium" className="px-4 py-3">
          {title}
        </Typography>
      ) : (
        title
      )}
      {emptyState ? (
        <div className="flex flex-col items-center justify-center p-10 text-center border-t border-gray-300">
          <Typography variant="title-xl-medium" className="mb-2 text-gray-800">
            {emptyStateTitle}
          </Typography>
          <Typography variant="text-sm-regular" className="text-gray-500">
            {emptyStateMessage}
          </Typography>
        </div>
      ) : (
        <ul
          className={cn(
            'border-t border-gray-300 divide-y divide-gray-300',
            className
          )}
        >
          {items?.map((item, index) => {
            if (!item.value) return null
            return (
              <li
                key={index}
                className={cn(
                  'flex items-center justify-between px-4 py-2.5 text-sm gap-1',
                  classNameRow
                )}
              >
                {typeof item.title === 'string' ? (
                  <Typography
                    variant="text-sm-regular"
                    className="text-gray-500"
                  >
                    {item.title}
                  </Typography>
                ) : (
                  item.title
                )}
                {typeof item.value === 'string' ? (
                  <Typography
                    variant="text-sm-regular"
                    className="text-gray-500"
                  >
                    {item.value}
                  </Typography>
                ) : (
                  item.value
                )}
              </li>
            )
          })}
          {children}
        </ul>
      )}
    </section>
  )
}

export default CardEnvelope
