import React, { useCallback, useMemo } from 'react'
import {
  DevicePhoneMobileIcon,
  LockClosedIcon,
  PencilSquareIcon,
  TrashIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline'
import {
  useReactTable,
  getPaginationRowModel,
  flexRender,
  getCoreRowModel,
  createColumnHelper
} from '@tanstack/react-table'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md/index'
import useTranslation from '@/hooks/useTranslation'
import { CustomColumn } from '@/types/react-table-config'
import { Typography } from '@/atoms/index'
import { SignerEnvelopeStatus } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { cn } from '@/lib/utils'
import { useEnvelope } from '@/providers'
import { SignerEnvelopeInitial } from '@/providers/Envelope/Envelope.types'
import { Button } from '@/ui/atoms/shadcn'
import { ButtonWithTooltip, ConfirmPop, IconWithTooltip } from '@/ui/molecules'

type Props = {
  draggable?: boolean
}

const TableSignersDragAndDrop: React.FC<Props> = ({ draggable = true }) => {
  const { t } = useTranslation('envelope')

  const {
    signers,
    setSigners,
    currentStep,
    handleDeleteSigner,
    setCurrentSignerAction
  } = useEnvelope()

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  const columns: CustomColumn<SignerEnvelopeInitial>[] = useMemo(() => {
    return [
      {
        Header: currentStepData?.table?.signers,
        accessor: 'name',
        width: '60%',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex flex-col break-words">
              <Typography
                type="h4"
                variant="text-xs-medium"
                className="text-gray-700 break-words dark:text-gray-700"
              >
                {valuesOriginal?.name}
              </Typography>
              <Typography
                type="h4"
                variant="text-xs-medium"
                className="mb-1 text-gray-400 break-all"
              >
                {valuesOriginal?.email}
              </Typography>
              <Typography
                type="h4"
                variant="text-xs-medium"
                className="break-words text-secondary"
              >
                {t?.role}:{' '}
                {
                  t?.optionsRoles?.find(
                    (option: any) => option.value === valuesOriginal?.role
                  )?.label
                }
              </Typography>
            </div>
          )
        }
      },
      {
        Header: currentStepData?.table?.mfa,
        accessor: 'shouldEnforceEmailValidation',
        width: '20%',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const tooltipLockClosed = t?.tooltipInfo?.tooltipLockClosed(
            valuesOriginal?.shouldEnforcePasscodeValidation
          )
          const tooltipEnvelope = t?.tooltipInfo?.tooltipEnvelope(
            valuesOriginal?.shouldEnforceEmailValidation
          )
          const tooltipDevicePhoneMobile =
            t?.tooltipInfo?.tooltipDevicePhoneMobile(
              valuesOriginal?.shouldEnforceSmsValidation
            )

          return (
            <div className="flex items-center gap-3">
              <IconWithTooltip
                icon={
                  <LockClosedIcon
                    className={`h-4 w-4 ${
                      valuesOriginal?.shouldEnforcePasscodeValidation
                        ? 'text-success-600'
                        : 'text-gray-400'
                    }`}
                  />
                }
                className={`h-4 w-4 ${
                  valuesOriginal?.shouldEnforcePasscodeValidation
                    ? 'text-success-600'
                    : 'text-gray-400'
                }`}
                tooltipText={tooltipLockClosed}
              />
              <IconWithTooltip
                icon={
                  <EnvelopeIcon
                    className={`h-4 w-4 ${
                      valuesOriginal?.shouldEnforceEmailValidation
                        ? 'text-success-600'
                        : 'text-gray-400'
                    }`}
                  />
                }
                className={`h-4 w-4 ${
                  valuesOriginal?.shouldEnforceEmailValidation
                    ? 'text-success-600'
                    : 'text-gray-400'
                }`}
                tooltipText={tooltipEnvelope}
              />
              <IconWithTooltip
                icon={
                  <DevicePhoneMobileIcon
                    className={`h-4 w-4 ${
                      valuesOriginal?.shouldEnforceSmsValidation
                        ? 'text-success-600'
                        : 'text-gray-400'
                    }`}
                  />
                }
                className={`h-4 w-4 ${
                  valuesOriginal?.shouldEnforceSmsValidation
                    ? 'text-success-600'
                    : 'text-gray-400'
                }`}
                tooltipText={tooltipDevicePhoneMobile}
              />
            </div>
          )
        }
      },
      {
        Header: currentStepData?.table?.actions,
        accessor: 'email',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const currentSignerHasReviewed =
            valuesOriginal?.status === SignerEnvelopeStatus.Approved ||
            valuesOriginal?.status === SignerEnvelopeStatus.Rejected

          return (
            <div className="relative flex items-center gap-3">
              <ButtonWithTooltip
                variant="ghost"
                onClick={() => {
                  if (currentSignerHasReviewed) return
                  setCurrentSignerAction({
                    id: valuesOriginal?.id,
                    action: 'update',
                    isOpen: true
                  })
                }}
                disabled={currentSignerHasReviewed}
                size="icon"
                tooltipText={t?.tooltipInfo?.editSigner}
              >
                <PencilSquareIcon className="w-5 h-5" />
              </ButtonWithTooltip>
              <ConfirmPop
                title={t?.popConfirmSigner?.title}
                description={t?.popConfirmSigner?.description}
                confirmButtonText={t?.popConfirmSigner?.confirmButtonText}
                cancelButtonText={t?.popConfirmSigner?.cancelButtonText}
                onConfirm={() => {
                  if (currentSignerHasReviewed) return
                  handleDeleteSigner(valuesOriginal?.id)
                }}
                disabled={currentSignerHasReviewed}
              >
                <ButtonWithTooltip
                  variant="ghost"
                  disabled={currentSignerHasReviewed}
                  size="icon"
                  tooltipText={t?.tooltipInfo?.deleteSigner}
                >
                  <TrashIcon className="w-5 h-5" />
                </ButtonWithTooltip>
              </ConfirmPop>
            </div>
          )
        },
        width: '20%'
      }
    ]
  }, [
    currentStepData?.table?.actions,
    currentStepData?.table?.mfa,
    currentStepData?.table?.signers,
    handleDeleteSigner,
    setCurrentSignerAction,
    t?.optionsRoles,
    t?.popConfirmSigner?.cancelButtonText,
    t?.popConfirmSigner?.confirmButtonText,
    t?.popConfirmSigner?.description,
    t?.popConfirmSigner?.title,
    t?.role,
    t?.tooltipInfo
  ])

  const columnHelper = createColumnHelper<SignerEnvelopeInitial>()
  const columnsFormatted = columns.map((column) => {
    return columnHelper.accessor(column.accessor, {
      id: column.accessor,
      cell: column.Cell,
      header: column.Header,
      meta: {
        width: column.width,
        noInternalPadding: column.noInternalPadding
      }
    })
  })

  const table = useReactTable({
    columns: columnsFormatted,
    data: signers as any,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const handleDragEnd = useCallback(
    (e: any) => {
      if (!e.destination) return

      const sourceIndex = e.source.index
      const destinationIndex = e.destination.index

      if (
        signers[sourceIndex].isDragDisabled ||
        signers[destinationIndex].isDragDisabled
      )
        return

      const tempData = Array.from(signers)
      const [sourceData] = tempData.splice(sourceIndex, 1)
      tempData.splice(destinationIndex, 0, sourceData)
      setSigners(tempData)
    },
    [setSigners, signers]
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <DragDropContext onDragEnd={draggable ? handleDragEnd : () => {}}>
      <table className="relative w-full divide-y divide-gray-200 dark:divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <tr key={`headerGroup-${headerGroupIndex}`}>
              {draggable && (
                <th
                  className={`text-left text-sm font-medium text-gray-500 py-3 px-1 `}
                ></th>
              )}
              {headerGroup.headers.map((header, headerIndex) => (
                <th
                  style={{
                    width: header.column.columnDef?.meta?.width
                  }}
                  className={`text-left text-sm font-medium text-gray-500 py-3 px-1 ${
                    header.column.columnDef?.meta?.noInternalPadding
                      ? 'px-0'
                      : ''
                  }`}
                  key={`header-${headerGroupIndex}-${headerIndex}`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <Droppable droppableId="droppable-1" isDropDisabled={!draggable}>
          {(provider) => (
            <tbody
              ref={provider.innerRef}
              {...provider.droppableProps}
              className="w-full text-capitalize"
            >
              {table.getRowModel().rows.map((row, rowIndex) => {
                const rowId = row.original.id
                const isRowDragDisabled =
                  row.original.isDragDisabled || !draggable
                return (
                  <Draggable
                    key={`draggable-${rowId}`}
                    draggableId={`draggable-${rowId}`}
                    index={rowIndex}
                    isDragDisabled={isRowDragDisabled}
                  >
                    {(provider) => (
                      <tr
                        {...(isRowDragDisabled ? {} : provider.draggableProps)}
                        ref={provider.innerRef}
                        className="h-auto border-b border-gray-200"
                      >
                        {draggable && (
                          <td
                            {...(isRowDragDisabled
                              ? {}
                              : provider.dragHandleProps)}
                            className={cn(
                              isRowDragDisabled
                                ? 'cursor-not-allowed'
                                : 'cursor-move'
                            )}
                          >
                            <div
                              className={cn(
                                'flex items-center h-auto px-1 py-3',
                                isRowDragDisabled &&
                                  'opacity-50 cursor-not-allowed'
                              )}
                            >
                              <Typography
                                variant="text-xs-medium"
                                className="text-black dark:text-white"
                              >
                                {rowIndex + 1}
                              </Typography>
                              <MdDragIndicator className="w-5 h-5 text-gray-400" />
                            </div>
                          </td>
                        )}
                        {row.getVisibleCells().map((cell, cellIndex) => {
                          const cellKey = `cell-${rowId}-${
                            cell.column.id || cellIndex
                          }`
                          return (
                            <td
                              className={`whitespace-pre-wrap break-words text-xs py-3 px-1 ${
                                cell.column.columnDef?.meta?.noInternalPadding
                                  ? 'px-0'
                                  : ''
                              }`}
                              style={{
                                width: cell.column.columnDef?.meta?.width
                              }}
                              key={cellKey}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )}
                  </Draggable>
                )
              })}
              {provider.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  )
}

export default TableSignersDragAndDrop
