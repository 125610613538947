import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  ButtonWithTooltip,
  Form,
  RenderFormField,
  RenderFormActions,
  Modal
} from '..'
import useVaultApi from '@/hooks/api/ecm/useVaultApi/useVaultApi'
import {
  ActionModalVaultType,
  DataModalActionVaultType,
  VaultFormType
} from '@/hooks/useVaultsData/useVaultsData.types'
import { RadioGroupPanel, SelectUsers } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  id?: string
  isOpen: boolean
  action: ActionModalVaultType
  setDataModalActionVault?: React.Dispatch<
    SetStateAction<DataModalActionVaultType>
  >
  onClose: () => void
  onSubmit: (
    values: VaultFormType,
    action: DataModalActionVaultType['action']
  ) => Promise<void> | void
  canBeRemoved?: boolean
  isLoading?: boolean
  nameInitial?: string
}

const ModalActionVault: React.FC<Props> = ({
  id,
  isOpen,
  action,
  setDataModalActionVault,
  onClose,
  onSubmit,
  canBeRemoved = true,
  isLoading = false,
  nameInitial
}) => {
  const { t, isReady } = useTranslation(['vaults', 'validations'], true)
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(false)
  const { useGetVault } = useVaultApi()
  const { data: dataVault, isLoading: isLoadingGetVault } = useGetVault(
    id ? id : null
  )

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z
      .string()
      .max(400, { message: t?.errorMaxLength?.(400) })
      .optional(),
    type: z.string(),
    userAccountMemberIds: z.array(z.string()).optional()
  })

  const title = useMemo(() => {
    const mappingTitles = {
      createVault: t?.titleModalCreate,
      updateVault: t?.titleModalUpdate,
      viewVault: t?.titleModalView
    }

    return mappingTitles[action as keyof typeof mappingTitles]
  }, [action, t?.titleModalCreate, t?.titleModalUpdate, t?.titleModalView])

  const formFields = useMemo(() => {
    return t?.modalActionVaults?.form
  }, [t?.modalActionVaults?.form])

  const form = useForm<VaultFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      type: 'Account',
      description: '',
      userAccountMemberIds: []
    }
  })
  const valueName = useWatch({
    control: form.control,
    name: 'name'
  })
  const valueType = useWatch({
    control: form.control,
    name: 'type'
  })
  const valueSelectUsers = useWatch({
    control: form.control,
    name: 'userAccountMemberIds'
  })

  const loadingSkeleton = useMemo(() => {
    if (action === 'createVault') {
      return !isReady
    }

    return isLoadingGetVault || !isReady || !valueName
  }, [action, isLoadingGetVault, isReady, valueName])

  useEffect(() => {
    if (action === 'createVault') {
      form.reset({
        name: nameInitial || '',
        type: 'Account',
        description: '',
        userAccountMemberIds: []
      })

      return
    }
    if ((action === 'updateVault' || action === 'viewVault') && dataVault) {
      const userAccountMemberIds = dataVault?.userAccountMembers?.map(
        (user) => user.id
      )
      form.reset({
        name: dataVault?.name || '',
        type: dataVault?.type || 'Account',
        description: dataVault?.description || '',
        userAccountMemberIds
      })

      if (
        dataVault?.type &&
        (dataVault?.type === 'AccountRoot' ||
          dataVault?.type === 'UserAccountRoot')
      ) {
        setDisabledButtonDelete(true)
      }
    }
  }, [
    dataVault,
    action,
    nameInitial,
    form,
    t?.modalActionVaults?.form?.type?.itemsRadio
  ])

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      displayAsDrawerOnMobile
    >
      <Form
        {...form}
        onSubmit={async (valuesSubmit) => await onSubmit(valuesSubmit, action)}
      >
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          disabled={action === 'viewVault'}
          showSkeleton={loadingSkeleton}
          {...form.formState.errors.name}
          {...formFields?.name}
        />
        <RadioGroupPanel
          value={valueType as any}
          onChange={(value) => {
            if (value !== valueType) {
              form.setValue('type', value as VaultFormType['type'])
            }
          }}
          items={formFields?.type?.itemsRadio}
          label={formFields?.type?.label}
          name={formFields?.type?.name}
          disabled={action === 'viewVault' || action === 'updateVault'}
          showSkeleton={loadingSkeleton}
        />
        {valueType === 'UserAccountGroup' && (
          <SelectUsers
            name={formFields?.userAccountMemberIds?.name}
            value={valueSelectUsers as string[]}
            onSelected={(option) => {
              form.setValue('userAccountMemberIds', option as string[])
            }}
            label={formFields?.userAccountMemberIds?.label}
            placeholder={formFields?.userAccountMemberIds?.placeholder}
            showSkeleton={loadingSkeleton}
            error={form.formState.errors.userAccountMemberIds as any}
            disabled={action === 'viewVault'}
          />
        )}
        <RenderFormField
          control={form.control}
          name="description"
          type="textarea"
          disabled={action === 'viewVault'}
          showSkeleton={loadingSkeleton}
          {...form.formState.errors.description}
          {...formFields?.description}
        />
        <RenderFormActions align="between">
          {action === 'viewVault' && (
            <>
              <div className="flex gap-4">
                <ButtonWithTooltip
                  variant="outlineDestructive"
                  type="button"
                  disabled={disabledButtonDelete || !canBeRemoved}
                  onClick={() => {
                    if (!canBeRemoved) {
                      return
                    }
                    setDataModalActionVault?.({
                      isOpen: true,
                      action: 'deleteVault',
                      id: id as string
                    })
                  }}
                  showSkeleton={loadingSkeleton}
                  tooltipText={t?.tooltipDelete}
                >
                  <TrashIcon className="w-4 h-4" />
                </ButtonWithTooltip>
                <Button
                  className="w-fit"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    setDataModalActionVault?.({
                      isOpen: true,
                      action: 'updateVault',
                      id: id as string
                    })
                  }}
                  loading={isLoading}
                  showSkeleton={loadingSkeleton}
                >
                  {formFields?.buttonUpdate?.label}
                </Button>
              </div>
              <Button
                onClick={onClose}
                className="w-fit"
                variant="neutral"
                type="button"
                showSkeleton={loadingSkeleton}
              >
                {formFields?.buttonClose?.label}
              </Button>
            </>
          )}
          {(action === 'createVault' || action === 'updateVault') && (
            <>
              <Button
                onClick={onClose}
                className="w-fit"
                variant="neutral"
                type="button"
                showSkeleton={loadingSkeleton}
              >
                {formFields?.buttonCancel?.label}
              </Button>
              <Button
                className="w-fit"
                type="submit"
                loading={isLoading}
                showSkeleton={loadingSkeleton}
              >
                {formFields?.buttonSubmitSave?.label}
              </Button>
            </>
          )}
          {action === 'deleteVault' && (
            <>
              <ButtonWithTooltip
                variant="outlineDestructive"
                type="button"
                disabled={disabledButtonDelete || !canBeRemoved}
                onClick={() => {
                  if (!canBeRemoved) {
                    return
                  }
                  setDataModalActionVault?.({
                    isOpen: true,
                    action: 'deleteVault',
                    id: id as string
                  })
                }}
                showSkeleton={loadingSkeleton}
                tooltipText={t?.tooltipDelete}
              >
                <TrashIcon className="w-4 h-4" />
              </ButtonWithTooltip>
              <Button
                className="w-fit"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setDataModalActionVault?.({
                    isOpen: true,
                    action: 'updateVault',
                    id: id as string
                  })
                }}
                loading={isLoading}
                showSkeleton={loadingSkeleton}
              >
                {formFields?.buttonUpdate?.label}
              </Button>
            </>
          )}
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalActionVault
