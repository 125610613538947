import React from 'react'
import { FieldValues, Control, FieldPath, UseFormReturn } from 'react-hook-form'
import { cn } from '@/lib/utils'
import {
  DatePicker,
  Input,
  InputPhone,
  InputWithMask,
  Select,
  SelectOption,
  SelectSearch,
  Textarea,
  Toggle,
  TokenInput
} from '@/ui/atoms'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form as FormShadcn
} from '@/ui/molecules/shadcn/Form'

type FormProps<TFieldValues extends FieldValues> = {
  children: React.ReactNode
  onSubmit: (values: TFieldValues) => void
  className?: string
} & UseFormReturn<TFieldValues, any, undefined>

const Form = <TFieldValues extends FieldValues>({
  children,
  onSubmit,
  className,
  ...form
}: FormProps<TFieldValues>) => (
  <FormShadcn<TFieldValues> {...form}>
    <form
      className={cn('grid gap-5 w-full', className)}
      noValidate
      onSubmit={form.handleSubmit((values) => onSubmit(values))}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
    >
      {children}
    </form>
  </FormShadcn>
)

type RenderFormRowProps = {
  children: React.ReactNode
  columns?: number
  gap?: number
  className?: string
}

const RenderFormRow: React.FC<RenderFormRowProps> = ({
  children,
  className,
  columns = 2,
  gap = 4
}) => {
  return (
    <div
      className={cn(
        `grid gap-${gap} grid-cols-1 sm:grid-cols-${columns}`,
        className
      )}
    >
      {children}
    </div>
  )
}

type FormFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>
  name: FieldPath<TFieldValues>
  label: string
  type?:
    | 'input'
    | 'inputPhone'
    | 'inputMask'
    | 'select'
    | 'selectSearch'
    | 'textarea'
    | 'password'
    | 'tokenInput'
    | 'toggle'
    | 'datepicker'
  maskType?: 'cpf' | 'cnpj' | 'cep'
  customMask?: string | (string | RegExp)[]
  placeholder?: string
  options?: SelectOption[]
  required?: boolean
  disabled?: boolean
  showSkeleton?: boolean
  fullWidth?: boolean
  ref?: any
  className?: string
  render?: (props: {
    field: {
      name: FieldPath<TFieldValues>
      value: any
      label: string
      onChange: (value: any) => void
      onBlur: () => void
    }
    fieldState: any
  }) => React.ReactNode
}

const RenderFormField = <TFieldValues extends FieldValues>({
  control,
  name,
  maskType,
  customMask,
  type = 'input',
  options,
  render,
  className,
  ...props
}: FormFieldProps<TFieldValues>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message

        const mappingFields = {
          input: (
            <Input {...field} {...props} type="text" error={errorMessage} />
          ),
          password: (
            <Input
              {...field}
              {...props}
              type="password"
              error={errorMessage}
              showPasswordToggle
            />
          ),
          select: (
            <Select
              {...field}
              {...props}
              fullWidth
              options={options as SelectOption[]}
              error={errorMessage}
            />
          ),
          selectSearch: <SelectSearch {...field} {...props} />,
          textarea: <Textarea {...field} {...props} error={errorMessage} />,
          tokenInput: <TokenInput {...field} {...props} size="sm" />,
          toggle: <Toggle {...field} {...props} checked={field.value} />,
          datepicker: <DatePicker {...field} {...props} error={errorMessage} />,
          inputPhone: (
            <InputPhone
              {...field}
              {...props}
              error={errorMessage}
              placeholder={props.placeholder}
              international
            />
          ),
          inputMask: (
            <InputWithMask
              {...field}
              {...props}
              maskType={maskType}
              customMask={customMask}
              error={errorMessage}
            />
          )
        }

        return (
          <FormItem className={cn('flex-1', className)} id={name}>
            <FormControl>
              {render
                ? (render({
                    field: {
                      ...field,
                      label: props.label
                    },
                    fieldState
                  }) as React.ReactElement)
                : mappingFields[type as keyof typeof mappingFields]}
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

type RenderFormActionsProps = {
  children: React.ReactNode
  align?: 'between' | 'end' | 'start'
  className?: string
}

const RenderFormActions: React.FC<RenderFormActionsProps> = ({
  children,
  className,
  align = 'end'
}) => {
  const classNameAlign = {
    between: 'justify-between',
    end: 'justify-end',
    start: 'justify-start'
  }[align]

  return (
    <div
      className={cn(
        'flex justify-end gap-4 pt-6 border-t border-gray-200',
        classNameAlign,
        className
      )}
    >
      {children}
    </div>
  )
}

export { Form, RenderFormRow, RenderFormField, RenderFormActions }
