import React, { useMemo, useState } from 'react'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { SignatureType } from '@/types/constants'
import { CustomColumn } from '@/types/react-table-config'
import { Table } from '@/organisms/index'
import { EmptyStateTable, Modal } from '..'
import { SignatureMark } from '@/hooks/api/administration/profile/user/signatures/useSignaturesApi.types'
import { IBreakpoint } from '@/hooks/useViewport/useViewport'
import { cn } from '@/lib/utils'
import { useLocale } from '@/providers'
import { DialogResultType } from '@/providers/ReviewEnvelope/ReviewEnvelope.types'
import { Avatar, Typography } from '@/ui/atoms'
import { Badge, Button } from '@/ui/atoms/shadcn'
import { formatData } from '@/utils'

enum Section {
  VerifyApplySignature = 'VerifyApplySignature',
  ListSignatures = 'ListSignatures'
}

type Props = {
  open: boolean
  onClose: () => void
  type: 'signatureList' | 'rubricList'
  onAddSignature?: (signature: DialogResultType) => void
  onHandleClickCancel?: (type: 'signature' | 'rubric') => void
}

const ModalListSignatures: React.FC<Props> = ({
  open,
  onClose,
  type,
  onAddSignature,
  onHandleClickCancel
}) => {
  const { t } = useTranslation('reviewEnvelope')
  const [currentSection, setCurrentSection] = useState<Section>(
    Section.VerifyApplySignature
  )
  const {
    dataListSignature,
    dataListRubric,
    tablePaginationRubric,
    tablePaginationSignature
  } = useReviewEnvelope()

  const { lang } = useLocale()
  const { breakpoint } = useViewport()

  const title = useMemo(() => {
    if (type === 'signatureList') {
      return currentSection === Section.VerifyApplySignature
        ? t?.modalListSignatures?.titleApply('signature')
        : t?.modalListSignatures?.title('signature')
    }
    if (type === 'rubricList') {
      return currentSection === Section.VerifyApplySignature
        ? t?.modalListSignatures?.titleApply('rubric')
        : t?.modalListSignatures?.title('rubric')
    }
  }, [currentSection, t?.modalListSignatures, type])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      create: { width: '30%', minWidth: '150px' },
      type: { width: '25%', minWidth: '120px' },
      signature: { width: '35%', minWidth: '170px' },
      action: { width: '10%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columnsBase: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      {
        Header: t?.modalListSignatures?.tableInformation?.create || '',
        accessor: 'createdAtUtc',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal?.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.modalListSignatures?.tableInformation?.type || '',
        accessor: 'style',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          const item = t?.modalListSignatures?.tableInformation?.mappingTypes?.[
            valuesOriginal.style as 'Draw' | 'Simulated' | 'Upload'
          ] || {
            label: '',
            color: ''
          }

          return (
            <div
              className={
                !value ? 'flex justify-center items-center text-accent-500' : ''
              }
            >
              {value ? (
                <Badge size="sm" className={item?.color} tooltip={item?.label}>
                  {item?.label}
                </Badge>
              ) : (
                '-'
              )}
            </div>
          )
        },
        ...tableColumnWidth.type
      },
      {
        Header: t?.modalListSignatures?.tableInformation?.image || '',
        accessor: 'text',
        Cell: (value) => {
          const [isLoadingImage, setIsLoadingImage] = useState(true)
          const valuesOriginal = value?.row?.original

          const handleLoadingComplete = () => {
            setIsLoadingImage(false)
          }

          return (
            <div className="relative">
              {isLoadingImage && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-8 w-36 skeleton" />
                </div>
              )}
              <div className="relative h-8">
                <Image
                  src={valuesOriginal.imageUrl}
                  layout="fill"
                  objectFit="contain"
                  alt="Signature"
                  onLoadingComplete={handleLoadingComplete}
                  className={cn(
                    isLoadingImage ? 'opacity-0' : 'opacity-100',
                    'transition-opacity duration-500'
                  )}
                />
              </div>
            </div>
          )
        },
        ...tableColumnWidth.signature
      }
    ]
  }, [
    lang,
    t?.modalListSignatures?.tableInformation?.create,
    t?.modalListSignatures?.tableInformation?.image,
    t?.modalListSignatures?.tableInformation?.mappingTypes,
    t?.modalListSignatures?.tableInformation?.type,
    tableColumnWidth.create,
    tableColumnWidth.signature,
    tableColumnWidth.type
  ])

  const columnsSignature: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      ...columnsBase,
      {
        Header: t?.modalListSignatures?.tableInformation?.action || '',
        accessor: 'action',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex-1">
              <Button
                variant="outlineSecondary"
                size="sm"
                className="px-2 py-1 text-xs whitespace-nowrap"
                onClick={() => {
                  onClose && onClose()
                  setCurrentSection(Section.VerifyApplySignature)
                  if (onAddSignature) {
                    onAddSignature({
                      signatureId: valuesOriginal.id,
                      signatureType:
                        type === 'rubricList'
                          ? SignatureType.Rubric
                          : SignatureType.Signature,
                      signatureStyle: valuesOriginal.style,
                      signatureImage: valuesOriginal.imageUrl,
                      signatureText: valuesOriginal.text,
                      fontTypedSignature: valuesOriginal.font
                    })
                  }
                }}
              >
                {t?.modalListSignatures?.tableInformation?.addSignature}
              </Button>
            </div>
          )
        },
        ...tableColumnWidth.action
      }
    ]
  }, [
    columnsBase,
    onAddSignature,
    onClose,
    t?.modalListSignatures?.tableInformation?.action,
    t?.modalListSignatures?.tableInformation?.addSignature,
    tableColumnWidth.action,
    type
  ])

  const columnsRubric: CustomColumn<SignatureMark>[] = useMemo(() => {
    return [
      ...columnsBase,
      {
        Header: t?.modalListSignatures?.tableInformation?.action || '',
        accessor: 'action',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex-1">
              <Button
                variant="outlineSecondary"
                size="sm"
                className="px-2 py-1 text-xs whitespace-nowrap"
                onClick={() => {
                  onClose && onClose()
                  setCurrentSection(Section.VerifyApplySignature)
                  if (onAddSignature) {
                    onAddSignature({
                      signatureId: valuesOriginal.id,
                      signatureType:
                        type === 'rubricList'
                          ? SignatureType.Rubric
                          : SignatureType.Signature,
                      signatureStyle: valuesOriginal.style,
                      signatureImage: valuesOriginal.imageUrl,
                      signatureText: valuesOriginal.text,
                      fontTypedSignature: valuesOriginal.font
                    })
                  }
                }}
              >
                {t?.modalListSignatures?.tableInformation?.addRubric}
              </Button>
            </div>
          )
        },
        ...tableColumnWidth.action
      }
    ]
  }, [
    columnsBase,
    onAddSignature,
    onClose,
    t?.modalListSignatures?.tableInformation?.action,
    t?.modalListSignatures?.tableInformation?.addRubric,
    tableColumnWidth.action,
    type
  ])

  const contentSections = useMemo(() => {
    return {
      [Section.VerifyApplySignature]: (
        <div className="flex flex-col">
          <Typography variant="text-sm-regular">
            {t?.modalListSignatures?.descriptionApply(
              type === 'rubricList' ? 'rubric' : 'signature'
            )}
          </Typography>
          <div className="flex justify-end gap-2 pt-5 mt-5 border-t border-gray-300">
            <Button
              onClick={() => {
                if (onHandleClickCancel) {
                  onHandleClickCancel(
                    type === 'rubricList' ? 'rubric' : 'signature'
                  )
                }
                setCurrentSection(Section.VerifyApplySignature)
              }}
              variant="neutral"
              type="button"
            >
              {t?.modalListSignatures?.buttonCreateNew}
            </Button>
            <Button
              onClick={() => {
                setCurrentSection(Section.ListSignatures)
              }}
              type="button"
            >
              {t?.modalListSignatures?.buttonView(
                type === 'rubricList' ? 'rubric' : 'signature'
              )}
            </Button>
          </div>
        </div>
      ),
      [Section.ListSignatures]: (
        <div className="h-full">
          {type === 'signatureList' && (
            <>
              {dataListSignature?.isLoading ||
              (dataListSignature?.isSuccess &&
                dataListSignature?.data &&
                dataListSignature?.data?.items?.length > 0) ? (
                <div className="flex items-center justify-center">
                  <Table<SignatureMark>
                    columns={columnsSignature}
                    data={dataListSignature?.data?.items || []}
                    fetchData={tablePaginationSignature.setPageNumber}
                    canPreviousPage={tablePaginationSignature.pageNumber > 0}
                    canNextPage={
                      (dataListSignature?.data?.pagination &&
                        dataListSignature?.data?.pagination?.totalItems /
                          dataListSignature?.data?.pagination?.pageSize >
                          tablePaginationSignature.pageNumber + 1) ||
                      false
                    }
                    pagination={dataListSignature?.data?.pagination}
                    isLoading={dataListSignature?.isLoading}
                    pageSize={tablePaginationSignature.pageSize}
                    pageNumber={tablePaginationSignature.pageNumber}
                    setPageSize={tablePaginationSignature.setPageSize}
                    setPageNumber={tablePaginationSignature.setPageNumber}
                  />
                </div>
              ) : (
                <EmptyStateTable type="profileSignatures" />
              )}
            </>
          )}
          {type === 'rubricList' && (
            <>
              {dataListRubric?.isLoading ||
              (dataListRubric?.isSuccess &&
                dataListRubric?.data &&
                dataListRubric?.data?.items?.length > 0) ? (
                <div className="flex items-center justify-center">
                  <Table<SignatureMark>
                    columns={columnsRubric}
                    data={dataListRubric?.data?.items || []}
                    fetchData={tablePaginationRubric.setPageNumber}
                    canPreviousPage={tablePaginationRubric.pageNumber > 0}
                    canNextPage={
                      (dataListRubric?.data?.pagination &&
                        dataListRubric?.data?.pagination?.totalItems /
                          dataListRubric?.data?.pagination?.pageSize >
                          tablePaginationRubric.pageNumber + 1) ||
                      false
                    }
                    pagination={dataListRubric?.data?.pagination}
                    isLoading={dataListRubric?.isLoading}
                    pageSize={tablePaginationRubric.pageSize}
                    pageNumber={tablePaginationRubric.pageNumber}
                    setPageSize={tablePaginationRubric.setPageSize}
                    setPageNumber={tablePaginationRubric.setPageNumber}
                  />
                </div>
              ) : (
                <EmptyStateTable type="profileRubrics" />
              )}
            </>
          )}
        </div>
      )
    }
  }, [
    columnsRubric,
    columnsSignature,
    dataListRubric?.data,
    dataListRubric?.isLoading,
    dataListRubric?.isSuccess,
    dataListSignature?.data,
    dataListSignature?.isLoading,
    dataListSignature?.isSuccess,
    onHandleClickCancel,
    t?.modalListSignatures,
    tablePaginationRubric.pageNumber,
    tablePaginationRubric.pageSize,
    tablePaginationRubric.setPageNumber,
    tablePaginationRubric.setPageSize,
    tablePaginationSignature.pageNumber,
    tablePaginationSignature.pageSize,
    tablePaginationSignature.setPageNumber,
    tablePaginationSignature.setPageSize,
    type
  ])

  return (
    <Modal
      title={title}
      isOpen={open}
      onClose={() => {
        onClose()
        setCurrentSection(Section.VerifyApplySignature)
      }}
      size={currentSection === Section.VerifyApplySignature ? 'md' : 'lg'}
    >
      {contentSections[currentSection]}
    </Modal>
  )
}

export default ModalListSignatures
