import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  IUserAccounts,
  UserAccountsActivate,
  UserAccountsChangeRole,
  UserAccountsChangeRoleWithId
} from '@/hooks/api/administration/useUserAccountApi/useUserAccountApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useUserAccountApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { isAdmin, isLogged } = useAuth()

  const useUserAccountsActive = (
    pageNumber = 0,
    pageSize = 10,
    typeView: 'active' | 'inactive' | 'invite'
  ) =>
    useQuery({
      queryKey: [QueryKeys.UserAccountsActive, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, IUserAccounts>(
          `/administration/user-accounts/active?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(typeView === 'active' && isLogged && isAdmin)
    })

  const useUserAccountsInactive = (
    pageNumber = 0,
    pageSize = 10,
    typeView: 'active' | 'inactive' | 'invite'
  ) =>
    useQuery({
      queryKey: [QueryKeys.UserAccountsInactive, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, IUserAccounts>(
          `/administration/user-accounts/inactive?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(typeView === 'inactive' && isLogged && isAdmin)
    })

  const useUserAccountsActionUpdate = () => {
    return useMutation({
      mutationFn: ({ action, id }: UserAccountsActivate) => {
        return handleApi<UserAccountsActivate, void>(
          `/administration/user-accounts/${id}/${action}`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountsActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountsInactive]
        })
      }
    })
  }

  const useUserAccountsChangeRole = () => {
    return useMutation({
      mutationFn: (data: UserAccountsChangeRoleWithId) => {
        const { id, ...rest } = data
        return handleApi<UserAccountsChangeRole, void>(
          `/administration/user-accounts/${id}/role`,
          'PATCH',
          rest
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountsActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserAccountsInactive]
        })
      }
    })
  }

  return {
    useUserAccountsActive,
    useUserAccountsInactive,
    useUserAccountsActionUpdate,
    useUserAccountsChangeRole
  }
}

export default useUserAccountApi
