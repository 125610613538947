import { useMemo } from 'react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { usePlans } from '@/providers'

type Plan = {
  name: string
  id: string
  featured: boolean
  description: string
  minUsersMessage: string
}

type PlanFeatureMap = {
  [key: string]: boolean | string
}

type Feature = {
  name: string
  plans: PlanFeatureMap
}

type Section = {
  name: string
  features: Feature[]
}

const PlansTable: React.FC = () => {
  const { t, isReady } = useTranslation('plans')
  const { isLoading: isLoadingPlansList } = usePlans()

  const isLoading = useMemo(() => {
    return (
      !t?.pricingTable?.plans?.length ||
      !t?.pricingTable?.sections?.length ||
      !isReady ||
      isLoadingPlansList
    )
  }, [
    isLoadingPlansList,
    isReady,
    t?.pricingTable?.plans?.length,
    t?.pricingTable?.sections?.length
  ])

  if (isLoading) return null

  return (
    <section className="flex flex-col px-4 py-8 mx-auto bg-white md:p-8 md:py-16 dark:bg-black max-w-7xl rounded-3xl">
      <div className="flex justify-center mb-14">
        <Typography variant="title-2xl-medium">
          {t.pricingTable.title}
        </Typography>
      </div>

      <div className="lg:hidden">
        <div className="max-w-2xl mx-auto space-y-16">
          {t?.pricingTable?.plans?.map((plan: Plan, id: any) => (
            <div
              key={`plan-${plan.id}-${id}`}
              className="border-t border-gray-900/10"
            >
              <div
                className={classNames(
                  plan.featured ? 'border-secondary-700' : 'border-transparent',
                  '-mt-px w-70 border-t-2 pt-10 md:w-80'
                )}
              >
                <h3
                  className={classNames(
                    plan.featured ? 'text-secondary-700' : 'text-gray-900',
                    'text-sm font-semibold leading-6'
                  )}
                >
                  {plan.name}
                </h3>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {plan.description}
                </p>
                <p className="mt-1 text-xs font-bold text-gray-600 leading-2">
                  {plan.minUsersMessage}
                </p>
              </div>

              <div className="mt-10 space-y-10">
                {t.pricingTable?.sections?.map((section: Section, id: any) => (
                  <div key={`${section.name}-${id}`}>
                    <h4 className="text-sm font-semibold leading-6 text-gray-900">
                      {section.name}
                    </h4>
                    <div className="relative mt-6">
                      <div
                        aria-hidden="true"
                        className="absolute inset-y-0 right-0 hidden w-1/2 bg-white rounded-lg shadow-sm dark:bg-black sm:block"
                      />

                      <div
                        className={classNames(
                          plan.featured
                            ? 'ring-2 ring-secondary-700'
                            : 'ring-1 ring-gray-900/10',
                          'relative rounded-lg bg-white dark:bg-black shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                        )}
                      >
                        <dl className="text-sm leading-6 divide-y divide-gray-200">
                          {section.features.map((feature, id) => (
                            <div
                              key={`${feature.name}-${id}`}
                              className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                            >
                              <dt className="pr-4 text-gray-600">
                                {feature.name}
                              </dt>
                              <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                {typeof feature.plans[plan.name] ===
                                'string' ? (
                                  <span
                                    className={
                                      plan.featured
                                        ? 'font-semibold text-secondary-700'
                                        : 'text-gray-900'
                                    }
                                  >
                                    {feature.plans[plan.name]}
                                  </span>
                                ) : (
                                  <>
                                    {feature.plans[plan.name] === true ? (
                                      <CheckIcon
                                        className="w-5 h-5 mx-auto text-secondary-700"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <XMarkIcon
                                        className="w-5 h-5 mx-auto text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </dd>
                            </div>
                          ))}
                        </dl>
                      </div>

                      <div
                        aria-hidden="true"
                        className={classNames(
                          plan.featured
                            ? 'ring-2 ring-secondary-700'
                            : 'ring-1 ring-gray-900/10',
                          'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                        )}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="hidden lg:block">
        <div className="grid grid-cols-4 border-t gap-x-8 border-gray-900/10 before:block">
          {t?.pricingTable?.plans?.map((plan: Plan, id: any) => (
            <div key={`${plan.id}-${id}`} aria-hidden="true" className="-mt-px">
              <div
                className={classNames(
                  plan.featured ? 'border-secondary-700' : 'border-transparent',
                  'border-t-2 pt-10'
                )}
              >
                <p
                  className={classNames(
                    plan.featured ? 'text-secondary-700' : 'text-gray-900',
                    'text-sm font-semibold leading-6'
                  )}
                >
                  {plan.name}
                </p>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {plan.description}
                </p>
                <p className="mt-1 text-xs font-bold text-gray-800 leading-2">
                  {plan.minUsersMessage}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="-mt-6 space-y-16">
          {t?.pricingTable?.sections?.map((section: Section, id: any) => (
            <div key={`${section.name}-${id}`}>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">
                {section.name}
              </h3>
              <div className="relative mt-10 -mx-8">
                <div
                  className="absolute inset-y-0 grid grid-cols-4 inset-x-8 gap-x-8 before:block"
                  aria-hidden="true"
                >
                  <div className="w-full h-full bg-white rounded-lg shadow-sm dark:bg-black" />
                  <div className="w-full h-full bg-white rounded-lg shadow-sm dark:bg-black" />
                  <div className="w-full h-full bg-white rounded-lg shadow-sm dark:bg-black" />
                </div>

                <table className="relative w-full border-separate border-spacing-x-8">
                  <thead>
                    <tr className="text-left">
                      <th scope="col">
                        <span className="sr-only">Feature</span>
                      </th>
                      {t?.pricingTable?.plans?.map((plan: Plan, id: any) => (
                        <th key={`${plan.id}-${id}`} scope="col">
                          <span className="sr-only">{plan.name} plan</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section?.features?.map(
                      (feature: Feature, featureIdx: number) => (
                        <tr key={`${feature.name}-${featureIdx}`}>
                          <th
                            scope="row"
                            className="w-1/4 py-3 pr-4 text-sm font-normal leading-6 text-left text-gray-900"
                          >
                            {feature.name}
                            {featureIdx !== section.features.length - 1 ? (
                              <div className="absolute h-px mt-3 bg-gray-200 inset-x-8" />
                            ) : null}
                          </th>
                          {t?.pricingTable?.plans?.map(
                            (plan: Plan, id: any) => (
                              <td
                                key={`${plan.id}-${id}-${featureIdx}`}
                                className="relative w-1/4 px-4 py-0 text-center"
                              >
                                <span className="relative w-full h-full py-3">
                                  {typeof feature.plans[plan.name] ===
                                  'string' ? (
                                    <span
                                      className={classNames(
                                        plan.featured
                                          ? 'font-semibold text-secondary-700'
                                          : 'text-gray-900',
                                        'text-sm leading-6'
                                      )}
                                    >
                                      {feature.plans[plan.name]}
                                    </span>
                                  ) : (
                                    <>
                                      {feature.plans[plan.name] === true ? (
                                        <CheckIcon
                                          className="w-5 h-5 mx-auto text-secondary-700"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <XMarkIcon
                                          className="w-5 h-5 mx-auto text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </>
                                  )}
                                </span>
                              </td>
                            )
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>

                <div
                  className="absolute inset-y-0 grid grid-cols-4 pointer-events-none inset-x-8 gap-x-8 before:block"
                  aria-hidden="true"
                >
                  {t?.pricingTable?.plans?.map((plan: Plan, id: any) => (
                    <div
                      key={`${plan.id}-${id}`}
                      className={classNames(
                        plan.featured
                          ? 'ring-2 ring-secondary-700'
                          : 'ring-1 ring-gray-900/10',
                        'rounded-lg'
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default PlansTable
