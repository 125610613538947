import React, { useMemo } from 'react'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelope } from '@/providers/index'
import Typography from '@/atoms/Typography'

const EmptyStateSigner: React.FC = () => {
  const { currentStep } = useEnvelope()

  const { t } = useTranslation('envelope')

  const currentStepData = useMemo(() => {
    return t?.steps?.[currentStep.stepName]
  }, [currentStep, t?.steps])

  return (
    <div className="flex flex-col items-center gap-5">
      <Image
        src="/assets/icons/empty-signers.svg"
        alt="Empty Signers"
        width={160}
        height={160}
      />
      <div className="flex flex-col items-center gap-1">
        <Typography
          type="h4"
          variant="title-lg-medium"
          className="text-gray-700 dark:text-gray-700"
        >
          {currentStepData?.emptyStateSigner}
        </Typography>
        <Typography
          type="p"
          variant="text-sm-regular"
          className="text-gray-500"
        >
          {currentStepData?.addSigner}
        </Typography>
      </div>
    </div>
  )
}

export default EmptyStateSigner
