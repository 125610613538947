import { CurrencyDollarIcon } from '@heroicons/react/24/outline'

const optionsRoles = [
  {
    label: 'Signatário',
    value: 'Sign'
  },
  {
    label: 'Aprovador',
    value: 'Approve'
  },
  {
    label: 'Reconhecedor',
    value: 'Recognize'
  },
  {
    label: 'Testemunha',
    value: 'Testify'
  },
  {
    label: 'Confirmar recebimento',
    value: 'ConfirmReceiving'
  },
  {
    label: 'Endossar em preto',
    value: 'EndorseInBlack'
  },
  {
    label: 'Endossar em branco',
    value: 'EndorseInWhite'
  }
]

const optionsMarkupOrientation = [
  {
    value: 'None',
    label: 'Nenhum'
  },
  {
    value: 'Top',
    label: 'Topo'
  },
  {
    value: 'Left',
    label: 'Margem Esquerda'
  },
  {
    value: 'Right',
    label: 'Margem Direita'
  },
  {
    value: 'Bottom',
    label: 'Rodapé'
  }
]

const optionsLanguage = [
  {
    label: 'Português',
    value: 'PtBr'
  },
  {
    label: 'Inglês',
    value: 'EnUs'
  }
]

const content = {
  steps: {
    details: {
      label: 'Detalhes',
      form: {
        vault: {
          id: 'vault',
          type: 'selectVault',
          name: 'vault',
          label: 'Cofre',
          placeholder: 'Selecione o cofre de destino',
          readonly: true
        },
        name: {
          id: 'name',
          type: 'input',
          name: 'name',
          label: 'Nome do envelope'
        },
        message: {
          id: 'message',
          type: 'textarea',
          name: 'message',
          label: 'Mensagem',
          tooltipInfo: 'Mensagem',
          maxLength: 4000,
          required: false
        },
        reviewReminder: {
          id: 'reviewReminder',
          type: 'toggle',
          name: 'reviewReminder',
          label: 'Lembrete de assinatura',
          defaultValue: true,
          tooltipInfo: 'Lembrete de assinatura'
        },
        buttonShowAdvancedSettings: {
          id: 'buttonShowAdvancedSettings',
          type: 'button',
          name: 'buttonShowAdvancedSettings',
          label: 'Configurações'
        },
        toBePublished: {
          id: 'toBePublished',
          type: 'toggle',
          name: 'toBePublished',
          defaultValue: false,
          label: 'Agendar publicação',
          tooltipInfo: 'Agendar publicação'
        },
        toBePublishedAtUtc: {
          id: 'toBePublishedAtUtc',
          type: 'datepicker',
          name: 'toBePublishedAtUtc',
          label: 'Publicar em',
          placeholder: '00/00/0000',
          allowPastDates: false,
          nextDayStart: true
        },
        hasExpiration: {
          id: 'hasExpiration',
          type: 'toggle',
          name: 'hasExpiration',
          defaultValue: false,
          label: 'Possui expiração',
          tooltipInfo: 'Possui expiração'
        },
        expiresAtUtc: {
          id: 'expiresAtUtc',
          type: 'datepicker',
          name: 'expiresAtUtc',
          label: 'Expira em',
          placeholder: '00/00/0000',
          allowPastDates: false,
          nextDayStart: true
        },
        expirationReminder: {
          id: 'expirationReminder',
          type: 'toggle',
          name: 'expirationReminder',
          label: 'Lembrete de expiração',
          tooltipInfo: 'Lembrete de expiração',
          defaultValue: true
        },
        language: {
          id: 'language',
          type: 'select',
          name: 'language',
          label: 'Idioma',
          options: optionsLanguage
        },
        privateDescription: {
          id: 'privateDescription',
          type: 'textarea',
          name: 'privateDescription',
          label: 'Descrição privada',
          tooltipInfo: 'Descrição privada',
          maxLength: 4000,
          required: false
        },
        publicDescription: {
          id: 'publicDescription',
          type: 'textarea',
          name: 'publicDescription',
          label: 'Descrição pública',
          tooltipInfo: 'Descrição pública',
          maxLength: 4000,
          required: false
        },
        markupOrientation: {
          id: 'markupOrientation',
          type: 'select',
          name: 'markupOrientation',
          label: 'Orientação da marcação',
          tooltipInfo:
            'Serão inseridas marcações em todas as páginas dos documentos desse envelope, posicionadas no local escolhido, essas marcações irão conter informações sobre o envelope, como seu identificador único e instruções de como validá-lo, incluindo um link via qrcode.',
          options: optionsMarkupOrientation
        }
      },
      tooltipInfoIsSandbox:
        'Atenção: Este é um envelope criado em modo de testes, portanto nenhum e-mail de lembrete será enviado',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Avançar',
        dropdownOptions: [
          {
            label: 'Salvar',
            key: 'save'
          }
        ]
      }
    },
    signers: {
      label: 'Signatários',
      buttonAddSigner: {
        label: 'Adicionar signatário',
        dropdownOptions: [
          {
            key: 'addContacts',
            label: 'Adicionar de contatos'
          },
          {
            key: 'addMyself',
            label: 'Adicionar eu mesmo'
          }
        ]
      },
      table: {
        signers: 'Signatários',
        role: 'Papel: ',
        mfa: 'MFA',
        actions: 'Ações'
      },
      addSigner: 'Adicione signatários para enviar o envelope',
      emptyStateSigner: 'Nenhum signatário adicionado',
      labelToggleSignatureInOrder: 'Assinatura em ordem',
      buttonBack: 'Voltar',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Avançar',
        dropdownOptions: [
          {
            label: 'Salvar',
            key: 'save'
          }
        ]
      }
    },
    signatureMarker: {
      label: 'Assinaturas',
      selectLabel: 'Selecione um documento',
      selectPlaceholder: 'Selecione',
      buttonOpenDocument: 'Abrir documento',
      buttonBack: 'Voltar',
      buttonCreateSignMark: 'Adicionar marca de assinatura',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        labelPublish: 'Publicar',
        labelSchedule: 'Agendar publicação',
        dropdownOptions: [
          {
            label: 'Salvar',
            key: 'save'
          }
        ]
      }
    },
    documents: {
      label: 'Documentos',
      addDocuments: 'Selecionar documentos',
      fileSize: 'Tamanho máximo de 25MB por documento',
      removeAll: 'Remover todos',
      buttonSave: 'Salvar',
      errorSizeFile: 'O arquivo deve ser um pdf e ter no máximo 25MB',
      errorMaxFiles: 'Máximo de 10 documentos excedido',
      or: 'ou crie a partir de um modelo',
      buttonBack: 'Voltar',
      action: {
        id: '1',
        type: 'button',
        name: 'buttonSubmitAdvanced',
        label: 'Avançar',
        dropdownOptions: [
          {
            label: 'Salvar',
            key: 'save'
          }
        ]
      }
    }
  },
  signDocumentArea: {
    selectLabel: 'Selecione um documento',
    selectPlaceholder: 'Selecione',
    buttonCreateSignMark: 'Adicionar marca de assinatura'
  },
  modalActionSigner: {
    title: (action: 'create' | 'update') =>
      action === 'create' ? 'Adicionar signatário' : 'Atualizar signatário',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Nome',
        placeholder: 'Nome do signatário'
      },
      email: {
        id: 'email',
        type: 'input',
        name: 'email',
        label: 'E-mail',
        placeholder: 'E-mail do signatário'
      },
      role: {
        id: 'role',
        type: 'select',
        name: 'role',
        label: 'Papel',
        placeholder: 'Papel do signatário',
        options: optionsRoles,
        required: false
      },
      title: {
        id: 'title',
        type: 'input',
        name: 'title',
        label: 'Título',
        placeholder: 'Ex: Contratante',
        required: false
      },
      shouldEnforceEmailValidation: {
        id: 'shouldEnforceEmailValidation',
        type: 'toggle',
        name: 'shouldEnforceEmailValidation',
        defaultValue: true,
        label: 'Requerer autenticação por e-mail'
      },
      shouldEnforcePasscodeValidation: {
        id: 'shouldEnforcePasscodeValidation',
        type: 'toggle',
        name: 'shouldEnforcePasscodeValidation',
        defaultValue: false,
        label: 'Requerer autenticação por senha'
      },
      passcode: {
        id: 'passcode',
        type: 'input',
        name: 'passcode',
        label: 'Senha',
        placeholder: 'Senha do signatário'
      },
      passcodeHint: {
        id: 'passcodeHint',
        type: 'input',
        name: 'passcodeHint',
        label: 'Dica de senha',
        placeholder: 'Dica de senha do signatário',
        tooltipInfo: 'Dica de senha',
        maxLength: 100
      },
      shouldEnforceSmsValidation: {
        id: 'shouldEnforceSmsValidation',
        type: 'toggle',
        name: 'shouldEnforceSmsValidation',
        defaultValue: false,
        label: 'Requerer autenticação por SMS',
        tooltipInfoIcon: CurrencyDollarIcon,
        tooltipInfo: '1 crédito de autenticação',
        tooltipInfoPrefix:
          'O signatário precisará validar seu número de celular através de um código que irá receber no momento da assinatura do envelope, este é um método de autenticação avançado e tem o custo de 1 crédito de autenticação, que será cobrado no momento da publicação do envelope, ',
        tooltipInfoLink: 'clique aqui',
        tooltipInfoSuffix: ' para adquirir créditos de autenticação avançada.',
        tooltipLink: '/billing/plans?trigger=signer-mfa-credits'
      },
      phoneNumber: {
        id: 'phoneNumber',
        type: 'inputPhone',
        name: 'phoneNumber',
        label: 'Telefone',
        placeholder: 'Telefone do signatário'
      },
      buttonCancel: {
        id: 'buttonCancel',
        type: 'button',
        name: 'buttonCancel',
        label: 'Cancelar'
      },
      buttonSubmitSave: {
        id: 'buttonSubmitSave',
        type: 'button',
        name: 'buttonSubmitSave',
        label: 'Salvar'
      }
    }
  },
  role: 'Papel',
  defaultMessage: 'Clique no botão abaixo para revisar este envelope.',
  updateSignerTitle: 'Atualizar signatário',
  uploadArea: {
    labelDragDocuments: 'Ou arraste e solte os documentos aqui',
    buttonAddDocuments: 'Adicionar documentos',
    fileSize: 'Tamanho máximo de 25MB por documento',
    removeAll: 'Remover todos',
    buttonSave: 'Salvar',
    errorSizeFile: 'O arquivo deve ser um pdf e ter no máximo 25MB',
    errorMaxFiles: 'Máximo de 10 documentos excedido',
    or: 'ou crie a partir de um modelo',
    editDocumentName: 'Editar documento',
    removeDocument: 'Remover documento'
  },
  editInformationDocument: {
    title: 'Editar documento',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Nome',
        placeholder: 'Nome do documento'
      },
      privateDescription: {
        id: 'privateDescription',
        type: 'textarea',
        name: 'privateDescription',
        label: 'Descrição privada',
        placeholder: 'Descrição privada do documento',
        maxLength: 4000,
        tooltipInfo: 'Descrição privada',
        required: false
      },
      publicDescription: {
        id: 'publicDescription',
        type: 'textarea',
        name: 'publicDescription',
        label: 'Descrição pública',
        placeholder: 'Descrição pública do documento',
        maxLength: 4000,
        tooltipInfo: 'Descrição pública',
        required: false
      },
      buttonCancel: {
        id: 'buttonCancel',
        type: 'button',
        name: 'buttonCancel',
        label: 'Cancelar'
      },
      buttonSubmitSave: {
        id: 'buttonSubmitSave',
        type: 'button',
        name: 'buttonSubmitSave',
        label: 'Salvar'
      }
    }
  },
  marksTypes: {
    Rubric: 'Rúbrica',
    Signature: 'Assinatura'
  },
  modalConfirmErrorPublish: {
    Envelopes: {
      title: 'Erro ao publicar envelope',
      description: 'Você não possui mais envelopes para a publicação',
      confirmButtonText: 'Comprar envelopes',
      cancelButtonText: 'Cancelar'
    },
    SignerMfaCredits: {
      title: 'Erro ao publicar envelope',
      description:
        'Você não possui mais créditos de autenticação para a publicação',
      confirmButtonText: 'Comprar créditos',
      cancelButtonText: 'Cancelar'
    },
    ApiEnvelopes: {
      title: 'Erro ao publicar envelope',
      description: 'Você não possui envelopes de api para a publicação',
      confirmButtonText: 'Comprar envelopes de api',
      cancelButtonText: 'Cancelar'
    },
    PublicationDateBeforeNow: {
      title: 'Data de publicação inválida',
      description: 'A data de publicação não pode ser anterior a data atual',
      confirmButtonText: 'Atualizar data',
      cancelButtonText: 'Cancelar'
    },
    ExpirationDateBeforeNow: {
      title: 'Data de expiração inválida',
      description: 'A data de expiração não pode ser anterior a data atual',
      confirmButtonText: 'Atualizar data',
      cancelButtonText: 'Cancelar'
    },
    RemoveSchedule: {
      title: 'Remover agendamento de publicação?',
      description:
        'Para salvar o envelope, a data de agendamento de publicação será removida.',
      confirmButtonText: 'Remover e salvar',
      cancelButtonText: 'Cancelar'
    }
  },
  modalHelpErrorTooManyRequests: {
    title: 'Muitas requisições',
    description:
      'Houve um erro ao salvar seu envelope, entre em contato com a nossa equipe suporte',
    continueButtonText: 'Fechar'
  },
  modalErrorCreateEnvelope: {
    title: 'Erro ao criar envelope',
    description:
      'Houve um erro ao salvar seu envelope, entre em contato com a nossa equipe suporte',
    continueButtonText: 'Fechar'
  },
  toasts: {
    noName: 'Nome do envelope obrigatório',
    noVault: 'Cofre obrigatório',
    noDocuments: 'Nenhum documento adicionado',
    noSigners: 'Nenhum signatário adicionado',
    successAddMark: 'Marca adicionada com sucesso',
    successUpdateMark: 'Marca atualizada com sucesso',
    successDeleteMark: 'Marca excluída com sucesso',
    successPublishEnvelope: 'Envelope publicado com sucesso',
    errorPublishEnvelope: (
      shouldBuy: 'Envelopes' | 'SignerMfaCredits' | 'ApiEnvelopes'
    ) =>
      shouldBuy === 'Envelopes'
        ? 'Você não possui mais envelopes para a publicação'
        : shouldBuy === 'SignerMfaCredits'
          ? 'Você não possui mais créditos de autenticação para a publicação'
          : shouldBuy === 'ApiEnvelopes'
            ? 'Você não possui envelopes de api para a publicação'
            : 'Erro ao publicar envelope',
    successCreateEnvelope: 'Envelope criado com sucesso',
    errorCreateEnvelope:
      'Houve um erro ao salvar seu envelope, entre em contato com a nossa equipe suporte',
    errorGetVault: 'Erro ao buscar cofre',
    successUpdateEnvelope: 'Envelope atualizado com sucesso',
    errorUpdateEnvelope: 'Erro ao atualizar envelope',
    loadingUploadingFiles: 'Enviando arquivo(s)...',
    successUploadingFiles: 'Arquivo(s) enviado(s) com sucesso',
    errorUploadingFiles: 'Erro ao enviar arquivo(s)',
    loadingCreateDocumentWithTemplate: 'Criando documento...',
    successCreateDocumentWithTemplate: 'Documento criado com sucesso',
    errorCreateDocumentWithTemplate: 'Erro ao criar documento',
    errorTooManyRequests: 'Muitas requisições, tente novamente mais tarde'
  },
  tooltipInfo: {
    editSigner: 'Editar signatário',
    deleteSigner: 'Excluir signatário',
    successCreateEnvelope: 'Envelope criado com sucesso',
    errorCreateEnvelope: 'Erro ao criar envelope',
    tooltipLockClosed: (isLocked: boolean) =>
      isLocked
        ? 'Autenticação por senha ativa'
        : 'Autenticação por senha inativa',
    tooltipEnvelope: (isLocked: boolean) =>
      isLocked
        ? 'Autenticação por email ativo'
        : 'Autenticação por email inativo',
    tooltipDevicePhoneMobile: (isLocked: boolean) =>
      isLocked ? 'Autenticação por SMS ativa' : 'Autenticação por SMS inativa'
  },
  optionsRoles,
  popConfirmSigner: {
    title: 'Excluir signatário',
    description: 'Tem certeza que deseja excluir este signatário?',
    confirmButtonText: 'Excluir',
    cancelButtonText: 'Cancelar'
  },
  popConfirmDocumentAll: {
    title: 'Excluir documentos',
    description: 'Tem certeza que deseja excluir todos os documentos?',
    confirmButtonText: 'Excluir',
    cancelButtonText: 'Cancelar'
  },
  popConfirmDocument: {
    title: 'Excluir documento',
    description: 'Tem certeza que deseja excluir este documento?',
    confirmButtonText: 'Excluir',
    cancelButtonText: 'Cancelar'
  },
  sectionListTemplates: {
    title: 'Meus modelos',
    searchPlaceholder: 'Buscar modelo',
    searchPlaceholderUser: 'Buscar usuário',
    menuItems: {
      edit: 'Editar',
      delete: 'Remover'
    },
    createdIn: 'Criado em',
    emptyState: {
      title: 'Nenhum modelo encontrado',
      description: 'Crie um novo modelo e ele aparecerá aqui'
    },
    selectLabelUser: 'Selecione um usuário',
    searchLabelTemplates: 'Buscar modelo'
  },
  documentTemplateFiller: {
    titleInformationDocument: 'Informações do documento',
    titleFillInFields: 'Preencha os campos do formulário',
    buttonSubmit: 'Avançar',
    buttonCancel: 'Cancelar',
    buttonPreviewDocument: 'Visualizar documento',
    form: {
      name: {
        id: 'name',
        type: 'input',
        name: 'name',
        label: 'Nome',
        placeholder: 'Nome do documento'
      },
      privateDescription: {
        id: 'privateDescription',
        type: 'textarea',
        name: 'privateDescription',
        label: 'Descrição privada',
        placeholder: 'Descrição privada do documento',
        maxLength: 4000,
        required: false
      },
      publicDescription: {
        id: 'publicDescription',
        type: 'textarea',
        name: 'publicDescription',
        label: 'Descrição pública',
        placeholder: 'Descrição pública do documento',
        maxLength: 4000,
        required: false
      },
      language: {
        id: 'language',
        type: 'select',
        name: 'language',
        label: 'Idioma',
        options: optionsLanguage
      },
      markupOrientation: {
        id: 'markupOrientation',
        type: 'select',
        name: 'markupOrientation',
        label: 'Orientação da marcação',
        options: optionsMarkupOrientation
      }
    }
  },
  modalHelpAboutVault: {
    title: 'O Cofre é obrigatório',
    description:
      'Para salvar um envelope é necessário selecionar um cofre de destino. Caso não tenha um cofre, crie um novo ou selecione um existente.',
    confirmButtonText: 'Criar cofre'
  }
}

export default content
