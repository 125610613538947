import React, { useEffect } from 'react'
import { EditorContent } from '@tiptap/react'
import { useTemplate } from '@/providers/Template'
import { cn } from '@/lib/utils'
import { ActionFieldType } from '@/providers/Template/Template.types'
import { TemplateEditorSkeleton } from '@/ui/atoms/index'
import { MenuTemplateEditor } from '@/ui/molecules/index'

type Props = {
  readonly?: boolean
}

const TemplateEditor: React.FC<Props> = ({ readonly }) => {
  const { editor, setDataDrawerActionField, setEditable, loadingEditor } =
    useTemplate()

  useEffect(() => {
    if (readonly) {
      setEditable(false)
    }
  }, [readonly, setEditable])

  const handleClickEditor = (e: any) => {
    if (
      e?.target &&
      e?.target?.nodeName === 'SPAN' &&
      e?.target?.getAttribute('data-field') === 'true' &&
      e?.target?.getAttribute('alias')
    ) {
      if (e.target.getAttribute('alias')?.length > 0) {
        setDataDrawerActionField({
          open: true,
          action: ActionFieldType.EDIT,
          alias: e.target.getAttribute('alias')
        })
      }
    }
  }

  if (!editor) {
    return null
  }

  return (
    <div className="relative flex flex-col items-center flex-1 w-full h-auto p-1">
      {!readonly && <MenuTemplateEditor />}
      <div className={cn('relative flex flex-col', !readonly && 'pt-2')}>
        {loadingEditor ? (
          <TemplateEditorSkeleton />
        ) : (
          <div onClick={handleClickEditor}>
            <EditorContent
              className={cn('bg-white', readonly && 'readonly-tiptap')}
              editor={editor}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplateEditor
