import { useCallback, useMemo, useState } from 'react'
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { CustomColumn } from '@/types/react-table-config'
import { Typography } from '@/atoms/index'
import {
  FiltersContactsType,
  UseContactsDataResponse
} from './useContactsModalCreateSignerData.types'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useContactApi from '@/hooks/api/ecm/useContactApi/useContactApi'
import { Contact } from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  onAddContact: (contact: Contact) => void
  onClose: () => void
}

const useContactsModalCreateSignerData = ({
  onAddContact,
  onClose
}: Props): UseContactsDataResponse => {
  const [filtersContacts, setFiltersContacts] = useState<FiltersContactsType>({
    query: null,
    typeFavorite: null
  })

  const { t } = useTranslation('contacts')

  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable(5)
  const { breakpoint } = useViewport()

  const { useUserAccountContacts, useActionFavoriteContact } = useContactApi()

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isSuccess: isSuccessContacts,
    isFetching: isFetchingContacts,
    isError: isErrorContacts,
    error: errorContacts
  } = useUserAccountContacts(pageNumber, pageSize, filtersContacts)
  const { mutateAsync: mutateAsyncActionFavoriteContact } =
    useActionFavoriteContact()

  const isLoadingUserAccountContacts = useMemo(() => {
    return isFetchingContacts || isLoadingContacts
  }, [isFetchingContacts, isLoadingContacts])

  const currentDataTable = useMemo(() => {
    return {
      data: contacts,
      isLoading: isLoadingContacts || isFetchingContacts,
      isSuccess: isSuccessContacts,
      isErrorContacts,
      errorContacts: errorContacts
    }
  }, [
    contacts,
    errorContacts,
    isErrorContacts,
    isFetchingContacts,
    isLoadingContacts,
    isSuccessContacts
  ])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      isFavorite: { width: '5%', minWidth: '75px' },
      name: { width: '50%', minWidth: '150px' },
      email: { width: '35%', minWidth: '100px' },
      actions: { width: '10%', minWidth: '50px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    return Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<Contact>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.isFavorite || '',
        accessor: 'isFavorite',
        Cell: ({ getValue, row }) => {
          const [isFavorite, setIsFavorite] = useState<boolean>(
            getValue('isFavorite')
          )
          const original = row.original

          const toggleFavorite = () => {
            const newIsFavorite = !isFavorite
            setIsFavorite(newIsFavorite)

            try {
              mutateAsyncActionFavoriteContact({
                id: original.id,
                action: newIsFavorite ? 'favorite' : 'unfavorite'
              })
            } catch {
              showToast.error(t?.toasts?.errorFavorite)
              setIsFavorite(isFavorite)
              return
            } finally {
              showToast.success(
                newIsFavorite
                  ? t?.toasts?.successFavorite
                  : t?.toasts?.successUnfavorite
              )
            }
          }

          return (
            <div className="flex flex-col flex-1">
              <button
                type="button"
                className={`p-4 text-sm font-medium rounded-md text-primary-700`}
                onClick={toggleFavorite}
              >
                {isFavorite ? (
                  <StarIconSolid className="w-5 h-5" />
                ) : (
                  <StarIconOutline className="w-5 h-5" />
                )}
              </button>
            </div>
          )
        },
        ...tableColumnWidth.isFavorite
      },
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer"
              onClick={() => {
                onAddContact && onAddContact(valuesOriginal)
              }}
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.email || '',
        accessor: 'email',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer"
              onClick={() => {
                onAddContact && onAddContact(valuesOriginal)
              }}
            >
              <Typography variant="text-sm-medium">
                {valuesOriginal.email}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.email
      },
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <Button
              variant="outlineSecondary"
              size="sm"
              className="px-2 py-1 text-xs whitespace-nowrap"
              onClick={() => {
                onClose && onClose()
                onAddContact && onAddContact(valuesOriginal)
              }}
            >
              {t?.tableInformation?.buttonSelectContact}
            </Button>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    mutateAsyncActionFavoriteContact,
    onAddContact,
    onClose,
    t?.tableInformation?.action,
    t?.tableInformation?.buttonSelectContact,
    t?.tableInformation?.email,
    t?.tableInformation?.isFavorite,
    t?.tableInformation?.name,
    t?.toasts?.errorFavorite,
    t?.toasts?.successFavorite,
    t?.toasts?.successUnfavorite,
    tableColumnWidth.actions,
    tableColumnWidth.email,
    tableColumnWidth.isFavorite,
    tableColumnWidth.name
  ])

  const handleToggleFavorite = useCallback(
    (typeFavorite: 'favorite' | 'unfavorite' | null) => {
      if (isLoadingUserAccountContacts) return
      setFiltersContacts({
        ...filtersContacts,
        typeFavorite: typeFavorite
      })
    },
    [filtersContacts, isLoadingUserAccountContacts]
  )

  const handleChangeQueryFilter = useCallback(
    (query: string | null) => {
      setFiltersContacts({
        ...filtersContacts,
        query
      })
    },
    [filtersContacts]
  )

  return {
    currentDataTable,
    columns,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    isLoadingUserAccountContacts,
    filtersContacts,
    handleChangeQueryFilter,
    handleToggleFavorite
  }
}

export default useContactsModalCreateSignerData
