import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { PlanType } from '@/types/global'
import { IconWithTooltip, Modal } from '..'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { useLocale, usePlans } from '@/providers'
import { ControlButtons, Toggle, Typography } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { formatCurrency } from '@/utils'

const ModalSelectFeaturesBeforePurchase: React.FC = () => {
  const { t, isReady } = useTranslation('plans')
  const [values, setValues] = useState({
    counterUsers: 1,
    apiAddon: true,
    whiteLabelAddon: true
  })

  const { useBillingCreateCheckoutSubscription } = useBillingApi()
  const {
    mutateAsync: mutateAsyncBillingCreateCheckoutSubscription,
    isLoading: isLoadingBillingCreateCheckoutSubscription
  } = useBillingCreateCheckoutSubscription()
  const { push } = useRouter()
  const { dataModal, resetModal, plans } = usePlans()
  const { lang } = useLocale()

  const handleCallback = useCallback(
    (value: number) => {
      setValues({ ...values, counterUsers: value })
    },
    [values]
  )

  const minUsers = useMemo(() => {
    return {
      One: 1,
      Pro: 5,
      Max: 10
    }[dataModal?.planType as PlanType]
  }, [dataModal?.planType])

  const pricesAddon = useMemo(() => {
    const apiAddon = plans?.find((plan) => plan.planId === dataModal?.planId)
      ?.pricesAddons?.apiAddon as number
    const whiteLabelAddon = plans?.find(
      (plan) => plan.planId === dataModal?.planId
    )?.pricesAddons?.whiteLabelAddon as number
    return {
      apiAddon: formatCurrency(apiAddon, lang, true),
      whiteLabelAddon: formatCurrency(whiteLabelAddon, lang, true)
    }
  }, [plans, lang, dataModal?.planId])

  const baseUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      const currentDomain = `${window.location.protocol}//${window.location.hostname}`
      if (currentDomain === 'http://localhost') {
        return process.env.NEXT_PUBLIC_APP_URL as string
      }
      return currentDomain
    }
  }, [])

  return (
    <Modal
      title={t?.modalSelectFeaturesBeforePurchase?.title}
      isOpen={dataModal?.isOpen as boolean}
      onClose={resetModal}
      displayAsDrawerOnMobile
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="flex">
            <Typography
              variant="text-sm-medium"
              className="text-gray-700"
              showSkeleton={!isReady}
            >
              {t?.modalSelectFeaturesBeforePurchase?.labelCounterUsers}
            </Typography>
            <IconWithTooltip
              icon={
                <InformationCircleIcon className="w-4 h-4 ml-1 text-primary" />
              }
              tooltipText={t?.modalSelectFeaturesBeforePurchase?.tooltips?.counterUsersMin(
                minUsers
              )}
            />
          </div>
          <ControlButtons onCallback={handleCallback} min={minUsers} />
        </div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="text-sm-medium"
            className="text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.modalSelectFeaturesBeforePurchase?.labelToggleApiAddon}
          </Typography>
          <div className="flex items-center gap-1">
            <Toggle
              name="apiAddon"
              checked={values.apiAddon}
              onChange={(checked) =>
                setValues({ ...values, apiAddon: checked })
              }
              showSkeleton={!isReady}
            />
            <Typography variant="text-sm-regular" className="text-gray-700">
              {pricesAddon.apiAddon}
              {t?.modalSelectFeaturesBeforePurchase?.suffixPerUser?.(
                dataModal?.recurrence
              )}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="text-sm-medium"
            className="text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.modalSelectFeaturesBeforePurchase?.labelToggleWhiteLabelAddon}
          </Typography>
          <div className="flex items-center gap-1">
            <Toggle
              name="whiteLabelAddon"
              checked={values.whiteLabelAddon}
              onChange={(checked) =>
                setValues({ ...values, whiteLabelAddon: checked })
              }
              showSkeleton={!isReady}
            />
            <Typography variant="text-sm-regular" className="text-gray-700">
              {pricesAddon.whiteLabelAddon}
              {t?.modalSelectFeaturesBeforePurchase?.suffixPerUser?.(
                dataModal?.recurrence
              )}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2 pt-5 mt-5 border-t border-gray-300">
        <Button
          onClick={resetModal}
          variant="neutral"
          type="button"
          showSkeleton={!isReady}
        >
          {t?.modalSelectFeaturesBeforePurchase?.buttonCancel}
        </Button>
        <Button
          onClick={() => {
            try {
              mutateAsyncBillingCreateCheckoutSubscription({
                planId: dataModal?.planId as string,
                includeApiAddon: values.apiAddon,
                includeWhiteLabelAddon: values.whiteLabelAddon,
                userSeats: values.counterUsers,
                userSeatType: dataModal?.planType as PlanType,
                cancelUrl:
                  `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_CANCEL_URL_SIGNER_MFA}` as string,
                successUrl:
                  `${baseUrl}${process.env.NEXT_PUBLIC_BILLING_REDIRECT_SUCCESS_URL_SIGNER_MFA}` as string
              }).then((data) => {
                push(data?.url as string)
              })
            } catch {
              showToast.error(
                t?.modalSelectFeaturesBeforePurchase?.toasts?.error
              )
            }
          }}
          type="button"
          showSkeleton={!isReady}
          loading={isLoadingBillingCreateCheckoutSubscription}
        >
          {t?.modalSelectFeaturesBeforePurchase?.buttonConfirm}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalSelectFeaturesBeforePurchase
