import { useCallback, useMemo, useState } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Typography } from '@/atoms/index'
import { Dropdown, type MenuItem } from '@/atoms/index'
import {
  TemplateFormType,
  DataModalActionTemplateType,
  UseTemplatesDataResponse,
  UseTemplatesDataProps,
  FiltersTemplatesType
} from './useTemplatesData.types'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useTemplatesApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { Template } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import { useAuth } from '@/providers'
import { showToast } from '@/ui/atoms/index'
import { Badge } from '@/ui/atoms/shadcn'

const useTemplatesData = ({
  type = 'default'
}: UseTemplatesDataProps): UseTemplatesDataResponse => {
  const { t } = useTranslation('templates')
  const { isAdmin, userContext } = useAuth()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [dataModalActionTemplate, setDataModalActionTemplate] =
    useState<DataModalActionTemplateType>({
      action: null,
      id: null
    })
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable(
    type === 'envelopeListTemplate' ? 12 : 10
  )
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const { push } = useRouter()

  const {
    useTemplateListAccountUsers,
    useDeleteTemplate,
    useTemplateOwners,
    useMoveTemplate
  } = useTemplatesApi()
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersTemplatesType>({})

  const fetchAll = useMemo(
    () => isAdmin && filters?.user === 'all',
    [filters?.user, isAdmin]
  )
  const fetchMe = useMemo(
    () =>
      filters?.user === userContext?.userAccountInformation?.id ||
      !filters?.user,
    [filters?.user, userContext]
  )

  const { data: dataTemplateOwners, isLoading: isLoadingTemplateOwners } =
    useTemplateOwners(!!isAdmin)
  const {
    data: dataListTemplates,
    isLoading: isLoadingListTemplates,
    isSuccess: isSuccessListTemplates,
    isFetching: isFetchingListTemplates
  } = useTemplateListAccountUsers(
    pageNumber,
    pageSize,
    filters?.query || null,
    fetchMe ? null : filters?.user,
    fetchAll ?? false,
    isReady
  )
  const {
    mutateAsync: mutateAsyncDeleteTemplate,
    isLoading: isLoadingDeleteTemplate
  } = useDeleteTemplate()
  const { mutateAsync: mutateAsyncMoveTemplate } = useMoveTemplate()

  const currentData = useMemo(() => {
    return {
      data: dataListTemplates,
      isLoading: isLoadingListTemplates || isFetchingListTemplates,
      isSuccess: isSuccessListTemplates
    }
  }, [
    dataListTemplates,
    isFetchingListTemplates,
    isLoadingListTemplates,
    isSuccessListTemplates
  ])

  const optionsUser = useCreateOptionsUser({
    users: dataTemplateOwners?.items as any[]
  })

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '25%', minWidth: '130px' },
      fields: { width: '20%', minWidth: '120px' },
      create: { width: '25%', minWidth: '130px' },
      update: { width: '25%', minWidth: '130px' },
      actions: { width: '5%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    return Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<Template>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <Link href={`/template/${valuesOriginal.id}`}>
              <a className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer">
                <Typography
                  variant="text-sm-medium"
                  className="hover:text-gray-500"
                >
                  {valuesOriginal.name}
                </Typography>
              </a>
            </Link>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.fields || '',
        accessor: 'fields',
        Cell: ({ getValue }) => {
          const value = getValue('fields')

          return (
            <Badge
              size="sm"
              className="bg-blue-100 text-info-600"
              tooltip={value}
            >
              {t?.tableInformation?.fieldsBadge(value)}
            </Badge>
          )
        },
        ...tableColumnWidth.fields
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <div>
                <Avatar
                  imgSrc={valuesOriginal.createdByAvatar}
                  name={valuesOriginal.createdByName ?? ''}
                />
              </div>
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.update || '',
        accessor: 'updatedByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.updatedByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.updatedByAvatar}
                name={valuesOriginal.updatedByName ?? ''}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.update
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'createEnvelope',
              label: t?.options?.createEnvelope
            },
            {
              key: 'edit',
              label: t?.options?.edit
            },
            {
              key: 'move',
              label: t?.options?.move
            },
            {
              key: 'rename',
              label: t?.options?.rename
            },
            {
              key: 'delete',
              label: t?.options?.delete
            },
            {
              key: 'goToVault',
              label: t?.options?.goToVault
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'createEnvelope') {
                  mixpanel.track('Create envelope', { origin: 'from-template' })
                  push(`/envelope?templateId=${valuesOriginal.id}`)
                }
                if (state.key === 'edit') {
                  push(`/template/${valuesOriginal.id}`)
                }
                if (state.key === 'move') {
                  setDataModalActionTemplate({
                    action: 'move',
                    id: valuesOriginal.id
                  })
                  setIsOpenModal(true)
                }
                if (state.key === 'rename') {
                  setDataModalActionTemplate({
                    action: 'rename',
                    id: valuesOriginal.id
                  })
                  setIsOpenModal(true)
                }
                if (state.key === 'delete') {
                  setDataModalActionTemplate({
                    action: 'delete',
                    id: valuesOriginal.id
                  })
                  setIsOpenModal(true)
                }
                if (state.key === 'goToVault') {
                  push(`/vaults/${valuesOriginal.vaultId}`)
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    push,
    t?.options?.createEnvelope,
    t?.options?.delete,
    t?.options?.edit,
    t?.options?.goToVault,
    t?.options?.move,
    t?.options?.rename,
    t?.tableInformation,
    tableColumnWidth.actions,
    tableColumnWidth.create,
    tableColumnWidth.fields,
    tableColumnWidth.name,
    tableColumnWidth.update
  ])

  const onSubmitActionModal = useCallback(
    async (
      values: TemplateFormType,
      action: DataModalActionTemplateType['action']
    ) => {
      console.log('values', values, 'action', action)
    },
    []
  )

  const handleDeleteTemplate = useCallback(async () => {
    try {
      await mutateAsyncDeleteTemplate(dataModalActionTemplate?.id as string)

      setDataModalActionTemplate({
        action: null,
        id: null
      })
      setIsOpenModal(false)

      showToast.success(t?.toasts?.successDelete)
    } catch {
      showToast.error(t?.toasts?.errorDelete)
      setDataModalActionTemplate({
        action: null,
        id: null
      })
      setIsOpenModal(false)
    }
  }, [
    dataModalActionTemplate?.id,
    mutateAsyncDeleteTemplate,
    t?.toasts?.errorDelete,
    t?.toasts?.successDelete
  ])

  const handleSearchVaultCallback = useCallback(
    async (
      {
        vaultId
      }: {
        vaultId: string
      },
      templateId: string
    ) => {
      try {
        await mutateAsyncMoveTemplate({ id: templateId, vaultId })
        setDataModalActionTemplate({
          action: null,
          id: null
        })
        setIsOpenModal(false)
      } catch {
        setDataModalActionTemplate({
          action: null,
          id: null
        })
        setIsOpenModal(false)
      }
    },
    [mutateAsyncMoveTemplate]
  )

  const loadingSubmit = useMemo(() => {
    return isLoadingDeleteTemplate
  }, [isLoadingDeleteTemplate])

  return {
    currentData,
    columns,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    onSubmitActionModal,
    dataModalActionTemplate,
    setDataModalActionTemplate,
    isOpenModal,
    setIsOpenModal,
    handleDeleteTemplate,
    optionsUser,
    handleSearchVaultCallback,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isLoadingTemplateOwners,
    loadingSubmit
  }
}

export default useTemplatesData
