import React from 'react'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import CardEnvelope from '../CardEnvelope'
import Timeline from '../Timeline'

const SectionReviewEnvelopeInfo: React.FC = () => {
  const {
    sectionEnvelopeInfo,
    sectionEnvelopeViewDocuments,
    sectionEnvelopeSigners,
    sectionEnvelopeTimeline,
    isLoadingSkeleton
  } = useReviewEnvelope()

  return (
    <div className="flex flex-col flex-1 gap-5">
      <CardEnvelope
        title={sectionEnvelopeInfo?.title}
        items={sectionEnvelopeInfo?.items}
        showSkeleton={isLoadingSkeleton}
        classNameSkeleton="h-80"
      />
      <CardEnvelope
        title={sectionEnvelopeViewDocuments?.title}
        items={sectionEnvelopeViewDocuments?.items}
        emptyState={sectionEnvelopeViewDocuments?.emptyState}
        emptyStateTitle={sectionEnvelopeViewDocuments?.emptyStateTitle}
        emptyStateMessage={sectionEnvelopeViewDocuments?.emptyStateMessage}
        showSkeleton={isLoadingSkeleton}
        classNameSkeleton="h-40"
      />
      <CardEnvelope
        title={sectionEnvelopeSigners?.title}
        items={sectionEnvelopeSigners?.items}
        emptyState={sectionEnvelopeSigners?.emptyState}
        emptyStateTitle={sectionEnvelopeSigners?.emptyStateTitle}
        emptyStateMessage={sectionEnvelopeSigners?.emptyStateMessage}
        showSkeleton={isLoadingSkeleton}
        classNameRow="flex-col w-full flex-1 items-start justify-start sm:flex-row sm:items-center sm:justify-between"
        classNameSkeleton="h-40"
      />
      <CardEnvelope
        title={sectionEnvelopeTimeline?.title}
        emptyState={sectionEnvelopeTimeline?.emptyState}
        emptyStateTitle={sectionEnvelopeTimeline?.emptyStateTitle}
        emptyStateMessage={sectionEnvelopeTimeline?.emptyStateMessage}
        showSkeleton={isLoadingSkeleton}
        classNameSkeleton="h-80"
      >
        <Timeline items={sectionEnvelopeTimeline?.items} />
      </CardEnvelope>
    </div>
  )
}

export default SectionReviewEnvelopeInfo
